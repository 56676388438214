import ErrorMessage from "@pages/ErrorMessage"
import Landing from "@pages/Landing"
import MobileSites from "@pages/MobileSites"
import Overview from "@pages/Overview"
import Landsec from "@pages/Landsec"


const mobileRoutes = [
  {
    name: "Introduction",
    path: "/", 
    onNav: true,
    element: Landing,
  },
  {
    name: "Landsec",
    path: "/landsec", 
    onNav: true,
    element: Landsec,
  },
  {
    name: "Map",
    path: "/map", 
    onNav: true,
    element: Overview,
  },
  {
    name: "Seed Portfolio",
    path: "/sites", 
    onNav: true,
    element: MobileSites,
  },
  {
    name: "Seed Portfolio",
    path: "/sites/:id", 
    onNav: false,
    element: MobileSites,
  },
  { 
    path: "/*", 
    name: "Not Found", 
    onNav: false, 
    element: ErrorMessage 
  },
]

export default mobileRoutes
