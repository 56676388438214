import { useEffect, createContext } from "react"

import DefaultLayout from "./DefaultLayout"
import MobileLayout from "./MobileLayout"

import { useStore } from "@state/store"
import verify from "@utils/verify"


export const AuthContext = createContext(null);

function App() {
  const tokenIsValid = useStore(s => s.tokenIsValid)
  const setTokenIsValid = useStore(s => s.setTokenIsValid)
  const isMobile = useStore((s) => s.isMobile)
  const setIsMobile = useStore((s) => s.setIsMobile)
  function getIsMobile() {
    const width = innerWidth < 700
    const portrait = window.matchMedia("(orientation: portrait)").matches
    const isMobile = width || portrait 
    return isMobile
  }

  useEffect(() => {
    setIsMobile(getIsMobile())
    addEventListener("resize", () => {
      setIsMobile(getIsMobile())
    })
    verify(
      () => setTokenIsValid(true),
      () => setTokenIsValid(false),
    )
  }, [])
  return (
    <AuthContext.Provider value={tokenIsValid}>
      {isMobile ? <MobileLayout /> : <DefaultLayout />}
      {/* <div style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: "999999999999",
        fontSize: "20rem",
        color: "red"
      }}>
        {isMobile.toString()}
      </div> */}
    </AuthContext.Provider>
  )
}

export default App
