import { useEffect } from "react"
import styled from "styled-components"
import { useParams, useNavigate } from "react-router-dom"

import Logo from "@components/SVG/Logo/Logo"

import verify from "@utils/verify"
import { useStore } from "@state/store"
import globalStyles from "@data/globalStyles"
import data from "@data/sitesData"

function SitesMenu() {
  const setTokenIsValid = useStore((s) => s.setTokenIsValid)
  const activeSite = useStore((s) => s.activeSite)
  const setActiveSite = useStore((s) => s.setActiveSite)
  const { id } = useParams()
  const navigate = useNavigate()

  function handleNav(path) {
    verify(
      () => {
        navigate(path)
      },
      () => {
        setTokenIsValid(false)
        navigate("/login")
      }
    )
  }

  useEffect(() => {
      setActiveSite(id ? Number(id) : null)
  }, [])

  return (
    <MenuWrapper>
      <Logo
        className="landsec"
        textColor={globalStyles.colors.white}
        onClick={() => {
          handleNav("/landsec")
        }}
      />
      <Button
        style={{
          opacity: null === activeSite ? 1 : 0.4,
        }}
        onClick={() => {
          setActiveSite(null)
          handleNav(`/sites`)
        }}
      >
        <p>SEED</p>
        <p>PORTFOLIO</p>
      </Button>
      {data.map((obj, i) => {
        return (
          <Button
            key={`btn${i}`}
            style={{
              opacity: i === activeSite ? 1 : 0.4,
            }}
            onClick={() => {
              setActiveSite(i)
              handleNav(`/sites/${i}`)
            }}
          >
            {obj.name.map((txt, i) => {
              return <p key={`btnP${i}`}>{txt}</p>
            })}
          </Button>
        )
      })}
    </MenuWrapper>
  )
}

export default SitesMenu

const MenuWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 12%;
  padding-right: 7.5%;
  background-color: ${globalStyles.colors.lightBlack};
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom: 0.2rem solid ${globalStyles.colors.blue};

  .landsec {
    width: 8rem;
    cursor: pointer;
  }
`

const Button = styled.button`
  background: none;
  border: none;
  color: ${globalStyles.colors.blue};
  transition: opacity 0.8s ease-in-out;
  cursor: pointer;
  font-size: 2rem;
`
