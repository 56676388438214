import { useEffect, useState, Fragment } from "react"
import { useLocation } from "react-router-dom"

import BurgerIcon from "@components/BurgerIcon/BurgerIcon"
import NavMenu from "./NavMenu/NavMenu"

import { useStore } from "@state/store"

function Nav() {
  const isMobile = useStore(s => s.isMobile)
  const { pathname } = useLocation()
  const [navVisible, setNavVisible] = useState(false)

  function handleClick() {
    setNavVisible(!navVisible)
  }

  useEffect(() => {
    setTimeout(
      () => {
        setNavVisible(false)
      },
      pathname === "/" ? 0 : 700
    )
  }, [pathname])

  return (
    <Fragment>
      <NavMenu open={navVisible} setOpen={setNavVisible} />
      <BurgerIcon
        width={isMobile ? "30rem" : "6vh"}
        style={{
          top: "6%",
          right: "2.5rem",
          transition: "opacity ease 0.5s",
          opacity: pathname === "/" ? 0 : 1,
          transform: "translateY(-50%)"
        }}
        isAnimated
        cue={navVisible}
        onClick={handleClick}
      />
    </Fragment>
  )
}

export default Nav
