import styled from "styled-components"

function City({ cue }) {
  return (
    <CityWrapper
      style={{
        opacity: cue ? 0.3 : 1,
      }}
    >
      <path
        fill="#215762"
        stroke="#1B606B50"
        strokeWidth={15}
        d="M1920,310.43v154.86c-7.14,8.63-10.16,20.45-10.73,33.31c-0.59,13.49-0.85,27.11-3.16,40.34
				c-1.39,7.93-4.98,16.46-10.16,22.51c-8.14,9.5-10.66,19.3-8.2,31.01c-13.3,4.04-26.75,7.66-39.87,12.21
				c-21.74,7.55-38.57,23.27-54.09,39.52c-15.13,15.85-30.29,33.62-44.51,48.42c-20.91,21.77-45.02,26.64-72.28,13.7
				c-21.01-9.99-41.02-22.08-61.9-32.37c-17.93-8.83-36.48-16.42-54.74-24.58c-2.42-1.08-4.82-2.19-7.23-3.32l-0.03-0.01
				l-11.46-3.26c-33.99-7.73-67.78-16.5-102-22.93c-46.36-8.71-90.8-23.88-135.91-36.93c-24.01-6.95-45.17-9.94-69.69-15.16
				c-1.5-22.64-3.85-45-5.5-67.63c0-0.01,0.22-4.43,0.22-4.44c-0.22-20.89-1.67-47.91-2.65-55.56c-0.36-4.89-0.82-13.94-1.21-18.61
				c-0.15-1.75-0.43-3.62-1.21-5.14c-6.81-13.18-13.84-26.24-20.59-39.44c-1.87-3.65-3.77-4.44-7.95-3.28
				c-12.52,3.47-25.14,6.58-38.29,9.85c-4.4,1.1-8.21-3.15-6.7-7.42c0.15-0.43,0.29-0.86,0.43-1.29
				c3.69-11.45,7.87-22.81,10.59-34.49c1.9-8.12,5.16-15.08,10.22-21.54c5.97-7.59,11.42-15.59,17.45-23.13
				c3.2-3.98,3.48-7.42,1.52-12.18c-8.38-20.32-16.32-40.82-24.39-61.27c-0.01-0.02-0.01-0.03-0.02-0.05
				c-1.31-3.34,0.85-7.03,4.39-7.61c3.19-0.53,6.29-1.02,9.41-1.42c8.24-1.05,16.49-1.99,24.74-2.84c3.83-0.4,5.75-2.18,5.45-6.12
				c-0.07-0.9,0.03-1.8,0.04-2.7c0.19-10.57,4.43-16.35,14.63-19.02c1.86-0.49,4.39,0.47,6.28,1.42
				c57.54,28.83,115.03,57.78,172.62,86.54c2.84,1.41,6.46,1.84,9.7,1.76c6.4-0.17,12.5-3.12,18.4,3.66
				c2.22,2.55,9.85,0.79,14.97,0.36c18.35-1.53,36.68-3.34,55.02-5.01c9.17-0.83,18.38-1.31,27.52-2.43
				c9.5-1.17,10.18-0.86,11.31,8.69c0.18,1.6,0.4,3.19,0.64,4.84c0.01,0.98-0.07,1.93-0.21,2.83
				c-1.58,10.51-11.95,16.07-11.95,16.07c3.29,16.94,7.05,33.63,9.58,50.5c1.17,7.73,4.57,10.73,11.81,10.39
				c23.28-1.07,46.6-1.59,69.81-3.45c23.15-1.86,46.23-4.71,69.26-7.77c31.83-4.23,63.38-9.94,94.27-19.05
				c41.94-12.37,84.01-24.31,126.78-36.65c2.3,3.45,4.52,6.34,6.26,9.48C1917.79,306.55,1918.89,308.49,1920,310.43z"
      />
    </CityWrapper>
  )
}

export default City

const CityWrapper = styled.g``
