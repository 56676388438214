//@ts-nocheck
import styled from "styled-components"
import globalStyles from "@data/globalStyles"

export default function Detail({ cue }) {
  const roadsColor = "#66797c"
  return (
    <DetailWrapper
      style={{
        pointerEvents: cue ? "auto" : "none",
        opacity: cue ? 1 : 0,
        transitionDelay: cue ? "0.8s" : "0s",
      }}
    >
      <g>
        <path
          fill="#6FB274"
          d="M1070.3,787.3c0-4.6-0.1-19.9,0-31c0-1.2,1.2-2,2.3-1.6l22.1,8.4c0.9,0.3,1.3,1.3,1,2.2
		c-1.6,4.3-6,16.7-8.2,25.6c-0.2,0.9-1.1,1.4-2,1.3c-6.1-1.3-11.6-2.6-13.9-3.1C1070.8,788.8,1070.3,788.1,1070.3,787.3z"
        />
        <polygon
          fill="#6FB274"
          points="1291.7,801.3 1306.3,801.4 1306.2,813.9 1291.7,813.9 	"
        />
        <path
          fill="#6FB274"
          d="M1225.4,832.3l6.3,7.1l8.3-7.2l-3.4-6.6c-0.3-0.5-0.9-0.7-1.3-0.4l-9.7,5.6
		C1225.1,831.2,1225,831.9,1225.4,832.3z"
        />
        <path
          fill="#6FB274"
          d="M1191.5,846.9l22.6,12c2,1.1,4.2,1.7,6.5,1.8l0,0l-20.2-34.4C1200.3,826.2,1192.2,829.4,1191.5,846.9z"
        />
        <path
          fill="#6FB274"
          d="M1178.2,858.5l3.9-7c0.1-0.2,0.1-0.5,0-0.7l-0.5-1c-0.1-0.3-0.1-0.6,0.1-0.8l4.1-3.8c0.3-0.2,0.6-0.2,0.9,0
		l2.4,2l-1.4,11.8L1178.2,858.5z"
        />
        <polygon
          fill="#6FB274"
          points="1197.5,815.6 1204.9,809.7 1207.2,820 1201.2,824.4 	"
        />
        <path
          fill="#6FB274"
          d="M1249.7,726.2l-7.1,2c-0.7,0.2-1,1.1-0.6,1.7l10.9,14.3L1249.7,726.2z"
        />
        <polygon
          fill="#6FB274"
          points="1448.5,782.4 1453.9,785.3 1449.9,793.9 1444.2,791.4 	"
        />
        <path
          fill="#6FB274"
          d="M1428,803.8l-2.4,6c-0.2,0.5,0,1.1,0.5,1.4l6.4,3.7l3.7-7.6L1428,803.8z"
        />
        <path
          fill="#6FB274"
          d="M1384.5,829.9l12.2,6.6c0.5,0.3,1.1,0.1,1.4-0.4l2.1-4l8.2,4.4l4.6-10.6c0,0-3.7-7.1-4.1-9.5s0-6.5,0-6.5
		s-7,4.6-9.4,5.8s-4.8,0.7-6.5,1.9s-3.8,2.7-4.7,4.6S1384.5,829.9,1384.5,829.9z"
        />
        <path
          fill="#6FB274"
          d="M1376.7,807.9l10.7,0.8l1.1-3.3c0.1-0.4,0-0.8-0.2-1c-0.5-0.5-1.2-1.5-1-3.6c-6.4,0-9.7,0-9.7,0
		L1376.7,807.9z"
        />
        <path
          fill="#6FB274"
          d="M1433.4,836.3l10.9,5.6c0.9,0.4,1.9,0.1,2.4-0.8l2.6-5c0.5-0.9,1.5-1.2,2.4-0.8l6.9,3.3l1.9-4.3l-18.6-9.5
		c-0.5-0.3-1.2-0.3-1.7,0l-1.4,0.8c-0.3,0.2-0.6,0.5-0.7,0.8L1433.4,836.3z"
        />
        <path
          fill="#6FB274"
          d="M1506.7,786.7l4.1,2.5l2,0.6l1.2-2.7c0.2-0.4,0-0.9-0.3-1.2l-4.6-3c-0.4-0.3-1-0.2-1.3,0.3l-1.4,2.2
		C1506.1,785.8,1506.2,786.4,1506.7,786.7z"
        />
        <path
          fill="#6FB274"
          d="M1509.5,813.6l6.6-9.7l3.6-7.4c0.3-0.6,0.9-0.8,1.5-0.4l1.9,1.1l-6.8,20.5L1509.5,813.6z"
        />
        <path
          fill="#6FB274"
          d="M1548.5,842.5l-9.4,0.8c0,0,0.6,4,0.1,5.8s-1,5-1,5l10.8,3.2l0.6-13.7
		C1549.6,842.9,1549.1,842.4,1548.5,842.5z"
        />
        <path
          fill="#6FB274"
          d="M1489.9,850.6l2.8,0.3c0.3,0,0.6,0.4,0.6,0.7l-1.6,14.1l6.6,1.9l1.3-15.5c0-0.3-0.2-0.6-0.5-0.7l-1.7-0.3
		c-0.3-0.1-0.5-0.3-0.5-0.7l0.1-1.6c0-0.3-0.2-0.6-0.5-0.7l-6-0.8c-0.4,0-0.7,0.2-0.7,0.6l-0.2,1.9
		C1489.3,850.2,1489.6,850.5,1489.9,850.6z"
        />
        <path
          fill="#6FB274"
          d="M1347.5,669.8l-1.2,8.3c-0.1,0.6,0.3,1.2,0.9,1.3l9.3,2.1c0.7,0.2,1.4-0.3,1.5-1l1.1-8.2L1347.5,669.8z"
        />
        <path
          fill="#6FB274"
          d="M1201.5,645.5c2.9-0.1,11.2-0.4,14.9-0.5c0.4,0,0.8,0.3,0.9,0.7c0.5,2.8,1.1,7,2.4,11.8
		c0.5,1.9-1.9,5.7-1.1,9.2c1,4.5,3.7,5,4.9,5c0.4,0,0.7,0.3,0.8,0.6l6.8,23.5c0.1,0.4,0,0.7-0.3,1l-8.9,6.1c-0.4,0.3-1,0.1-1.2-0.3
		l-11.2-20c0-0.1-0.1-0.1-0.1-0.2l-8.6-35.7C1200.6,646,1201,645.5,1201.5,645.5z"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1067.4,880.2
		c0,0,14.3-64.3,21.7-87.6c7.3-23.2,14.9-43.4,21.9-53.2c7.1-9.8,17.2-20.6,27-25s20.4-6.5,30.8-11.6c10.4-5.1,50.4-25.5,57-27.9
		c6.6-2.4,14.9,2.5,19.9,0.4c5-2.1,8.3-7.6,12.6-8.8c4.4-1.2,31.6,3,31.6,3"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1000.9,681.2
		c0,0,74.5,40.4,83.2,44s23,13.8,30.1,17.4c7.1,3.6,26.3,16.7,26.3,16.7"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1172.5,701
		c0,0,12,27.4,15.2,39.3c3.2,11.9,9.3,33.5,13.9,53c4.6,19.4,4.7,22.9,6.4,27.7c1.7,4.9,10.6,11.3,14,14.8
		c3.4,3.5,6.1,8.5,12.6,11.5c6.5,2.9,25.7,10.5,32.8,11.5c7.2,1.1,12.8,2,12.8,2"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1280.7,833.8
		c0,0,45.3-6.6,49.9-6.5c4.7,0.1,10,0.8,18.4,0.4c8.4-0.5,15.3-1,15.3-1"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1367.6,865.7
		c0,0-8.2-9.8-17.7-18.1s-20.9-13.5-20.4-27.2c0.6-13.7,0-29.9-0.5-38c-0.5-8.1-3.4-16.6,2.7-29.3c6.2-12.7,11.2-29.4,11.2-31.9
		c0-2.5,5.2-8.6,9.8-9.5c4.7-0.8,15.3-1.3,20.9-1.5c5.6-0.2,10.2,2.4,11.7,8c1.4,5.6,8.2,29.5,10.1,40.4c2,11,9.7,28.9,5.1,40.6
		c-4.6,11.8-7.1,11.7-7.2,17.9"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1410.2,808.5
		c0,0-6,4.6-10.7,7c-4.7,2.4-8.7-1.4-15.9,16.2c-6.4,15.7-14.3,30.8-15.9,34c-0.2,0.4-0.4,0.8-0.5,1.3l-3.6,13.2"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1415.8,777.5
		c0.9,1.9,11.1,6.8,16,9.4c4.9,2.6,9,2.7,14.6,5.3c5.6,2.6,26.8,14.9,33,18.1s13.6,7.4,20.1,11s19,14.7,23.4,15.4s52-7.9,61.1-8.9"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1462,695.1
		c0,0-1.7,22.7-1.8,27.7c-0.1,5-2.2,15.5,1.8,20.3s7.6,10.1,9.8,9.9s8.2-7,12.6-8.2c4.4-1.2,12.2-2.9,12.2-2.9s10-0.9,13.4-1
		c3.4-0.1,7.4,0,7.4,0"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1583.4,752
		l-36.7-24.6c-0.9-0.6-1.9-1.1-3-1.3l-16.8-3.6l-19.8-6.9c-1.5-0.5-3.2,0.2-3.8,1.7c-1.9,4.4-4.3,9.2-5.1,12.5c-1,4-4.3,10.8-0.3,15
		s16,13.1,20.9,16.6c2.7,1.9,13.3,10.1,22.3,17.1c9.3,7.2,19.6,12.8,30.6,16.8l2.1,0.8l9.7,3.5"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1335,746.6
		c0,0,8.3,5.8,20.2,5.4s29-2.2,29-2.2"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1129.1,769.1
		c0,0,23.2,8.6,33.1,14c9.9,5.5,21.1,21,21.1,21s4,4.4-1.8,11.2c-5.7,6.7-16.3,20.6-21.7,26.1c-4.6,4.6-14.8,15.6-17.9,18.9
		c-0.6,0.6-1.2,1.3-1.7,2c-1.1,1.3-2.9,3.5-3.5,4.1c-0.8,0.8-2.1,1.9-3.8,0.8s-6.6-7-8.7-7.9s-4.5,1.4-6.7-0.5"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1067.2,796.3
		c5.9,1.4,16.8,4.4,21.4,5.1s17.6,6.9,17.6,6.9"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1151.5"
          y1="724.9"
          x2="1177.2"
          y2="712.2"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1220.7,662.4
		c0,0-1.7,5,1.4,6.9c3.1,1.9,9-0.1,11.2-0.4c2.2-0.3,8.1,2.7,8.6,6.1c0.6,3.4,3,21.3,3,21.3l9.8,55.7c0,0,2,8.5-1.2,15.9
		s-0.7,19.9-0.7,19.9l1.1,7.8"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1169.2"
          y1="773.3"
          x2="1194.3"
          y2="765.1"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1194.9,766.9
		l60.5-16.6c0,0,9.8-4.8,12.9-4.7c3.1,0.1,16.5,0.9,16.5,0.9l19.9,1.6"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1282.2,786.4
		c0,0,19.9,3.5,28.6,3.7c8.7,0.2,13.1-1.9,20-1.8c6.9,0.1,17.4,1.3,22.7,1.4c5.3,0.1,13.1,0.6,17.8,0c4.7-0.5,6.6-1.1,11.6-1.6
		s19.4-1.5,19.4-1.5"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1366.7,750.9
		c0,0,1,12.5,1.2,17.5c0.2,5,1.9,13.4,1.8,21.9"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1305.3"
          y1="830.6"
          x2="1327.2"
          y2="864.4"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1314"
          y1="829.3"
          x2="1334.1"
          y2="858.6"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1321.7"
          y1="828.4"
          x2="1358"
          y2="879.7"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1365.7,813.4
		c0,0-19.2,0.8-21.1,1.2s-4.2-0.1-7.5-1c-3.3-0.9-7.3,0.2-7.3,0.2"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1366.7,806.8
		c0,0-3.1,19.7-2.8,23.4c0.3,3.7,0.5,5.5,3.3,6.8s7.1,4.5,8.9,5.6s21.2,9.8,21.2,9.8s3.2,0.5,1.7,4.2c-1.5,3.7-4.3,9.2-4.3,9.2
		l-6.7,14.4"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1391.5"
          y1="850"
          x2="1386.4"
          y2="861.8"
        />
        <polyline
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		1387.1,847.9 1390.3,840 1401.3,845.8 	"
        />

        <polyline
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		1400,832.3 1406.2,835.8 1398.4,852.9 	"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1329.8,804.9
		l26.5,1.6l29.2,2c0.8,0.1,1.6,0.3,2.3,0.6l6.7,3.3"
        />

        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M1375.9,812c0-0.2,3.2-23.6,3.2-23.6"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1348.7"
          y1="814.1"
          x2="1351.2"
          y2="789.7"
        />

        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M1375.1,814.6c0,0,6.2-0.7,8.3,0.9c1.2,0.9,0.7,2.4,3.3,2.3c3-0.1,1.7,4.6,1.4,4.6"
        />

        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M1381.9,820.8c0,0,1.3-2.5,3.5-2.8c2.2-0.3,3.5-0.9,4.8-2.4"
        />

        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M1132.6,764.8c0,0,19.7,11.5,23,12.4c3.3,1,19.7,4.4,19.7,4.4l21.9-9.4l43.9-10.4"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1180.4"
          y1="790"
          x2="1198.4"
          y2="779.8"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1199.1"
          y1="784.3"
          x2="1237.6"
          y2="774.8"
        />

        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M1235.2,763.2c0,0,2.4,13.5,3.6,16.4c1.2,3,8,19,8,19"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1256.8,793.9
		c0,0-0.8,8.9-0.4,11.1c0.4,2.2,2.2,12.8,2.2,12.8s0.6,6.5,1.1,7.7c0.5,1.2,1.1,3,3.5,2.8s17-2,17-2l23-2.5c0,0,14.9-1.6,17.5-2.3
		c2.6-0.7,9.5-3.1,9.5-3.1"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1228.2,843.2
		l26.9-23.1c1.8-1.6,4.2-2.4,6.7-2.3l18.6,0.8"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1157.8,639.8
		l19.1,46.5c0,0,2.1,2.2,2.7,3.5c0.6,1.3,2.1,6.1,2.1,6.1"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1163.1"
          y1="705.2"
          x2="1164.4"
          y2="709.1"
        />
        <polyline
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		1161.6,710.3 1170.2,706.4 1171.4,709.4 	"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1184.9,767.8
		l-3.9-13.7c-0.3-1,0.3-2.1,1.3-2.5l7.8-2.8"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1481.9,705.6
		c0,0-6.1,24.3-3.7,30.6s7.7,15.7,10.5,20.6s2.6,9.6,2.6,9.6"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1374.7,710.2
		c5.6-0.5,8.6-4.6,12.1-8.3c3.5-3.7,5.8-9.4,10.5-9.8s16.8,5.8,22.2,7.5c5.4,1.6,19.6,4.9,27.1,7c7.5,2.1,14.5,7.5,14.5,7.5l2.5,1.5
		c1.8,1.1,2.8,3.1,2.6,5.1l-0.7,9.8c-0.2,3.2-3.1,5.5-6.2,5.1l-5.1-0.6c-2.5-0.3-5,1.2-5.9,3.6l-1.7,4.6c-0.3,0.9-0.4,1.8-0.3,2.7
		c0.4,2.8,1,9.6-1.2,17.8"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1386.8,701.9
		c0,0-3.9,8-1.4,16.4"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1443.1,693.7
		c0,0,0,2.3,1.2,4c1.2,1.6,0,5.8-1.4,10.1c-1,2.9-4.5,11.6-6.5,16.6c-0.8,2-3,3-5,2.2l-30.8-11.5c0,0-9.1-3-15-2.1"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1441.7"
          y1="763.6"
          x2="1431.8"
          y2="786.8"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1307.8,667.5
		c0,0,4-0.7,6.1,0c2.1,0.7,5.6,2.3,4.9,6.8c-0.7,4.4-2.1,8.4-1.9,11.7c0.2,3.3,2.1,7.2,1.4,11.2s-1.6,13.3-1.6,19.2
		s1.6,18.9,2.8,23.1c1.2,4.2-0.5,6.1,1.6,10.1c2.1,4,3.5,7.9,3.5,7.9"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1286.5,723.1
		c0,0,13.3,1.9,18,0.9c4.7-0.9,11.9-2.8,11.9-2.8"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1407.6"
          y1="686.2"
          x2="1400.6"
          y2="715"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1415.1"
          y1="687.4"
          x2="1411.8"
          y2="696"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1422.3"
          y1="689"
          x2="1417.7"
          y2="698.9"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1364.6,710.3
		c0,0-0.7,9.4-1.4,12.6c-0.7,3.3-3.7,16.8-3.7,16.8"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1209.3,682
		c0,0,24.8,47,26,49.8c1.2,2.8,5.6,11.9,5.6,11.9"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1261.5,691.6
		c0,0,1.2,40.9,1.6,42.8c0.5,1.9,2.8,0.5,4,2.1c1.2,1.6,2.3,8.9,2.3,8.9"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1095,803.1
		l10.8-27.7c0.6-1.6,2.4-2.3,4-1.6l20.5,9.4l7.7-0.5"
        />
        <polyline
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		1403.4,715.4 1403.4,723.6 1404.1,729.5 	"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1397.3,728.8
		l10.3,1.3c1.3,0.2,2.5-0.7,2.7-2l0,0c0.2-1.2-0.5-2.4-1.7-2.7l-10.3-3c-1.6-0.4-3.1,0.7-3.1,2.3v1.7
		C1395.2,727.6,1396.1,728.6,1397.3,728.8z"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1388.8,772.1
		c0,0,7.4-0.5,9.5,0c0,0,0,0,0.1,0c3.1,0.7,6.3,0.9,9.5,0.8l6.9-0.4"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1343.9,755.4
		c0,0-2.3,3-2.5,4.6c-0.1,1.5-0.9,3.2,1.8,4.4c2.7,1.3,11.1,1.4,11.1,1.4h13.9"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1382.4,762.1l1-2.2
		c0.3-0.7,1-1.1,1.7-1.2l10-0.4"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1371.8"
          y1="742.3"
          x2="1374.5"
          y2="735.9"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1337.1,739.4
		c0,0,5.4,2.5,7,2.7c1.6,0.2,9.5,0.7,9.5,0.7"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1300.5,724.2
		c0,0,4.7,11.8,5,14.7c0.3,2.2-0.6,12-1,16.8c-0.1,1.4,1,2.7,2.5,2.6l4.4-0.2l12.6-0.7l5.1,1.6"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1311.5,759.7
		l-0.2,6.4c0,1.1,0.9,2,1.9,1.9c3.7-0.1,11.5-0.3,14.5-0.2c4,0.2,11,5.3,16.7,5.8c5.7,0.6,24.3,1.1,24.3,1.1l24.5-0.2"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1308.9,789.9
		l-0.4-16.5c0-1.3-1.1-2.5-2.4-2.6l-23.5-2.1"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1252.5"
          y1="738.1"
          x2="1263.6"
          y2="735.3"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1266.2,735.9
		l12.4-10.4c1.2-1,2.5-1.7,4-2l3.1-0.7"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1342.6,672.3
		c0,0-6.9-0.8-10.3,0.4s-4.8,4.3-4,6.7c0.8,2.3,4.7,9.2,7.7,13c3,3.7,7.1,7.7,9.6,12.3s4.6,8.1,4.6,8.1"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1363.6,723.5
		c0,0,9.4,1.3,12.2,1.3s10.6-1.3,10.6-1.3"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1391,738.9
		c0,0,4.2-0.9,5.5-1.8c0.9-0.6,2.4-2,3.3-2.8c0.4-0.4,1.1-0.6,1.6-0.4l3,1l4.4,1.4c0.8,0.3,1.3,1.1,1.1,1.9l-1.8,7.3
		c-0.2,0.9-1.1,1.4-2,1.2l-2.3-0.6c-0.8-0.2-1.3-1-1.2-1.9l1.6-8.9"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1402.8"
          y1="740.9"
          x2="1408.9"
          y2="743"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1405.1"
          y1="746.8"
          x2="1403.3"
          y2="753.7"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1418.1,748.4
		l24.1,11.3c0.7,0.3,1,1.2,0.7,1.9l-1.2,2.5"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1478.2"
          y1="725.3"
          x2="1497.7"
          y2="729.7"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="2.8056"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1525.1"
          y1="730.6"
          x2="1583.9"
          y2="753"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1489.2,779.4
		l17.8,13.4l3.2-3.9l4.9,2l1.4-0.8L1532,801c0,0-8.7,17.5-8.8,20.9c-0.1,3.4,1.9,15.1,1.9,15.1"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1497,820.1
		c0,0-6.5,9.1-7.7,12c-1.2,2.9-4.2,9.2-4.9,16.7v1c0,1,0.7,1.8,1.6,2l0,0c1,0.2,1.7,1.1,1.6,2.2l-1.2,10.9"
        />
        <g>
          <path
            fill={roadsColor}
            d="M1468.7,817.1c3.9,1.3,8.5,4.7,11.9,7c5.9,4,15.4,10.4,21.2,14.4c0.7,0.5,1.5,0.7,2.1,0
			c0,0,1.1-1.3,1.1-1.3l2.2-2.6l4.5-5.2c0,0-0.1,0.2-0.1,0.2c1.2-3.6,2.4-7.2,3.7-10.7c0.9-2.1,1.7-4.2,2.4-6.4
			c1.9-5.7,3.9-11.5,5.8-17.2c0,0,0.5,0.2,0.5,0.2c-2,5.7-3.8,11.5-5.7,17.3c-0.7,2.2-1.4,4.3-1.9,6.5c-1.2,3.7-2.2,7.5-3.6,11.1
			c0,0-4.5,5.2-4.5,5.2l-2.2,2.6l-1.1,1.3c-1,1.2-2.8,1.2-4,0.2c-5.8-3.9-15.3-10.4-21.2-14.4c0,0-5.6-3.8-5.6-3.8
			c-1.9-1.2-3.9-2.3-5.9-3L1468.7,817.1L1468.7,817.1z"
          />
        </g>
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1455.8,702
		c0,0,3.7-0.8,6.1-0.2c2.3,0.6,4.1,2.9,6.2,2.9s4.4-2.2,6.4-2c1.5,0.2,12,3.8,17.6,5.7c0.7,0.2,1.3,0.4,1.8,0.6
		c0.9,0.3,1.5,1.2,1.5,2.2l-0.2,5.5l0.9,0.7c0.6,0.5,1.1,1.2,1.2,2l1.6,7"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1486.9,726.8
		c0,0-0.2-10.2,0.9-11c2.1-1.5,8.5,1.5,8.3,1.7"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1492"
          y1="708.4"
          x2="1490.3"
          y2="715.2"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1487.1,719.9
		c0,0,3.8,1.4,5.2,1.7c1.4,0.2,5.2,0.5,5.2,0.5"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1518.5,743.1
		l28.1,24.9c0,0,5.4-5.1,5.4-8.2s-4.9-6.1-6.8-8.1c-1.9-2-4.7-5.1-6-5.7c-1.2-0.5-2.1,0-2.3-1.1c-0.3-1.2-0.9-2.6-0.9-2.6"
        />
        <g>
          <path
            fill={roadsColor}
            d="M1520.3,744.3c2.3-1.7,5-2.5,7.8-2.5c3.9-0.1,8.3-0.8,12,1.1c1,0.5,2,0.9,2.7,1.7c1.2,2.1,1.7,4.3,3,6.4
			c0.3,0.5,0.7,1.1,1.1,1.3l-0.8,1.1c-0.7-0.6-1-1.3-1.3-1.9c-1-2.1-1.6-4.4-2.6-6.4c-3.8-3.3-9.2-2.6-13.9-2.3
			c-1.3,0.1-2.6,0.2-3.7,0.7c-1.2,0.5-2.3,1.1-3.3,1.9L1520.3,744.3L1520.3,744.3z"
          />
        </g>
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.0521"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1479.9,740.1
		l4.4,0.3c0.5,0,0.9,0.3,1,0.7l1.3,2.9"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1507.2,792.8
		c0,0-0.3,4.6,0.8,6.4s4.2,3.3,6.4,4s6,2.4,6,2.4"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1084.4,724.9
		c0,0,9.9-16.6,10.3-18c0.2-0.9,1-2.5,1.5-3.6c0.3-0.7,1.1-1.1,1.9-0.9l1.1,0.2c0.5,0.1,1.1-0.1,1.5-0.5c1.7-2.1,7.1-8.4,10-10
		c1.1-0.6,6.6-3.9,6.6-3.9"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1100.2,702.9
		c0,0,0.2,2.6-0.8,3.8c-1,1.2-4.8,6.5-6.8,9.4s-6.6,9.8-6.6,9.8s-2.3,1.9-3.6,3.9c-0.5,0.8-1,1.9-1.4,2.8c-0.6,1.5-0.6,3.1,0.1,4.6
		l2.2,4.7c0.3,0.7,0.5,1.5,0.5,2.3l0,0.2c0,2,1.2,3.9,3,4.8l0.5,0.3c1.5,0.8,2.6,2.2,2.9,3.9l0.2,1.2c0.1,0.4,0.1,0.8,0.1,1.2l0,0.2
		c0,1.6,0.7,3.2,1.9,4.3l0,0"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1107.9,738.9
		l7.5-11.1l3-4.5c0.4-0.6,1-1.1,1.6-1.5c1.5-0.8,4.3-2.4,4.7-2.8c0.5-0.6,6-4.8,6-4.8"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1096.2"
          y1="703.4"
          x2="1122.1"
          y2="726.3"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1100.2,706.3
		c0,0,2.5-0.5,3.7,0.7c1.2,1.2,12,10.6,12.8,11.6s1.8,4.2,1.8,4.2"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1111.1,713.1
		c0,0,9-5,9.9-6.9s1.4-6.7,1.4-6.7"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1139.3"
          y1="713.6"
          x2="1146.2"
          y2="729.5"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1148.3,735.9
		c0,0,1.9,2.5,2.2,3.4c0.3,0.9,0,5.1,0,5.1"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1156.4"
          y1="747"
          x2="1165.9"
          y2="765.9"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1121.4,759.1
		l-1.2,3.9c-0.3,0.9,0.1,1.9,1,2.3l9.6,4.7"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1157.1,760.5
		c0,0-0.2-3.7,0.5-4.5s2.4-1.9,2.4-1.9"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1263.4,857.7
		l-0.3-2.5c-0.1-0.6,0.1-1.1,0.4-1.6c1.9-2.3,6-6.3,7.9-9.8c0.1-0.3,1.5-6.9,1.5-6.9"
        />
        <polyline
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		1202.9,717.5 1207.6,731.5 1209.3,736.7 	"
        />
        <polyline
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		1228.5,718.5 1228.9,719.2 1207.6,731.5 	"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1190.5,750.9l28-6.8
		c1-0.2,2,0.2,2.5,1.1l7,12.1"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1192.1"
          y1="756.5"
          x2="1223.4"
          y2="749.3"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1305.1"
          y1="739.4"
          x2="1319.1"
          y2="738.1"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1318.3"
          y1="676.9"
          x2="1328.2"
          y2="676.9"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1376.7,710.2v1.2
		c0,1.5-1.3,2.6-2.7,2.5l-1.1-0.1c-1.3-0.1-2.3-1.2-2.3-2.5v-1"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1437.5,721.6
		l7.3,3.4c1,0.4,1.7,1.2,2.2,2.2l3.5,7.9"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1502.9,749.3
		l-1.8,2.9c-1.3,2.1-3.5,3.5-6,3.8l0,0c-1.2,0.2-2.4,0.6-3.4,1.3l-2.4,1.5"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1546.6"
          y1="768"
          x2="1532"
          y2="801"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1529.4"
          y1="781.9"
          x2="1522.8"
          y2="794.6"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1523.7"
          y1="774.8"
          x2="1516.4"
          y2="790.1"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1515.5"
          y1="718.5"
          x2="1513.3"
          y2="725.3"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1532,731.7l0.9-7.7
		c0,0,0.6,4.2,4.2,5.5c3,1.1,9.6,3.4,12,4.2c0.5,0.2,0.8,0.6,0.8,1.1v2.5"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1549.9"
          y1="729.7"
          x2="1552.9"
          y2="723.5"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1455.8,750.9
		l0.9-2.3c0.3-0.8,0.8-1.5,1.4-2l3.9-3.5"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1397.8"
          y1="767.7"
          x2="1414.7"
          y2="767.7"
        />
        <polyline
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		1312.8,790.3 1312.1,798.7 1312.8,800.8 1313.3,811 	"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1316.9"
          y1="810.8"
          x2="1316.4"
          y2="822"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1320.7"
          y1="807.5"
          x2="1321.7"
          y2="820.8"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1257.1"
          y1="808.7"
          x2="1269.4"
          y2="810.4"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1269.4"
          y1="799.5"
          x2="1269"
          y2="818"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1238.8,779.6
		c0,0,4-2.3,4.1-4.4l9.1-1.1l20.8,1.1h9.8"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1291.6"
          y1="747.1"
          x2="1290.4"
          y2="770.1"
        />
        <polyline
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		1284.9,757.3 1291,757.5 1304.6,756.5 	"
        />
        <polyline
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		1290.8,762.1 1308.4,763.6 1308.1,772.1 	"
        />
        <polyline
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="1306,781.1 
		1314.8,781.6 1316.9,789.7 	"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1322.5"
          y1="774.1"
          x2="1328.3"
          y2="775.4"
        />
        <polyline
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		1330.1,764.1 1330.1,765.8 1334.1,766.9 1333.6,769.9 	"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1352.3"
          y1="765.8"
          x2="1351.2"
          y2="774.1"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1317.9,730.7
		c0,0,4.6-1.9,8.6-1.2c4,0.7,12.7,5.3,12.7,5.3"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1262.8,728.9
		c2.2-0.4,4.8-0.2,6.2-1.8c1.4-1.6,3-5.8,3-5.8l-1.4-10.1l-0.1-0.8c0,0,0.9-16.5,0.9-17"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1284.9,718.5
		l-4.7-0.4l-0.7-0.5c-1.6-1.1-3.3-2.2-5-3.2c-1.7-1-3.8-2.4-3.8-3.3"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1211.9"
          y1="746.4"
          x2="1216.5"
          y2="766.9"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1170.3"
          y1="738.1"
          x2="1174.6"
          y2="747.1"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1116.5,732.9
		c0,0,2.8,3,4.7,3.9c1.9,0.8,5.1,1.4,5.1,1.4"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1179.6"
          y1="718"
          x2="1186.8"
          y2="714.8"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1192.1"
          y1="691.1"
          x2="1202.2"
          y2="714.5"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1199.1"
          y1="709.1"
          x2="1193.9"
          y2="712.2"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1225.7"
          y1="674.9"
          x2="1231.7"
          y2="697.7"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1202.2,662.4v3.7
		c0,0.7-0.1,1.3-0.4,2l-0.4,1.1c-0.3,0.7-0.4,1.5-0.4,2.3l0.4,5.6c0.1,1.4,0.8,2.8,1.9,3.7l0,0c0.9,0.8,1.5,1.8,1.8,3l0.2,0.8"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1272"
          y1="667.1"
          x2="1270.6"
          y2="692.3"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1294.7"
          y1="688.5"
          x2="1293.6"
          y2="697.7"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1306.3"
          y1="710.7"
          x2="1308.2"
          y2="723.2"
        />
        <polyline
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		1336.7,693.1 1336.7,705.8 1336.7,725.5 	"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1344.8"
          y1="716.4"
          x2="1336.7"
          y2="705.8"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1342.8"
          y1="700.3"
          x2="1342.1"
          y2="712.9"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1342.8,699.8
		c1.9-0.2,4.1,1.1,5.9,3.1c1.8,2,2.7,3.4,4.9,3c1.7-0.3,4.1-5.2,5.2-7.7c0.4-0.8,1.2-1.3,2.1-1.2l7.3,0.7"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1362.7"
          y1="697.1"
          x2="1361.2"
          y2="711.1"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1318.3,670.2
		l2.6-0.4c2.1-0.3,4.3,0.2,6.1,1.4c1,0.7,1.8,3.6,1.7,4.7"
        />

        <polyline
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		1220.2,779.1 1225.3,798.7 1243.6,790.9 	"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1238.8"
          y1="803.1"
          x2="1248.5"
          y2="825.8"
        />

        <rect
          x="1291"
          y="800.7"
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          width="16"
          height="14"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1312.1"
          y1="797.8"
          x2="1307"
          y2="800.7"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1291"
          y1="814.6"
          x2="1289.9"
          y2="825.1"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1307"
          y1="814.6"
          x2="1309.2"
          y2="823"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1282.6,797.1
		l4.5,0.5c0.9,0.1,1.7,0.5,2.3,1.2l1.6,1.8"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1280.9,817.7
		c0,0,9.8-1.9,10-3.6"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1286.8,832.9
		c0,0-0.7,3.1,0,4.5s15,26.9,15,26.9"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1241.6,860.4
		c0,0,13.1-18.7,15.4-20.4c2.3-1.7,7.6-3.1,10.9-3.1c3.3,0,12.2,1,12.2,1"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1229.4,861.8
		c0.6-0.2,21.2-25.4,22.2-27.2s8-9.3,8-9.3"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1215.8"
          y1="830.2"
          x2="1243.6"
          y2="814.6"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1159.2,857.5
		c0,0,25-19.5,27.6-20c2.7-0.5,5.1,1,5.1,1"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1158.1,843.2
		c0,0,8.4-2.5,11.1-6.9c2.8-4.3,6.8-9.1,9.2-8.8c2.3,0.4,3.8,0.2,6.3,1.7c2.5,1.5,7.5,5.4,7.5,5.4"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1178.7,819.8
		c2.3-0.5,4.2-2.4,5.9-3.9c1.7-1.5,6.6-5.6,6.6-5.6"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1188.4"
          y1="821.3"
          x2="1194.3"
          y2="815.9"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1203.4"
          y1="801.2"
          x2="1220.9"
          y2="796.3"
        />
        <polyline
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		1201.9,794.5 1205.4,793.9 1209.8,796.3 1223.6,792.8 	"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1223.6"
          y1="790.3"
          x2="1241"
          y2="785.4"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1270.6,789.7
		c-0.2-0.7,0.5-23.9,0.5-23.9"
        />
        <polyline
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		1231.7,741.6 1233.4,746.6 1237.6,746.6 1239.9,754.6 	"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1235.2"
          y1="741.6"
          x2="1237.6"
          y2="746.6"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1331,684.6
		c-0.5,1,0,15.7,0,15.7"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1414.6"
          y1="697.7"
          x2="1412.3"
          y2="706.6"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1435.8,704.1
		c0,0-2.3,7.5-3,9.8c-0.7,2.4-1.9,4.7,1.5,5.9c3.4,1.2,3.4,1.2,3.4,1.2"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1470.2"
          y1="737.9"
          x2="1478.9"
          y2="737.9"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1538.7,834.5
		c0,0,0.8,12.8,0,17.1c-0.8,4.3-2.9,28.1-2.9,28.1"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1522.8,836.6
		c0,0-3.6,5.9-5,9.7c-1.4,3.7-8.3,27.1-8.3,27.1"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="2.8056"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1460.2,864.9
		c0,0,4.9-2.8,7.7-2.4c2.8,0.4,21.3,3.4,21.3,3.4l51.5,15.8"
        />

        <polyline
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		1424.6,831.4 1433,836.3 1417.5,868.7 1410.7,880.2 	"
        />

        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M1440.5,799.1l8,4.4c0,0,2.8,1.2,0.8,4.6c-2,3.4-8.5,14.3-8.5,14.3"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1462"
          y1="783.9"
          x2="1479.9"
          y2="792.8"
        />

        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M1484.4,807.5c0,0,11.5,7.5,12.6,9.5c1.2,2-0.9,4.4-0.9,4.4"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1492.5"
          y1="774.1"
          x2="1500"
          y2="779.4"
        />

        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M1409.9,811c0,0.3,0,4.5,2.2,6s6.9,5,6.9,5l7.7,5.4"
        />

        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M1427.4,784.5c0,0-5.7,18.6-7.4,23.7c-1.6,5.1-6.9,17.5-6.9,17.5l-4.2,11"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1396.6"
          y1="808.2"
          x2="1404.2"
          y2="812.4"
        />

        <polyline
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		1399.5,815.5 1399.5,819.2 1404.2,822 	"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1422.4"
          y1="800.6"
          x2="1436.3"
          y2="807.5"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1414.7"
          y1="828.4"
          x2="1423.9"
          y2="832.9"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1406.2,835.8
		c0,0,7.3,4.1,10.1,5.1s4.4-1.6,4.4-1.6l29.9-61.2c0,0,11.1-25,13.3-27.8c2.2-2.8,5.4-3.6,5.4-6.4c0.1-2.8,0.8-8.1,0.8-8.1"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1328.7,783.2
		c0,0,5,1.1,10.6,1.3s30.2-1.5,30.2-1.5"
        />
        <polyline
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		1377.5,800.7 1387.1,800.7 1391,797.8 1401.3,797.5 	"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1384.5"
          y1="800.6"
          x2="1384.7"
          y2="793.3"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1391,797.8
		c-0.1-1.1,0-5,0-5"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1396.6"
          y1="797.7"
          x2="1396.6"
          y2="792.7"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="997.9"
          y1="689.7"
          x2="1080.8"
          y2="736.1"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1324.5"
          y1="860"
          x2="1331.4"
          y2="855.4"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1352.2"
          y1="849.5"
          x2="1354.3"
          y2="846.8"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1356.2"
          y1="853.5"
          x2="1358.7"
          y2="850.7"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1374.8"
          y1="841.6"
          x2="1360.4"
          y2="857.2"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1382.8"
          y1="846"
          x2="1374.1"
          y2="865.2"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1225.8"
          y1="798.4"
          x2="1228.4"
          y2="807.5"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1208.3"
          y1="787.1"
          x2="1209.8"
          y2="796.3"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1212.6"
          y1="786"
          x2="1214.1"
          y2="795.2"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1218.6"
          y1="784.6"
          x2="1220.1"
          y2="793.5"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1247.2"
          y1="774.8"
          x2="1248.4"
          y2="789.4"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1243.7"
          y1="790.5"
          x2="1252.9"
          y2="788.5"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1259.6,774.7
		c0,0,0,2.2,1.5,2.8c1.5,0.6,9.6,1.3,9.6,1.3"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1238.1"
          y1="746.4"
          x2="1244.9"
          y2="738.9"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1230.3"
          y1="738"
          x2="1236.6"
          y2="733.8"
        />
        <polyline
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="1272,721.3 
		1275.7,721.4 1276.1,720.3 1279.8,720 1280.2,718.1 	"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1296.1"
          y1="702.3"
          x2="1296.3"
          y2="712.4"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1412.5,804.7
		l3.8-0.9c1.3-0.3,2.1-1.4,2.2-2.7l0,0c0-1.2-0.8-2.3-2-2.7l-2.2-0.7"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1502.1,822.4
		c0,0-0.4-1.6,1.4-4.4c1.8-2.7,1-2.4,3-3.2c2-0.8,3-1.1,4-2.6s5.7-8.2,5.7-8.2"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1512.5,802.3
		l-0.2,2.8c-0.1,0.7,0.3,1.4,0.9,1.8l0.5,0.3"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1467.6,822.2
		c0.2,0,9.1,4.9,9.1,4.9"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1527.4,852
		c0,0,11.5,2.2,11.6,2.1c0.2-0.1,10,3.3,10,3.3"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1530.2,841.8
		c0.2,0,9.1-0.5,9.1-0.5"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1518.3,845.1
		l1.3,0.5c1,0.4,1.6,1.2,1.8,2.3l0.3,1.8c0.1,0.5,0.1,0.9-0.1,1.4l-0.5,1.9c-0.4,1.3,0.2,2.7,1.3,3.4l0,0c0.9,0.6,1.4,1.5,1.4,2.5
		l0,3.6"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1513.2"
          y1="861.7"
          x2="1537.8"
          y2="863.3"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1537"
          y1="865.9"
          x2="1543.1"
          y2="866.4"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1509.3"
          y1="850"
          x2="1515.9"
          y2="852.6"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1055.8,879.9
		c0,0,12-45.6,12-54.6c0-9-0.8-33-0.8-33s-0.2-54.4,0.3-60c0.5-5.6,1.7-22.1,6.7-34.3c5-12.2,12.8-29,20.7-35.5
		c7.9-6.5,17.5-12.8,28.7-15.3c11.2-2.5,33.7-8.1,48-9s15.4-2.3,18.2,0c2.8,2.3,1.9,4.4,6.2,4.2c4.4-0.2,19.6-0.9,24.3-0.9
		c3.6,0,13.3,0.7,17.6,1.1c1.3,0.1,2.5-0.5,3.1-1.6l0,0c0.8-1.2,2.2-1.8,3.5-1.5c4.6,0.9,15.2,3.1,22.2,5.2
		c9.2,2.6,13.2,3.4,14.2,6.2c0.9,2.8-0.3,4.7,3.4,5.6c3.7,0.9,9.8,1.9,9.8,1.9l26,4.8l40.7,9.7l28.2,6.5l26,6.4l16.2,3.6l22,5.9
		l8.9,2.2"
        />
        <polyline
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		1140.5,643.7 1153.9,676.7 1165.2,704.4 	"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1149.2"
          y1="678.3"
          x2="1153.9"
          y2="676.7"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1326.9,585.5
		c0,0,16.2,4.4,27.3,7.5c11.1,3,21.1,5.8,27.7,7.4c6.5,1.6,22.7,8.9,22.7,8.9"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1402,604.8
		c0.9-3.1,2.3-6.9,2.9-8.5s0.3-2.7,4.8-2.2s23.4,6.1,23.4,6.1"
        />
        <polyline
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		1434.4,595.3 1429.5,614.8 1455.5,619.9 	"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1413.2"
          y1="594.6"
          x2="1409.9"
          y2="607.4"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1420.7"
          y1="596.5"
          x2="1417.2"
          y2="610.7"
        />
        <polyline
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		1420.8,612.2 1422.4,606.3 1428.4,607.9 1429.2,605.4 1431.4,605.9 	"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1390,603.2l2.5-3.6
		c0.4-0.5,1-0.8,1.6-0.7l9.3,1.2"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1446"
          y1="598.6"
          x2="1442.4"
          y2="612.2"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1451.3"
          y1="599.9"
          x2="1449.9"
          y2="606.6"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1498.2"
          y1="629.3"
          x2="1501.5"
          y2="618.8"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1519"
          y1="626.6"
          x2="1516.9"
          y2="634.7"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1531.6"
          y1="630.5"
          x2="1529.6"
          y2="638.5"
        />
        <polyline
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="1456.8,620 
		1459.3,620.7 1458.8,622.4 1482.5,627.5 1483.7,625.6 1539.4,640.7 1546.1,641.7 1551.6,643 1551.4,644.4 1583.9,658.7 	"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1381.2"
          y1="588.8"
          x2="1375.2"
          y2="676.2"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1362.3,698.8
		l2.6,3.1c1,1.3,2.4,2.3,3.9,2.9l0,0c1.4,0.6,2.9,0.9,4.5,0.8l1.7-0.1c1.7-0.1,3.4,0.2,4.9,1l2,1"
        />
        <polyline
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="1345.2,669 
		1343.7,680.8 1345.3,682.4 1345.2,687.4 1332.4,686.9 	"
        />
        <polyline
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		1335.2,672.3 1335.1,680.3 1335,687 	"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1343.7"
          y1="680.8"
          x2="1335"
          y2="680.5"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1335"
          y1="684"
          x2="1345.3"
          y2="684.6"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1338.7"
          y1="674"
          x2="1344.4"
          y2="674.8"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1314.8"
          y1="662.1"
          x2="1314.4"
          y2="667.2"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1209"
          y1="735.3"
          x2="1230.6"
          y2="722.8"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1216.4"
          y1="726.1"
          x2="1218.1"
          y2="729.9"
        />
        <polyline
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		1200.2,709.7 1206.2,706.4 1201.2,694.1 	"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1207.2"
          y1="691.2"
          x2="1212.2"
          y2="701.1"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1205.5"
          y1="704.9"
          x2="1217.5"
          y2="698.1"
        />
        <polyline
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		1174.9,706.7 1179,704.7 1181.5,704.5 1189.6,700.9 1191.8,698.4 1194.2,697.2 1212.7,688.5 	"
        />
        <polyline
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		1183.6,703.5 1185.7,704.7 1188.7,711.9 1184.6,713.8 1181.5,704.5 	"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1189.6"
          y1="700.9"
          x2="1193.1"
          y2="710.4"
        />
        <polyline
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		1186.6,707.1 1189.5,705.7 1191,704.9 	"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1191.6"
          y1="710.5"
          x2="1189.6"
          y2="705.4"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1182.1"
          y1="706.4"
          x2="1185.6"
          y2="705"
        />
        <polyline
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		1202.6,697.7 1205.2,696.7 1206.2,691.5 	"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1210.7,701.9
		c0,0-2.7-0.3-3.6-1.2c-0.9-0.9-1.9-3.9-1.9-3.9"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1268"
          y1="645"
          x2="1266.9"
          y2="666.3"
        />
        <polyline
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="1129,645.9 
		1131.6,652.2 1131.6,659.8 	"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1119.8"
          y1="648.1"
          x2="1122.8"
          y2="656.7"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1109"
          y1="652.4"
          x2="1139.4"
          y2="714"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1231.4,642.2
		l4.8,10.8c0.5,1.1,0.6,2.3,0.4,3.5l-2.2,12.8"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1241.7,673.5
		c0,0,2.9-13.6,1.5-17.6c-1.4-4-3.7-13.7-3.7-13.7"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1224.5"
          y1="669.8"
          x2="1226"
          y2="676.1"
        />
        <polyline
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		1276.4,667.7 1278.8,670.5 1279.2,677.7 1278.1,679.4 1278.5,683.5 1281,683.5 1282.9,680.6 1282.5,679 1279.2,677.7 	"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1275.2"
          y1="691.7"
          x2="1278.5"
          y2="683.5"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1282.8"
          y1="680.1"
          x2="1286.7"
          y2="680.2"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1282.5"
          y1="679"
          x2="1287.2"
          y2="676.2"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1227.3"
          y1="641.5"
          x2="1227.1"
          y2="635.5"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1220"
          y1="641.4"
          x2="1224.5"
          y2="669.8"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1387.7,679.2
		l-1.5,10.8c-0.2,1.1,0.1,2.2,0.6,3.2l3,5.4"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1497.7,768.8
		l15.8,11.5c0.3,0.2,0.7,0.4,1.2,0.4l0,0c0.9,0.1,1.7-0.4,2.1-1.1l0.1-0.2c0.4-0.7,0.4-1.7,0-2.4l-2.1-3.3c-0.1-0.2-0.3-0.4-0.5-0.5
		l-11-9.1"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1455.1,849.1
		l1.5,0.2c0.9,0.1,1.7,0.6,2.3,1.3l4.3,5.1c0.2,0.3,0.4,0.6,0.6,0.9l2.4,5.3"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1458.2,843.9
		c0,0,3.6,2.6,8.1,3.7c4.5,1.1,18.6,3.7,18.6,3.7"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1463.6,856.2
		c0,0,2.5-1.1,2.6-1.6c0.1-0.5,0.2-3.7,1.7-4.3s11.6,1.2,11.6,1.2s0.8,0.6,0.5,1.9c-0.2,1.3,0.3,2.8-1.9,2.6s-11.9-1.5-11.9-1.5"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1473.2"
          y1="849.2"
          x2="1473.2"
          y2="850.3"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1477.4"
          y1="851.3"
          x2="1477"
          y2="855.6"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1473.9"
          y1="850.8"
          x2="1473.6"
          y2="855.2"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1470.7"
          y1="850.5"
          x2="1470.3"
          y2="855.2"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1461.3"
          y1="835.7"
          x2="1468.7"
          y2="839.3"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1430"
          y1="834.5"
          x2="1431.8"
          y2="830.5"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1452.6"
          y1="851.3"
          x2="1448"
          y2="846.7"
        />

        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M1406.4,845.8l2-3.1l11.7,5.3c0.4,0.2,0.7,0.6,0.7,1.1v2.3"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1416.8"
          y1="841.2"
          x2="1415"
          y2="845.4"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1413.1"
          y1="859.8"
          x2="1420.1"
          y2="863.4"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1424.7"
          y1="854.3"
          x2="1433.6"
          y2="860.8"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1419.5"
          y1="830.4"
          x2="1421.2"
          y2="827.1"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1312.9"
          y1="852.8"
          x2="1317.4"
          y2="849.6"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1218.5"
          y1="814.3"
          x2="1220.6"
          y2="818.8"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1156.2"
          y1="848.7"
          x2="1160.9"
          y2="855.9"
        />

        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M1104.1,816.6c0,0,9.4,9,12.1,12.9"
        />

        <polyline
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		1113.6,813.5 1113.4,818.1 1109.7,822 	"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1078.9"
          y1="829"
          x2="1093.1"
          y2="833.3"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1102.2,844
		c0,0,1.8-3.2,3-5.4c1.2-2.2,2-4,4.8-1.4s4,3,3.7,6.5c-0.3,3.4-1,8.4-1,8.4"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1185.8,838.5
		l1.9,4.4c0.3,0.7,0.9,1.3,1.6,1.7l1.3,0.7"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1248.2,752.1
		c0-0.5-1.2-7.7-1.2-7.7"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1235"
          y1="750.6"
          x2="1236.2"
          y2="755.6"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1227.7"
          y1="716"
          x2="1231.5"
          y2="713.4"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1216.4,739.6
		c0,0,1,0.3,1.7,0.9c0.7,0.5,3.3,5.5,3.3,5.5"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1531.8,628.5
		c0,0,0.3-2.6,2.2-5c1.9-2.3,10.8-6.2,12.3-5.8c1.6,0.5,21.4,8.7,21.4,8.7"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1524.8"
          y1="627.2"
          x2="1537.3"
          y2="595.7"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1536.8,621
		c0,0-1.1-2.3-0.2-4.7c0.9-2.3,7.5-17.9,7.5-17.9"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1424.6,582.9
		c0,0,30.1,8.7,35.2,10.1c5.1,1.4,9,3.7,12.5,3.6c1.1-0.1,2.1-0.2,2.9-0.2c1.8-0.1,3.5-0.1,6.1,1.2c3.9,1.9,9,5,9,5"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1516.9,589.7
		c-0.3,0.5-6.8,13.2-9.1,17.8c-0.5,0.9-0.1,2,0.9,2.4l5.9,2.5l6-12.1c0.2-0.5,0.8-0.7,1.3-0.5l2.8,1.2"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1528.6"
          y1="591.7"
          x2="1521.9"
          y2="609.1"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1511.3,599.9
		c0.3,0.5,5.5,6.6,5.5,6.6l19.9,9.7"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1514.5"
          y1="612.4"
          x2="1510.8"
          y2="620.5"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1494.7"
          y1="591.4"
          x2="1487.7"
          y2="610.7"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1472.3"
          y1="604.8"
          x2="1475.2"
          y2="596.4"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1456.7,599
		c0-1.4-0.3-6.7-0.3-6.7"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1482.3"
          y1="598.1"
          x2="1476.5"
          y2="606"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1152.1"
          y1="563"
          x2="1149.6"
          y2="554.4"
        />
        <polyline
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		1195.5,560.7 1194.1,550.4 1181,529.5 	"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1255.2,557.5v7.9
		c0,0,14.8,1.6,22.9,1.1c8.1-0.6,16.7-1.8,16.7-1.8l3.5,0.8c1.6,0.4,2.8,1.7,2.8,3.4l0.3,8"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1120.8,568.6l-1-2.9
		c-1.3-3.8,1.2-7.9,5.2-8.5l38.3-5.3c4.6-0.6,8.9,2.4,9.8,6.9l0.6,2.8"
        />
        <polyline
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		1077.3,583.9 1076.1,579.4 1098.1,571.3 	"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1064.7,590
		c0,0-7.8-3.9-9.4-3.1c-1.6,0.8-16.2,12.3-16.2,12.3"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1126.9"
          y1="556.8"
          x2="1118.5"
          y2="532.3"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1149.6"
          y1="554.4"
          x2="1142.8"
          y2="529.5"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1426.1,583.4
		l-6.3-1.7c-3.9-1-7.3-3.3-9.7-6.5l-1.2-1.5"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1284.9,572.5
		l-1.2-6.4c0,0,7.1-1.7,11.4-8.5c4.3-6.9,39,1.1,39,1.1"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1306.3,578.8
		c0,0,10.2-3.9,9.9-10.7s-1.6-27.7-1.6-27.7"
        />
        <path
          fill={roadsColor}
          d="M1559.3,600.6l-4.2-1.1c-3.5,13.4-5,19.5-8.4,32.9c-5.9-2-11.8-4.1-17.6-6.2c-15-5.5-31.3-13.3-46.2-19.2
		c-15.4-6.6-31.8-11.3-48-15.3c-8.1-2-16.4-4.3-24.7-4.8l-9.3-0.7c-11.1-0.7-21.4-1.8-32.6-2.2c-11.2-0.6-25.1-3.1-36.2-2.2
		c-8.5,0.7-22.6-4.5-35.9-7.1l0.3-35.5l-4.5-0.2l-0.2,33.5l0,0.1l-1.8-0.3c-17.7-3-34.4-9.9-59.6-11.9c-27.6-2.2-78.2-0.1-97.6,4.3
		c-11.4,2.6-47.8,13.7-62.2,20.6c-7.9-17.5-19.5-40.5-25.8-54.3l-4.2,1.9c7,16,18.3,38.7,25.4,54.7c-9.4,4.7-17.6,13.7-23.4,18.5
		c-9.8,8.1-24,27.1-34,46.8c-6.7,13.3-9.8,26.9-14.4,37.7c-4.8,11.3-4.4,23.3-4.6,33.4c-0.3,15.7,0.9,27.4,0.9,28.2l4.2-0.2
		c0-0.2-1.3-12.1-0.9-28.1c0.2-9.7-0.2-21.2,4.3-31.8c5.2-12.3,8.5-25.9,15-38.7c10.2-19.8,23.9-37.5,32.1-44.3
		c5.6-4.6,13.4-13.4,22.4-17.9l0.1,0.2c17.9,40.6,53.4,104.8,71.8,145.2c0,0,0,0,0,0c1.9,4.1,1.5,10.7,0,15.8
		c-0.9,3.1-5.7,8.4-5.7,8.4l0,0c-2.5,2.3-4.8,5.1-6.6,7.9c-10,15.6-19,31.8-27.2,48.4c-3.2,6.8-6.9,13.3-9.3,20.6
		c-2,7.5,10,9.7,15,12.1c3.4,2.3,8,9.8,8,9.8c1.7,2.7,3.4,5.3,5.6,7.6c3.2,2.5,6.7,4.3,10,6.4c5.8,3.4,11.7,6.8,17.5,10.2l2.1-3.6
		c-5.8-3.4-11.6-6.9-17.3-10.4c-2.8-1.7-5.8-3.5-8.4-5.3c-2.1-1.5-4-5-5.5-7.3c0,0-4.9-7.5-8.4-10.5c-3.5-2.4-9.2-3.4-12.7-5.7
		c-0.6-0.4-1.2-0.9-1.4-1.2c-0.1-0.2-0.1-0.3-0.1-0.3c0-0.1,0,0,0.1-0.5c3.6-10.1,9.2-19.9,13.8-29.5c6.9-12.9,14.1-25.8,22-38
		c0.9-1.4,2.1-3,3.1-4.2c0.9-1,1.9-1.9,2.9-2.8c3.8-3.4,8.5-2.3,8.5-2.3s5.7,1.5,10.7,3.3c0.3,0.1,4.3,1.7,5.6,2.4
		c2.7,1.4,4.4,4.3,6.1,7c3,4.8,17.5,28.5,30.6,50c-6,5.8-7.3,10.8-7.4,11.2c-1.5,5.8-6.4,43.2-6.5,44l2.8,0.3c0-0.2,5-37.9,6.4-43.6
		c0-0.1,1.2-4.3,6.2-9.4c9.4,15.4,29.6,49.6,32.2,53.7l2.4-1.4c-6.6-11.6-25-43.4-31.7-55c2.2-1.8,5-3.7,8.4-5.4
		c6.3-3.2,13.4-7.2,20.3-10.9c9.9-5.4,19.2-10.6,24-12.5c6.4-2.5,20.1-6.6,25.3-8.2l-4.1,92.6l2.8,0.2l9.5-181.6
		c7.9,5.7,14.9,12.9,23.8,17.5l4.7,2.8c12.7,7.5,25.4,15.1,38.7,21.7c6.7,3.2,13.9,5.5,20.9,7.9c12.5,4.1,24.9,8.4,37.6,11.8
		c-0.3,1.8-0.5,3-0.5,3.6c0,1.7-0.5,9.4-0.9,16.8c-0.3,5.6-0.7,11.3-0.8,14.5c-0.2,4.3-1.4,7.2-2.5,10c-0.8,1.9-1.5,3.8-2,6.1
		c-0.8,4.3,0.2,6.7,2.8,12.2c0.9,2,2.1,4.6,3.5,7.9c5.3,12.6,22.1,22.4,22.9,22.9l9.6,5.2c-3.1,4.9-12.4,19.8-12.4,19.8l2.3,1.6
		c0.1-0.1,9.4-15.1,12.5-20.1l36.4,19.7l5.1-0.4l-40-21.7c3-4.9,6.3-10.5,8.7-15.4c3.5-7.3,6.6-15.5,9-22.1c1.4-3.7,2.5-6.8,3.6-9.2
		c2.9-6.9,11.9-24.7,17.3-31.9c1.8-2.4,4.3-5.3,6.9-8.2c2.1-2.4,4.4-4.7,7-6.7c1.5-1.2,2.8-2.5,4-3.9c9.8-11.5,17.3-24.6,22.6-38.7
		c4.5-12.3,7.6-30.2,10.8-43.1c2.6-11.8,5.9-26.2,9.3-40.5c3,1,30.9,12.2,34,13.2l1.5-4.7c-3.2-1-31.2-12.3-34.4-13.3
		C1554.6,619.4,1556.1,612.3,1559.3,600.6z M1153.3,754.5c-0.5,1.8-2,3.2-3.8,3.6c-4.1,1-7.6-2.7-6.5-6.7c0.5-1.8,2-3.2,3.8-3.6
		C1150.9,746.8,1154.4,750.5,1153.3,754.5z M1281.1,784.6c-4.4,1.3-19.5,5.7-26.5,8.5c-4.9,2-14.3,7.1-24.3,12.6
		c-6.9,3.8-14,7.7-20.2,10.9c-3.4,1.7-6.2,3.6-8.5,5.5c-8.8-15.2-17.7-30.4-26.7-45.4c-1.8-2.8-3.8-6.6-5.9-9.2
		c-1.2-1.6-2.8-3-4.7-4.1c-1.9-1-5-2.1-5-2.1c-0.9-0.5-1.6-1.4-2-2.3c-2.3-5.7,0.3-10.8,4.8-14.2c6.7-4.6,13.5-8.9,20.3-13.2
		c16.7-10.5,33.1-21.3,50.1-31.2c8.6-3.5,17.8-6.1,27.1-5.3c7.3,0.3,14.6,0.8,21.8,1.6c1.4,0.3,2.7,0.3,4,0.5L1281.1,784.6z
		 M1285.7,692.4c-1.3-0.2-2.5-0.3-3.7-0.5c-7.4-0.8-14.8-1.4-22.2-1.7c-10-0.8-20,1.9-29.2,5.8c-20.3,11.8-40.1,25-60,37.5
		c-3.8,2.4-7.6,4.9-11.3,7.5c-0.1,0.1-0.2,0.1-0.4,0.2c-6.9,4.6-10.6,0.7-12.9-3.4c-0.7-1.5-1.2-2.5-1.2-2.5
		c-14.9-32.8-53.5-104.1-72.5-146.1l0-0.1c14.1-6.7,50.2-17.7,61.5-20.3c19-4.4,69.8-6.3,96.3-4.2c25,2,40.3,8.7,59.2,12.5l2.4,0.5
		L1285.7,692.4z M1368.4,740.7c-10.4-3.6-19.7-9.1-29.3-14.5c-7.9-4.5-15.9-9.3-23.8-14c-9.1-4.8-16.4-12.5-25-18.3
		c-0.3-0.2-0.6-0.3-0.8-0.4l6.4-114.4c10.8,1.8,25.4,7.7,35.9,7.4c13.9-1,22.9,1.3,36.6,2.1c11.1,0.4,22.9,1.4,33.9,2.1
		c4.6,0.4,11.5,1,15.9,1.7c14.3,2.7,28.1,6.9,42,11.3c-4.6,12.8-27.2,75.8-30.7,86.5c-3.6,10.9-6.5,27.2-8,35.9l-0.5,2.7
		c-0.4,2.3-1.2,6.4-2.2,11.1c-1.2,6.2-2.3,11.7-3.1,15.9C1399.7,751.4,1384,746.1,1368.4,740.7z M1482.6,783.5
		c-0.4,1.7-1,3.4-1.8,4.9c-4.5,8-9.2,17.5-11.2,22.3c-1,2.5-2.2,5.7-3.6,9.4c-2.4,6.6-5.5,14.7-8.9,21.9c-2.3,4.8-5.6,10.5-8.6,15.3
		l-9.7-5.3c-0.2-0.1-16.8-9.8-21.7-21.5c-1.4-3.4-2.6-5.9-3.5-8c-2.5-5.4-3.2-7.1-2.6-10.5c0.4-2,1.1-3.7,1.8-5.5
		c1.2-2.9,2.5-6.2,2.7-10.9c0.2-3.2,0.5-8.8,0.8-14.4c0.5-7.8,0.9-15.1,0.9-16.9c0-0.4,0.2-1.5,0.4-2.9c7.9,1.9,15.8,3.6,23.8,5.2
		c11.9,2.2,24,4.5,36.3,3.7c3.2-0.4,6.4-0.5,9.5-0.6c-0.7,2-2,4-2.6,6C1483.5,778.6,1483.3,779.8,1482.6,783.5z M1487.1,778.2
		c0,0-0.1,0.1-0.1,0.1c0.9-2.8,3.2-7.7,3.9-9.1c0,0,0,0,0,0c1.4-0.1,2.8-0.3,4.2-0.6C1492.2,771.9,1489.2,775.4,1487.1,778.2z
		 M1531.3,699.7c-4,21.3-13.4,41.7-27.5,58.3c-2.3,2.6-4.8,4.8-7.8,5.7c-5.6,1.5-12.7,1.1-18.7,1.9c-11.7,0.7-23.4-1.6-35-3.7
		c-8-1.5-15.9-3.3-23.8-5.2c0.9-4.8,2.1-11,3.1-16.1c0.9-4.7,1.8-8.8,2.2-11.2l0.5-2.7c1.5-8.7,4.4-24.8,7.9-35.5
		c3.5-10.8,26.2-73.9,30.7-86.6c0.9,0.3,1.8,0.6,2.8,0.9c20.6,7.3,41.2,17.7,61.7,25.3c6,2.2,12.1,4.3,18.1,6.4
		C1541.9,652.7,1534.4,686.4,1531.3,699.7z"
        />
        <polyline
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="1573.7,796 
		1573.9,829.3 1583.4,847.1 	"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="4.2083"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1284.9,573.9
		l-1.3-3.6c-0.7-2,0-4.2,1.7-5.4c1.9-1.2,4.7-3.5,8.8-7.4c8.9-8.7,39.8,1.1,39.8,1.1l90.6,16.3c6.9,1.2,13.8,1.9,20.8,2l18.3,0.3
		c9.4,0.2,18.8,1.3,27.9,3.5l8.4,2c8.9,2.1,17.6,4.8,26.1,8.1l29.8,11.3c0,0,12-1.7,15.1-10.9s15.7-42.7,15.7-42.7"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="4.2083"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1556.8"
          y1="603.6"
          x2="1585.4"
          y2="616.9"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="4.2083"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1463.5,577.3
		c0,0,17.5-4.2,25.7-12.9c8.3-8.7,29.8-26.8,29.8-26.8c4.5,5.8,21.3,18.5,26.2,28.2c4.9,9.7,11.6,36.5,11.6,36.5"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1364.4"
          y1="540.4"
          x2="1472.3"
          y2="576.2"
        />
        <polyline
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		1541.3,561.1 1561.3,568 1577.5,573.6 	"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1546.8,598.1
		c0,0,12-13.9,16.6-29"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1538.1,594.6
		c0,0,14.7-19.9,17-28.1"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1545.4"
          y1="569.9"
          x2="1538.1"
          y2="579.1"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="0.7014"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1503.4,551.1
		c0,0,5,0.8,7.4,1.3s4.2-0.9,4.7-3.7c0.5-2.8,1.4-8.3,1.4-8.3"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1571.1"
          y1="612.4"
          x2="1565.8"
          y2="640.3"
        />

        <line
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="1580"
          y1="615.9"
          x2="1577.1"
          y2="644"
        />
        <path
          fill="none"
          stroke={roadsColor}
          strokeWidth="1.4028"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M1361.9,585.5
		c0,0-30.4-2.1-35-4.9c-4.6-2.8-7-1.9-7.8-9.6c-0.8-7.8-2.2-31.9-2.2-31.9"
        />
        <g>
          <g>
            <g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1153.2,792.2
						c-3.2,4.2-6.3,8.4-9.5,12.7l-4.6,6.4c-1.5,2.2-3.1,4.3-4.4,6.6"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1153.9,792.7
						c-3.2,4.2-6.3,8.4-9.4,12.7l-4.6,6.4c-1.5,2.1-3.1,4.3-4.4,6.5"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1134.7,817.8
						c-2.6,4.7-4.4,9.7-5.8,14.8c-1.4,5.1-1.6,10.5-1.8,15.8"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1135.4,818.2
						c-2.5,4.5-4.3,9.5-5.7,14.6c-1.4,5-1.6,10.3-1.7,15.6"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1127.1,848.3
						c0,1.3-0.1,2.6-0.1,4l0,1c0,0.3,0,0.6-0.1,1l-0.1,2l-0.3,3.9c-0.1,1.3-0.3,2.6-0.5,3.9l-2.9,15.5"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1128,848.4
						c0,1.3-0.1,2.6-0.1,3.9l0,1c0,0.4,0,0.7-0.1,1l-0.1,2l-0.3,3.9c-0.1,1.3-0.3,2.7-0.5,4l-2.9,15.5"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1124.1,879.7
						l1.6-16c0.2-2.7,0.2-5.4,0.3-8.1c0-0.7,0-1.3,0-2l-0.1-2c0-1.3-0.1-2.7-0.1-4"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1123.3,879.6
						l1.6-16c0.2-2.6,0.2-5.3,0.3-8c0-0.7,0-1.4,0-2l-0.1-2c0-1.3-0.1-2.7-0.1-4"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1125.8,847.5
						c-0.1-5.4,0.2-10.7,1.2-16c1-5.2,2.7-10.4,5.1-15.1"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1125,847.5
						c-0.1-5.4,0.2-10.8,1.2-16.1c1-5.3,2.7-10.5,5.2-15.3"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1132.1,816.4
						c2.4-4.8,5.6-9.1,8.6-13.6c3.1-4.3,6.7-8.4,10.2-12.4"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1131.4,816
						c1.2-2.4,2.6-4.7,4.1-7c1.5-2.3,3-4.4,4.5-6.7c3.2-4.4,6.7-8.4,10.3-12.5"
                />
              </g>
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1164.4,800.8
						c-3.8,4.7-7.5,9.4-11.2,14.1c-1.9,2.4-3.7,4.7-5.5,7.1c-0.9,1.2-1.8,2.4-2.6,3.7c-0.8,1.2-1.7,2.4-2.6,3.6"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1165.1,801.3
						c-3.8,4.7-7.5,9.4-11.2,14.1c-1.9,2.4-3.7,4.7-5.5,7.1c-0.9,1.2-1.8,2.4-2.6,3.6c-0.8,1.3-1.8,2.5-2.6,3.7"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1142.4,829.3
						c-1.8,2.4-3.4,5-4.7,7.7c-1.3,2.7-2.4,5.6-3.4,8.4c-0.5,1.4-1,2.8-1.4,4.3l-0.7,2.2l-0.3,1.1c-0.1,0.4-0.2,0.7-0.3,1.1l-2,8.8"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1143.1,829.8
						c-1.8,2.4-3.4,4.9-4.6,7.6c-1.3,2.7-2.3,5.5-3.3,8.3c-0.5,1.4-1,2.8-1.4,4.3l-0.7,2.1l-0.3,1.1c-0.1,0.3-0.2,0.7-0.3,1.1
						l-2,8.8"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1130.5,863
						l0.7-9.2c0.3-3,0.7-6.1,1.3-9.1c0.6-3,1.3-6,2.2-8.9c0.5-1.4,1-2.9,1.8-4.2c0.7-1.3,1.5-2.6,2.4-3.9"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1129.6,862.9
						l0.7-9.2c0.3-3.1,0.7-6.1,1.3-9.2c0.6-3,1.3-6,2.3-9c0.5-1.5,1.1-2.9,1.9-4.3c0.8-1.4,1.6-2.7,2.4-4"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1138.9,827.8
						c3.4-5.1,7.1-10,10.7-14.9c3.7-4.9,7.5-9.8,11.3-14.6"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1138.2,827.4
						c3.4-5.1,7.1-10.1,10.8-15c3.7-4.9,7.5-9.8,11.3-14.6"
                />
              </g>
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1166.9,803.9
						c-7.4,9.1-14.9,18.2-22.1,27.4"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1167.6,804.4
						c-7.4,9.1-14.9,18.1-22.1,27.4"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1144.8,831.3
						c-1.8,2.3-3.6,4.6-5.1,7.2c-1.5,2.6-2.7,5.3-3.7,8c-1.1,2.7-2,5.6-2.8,8.4l-2.4,8.5"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1145.5,831.8
						c-1.8,2.3-3.6,4.6-5,7.1c-1.4,2.5-2.6,5.2-3.7,7.9c-1,2.7-2,5.5-2.7,8.3l-2.4,8.5"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1131.7,863.7
						c2.4-5.2,4.5-10.5,7.3-15.5c1.4-2.5,2.7-5,4.2-7.4c0.7-1.2,1.6-2.3,2.5-3.5l2.7-3.4"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1130.9,863.3
						c2.4-5.2,4.5-10.6,7.4-15.5c1.4-2.5,2.7-5,4.2-7.5c0.7-1.2,1.6-2.4,2.5-3.5l2.7-3.4"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1148.4,833.9
						c7.2-8.9,14.5-17.7,21.8-26.5"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1147.7,833.4
						c7.2-8.9,14.5-17.7,21.8-26.5"
                />
              </g>
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <g>
                <line
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  x1="1172.3"
                  y1="810"
                  x2="1149"
                  y2="839.3"
                />

                <line
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  x1="1173"
                  y1="810.5"
                  x2="1149.6"
                  y2="839.9"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1149,839.3
						l-5.8,7.3c-1,1.2-1.9,2.5-2.9,3.7c-0.9,1.3-1.8,2.6-2.6,3.9c-3.3,5.4-5.7,11.3-7.4,17.2"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1149.6,839.9
						l-5.8,7.3c-1,1.2-1.9,2.4-2.8,3.7c-0.9,1.3-1.8,2.6-2.6,3.9c-3.2,5.3-5.6,11.1-7.4,17.1"
                />
              </g>
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <g>
                <line
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  x1="1174.7"
                  y1="813.5"
                  x2="1157.2"
                  y2="834.5"
                />

                <line
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  x1="1175.4"
                  y1="814.1"
                  x2="1157.9"
                  y2="835.1"
                />
              </g>
              <g>
                <line
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  x1="1157.2"
                  y1="834.5"
                  x2="1139.7"
                  y2="855.5"
                />

                <line
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  x1="1157.9"
                  y1="835.1"
                  x2="1140.3"
                  y2="856.1"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1139.7,855.5
						c-1.4,1.8-3,3.5-4.3,5.4c-1.3,1.9-2.5,3.9-3.4,6c-0.9,2.1-1.7,4.3-2.2,6.5l-1.8,6.6"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1140.3,856.1
						c-1.4,1.8-3,3.5-4.3,5.3c-1.3,1.8-2.4,3.8-3.3,5.9c-0.9,2.1-1.6,4.2-2.2,6.4l-1.8,6.6"
                />
              </g>
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1156.6,795.2
						c-3.2,4-6.5,8-9.6,12.1c-1.6,2-3.2,4.1-4.7,6.2c-1.5,2.1-2.9,4.3-4.3,6.5"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1157.3,795.7
						c-3.2,4-6.5,8-9.6,12.1c-1.6,2-3.2,4.1-4.6,6.2c-1.5,2.1-2.9,4.3-4.3,6.4"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1138.1,820
						c-1.4,2.2-2.7,4.4-3.9,6.7c-1.2,2.3-2.3,4.7-3.1,7.2c-0.7,2.5-1.1,5.1-1.5,7.7c-0.3,2.6-0.6,5.1-0.7,7.7"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1138.8,820.5
						c-1.4,2.2-2.7,4.4-3.8,6.7c-1.2,2.3-2.3,4.6-3,7c-0.7,2.4-1.1,5-1.4,7.5c-0.3,2.5-0.6,5.1-0.7,7.7"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1129,849.3
						c-0.1,1.3-0.2,2.6-0.2,3.9c-0.1,1.3-0.2,2.6-0.3,3.8l-0.3,3.8c-0.2,1.3-0.3,2.5-0.6,3.8l-2.9,15.2"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1129.8,849.4
						c-0.1,1.3-0.2,2.6-0.2,3.9c-0.1,1.3-0.2,2.6-0.3,3.9l-0.3,3.9c-0.2,1.3-0.3,2.6-0.6,3.9l-2.9,15.2"
                />
              </g>
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <g>
                <line
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  x1="997.9"
                  y1="683.7"
                  x2="1026.2"
                  y2="698.6"
                />

                <line
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  x1="998.3"
                  y1="682.9"
                  x2="1026.6"
                  y2="697.9"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1026.2,698.6
						c9.4,4.9,18.8,10,28.1,15.1"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1026.6,697.9
						c9.4,4.9,18.8,10,28.1,15.1"
                />
              </g>
              <g>
                <line
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  x1="1054.3"
                  y1="713.7"
                  x2="1082.4"
                  y2="728.9"
                />

                <line
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  x1="1054.7"
                  y1="713"
                  x2="1082.8"
                  y2="728.1"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1082.4,728.9
						c4.7,2.5,9.5,4.8,14.2,7.3c4.7,2.5,9.3,5.1,13.8,7.9"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1082.8,728.1
						c4.7,2.5,9.5,4.8,14.2,7.3c4.7,2.5,9.4,5.1,13.9,7.9"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1110.5,744.1
						c9,5.6,17.9,11.5,27,17.1"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1110.9,743.4
						c9.1,5.6,18,11.5,27,17.1"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1137.5,761.2
						c4.5,2.8,9.1,5.5,13.7,8.2c2.3,1.3,4.7,2.6,7.2,3.6c2.5,1.1,5,1.9,7.6,2.5"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1137.9,760.4
						c4.5,2.8,9.1,5.5,13.7,8.2c4.6,2.6,9.4,4.9,14.6,6.1"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1166,775.5
						c2.6,0.6,5.3,1,7.9,1.4c2.6,0.4,5.3,0.6,8,0.8c5.3,0.3,10.7,0.2,16-0.9"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1166.2,774.7
						c2.6,0.6,5.2,1,7.8,1.3c2.6,0.4,5.3,0.6,7.9,0.8c5.3,0.3,10.6,0.2,15.8-0.8"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1197.9,776.8
						c10.5-2.1,20.7-5.3,31-7.8"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1197.7,775.9
						c10.4-2.1,20.6-5.3,31-7.8"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1228.9,769
						c5.2-1.1,10.5-1.2,15.8-1c5.3,0.2,10.6,0.5,15.9,1.1"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1228.7,768.2
						c5.3-1.1,10.7-1.2,16-1.1c5.3,0.2,10.7,0.5,16,1.1"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1260.6,769
						c10.6,1.1,21.1,2.6,31.7,4"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1260.7,768.2
						c10.6,1.1,21.2,2.6,31.7,4"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1292.2,773
						l15.8,2.2c5.3,0.7,10.5,1.5,15.8,2.1"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1292.4,772.2
						l15.8,2.2c5.3,0.7,10.5,1.5,15.8,2.1"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1323.9,777.3
						c5.3,0.6,10.6,1.1,16,1.2c5.3,0.2,10.7-0.1,16-0.3"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1324,776.5
						c5.3,0.6,10.6,1.1,15.9,1.2c5.3,0.2,10.6-0.1,15.9-0.3"
                />
              </g>
              <g>
                <line
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  x1="1355.8"
                  y1="778.2"
                  x2="1387.8"
                  y2="777.3"
                />

                <line
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  x1="1355.8"
                  y1="777.3"
                  x2="1387.7"
                  y2="776.5"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1387.8,777.3
						c10.6-0.3,21.3-0.6,31.9-1.1"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1387.7,776.5
						c10.6-0.3,21.3-0.6,31.9-1.1"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1419.7,776.2
						c2.7-0.1,5.3-0.3,8-0.5c2.7-0.2,5.3-0.6,8-1.1c5.2-1,10.4-2.5,15.4-4.6"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1419.7,775.4
						c2.7-0.1,5.3-0.3,8-0.5c2.7-0.2,5.3-0.5,7.9-1.1c5.2-1,10.3-2.5,15.2-4.5"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1451,770.1
						c2.5-1,4.9-2.3,7.1-3.7c2.3-1.5,4.4-3.2,6.2-5.2c3.5-4.2,5.5-9.4,6.6-14.6"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1450.7,769.3
						c2.4-1,4.8-2.3,7-3.7c2.2-1.4,4.3-3.1,6-5.1c3.3-4,5.3-9.1,6.5-14.2"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1470.9,746.5
						c1.1-5.3,1.4-10.7,1-16c-0.2-2.7-0.5-5.3-1-8c-0.5-2.6-1.1-5.2-1.6-7.8"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1470.1,746.3
						c1.1-5.2,1.4-10.5,1-15.8c-0.2-2.6-0.5-5.3-1-7.9c-0.5-2.6-1.1-5.2-1.6-7.8"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1469.3,714.7
						c-1.1-5.2-2-10.4-1.9-15.7c0-5.3,0.9-10.5,2.2-15.7"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1468.5,714.9
						c-1.1-5.2-2-10.5-1.9-15.9c0-5.4,0.9-10.7,2.2-15.9"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1469.6,683.3
						c0.4-1.3,0.7-2.5,1.1-3.8l1.2-3.8l2.5-7.6l4.9-15.2"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1468.8,683.1
						c0.4-1.3,0.7-2.6,1.1-3.8l1.2-3.8l2.5-7.6l4.9-15.2"
                />
              </g>
              <g>
                <line
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  x1="1479.3"
                  y1="652.9"
                  x2="1489.1"
                  y2="622.5"
                />

                <line
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  x1="1478.5"
                  y1="652.6"
                  x2="1488.3"
                  y2="622.3"
                />
              </g>
              <g>
                <line
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  x1="1489.1"
                  y1="622.5"
                  x2="1499"
                  y2="592.1"
                />

                <line
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  x1="1488.3"
                  y1="622.3"
                  x2="1498.1"
                  y2="591.9"
                />
              </g>
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <g>
                <line
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  x1="1343"
                  y1="880.1"
                  x2="1334.9"
                  y2="849.8"
                />

                <line
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  x1="1342.2"
                  y1="880.3"
                  x2="1334.1"
                  y2="850"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1334.9,849.8
						c-2.7-10.1-5.4-20.2-8.1-30.3"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1334.1,850
						c-2.7-10.1-5.4-20.2-8.1-30.3"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1326.8,819.4
						c-2.6-10.1-5-20.3-7.2-30.5"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1326,819.7
						c-2.6-10.1-5-20.3-7.2-30.6"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1319.7,788.9
						c-1.1-5.1-2.2-10.2-2.9-15.4c-0.7-5.2-2.1-10.3-3.4-15.4"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1318.8,789.1
						c-1.1-5.1-2.2-10.2-2.9-15.4c-0.7-5.1-2.1-10.2-3.4-15.2"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1313.3,758.2
						c-2.7-10.1-5.6-20.2-7.9-30.3"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1312.5,758.4
						c-2.7-10.1-5.6-20.1-8-30.4"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1305.4,727.9
						c-2.2-10.2-3.5-20.6-3.8-31"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1304.5,728
						c-2.2-10.3-3.5-20.7-3.8-31.2"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1301.6,696.8
						c-0.2-5.2,0-10.4,0.5-15.6l1.4-15.6"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1300.7,696.9
						c-0.2-5.2,0-10.5,0.5-15.7l1.4-15.6"
                />
              </g>
              <g>
                <line
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  x1="1303.4"
                  y1="665.6"
                  x2="1306.2"
                  y2="634.3"
                />

                <line
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  x1="1302.6"
                  y1="665.5"
                  x2="1305.4"
                  y2="634.3"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1306.2,634.3
						l1.4-15.6c0.2-2.6,0.5-5.2,0.6-7.9l0-3.9l-0.1-3.9"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1305.4,634.3
						l1.4-15.6c0.2-2.6,0.5-5.2,0.6-7.8l0-3.9l-0.1-3.9"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1308.1,603
						c-0.4-10.5-1.1-20.9-1.8-31.3"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1307.2,603
						c-0.4-10.4-1.1-20.9-1.8-31.3"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1306.3,571.7
						c-0.7-10.4-1.5-20.9-2.4-31.3"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1305.4,571.7
						c-0.7-10.4-1.5-20.9-2.4-31.3"
                />
              </g>
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1305.6,540.4
						c0.8,10.6,1.5,21.2,2.1,31.8"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1306.4,540.4
						c0.8,10.6,1.5,21.2,2.1,31.8"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1307.7,572.2
						c0.6,10.6,1.3,21.2,1.6,31.8"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1308.6,572.1
						c0.6,10.6,1.3,21.2,1.6,31.8"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1309.3,604
						c0.1,2.6,0.1,5.3,0,7.9c0,1.3-0.2,2.6-0.3,4l-0.4,4l-1.4,15.9"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1310.1,604
						c0.1,2.7,0.1,5.3,0,8c0,1.3-0.2,2.7-0.3,4l-0.4,4l-1.4,15.9"
                />
              </g>
              <g>
                <line
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  x1="1307.3"
                  y1="635.7"
                  x2="1304.4"
                  y2="667.4"
                />

                <line
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  x1="1308.1"
                  y1="635.8"
                  x2="1305.3"
                  y2="667.5"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1304.4,667.4
						l-1.4,15.9c-0.4,5.3-0.5,10.6-0.2,16"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1305.3,667.5
						l-1.4,15.8c-0.4,5.3-0.5,10.6-0.2,15.9"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1302.8,699.2
						c0.5,10.6,1.9,21.2,4.3,31.6"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1303.6,699.2
						c0.5,10.6,1.9,21.1,4.3,31.4"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1307.1,730.8
						c1.2,5.2,2.7,10.3,4.4,15.3c1.7,5,3.7,10,6.2,14.7"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1308,730.6
						c1.2,5.2,2.7,10.3,4.4,15.3c1.7,5,3.6,10,6.1,14.6"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1317.7,760.9
						c1.2,2.4,2.7,4.6,4.4,6.7c0.5,0.5,0.9,1,1.3,1.5l1.5,1.4c1.2,0.7,2.3,1.4,3.5,2c2.3,1.3,4.7,2.5,7.1,3.6
						c1.2,0.5,2.4,1.1,3.7,1.5c1.3,0.4,2.6,0.9,4,0.9"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1318.4,760.5
						c1.2,2.3,2.6,4.6,4.3,6.6c0.4,0.5,0.8,1,1.3,1.5l1.4,1.3c1.1,0.6,2.3,1.3,3.4,2c2.3,1.3,4.7,2.5,7,3.6c1.2,0.5,2.4,1.1,3.6,1.5
						c1.2,0.4,2.5,0.8,3.7,0.8"
                />
              </g>
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <line
                fill="none"
                stroke={roadsColor}
                strokeWidth="0.3507"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                x1="1127.3"
                y1="880.1"
                x2="1131.7"
                y2="863.6"
              />

              <line
                fill="none"
                stroke={roadsColor}
                strokeWidth="0.3507"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                x1="1126.5"
                y1="879.8"
                x2="1130.9"
                y2="863.4"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <line
                fill="none"
                stroke={roadsColor}
                strokeWidth="0.3507"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                x1="1129.6"
                y1="862.9"
                x2="1126.2"
                y2="879.6"
              />

              <line
                fill="none"
                stroke={roadsColor}
                strokeWidth="0.3507"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                x1="1130.4"
                y1="863"
                x2="1127"
                y2="879.7"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <g>
                <line
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  x1="1583.9"
                  y1="741.7"
                  x2="1552.4"
                  y2="738.1"
                />

                <line
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  x1="1583.8"
                  y1="742.5"
                  x2="1552.3"
                  y2="739"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1552.4,738.1
						c-5.3-0.5-10.6-0.6-15.9-0.6c-5.3,0.1-10.6,0.3-15.9,0.9"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1552.3,739
						c-5.2-0.5-10.5-0.6-15.8-0.6c-5.3,0.1-10.6,0.3-15.8,0.9"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1520.6,738.5
						c-2.6,0.3-5.3,0.7-7.9,1.4c-2.5,0.7-5.1,1.1-7.7,1.3c-5.2,0.3-10.5-0.7-15.3-2.7"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1520.7,739.3
						c-2.6,0.3-5.2,0.7-7.7,1.4c-2.6,0.7-5.2,1.2-7.9,1.3c-5.4,0.3-10.7-0.7-15.7-2.7"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1489.7,738.5
						c-2.4-1-4.8-2.1-6.9-3.7c-2-1.6-3.8-3.6-5.2-5.7c-2.9-4.3-4.8-9.4-6-14.4"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1489.4,739.3
						c-2.5-1-4.9-2.1-7.1-3.8c-2.1-1.7-3.9-3.7-5.4-5.9c-3-4.5-4.9-9.6-6.1-14.7"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1471.6,714.6
						c-2.3-10.2-2.9-21.1-0.1-31.2"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1470.8,714.8
						c-1.3-5.2-1.8-10.5-2-15.8c-0.1-2.7,0.1-5.3,0.4-8l0.2-2c0.1-0.7,0.1-1.3,0.3-2c0.3-1.4,0.7-2.6,1-3.9"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1471.5,683.4
						c3-10.1,6.3-20.2,9.5-30.3"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1470.7,683.2
						c3-10.2,6.3-20.2,9.5-30.3"
                />
              </g>
              <g>
                <line
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  x1="1481"
                  y1="653.1"
                  x2="1490.9"
                  y2="623"
                />

                <line
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  x1="1480.2"
                  y1="652.9"
                  x2="1490.1"
                  y2="622.7"
                />
              </g>
              <g>
                <line
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  x1="1490.9"
                  y1="623"
                  x2="1500.8"
                  y2="592.8"
                />

                <line
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  x1="1490.1"
                  y1="622.7"
                  x2="1500"
                  y2="592.6"
                />
              </g>
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1583.5,743.8
						l-16.2-1.7l-8.1-0.9l-4.1-0.4c-1.3-0.2-2.7-0.3-4.1-0.4"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1583.4,744.7
						l-16.2-1.7l-8.1-0.9l-4.1-0.4c-1.4-0.2-2.7-0.3-4-0.3"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1551,740.4
						c-5.5-0.2-10.9,0-16.4,0.3c-5.4,0.3-10.9,0.8-16.3,1.6"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1550.9,741.3
						c-5.4-0.2-10.9,0-16.3,0.3c-5.4,0.3-10.8,0.8-16.2,1.6"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1518.3,742.4
						c-2.7,0.4-5.4,1.1-8,1.7c-2.6,0.7-5.3,1.3-7.9,2.1c-5.2,1.5-10.5,3-15.5,5.3"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1518.4,743.2
						c-2.7,0.4-5.3,1.1-7.9,1.7c-2.6,0.7-5.3,1.3-7.9,2c-5.2,1.5-10.4,3-15.4,5.2"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1486.9,751.4
						c-5,2.3-9.6,5.2-14.3,8.1l-7,4.3l-7,4.2"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1487.2,752.2
						c-4.9,2.2-9.5,5.2-14.2,8l-7,4.3l-7,4.2"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1458.6,767.9
						c-2.3,1.4-4.6,2.9-7,4.1c-2.4,1.2-4.9,2.2-7.5,2.9c-5.2,1.5-10.6,2.1-16,2.6"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1459.1,768.7
						c-2.3,1.4-4.6,2.9-7.1,4.1c-2.5,1.2-5,2.2-7.7,3c-5.3,1.5-10.7,2.2-16.2,2.7"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1428,777.6
						c-10.8,0.8-21.7,1.1-32.6,1.5"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1428.1,778.4
						c-10.9,0.8-21.8,1.1-32.7,1.5"
                />
              </g>
              <g>
                <line
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  x1="1395.4"
                  y1="779.1"
                  x2="1362.8"
                  y2="780"
                />

                <line
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  x1="1395.5"
                  y1="779.9"
                  x2="1362.8"
                  y2="780.9"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1362.8,780
						c-5.4,0.2-10.9,0.4-16.3,0.7c-2.7,0.2-5.4,0.3-8.1,0.4c-2.7,0.1-5.4,0-8.1-0.2"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1362.8,780.9
						c-5.4,0.2-10.9,0.4-16.3,0.7c-2.7,0.2-5.4,0.3-8.2,0.4c-2.7,0.1-5.5,0-8.2-0.2"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1330.2,780.9
						c-5.4-0.4-10.8-1.3-16.1-2.3c-5.3-1.1-10.6-2.3-16-3.1"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1330.1,781.7
						c-5.5-0.4-10.9-1.3-16.2-2.4c-5.3-1.1-10.6-2.3-16-3.1"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1298.1,775.4
						c-10.8-1.5-21.6-3-32.4-4.2"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1297.9,776.3
						c-10.8-1.5-21.6-3-32.4-4.2"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1265.6,771.2
						c-5.4-0.6-10.9-1-16.3-1.3c-2.7-0.1-5.5-0.2-8.2-0.1c-2.7,0.1-5.5,0.4-8.2,0.8"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1265.6,772.1
						c-5.4-0.6-10.8-1-16.3-1.3c-2.7-0.1-5.4-0.2-8.1-0.1c-2.7,0.1-5.4,0.4-8.1,0.8"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1233,770.7
						c-5.4,0.9-10.7,2.3-15.9,3.8c-5.2,1.5-10.4,3.1-15.8,4.1"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1233.1,771.5
						c-5.3,0.9-10.6,2.3-15.8,3.8c-5.2,1.5-10.5,3.1-15.8,4.1"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1201.3,778.6
						c-5.3,0.8-10.8,1.6-16.2,1.5c-5.4-0.2-10.8-1.1-16.1-2.1"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1201.4,779.4
						c-5.4,0.9-10.8,1.6-16.3,1.5c-5.5-0.2-10.9-1.1-16.2-2.1"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1169,778
						c-5.4-0.9-10.5-2.5-15.4-4.8c-4.9-2.3-9.5-5.2-14.1-8.2"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1168.8,778.8
						c-5.4-0.9-10.7-2.5-15.7-4.9c-5-2.3-9.6-5.3-14.2-8.2"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1139.5,765
						c-2.3-1.5-4.6-2.9-7-4.3l-7-4.3c-4.6-2.9-9.3-5.7-13.9-8.5"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1139,765.7
						c-2.3-1.5-4.6-2.9-6.9-4.3l-7-4.3c-4.6-2.9-9.3-5.7-13.9-8.5"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1111.6,747.9
						c-4.7-2.8-9.4-5.5-14.3-8l-14.4-7.8"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1111.2,748.7
						c-4.7-2.8-9.4-5.5-14.2-8l-14.4-7.8"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1083,732.1
						c-9.6-5.2-19.2-10.4-28.7-15.6"
                />
                <path
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  d="M1082.6,732.9
						c-9.6-5.2-19.2-10.4-28.7-15.6"
                />
              </g>
              <g>
                <line
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  x1="1054.3"
                  y1="716.6"
                  x2="1025.6"
                  y2="700.9"
                />

                <line
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  x1="1053.9"
                  y1="717.3"
                  x2="1025.2"
                  y2="701.7"
                />
              </g>
              <g>
                <line
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  x1="1025.6"
                  y1="700.9"
                  x2="996.9"
                  y2="685.3"
                />
                <line
                  fill="none"
                  stroke={roadsColor}
                  strokeWidth="0.3507"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  x1="1025.2"
                  y1="701.7"
                  x2="996.5"
                  y2="686"
                />
              </g>
            </g>
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M1142.7,805h0.5l1,3.9l0.9-3.9h0.7l-1.2,5h-0.7l-0.9-3.5l-1,3.5h-0.7l-1.2-5h0.7l0.9,3.9L1142.7,805z"
            />
            <path
              fill="#FFFFFF"
              d="M1146.6,809.7c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.4,0.1-0.6,0.3-0.8c0.2-0.2,0.5-0.3,0.9-0.3h1l0,0.6h-1.1
				c-0.2,0-0.3,0-0.4,0.1c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.2,0.1,0.3,0.2,0.4s0.3,0.1,0.5,0.1c0.3,0,0.5,0,0.6-0.1
				c0.1-0.1,0.2-0.1,0.2-0.3l0.1,0.5c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.3,0-0.4,0
				C1147.1,810,1146.8,809.9,1146.6,809.7z M1148.5,807.7c0-0.2-0.1-0.4-0.2-0.5s-0.3-0.2-0.5-0.2c-0.1,0-0.3,0-0.4,0.1
				c-0.1,0-0.3,0.1-0.4,0.2l-0.5-0.3c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.5-0.1,0.7-0.1c0.3,0,0.5,0.1,0.8,0.2
				c0.2,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.2,0.4,0.2,0.7v2.3h-0.7V807.7z"
            />
            <path
              fill="#FFFFFF"
              d="M1149.9,806.4h1.6v0.6h-1.6V806.4z M1150.5,809.7c-0.1-0.2-0.2-0.4-0.2-0.7v-3.7h0.7v3.7
				c0,0.1,0,0.2,0.1,0.2s0.1,0.1,0.2,0.1h0.3v0.7h-0.4C1150.8,810,1150.6,809.9,1150.5,809.7z"
            />
            <path
              fill="#FFFFFF"
              d="M1153.1,809.8c-0.2-0.1-0.4-0.3-0.5-0.5c-0.1-0.2-0.2-0.5-0.2-0.9V808c0-0.4,0.1-0.7,0.2-0.9
				c0.1-0.2,0.3-0.4,0.5-0.6s0.5-0.2,0.8-0.2c0.3,0,0.6,0.1,0.8,0.2s0.4,0.4,0.5,0.6c0.1,0.3,0.2,0.6,0.2,1v0.2h-2.4v-0.6h1.7
				c0-0.3-0.1-0.5-0.2-0.7s-0.3-0.2-0.5-0.2c-0.3,0-0.5,0.1-0.6,0.3c-0.1,0.2-0.2,0.4-0.2,0.7v0.5c0,0.3,0.1,0.5,0.2,0.7
				c0.2,0.2,0.4,0.2,0.7,0.2c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.3-0.1,0.4-0.2l0.5,0.5c-0.2,0.2-0.4,0.3-0.6,0.4
				c-0.2,0.1-0.4,0.1-0.7,0.1C1153.6,810,1153.3,810,1153.1,809.8z"
            />
            <path
              fill="#FFFFFF"
              d="M1156.2,806.4h0.7v3.6h-0.7V806.4z M1157.9,807.1c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0-0.4,0.1-0.5,0.2
				s-0.2,0.3-0.2,0.5l-0.1-0.7c0.1-0.2,0.3-0.4,0.4-0.5c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.3,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2
				l-0.5,0.6C1158.1,807.2,1158,807.1,1157.9,807.1z"
            />
            <path
              fill="#FFFFFF"
              d="M1159.9,809c0,0.1,0,0.2,0.1,0.2c0,0,0.1,0.1,0.2,0.1h0.3v0.7h-0.4c-0.3,0-0.5-0.1-0.6-0.2
				c-0.1-0.2-0.2-0.4-0.2-0.7V805h0.7V809z"
            />
            <path
              fill="#FFFFFF"
              d="M1161.8,809.8c-0.2-0.1-0.4-0.3-0.5-0.5s-0.2-0.5-0.2-0.8v-0.6c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
				c0.2-0.1,0.5-0.2,0.8-0.2s0.6,0.1,0.8,0.2c0.2,0.1,0.4,0.3,0.5,0.5s0.2,0.5,0.2,0.8v0.6c0,0.3-0.1,0.6-0.2,0.8s-0.3,0.4-0.5,0.5
				s-0.5,0.2-0.8,0.2S1162,810,1161.8,809.8z M1163.2,809.1c0.1-0.2,0.2-0.4,0.2-0.6v-0.6c0-0.3-0.1-0.5-0.2-0.6
				c-0.1-0.2-0.3-0.2-0.6-0.2s-0.4,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.2,0.6v0.6c0,0.3,0.1,0.5,0.2,0.6c0.1,0.2,0.3,0.2,0.6,0.2
				S1163,809.3,1163.2,809.1z"
            />
            <path
              fill="#FFFFFF"
              d="M1165.6,809.8c-0.2-0.1-0.4-0.3-0.5-0.5s-0.2-0.5-0.2-0.8v-0.6c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
				c0.2-0.1,0.5-0.2,0.8-0.2s0.6,0.1,0.8,0.2c0.2,0.1,0.4,0.3,0.5,0.5s0.2,0.5,0.2,0.8v0.6c0,0.3-0.1,0.6-0.2,0.8s-0.3,0.4-0.5,0.5
				s-0.5,0.2-0.8,0.2S1165.9,810,1165.6,809.8z M1167,809.1c0.1-0.2,0.2-0.4,0.2-0.6v-0.6c0-0.3-0.1-0.5-0.2-0.6
				c-0.1-0.2-0.3-0.2-0.6-0.2s-0.4,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.2,0.6v0.6c0,0.3,0.1,0.5,0.2,0.6c0.1,0.2,0.3,0.2,0.6,0.2
				S1166.9,809.3,1167,809.1z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M1196.8,777.6h0.5l1,3.9l0.9-3.9h0.7l-1.2,5h-0.7l-0.9-3.5l-1,3.5h-0.7l-1.2-5h0.7l0.9,3.9L1196.8,777.6z
				"
            />
            <path
              fill="#FFFFFF"
              d="M1200.7,782.4c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.4,0.1-0.6,0.3-0.8c0.2-0.2,0.5-0.3,0.9-0.3h1l0,0.6h-1.1
				c-0.2,0-0.3,0-0.4,0.1c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.2,0.1,0.3,0.2,0.4s0.3,0.1,0.5,0.1c0.3,0,0.5,0,0.6-0.1
				c0.1-0.1,0.2-0.1,0.2-0.3l0.1,0.5c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.3,0-0.4,0
				C1201.2,782.6,1200.9,782.5,1200.7,782.4z M1202.5,780.3c0-0.2-0.1-0.4-0.2-0.5s-0.3-0.2-0.5-0.2c-0.1,0-0.3,0-0.4,0.1
				c-0.1,0-0.3,0.1-0.4,0.2l-0.5-0.3c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.5-0.1,0.7-0.1c0.3,0,0.5,0.1,0.8,0.2
				c0.2,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.2,0.4,0.2,0.7v2.3h-0.7V780.3z"
            />
            <path
              fill="#FFFFFF"
              d="M1204,779h1.6v0.6h-1.6V779z M1204.5,782.4c-0.1-0.2-0.2-0.4-0.2-0.7V778h0.7v3.7c0,0.1,0,0.2,0.1,0.2
				s0.1,0.1,0.2,0.1h0.3v0.7h-0.4C1204.9,782.6,1204.7,782.5,1204.5,782.4z"
            />
            <path
              fill="#FFFFFF"
              d="M1207.1,782.4c-0.2-0.1-0.4-0.3-0.5-0.5c-0.1-0.2-0.2-0.5-0.2-0.9v-0.4c0-0.4,0.1-0.7,0.2-0.9
				c0.1-0.2,0.3-0.4,0.5-0.6s0.5-0.2,0.8-0.2c0.3,0,0.6,0.1,0.8,0.2s0.4,0.4,0.5,0.6c0.1,0.3,0.2,0.6,0.2,1v0.2h-2.4v-0.6h1.7
				c0-0.3-0.1-0.5-0.2-0.7s-0.3-0.2-0.5-0.2c-0.3,0-0.5,0.1-0.6,0.3c-0.1,0.2-0.2,0.4-0.2,0.7v0.5c0,0.3,0.1,0.5,0.2,0.7
				c0.2,0.2,0.4,0.2,0.7,0.2c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.3-0.1,0.4-0.2l0.5,0.5c-0.2,0.2-0.4,0.3-0.6,0.4
				c-0.2,0.1-0.4,0.1-0.7,0.1C1207.7,782.6,1207.4,782.6,1207.1,782.4z"
            />
            <path
              fill="#FFFFFF"
              d="M1210.3,779h0.7v3.6h-0.7V779z M1212,779.7c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0-0.4,0.1-0.5,0.2
				s-0.2,0.3-0.2,0.5l-0.1-0.7c0.1-0.2,0.3-0.4,0.4-0.5c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.3,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2
				l-0.5,0.6C1212.2,779.8,1212.1,779.7,1212,779.7z"
            />
            <path
              fill="#FFFFFF"
              d="M1214,781.7c0,0.1,0,0.2,0.1,0.2c0,0,0.1,0.1,0.2,0.1h0.3v0.7h-0.4c-0.3,0-0.5-0.1-0.6-0.2
				c-0.1-0.2-0.2-0.4-0.2-0.7v-4.1h0.7V781.7z"
            />
            <path
              fill="#FFFFFF"
              d="M1215.9,782.4c-0.2-0.1-0.4-0.3-0.5-0.5s-0.2-0.5-0.2-0.8v-0.6c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
				c0.2-0.1,0.5-0.2,0.8-0.2s0.6,0.1,0.8,0.2c0.2,0.1,0.4,0.3,0.5,0.5s0.2,0.5,0.2,0.8v0.6c0,0.3-0.1,0.6-0.2,0.8s-0.3,0.4-0.5,0.5
				s-0.5,0.2-0.8,0.2S1216.1,782.6,1215.9,782.4z M1217.2,781.7c0.1-0.2,0.2-0.4,0.2-0.6v-0.6c0-0.3-0.1-0.5-0.2-0.6
				c-0.1-0.2-0.3-0.2-0.6-0.2s-0.4,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.2,0.6v0.6c0,0.3,0.1,0.5,0.2,0.6c0.1,0.2,0.3,0.2,0.6,0.2
				S1217.1,781.9,1217.2,781.7z"
            />
            <path
              fill="#FFFFFF"
              d="M1219.7,782.4c-0.2-0.1-0.4-0.3-0.5-0.5s-0.2-0.5-0.2-0.8v-0.6c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
				c0.2-0.1,0.5-0.2,0.8-0.2s0.6,0.1,0.8,0.2c0.2,0.1,0.4,0.3,0.5,0.5s0.2,0.5,0.2,0.8v0.6c0,0.3-0.1,0.6-0.2,0.8s-0.3,0.4-0.5,0.5
				s-0.5,0.2-0.8,0.2S1219.9,782.6,1219.7,782.4z M1221.1,781.7c0.1-0.2,0.2-0.4,0.2-0.6v-0.6c0-0.3-0.1-0.5-0.2-0.6
				c-0.1-0.2-0.3-0.2-0.6-0.2s-0.4,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.2,0.6v0.6c0,0.3,0.1,0.5,0.2,0.6c0.1,0.2,0.3,0.2,0.6,0.2
				S1220.9,781.9,1221.1,781.7z"
            />
            <path
              fill="#FFFFFF"
              d="M1224.9,777.6h0.7v5h-0.7V777.6z M1225.3,777.6h2.9v0.7h-2.9V777.6z M1225.3,779.8h2.5v0.7h-2.5V779.8z
				 M1225.3,781.9h2.9v0.7h-2.9V781.9z"
            />
            <path
              fill="#FFFFFF"
              d="M1229.2,782.4c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.4,0.1-0.6,0.3-0.8c0.2-0.2,0.5-0.3,0.9-0.3h1l0,0.6h-1.1
				c-0.2,0-0.3,0-0.4,0.1c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.2,0.1,0.3,0.2,0.4s0.3,0.1,0.5,0.1c0.3,0,0.5,0,0.6-0.1
				c0.1-0.1,0.2-0.1,0.2-0.3l0.1,0.5c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.3,0-0.4,0
				C1229.7,782.6,1229.4,782.5,1229.2,782.4z M1231,780.3c0-0.2-0.1-0.4-0.2-0.5s-0.3-0.2-0.5-0.2c-0.1,0-0.3,0-0.4,0.1
				c-0.1,0-0.3,0.1-0.4,0.2l-0.5-0.3c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.5-0.1,0.7-0.1c0.3,0,0.5,0.1,0.8,0.2
				c0.2,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.2,0.4,0.2,0.7v2.3h-0.7V780.3z"
            />
            <path
              fill="#FFFFFF"
              d="M1233.4,782.6c-0.2,0-0.4-0.1-0.5-0.2c-0.2-0.1-0.3-0.2-0.4-0.3l0.4-0.5c0.2,0.1,0.4,0.3,0.6,0.3
				c0.2,0.1,0.4,0.1,0.6,0.1c0.3,0,0.5,0,0.6-0.1c0.1-0.1,0.2-0.2,0.2-0.4c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.1
				c-0.1,0-0.3,0-0.5-0.1c0,0,0,0-0.1,0s0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.3-0.1-0.4-0.3
				s-0.2-0.3-0.2-0.6s0.1-0.5,0.2-0.6c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.5-0.1,0.8-0.1c0.2,0,0.4,0,0.5,0.1c0.2,0,0.3,0.1,0.5,0.2
				c0.2,0.1,0.3,0.2,0.4,0.3l-0.4,0.5c-0.2-0.1-0.4-0.2-0.5-0.3c-0.2-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.4,0-0.5,0.1
				c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0,0.2,0.1,0.2s0.2,0.1,0.3,0.1s0.2,0,0.4,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0
				c0.2,0,0.5,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.4,0.2,0.6c0,0.3-0.1,0.5-0.2,0.6c-0.1,0.2-0.3,0.3-0.5,0.4
				c-0.2,0.1-0.5,0.1-0.8,0.1C1233.8,782.6,1233.6,782.6,1233.4,782.6z"
            />
            <path
              fill="#FFFFFF"
              d="M1236.1,779h1.6v0.6h-1.6V779z M1236.7,782.4c-0.1-0.2-0.2-0.4-0.2-0.7V778h0.7v3.7c0,0.1,0,0.2,0.1,0.2
				s0.1,0.1,0.2,0.1h0.3v0.7h-0.4C1237,782.6,1236.8,782.5,1236.7,782.4z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M1261.8,790.7c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.1-0.4-0.2-0.5-0.3l0.4-0.5c0.2,0.2,0.4,0.3,0.6,0.4
				s0.5,0.1,0.7,0.1c0.4,0,0.6-0.1,0.8-0.2s0.3-0.3,0.3-0.6v0c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.3-0.2s-0.3-0.1-0.6-0.1
				c0,0,0,0,0,0c0,0,0,0,0,0l-0.1,0c-0.3-0.1-0.6-0.1-0.8-0.2s-0.4-0.2-0.5-0.4c-0.1-0.2-0.2-0.5-0.2-0.8v0c0-0.3,0.1-0.6,0.2-0.8
				c0.1-0.2,0.3-0.4,0.6-0.5s0.6-0.2,1-0.2c0.2,0,0.4,0,0.5,0.1c0.2,0,0.3,0.1,0.5,0.2s0.3,0.2,0.5,0.3l-0.4,0.6
				c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.4-0.1-0.6-0.1c-0.3,0-0.6,0.1-0.8,0.2s-0.3,0.3-0.3,0.6v0c0,0.2,0,0.3,0.1,0.4
				c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.3,0.1,0.6,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0c0.3,0.1,0.6,0.1,0.8,0.2
				c0.2,0.1,0.4,0.2,0.5,0.4s0.2,0.4,0.2,0.8v0c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.2-0.4,0.4-0.6,0.5c-0.3,0.1-0.6,0.2-1,0.2
				C1262.2,790.8,1262,790.8,1261.8,790.7z"
            />
            <path
              fill="#FFFFFF"
              d="M1265.8,790.6c-0.2-0.1-0.4-0.3-0.5-0.5s-0.2-0.5-0.2-0.8v-0.6c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
				c0.2-0.1,0.5-0.2,0.8-0.2s0.6,0.1,0.8,0.2c0.2,0.1,0.4,0.3,0.5,0.5s0.2,0.5,0.2,0.8v0.6c0,0.3-0.1,0.6-0.2,0.8s-0.3,0.4-0.5,0.5
				s-0.5,0.2-0.8,0.2S1266,790.7,1265.8,790.6z M1267.1,789.9c0.1-0.2,0.2-0.4,0.2-0.6v-0.6c0-0.3-0.1-0.5-0.2-0.6
				c-0.1-0.2-0.3-0.2-0.6-0.2s-0.4,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.2,0.6v0.6c0,0.3,0.1,0.5,0.2,0.6c0.1,0.2,0.3,0.2,0.6,0.2
				S1267,790.1,1267.1,789.9z"
            />
            <path
              fill="#FFFFFF"
              d="M1269.7,789.4c0,0.2,0.1,0.4,0.2,0.6s0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5
				l0,0.7c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.4,0-0.7-0.1-0.9-0.4s-0.3-0.6-0.3-1.1v-2.2h0.7V789.4z
				 M1271.2,787.2h0.7v3.6h-0.7V787.2z"
            />
            <path
              fill="#FFFFFF"
              d="M1272.7,787.2h1.6v0.6h-1.6V787.2z M1273.2,790.5c-0.1-0.2-0.2-0.4-0.2-0.7v-3.7h0.7v3.7
				c0,0.1,0,0.2,0.1,0.2s0.1,0.1,0.2,0.1h0.3v0.7h-0.4C1273.6,790.8,1273.4,790.7,1273.2,790.5z"
            />
            <path
              fill="#FFFFFF"
              d="M1275.2,785.8h0.7v5h-0.7V785.8z M1277.4,788.6c0-0.3-0.1-0.4-0.2-0.6c-0.1-0.1-0.3-0.2-0.5-0.2
				c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5l-0.1-0.7c0.1-0.2,0.3-0.4,0.4-0.5s0.4-0.2,0.6-0.2c0.4,0,0.7,0.1,0.9,0.4
				c0.2,0.3,0.3,0.6,0.3,1.1v2.2h-0.7V788.6z"
            />
            <path
              fill="#FFFFFF"
              d="M1278.8,787.2h0.7l0.6,2.5l0.9-2.5h0.5l0.9,2.5l0.6-2.5h0.7l-1.1,3.6h-0.5l-0.9-2.5l-0.9,2.5h-0.5
				L1278.8,787.2z"
            />
            <path
              fill="#FFFFFF"
              d="M1284.7,790.5c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.4,0.1-0.6,0.3-0.8c0.2-0.2,0.5-0.3,0.9-0.3h1l0,0.6h-1.1
				c-0.2,0-0.3,0-0.4,0.1c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.2,0.1,0.3,0.2,0.4s0.3,0.1,0.5,0.1c0.3,0,0.5,0,0.6-0.1
				c0.1-0.1,0.2-0.1,0.2-0.3l0.1,0.5c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.3,0-0.4,0
				C1285.3,790.8,1284.9,790.7,1284.7,790.5z M1286.6,788.5c0-0.2-0.1-0.4-0.2-0.5s-0.3-0.2-0.5-0.2c-0.1,0-0.3,0-0.4,0.1
				c-0.1,0-0.3,0.1-0.4,0.2l-0.5-0.3c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.5-0.1,0.7-0.1c0.3,0,0.5,0.1,0.8,0.2
				c0.2,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.2,0.4,0.2,0.7v2.3h-0.7V788.5z"
            />
            <path
              fill="#FFFFFF"
              d="M1288.3,787.2h0.7v3.6h-0.7V787.2z M1290,787.9c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0-0.4,0.1-0.5,0.2
				s-0.2,0.3-0.2,0.5l-0.1-0.7c0.1-0.2,0.3-0.4,0.4-0.5c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.3,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2
				l-0.5,0.6C1290.2,788,1290.1,787.9,1290,787.9z"
            />
            <path
              fill="#FFFFFF"
              d="M1291.3,785.8h0.7v5h-0.7V785.8z M1291.6,789.4l1.8-2.2h0.8l-2.7,3L1291.6,789.4z M1292.4,788.7l0.4-0.5
				l1.6,2.5h-0.8L1292.4,788.7z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M1123.5,563.7h3.5v0.7h-3.5V563.7z M1124.9,564.1h0.7v4.6h-0.7V564.1z"
            />
            <path
              fill="#FFFFFF"
              d="M1128.1,568.5c-0.2-0.1-0.4-0.3-0.5-0.5c-0.1-0.2-0.2-0.5-0.2-0.9v-0.4c0-0.4,0.1-0.7,0.2-0.9
				c0.1-0.2,0.3-0.4,0.5-0.6s0.5-0.2,0.8-0.2c0.3,0,0.6,0.1,0.8,0.2s0.4,0.4,0.5,0.6c0.1,0.3,0.2,0.6,0.2,1v0.2h-2.4v-0.6h1.7
				c0-0.3-0.1-0.5-0.2-0.7s-0.3-0.2-0.5-0.2c-0.3,0-0.5,0.1-0.6,0.3c-0.1,0.2-0.2,0.4-0.2,0.7v0.5c0,0.3,0.1,0.5,0.2,0.7
				c0.2,0.2,0.4,0.2,0.7,0.2c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.3-0.1,0.4-0.2l0.5,0.5c-0.2,0.2-0.4,0.3-0.6,0.4
				c-0.2,0.1-0.4,0.1-0.7,0.1C1128.6,568.7,1128.3,568.7,1128.1,568.5z"
            />
            <path
              fill="#FFFFFF"
              d="M1131.3,565.1h0.7v3.6h-0.7V565.1z M1133.4,566.5c0-0.3-0.1-0.4-0.2-0.6s-0.3-0.2-0.5-0.2
				c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5l-0.1-0.7c0.1-0.2,0.3-0.4,0.4-0.5s0.4-0.2,0.6-0.2c0.2,0,0.5,0.1,0.6,0.2
				s0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.5,0.1,0.8v2.2h-0.7V566.5z M1135.5,566.5c0-0.3-0.1-0.4-0.2-0.6s-0.3-0.2-0.5-0.2
				c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.6l-0.1-0.7c0.1-0.2,0.3-0.4,0.4-0.5s0.4-0.2,0.6-0.2c0.3,0,0.5,0.1,0.7,0.2
				s0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.5,0.1,0.8v2.2h-0.7V566.5z"
            />
            <path
              fill="#FFFFFF"
              d="M1137.3,565.1h0.7v5h-0.7V565.1z M1138.3,568.6c-0.2-0.1-0.3-0.3-0.4-0.5l0-0.7c0,0.2,0,0.3,0.1,0.4
				s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.1,0.4,0.1c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.6v-0.8c0-0.3-0.1-0.5-0.2-0.6
				c-0.1-0.1-0.3-0.2-0.5-0.2c-0.1,0-0.3,0-0.4,0.1c-0.1,0.1-0.2,0.1-0.2,0.2s-0.1,0.2-0.1,0.4l-0.1-0.7c0.1-0.2,0.2-0.4,0.4-0.5
				c0.2-0.1,0.4-0.2,0.6-0.2c0.3,0,0.5,0.1,0.6,0.2c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.5,0.1,0.8v0.8c0,0.3,0,0.6-0.1,0.8
				c-0.1,0.2-0.2,0.4-0.4,0.5c-0.2,0.1-0.4,0.2-0.7,0.2C1138.6,568.7,1138.4,568.7,1138.3,568.6z"
            />
            <path
              fill="#FFFFFF"
              d="M1141.8,567.7c0,0.1,0,0.2,0.1,0.2c0,0,0.1,0.1,0.2,0.1h0.3v0.7h-0.4c-0.3,0-0.5-0.1-0.6-0.2
				c-0.1-0.2-0.2-0.4-0.2-0.7v-4.1h0.7V567.7z"
            />
            <path
              fill="#FFFFFF"
              d="M1143.7,568.5c-0.2-0.1-0.4-0.3-0.5-0.5c-0.1-0.2-0.2-0.5-0.2-0.9v-0.4c0-0.4,0.1-0.7,0.2-0.9
				c0.1-0.2,0.3-0.4,0.5-0.6s0.5-0.2,0.8-0.2c0.3,0,0.6,0.1,0.8,0.2s0.4,0.4,0.5,0.6c0.1,0.3,0.2,0.6,0.2,1v0.2h-2.4v-0.6h1.7
				c0-0.3-0.1-0.5-0.2-0.7s-0.3-0.2-0.5-0.2c-0.3,0-0.5,0.1-0.6,0.3c-0.1,0.2-0.2,0.4-0.2,0.7v0.5c0,0.3,0.1,0.5,0.2,0.7
				c0.2,0.2,0.4,0.2,0.7,0.2c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.3-0.1,0.4-0.2l0.5,0.5c-0.2,0.2-0.4,0.3-0.6,0.4
				c-0.2,0.1-0.4,0.1-0.7,0.1C1144.2,568.7,1144,568.7,1143.7,568.5z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M1432.2,865.2h0.7v5h-0.7V865.2z M1432.6,869.5h1.3c0.4,0,0.6-0.1,0.8-0.2s0.2-0.3,0.2-0.6v0
				c0-0.2,0-0.3-0.1-0.4s-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.1-0.5-0.1h-1.5v-0.6h1.5c0.3,0,0.5-0.1,0.6-0.2c0.1-0.1,0.2-0.3,0.2-0.5
				c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.1-0.4-0.2-0.7-0.2h-1.4v-0.6h1.6c0.3,0,0.6,0.1,0.8,0.2s0.4,0.3,0.5,0.5s0.2,0.4,0.2,0.7
				c0,0.2,0,0.3-0.1,0.5c-0.1,0.2-0.2,0.3-0.3,0.4s-0.3,0.2-0.5,0.2c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.3,0.3,0.3,0.4
				c0.1,0.2,0.1,0.4,0.1,0.5v0c0,0.3-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.4-0.5,0.5c-0.2,0.1-0.5,0.2-0.8,0.2h-1.6V869.5z"
            />
            <path
              fill="#FFFFFF"
              d="M1437.3,870c-0.2-0.1-0.4-0.3-0.5-0.5s-0.2-0.5-0.2-0.8v-0.6c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
				c0.2-0.1,0.5-0.2,0.8-0.2s0.6,0.1,0.8,0.2c0.2,0.1,0.4,0.3,0.5,0.5s0.2,0.5,0.2,0.8v0.6c0,0.3-0.1,0.6-0.2,0.8s-0.3,0.4-0.5,0.5
				s-0.5,0.2-0.8,0.2S1437.5,870.2,1437.3,870z M1438.6,869.3c0.1-0.2,0.2-0.4,0.2-0.6v-0.6c0-0.3-0.1-0.5-0.2-0.6
				c-0.1-0.2-0.3-0.2-0.6-0.2s-0.4,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.2,0.6v0.6c0,0.3,0.1,0.5,0.2,0.6c0.1,0.2,0.3,0.2,0.6,0.2
				S1438.5,869.5,1438.6,869.3z"
            />
            <path
              fill="#FFFFFF"
              d="M1440.5,866.6h0.7v3.6h-0.7V866.6z M1442.2,867.3c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0-0.4,0.1-0.5,0.2
				s-0.2,0.3-0.2,0.5l-0.1-0.7c0.1-0.2,0.3-0.4,0.4-0.5c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.3,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2
				l-0.5,0.6C1442.4,867.4,1442.3,867.3,1442.2,867.3z"
            />
            <path
              fill="#FFFFFF"
              d="M1444.1,870c-0.2-0.1-0.4-0.3-0.5-0.5s-0.2-0.5-0.2-0.8v-0.6c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
				c0.2-0.1,0.5-0.2,0.8-0.2s0.6,0.1,0.8,0.2c0.2,0.1,0.4,0.3,0.5,0.5s0.2,0.5,0.2,0.8v0.6c0,0.3-0.1,0.6-0.2,0.8s-0.3,0.4-0.5,0.5
				s-0.5,0.2-0.8,0.2S1444.3,870.2,1444.1,870z M1445.4,869.3c0.1-0.2,0.2-0.4,0.2-0.6v-0.6c0-0.3-0.1-0.5-0.2-0.6
				c-0.1-0.2-0.3-0.2-0.6-0.2s-0.4,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.2,0.6v0.6c0,0.3,0.1,0.5,0.2,0.6c0.1,0.2,0.3,0.2,0.6,0.2
				S1445.3,869.5,1445.4,869.3z"
            />
            <path
              fill="#FFFFFF"
              d="M1448,868.8c0,0.2,0.1,0.4,0.2,0.6s0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5l0,0.7
				c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.4,0-0.7-0.1-0.9-0.4s-0.3-0.6-0.3-1.1v-2.2h0.7V868.8z M1449.5,866.6h0.7
				v3.6h-0.7V866.6z"
            />
            <path
              fill="#FFFFFF"
              d="M1451.7,870.1c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V868c0-0.3,0-0.6,0.1-0.8s0.2-0.4,0.4-0.5
				c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2s0.3,0.3,0.4,0.5l-0.1,0.7c0-0.2,0-0.3-0.1-0.4s-0.1-0.2-0.2-0.2
				c-0.1-0.1-0.2-0.1-0.4-0.1c-0.2,0-0.4,0.1-0.5,0.2s-0.2,0.3-0.2,0.6v0.8c0,0.3,0.1,0.5,0.2,0.6s0.3,0.2,0.5,0.2
				c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.1,0.2-0.3c0.1-0.1,0.1-0.2,0.1-0.4l0,0.8c-0.1,0.2-0.2,0.3-0.3,0.4c-0.2,0.1-0.4,0.2-0.6,0.2
				C1452.1,870.2,1451.9,870.2,1451.7,870.1z M1451.7,871.5c-0.2-0.1-0.4-0.2-0.5-0.4l0.5-0.4c0.1,0.1,0.2,0.2,0.3,0.3
				c0.1,0.1,0.3,0.1,0.4,0.1c0.3,0,0.5-0.1,0.6-0.2c0.1-0.1,0.2-0.4,0.2-0.6v-3.6h0.7v3.4c0,0.3-0.1,0.6-0.2,0.8
				c-0.1,0.2-0.3,0.4-0.5,0.5c-0.2,0.1-0.5,0.2-0.8,0.2C1452.2,871.6,1451.9,871.6,1451.7,871.5z"
            />
            <path
              fill="#FFFFFF"
              d="M1455,865.2h0.7v5h-0.7V865.2z M1457.2,868c0-0.3-0.1-0.4-0.2-0.6c-0.1-0.1-0.3-0.2-0.5-0.2
				c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5l-0.1-0.7c0.1-0.2,0.3-0.4,0.4-0.5s0.4-0.2,0.6-0.2c0.4,0,0.7,0.1,0.9,0.4
				c0.2,0.3,0.3,0.6,0.3,1.1v2.2h-0.7V868z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M1521.5,743.2h0.7v5h-0.7V743.2z M1521.8,747.5h2.8v0.7h-2.8V747.5z"
            />
            <path
              fill="#FFFFFF"
              d="M1526,748c-0.2-0.1-0.4-0.3-0.5-0.5s-0.2-0.5-0.2-0.8v-0.6c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
				c0.2-0.1,0.5-0.2,0.8-0.2s0.6,0.1,0.8,0.2c0.2,0.1,0.4,0.3,0.5,0.5s0.2,0.5,0.2,0.8v0.6c0,0.3-0.1,0.6-0.2,0.8s-0.3,0.4-0.5,0.5
				s-0.5,0.2-0.8,0.2S1526.2,748.1,1526,748z M1527.4,747.3c0.1-0.2,0.2-0.4,0.2-0.6v-0.6c0-0.3-0.1-0.5-0.2-0.6
				c-0.1-0.2-0.3-0.2-0.6-0.2s-0.4,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.2,0.6v0.6c0,0.3,0.1,0.5,0.2,0.6c0.1,0.2,0.3,0.2,0.6,0.2
				S1527.2,747.5,1527.4,747.3z"
            />
            <path
              fill="#FFFFFF"
              d="M1529.3,744.6h0.7v3.6h-0.7V744.6z M1531.4,746c0-0.3-0.1-0.4-0.2-0.6c-0.1-0.1-0.3-0.2-0.5-0.2
				c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5l-0.1-0.7c0.1-0.2,0.3-0.4,0.4-0.5s0.4-0.2,0.6-0.2c0.4,0,0.7,0.1,0.9,0.4
				c0.2,0.3,0.3,0.6,0.3,1.1v2.2h-0.7V746z"
            />
            <path
              fill="#FFFFFF"
              d="M1533.6,748c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V746c0-0.3,0-0.6,0.1-0.8s0.2-0.4,0.4-0.5
				c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2s0.3,0.3,0.4,0.5l-0.1,0.7c0-0.2,0-0.3-0.1-0.4s-0.1-0.2-0.2-0.2
				c-0.1-0.1-0.2-0.1-0.4-0.1c-0.2,0-0.4,0.1-0.5,0.2s-0.2,0.3-0.2,0.6v0.8c0,0.3,0.1,0.5,0.2,0.6s0.3,0.2,0.5,0.2
				c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.1,0.2-0.3c0.1-0.1,0.1-0.2,0.1-0.4l0,0.8c-0.1,0.2-0.2,0.3-0.3,0.4c-0.2,0.1-0.4,0.2-0.6,0.2
				C1534.1,748.2,1533.8,748.2,1533.6,748z M1535.2,743.2h0.7v5h-0.7V743.2z"
            />
            <path
              fill="#FFFFFF"
              d="M1537.5,748c-0.2-0.1-0.4-0.3-0.5-0.5s-0.2-0.5-0.2-0.8v-0.6c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
				c0.2-0.1,0.5-0.2,0.8-0.2s0.6,0.1,0.8,0.2c0.2,0.1,0.4,0.3,0.5,0.5s0.2,0.5,0.2,0.8v0.6c0,0.3-0.1,0.6-0.2,0.8s-0.3,0.4-0.5,0.5
				s-0.5,0.2-0.8,0.2S1537.8,748.1,1537.5,748z M1538.9,747.3c0.1-0.2,0.2-0.4,0.2-0.6v-0.6c0-0.3-0.1-0.5-0.2-0.6
				c-0.1-0.2-0.3-0.2-0.6-0.2s-0.4,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.2,0.6v0.6c0,0.3,0.1,0.5,0.2,0.6c0.1,0.2,0.3,0.2,0.6,0.2
				S1538.8,747.5,1538.9,747.3z"
            />
            <path
              fill="#FFFFFF"
              d="M1540.8,744.6h0.7v3.6h-0.7V744.6z M1542.9,746c0-0.3-0.1-0.4-0.2-0.6c-0.1-0.1-0.3-0.2-0.5-0.2
				c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5l-0.1-0.7c0.1-0.2,0.3-0.4,0.4-0.5s0.4-0.2,0.6-0.2c0.4,0,0.7,0.1,0.9,0.4
				c0.2,0.3,0.3,0.6,0.3,1.1v2.2h-0.7V746z"
            />
            <path
              fill="#FFFFFF"
              d="M1546.7,743.2h0.7v5h-0.7V743.2z M1547.1,747.5h1.3c0.4,0,0.6-0.1,0.8-0.2s0.2-0.3,0.2-0.6v0
				c0-0.2,0-0.3-0.1-0.4s-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.1-0.5-0.1h-1.5v-0.6h1.5c0.3,0,0.5-0.1,0.6-0.2c0.1-0.1,0.2-0.3,0.2-0.5
				c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.1-0.4-0.2-0.7-0.2h-1.4v-0.6h1.6c0.3,0,0.6,0.1,0.8,0.2s0.4,0.3,0.5,0.5s0.2,0.4,0.2,0.7
				c0,0.2,0,0.3-0.1,0.5c-0.1,0.2-0.2,0.3-0.3,0.4s-0.3,0.2-0.5,0.2c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.3,0.3,0.3,0.4
				c0.1,0.2,0.1,0.4,0.1,0.5v0c0,0.3-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.4-0.5,0.5c-0.2,0.1-0.5,0.2-0.8,0.2h-1.6V747.5z"
            />
            <path
              fill="#FFFFFF"
              d="M1551.1,744.6h0.7v3.6h-0.7V744.6z M1552.8,745.3c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0-0.4,0.1-0.5,0.2
				s-0.2,0.3-0.2,0.5l-0.1-0.7c0.1-0.2,0.3-0.4,0.4-0.5c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.3,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2
				l-0.5,0.6C1553,745.4,1552.9,745.3,1552.8,745.3z"
            />
            <path
              fill="#FFFFFF"
              d="M1554.1,743.2h0.7v0.7h-0.7V743.2z M1554.1,744.6h0.7v3.6h-0.7V744.6z"
            />
            <path
              fill="#FFFFFF"
              d="M1556.3,748c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V746c0-0.3,0-0.6,0.1-0.8s0.2-0.4,0.4-0.5
				c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2s0.3,0.3,0.4,0.5l-0.1,0.7c0-0.2,0-0.3-0.1-0.4s-0.1-0.2-0.2-0.2
				c-0.1-0.1-0.2-0.1-0.4-0.1c-0.2,0-0.4,0.1-0.5,0.2s-0.2,0.3-0.2,0.6v0.8c0,0.3,0.1,0.5,0.2,0.6s0.3,0.2,0.5,0.2
				c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.1,0.2-0.3c0.1-0.1,0.1-0.2,0.1-0.4l0,0.8c-0.1,0.2-0.2,0.3-0.3,0.4c-0.2,0.1-0.4,0.2-0.6,0.2
				C1556.7,748.2,1556.5,748.2,1556.3,748z M1557.9,743.2h0.7v5h-0.7V743.2z"
            />
            <path
              fill="#FFFFFF"
              d="M1560.1,748c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V746c0-0.3,0-0.6,0.1-0.8s0.2-0.4,0.4-0.5
				c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2s0.3,0.3,0.4,0.5l-0.1,0.7c0-0.2,0-0.3-0.1-0.4s-0.1-0.2-0.2-0.2
				c-0.1-0.1-0.2-0.1-0.4-0.1c-0.2,0-0.4,0.1-0.5,0.2s-0.2,0.3-0.2,0.6v0.8c0,0.3,0.1,0.5,0.2,0.6s0.3,0.2,0.5,0.2
				c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.1,0.2-0.3c0.1-0.1,0.1-0.2,0.1-0.4l0,0.8c-0.1,0.2-0.2,0.3-0.3,0.4c-0.2,0.1-0.4,0.2-0.6,0.2
				C1560.5,748.2,1560.3,748.2,1560.1,748z M1560.1,749.5c-0.2-0.1-0.4-0.2-0.5-0.4l0.5-0.4c0.1,0.1,0.2,0.2,0.3,0.3
				c0.1,0.1,0.3,0.1,0.4,0.1c0.3,0,0.5-0.1,0.6-0.2c0.1-0.1,0.2-0.4,0.2-0.6v-3.6h0.7v3.4c0,0.3-0.1,0.6-0.2,0.8
				c-0.1,0.2-0.3,0.4-0.5,0.5c-0.2,0.1-0.5,0.2-0.8,0.2C1560.6,749.6,1560.3,749.6,1560.1,749.5z"
            />
            <path
              fill="#FFFFFF"
              d="M1564,748c-0.2-0.1-0.4-0.3-0.5-0.5c-0.1-0.2-0.2-0.5-0.2-0.9v-0.4c0-0.4,0.1-0.7,0.2-0.9
				c0.1-0.2,0.3-0.4,0.5-0.6s0.5-0.2,0.8-0.2c0.3,0,0.6,0.1,0.8,0.2s0.4,0.4,0.5,0.6c0.1,0.3,0.2,0.6,0.2,1v0.2h-2.4v-0.6h1.7
				c0-0.3-0.1-0.5-0.2-0.7s-0.3-0.2-0.5-0.2c-0.3,0-0.5,0.1-0.6,0.3c-0.1,0.2-0.2,0.4-0.2,0.7v0.5c0,0.3,0.1,0.5,0.2,0.7
				c0.2,0.2,0.4,0.2,0.7,0.2c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.3-0.1,0.4-0.2l0.5,0.5c-0.2,0.2-0.4,0.3-0.6,0.4
				c-0.2,0.1-0.4,0.1-0.7,0.1C1564.6,748.2,1564.3,748.1,1564,748z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M1549,607.1h0.7v5h-0.7v-3.7l0,0.2l-1.2,2.8h-0.5l-1.2-2.8l0-0.3v3.7h-0.7v-5h0.7l1.5,3.3L1549,607.1z"
            />
            <path
              fill="#FFFFFF"
              d="M1551.4,612c-0.2-0.1-0.4-0.3-0.5-0.5s-0.2-0.5-0.2-0.8V610c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
				c0.2-0.1,0.5-0.2,0.8-0.2s0.6,0.1,0.8,0.2c0.2,0.1,0.4,0.3,0.5,0.5s0.2,0.5,0.2,0.8v0.6c0,0.3-0.1,0.6-0.2,0.8s-0.3,0.4-0.5,0.5
				s-0.5,0.2-0.8,0.2S1551.6,612.1,1551.4,612z M1552.7,611.3c0.1-0.2,0.2-0.4,0.2-0.6V610c0-0.3-0.1-0.5-0.2-0.6
				c-0.1-0.2-0.3-0.2-0.6-0.2s-0.4,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.2,0.6v0.6c0,0.3,0.1,0.5,0.2,0.6c0.1,0.2,0.3,0.2,0.6,0.2
				S1552.6,611.4,1552.7,611.3z"
            />
            <path
              fill="#FFFFFF"
              d="M1554.6,608.6h0.7v3.6h-0.7V608.6z M1556.8,610c0-0.3-0.1-0.4-0.2-0.6c-0.1-0.1-0.3-0.2-0.5-0.2
				c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5l-0.1-0.7c0.1-0.2,0.3-0.4,0.4-0.5s0.4-0.2,0.6-0.2c0.4,0,0.7,0.1,0.9,0.4
				c0.2,0.3,0.3,0.6,0.3,1.1v2.2h-0.7V610z"
            />
            <path
              fill="#FFFFFF"
              d="M1559.3,610.7c0,0.2,0.1,0.4,0.2,0.6s0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5
				l0,0.7c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.4,0-0.7-0.1-0.9-0.4s-0.3-0.6-0.3-1.1v-2.2h0.7V610.7z
				 M1560.7,608.6h0.7v3.6h-0.7V608.6z"
            />
            <path
              fill="#FFFFFF"
              d="M1562.5,608.6h0.7v3.6h-0.7V608.6z M1564.6,610c0-0.3-0.1-0.4-0.2-0.6s-0.3-0.2-0.5-0.2
				c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5l-0.1-0.7c0.1-0.2,0.3-0.4,0.4-0.5s0.4-0.2,0.6-0.2c0.2,0,0.5,0.1,0.6,0.2
				s0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.5,0.1,0.8v2.2h-0.7V610z M1566.7,610c0-0.3-0.1-0.4-0.2-0.6s-0.3-0.2-0.5-0.2
				c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.6l-0.1-0.7c0.1-0.2,0.3-0.4,0.4-0.5s0.4-0.2,0.6-0.2c0.3,0,0.5,0.1,0.7,0.2
				s0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.5,0.1,0.8v2.2h-0.7V610z"
            />
            <path
              fill="#FFFFFF"
              d="M1569.1,612c-0.2-0.1-0.4-0.3-0.5-0.5c-0.1-0.2-0.2-0.5-0.2-0.9v-0.4c0-0.4,0.1-0.7,0.2-0.9
				c0.1-0.2,0.3-0.4,0.5-0.6s0.5-0.2,0.8-0.2c0.3,0,0.6,0.1,0.8,0.2s0.4,0.4,0.5,0.6c0.1,0.3,0.2,0.6,0.2,1v0.2h-2.4v-0.6h1.7
				c0-0.3-0.1-0.5-0.2-0.7s-0.3-0.2-0.5-0.2c-0.3,0-0.5,0.1-0.6,0.3c-0.1,0.2-0.2,0.4-0.2,0.7v0.5c0,0.3,0.1,0.5,0.2,0.7
				c0.2,0.2,0.4,0.2,0.7,0.2c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.3-0.1,0.4-0.2l0.5,0.5c-0.2,0.2-0.4,0.3-0.6,0.4
				c-0.2,0.1-0.4,0.1-0.7,0.1C1569.6,612.2,1569.3,612.1,1569.1,612z"
            />
            <path
              fill="#FFFFFF"
              d="M1572.2,608.6h0.7v3.6h-0.7V608.6z M1574.4,610c0-0.3-0.1-0.4-0.2-0.6c-0.1-0.1-0.3-0.2-0.5-0.2
				c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5l-0.1-0.7c0.1-0.2,0.3-0.4,0.4-0.5s0.4-0.2,0.6-0.2c0.4,0,0.7,0.1,0.9,0.4
				c0.2,0.3,0.3,0.6,0.3,1.1v2.2h-0.7V610z"
            />
            <path
              fill="#FFFFFF"
              d="M1575.9,608.6h1.6v0.6h-1.6V608.6z M1576.4,611.9c-0.1-0.2-0.2-0.4-0.2-0.7v-3.7h0.7v3.7
				c0,0.1,0,0.2,0.1,0.2s0.1,0.1,0.2,0.1h0.3v0.7h-0.4C1576.8,612.1,1576.6,612.1,1576.4,611.9z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M1511.1,551.1h0.7v5h-0.7V551.1z M1511.6,555.4h1.3c0.4,0,0.6-0.1,0.8-0.2s0.2-0.3,0.2-0.6v0
				c0-0.2,0-0.3-0.1-0.4s-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.1-0.5-0.1h-1.5v-0.6h1.5c0.3,0,0.5-0.1,0.6-0.2c0.1-0.1,0.2-0.3,0.2-0.5
				c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.1-0.4-0.2-0.7-0.2h-1.4v-0.6h1.6c0.3,0,0.6,0.1,0.8,0.2s0.4,0.3,0.5,0.5s0.2,0.4,0.2,0.7
				c0,0.2,0,0.3-0.1,0.5c-0.1,0.2-0.2,0.3-0.3,0.4s-0.3,0.2-0.5,0.2c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.3,0.3,0.3,0.4
				c0.1,0.2,0.1,0.4,0.1,0.5v0c0,0.3-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.4-0.5,0.5c-0.2,0.1-0.5,0.2-0.8,0.2h-1.6V555.4z"
            />
            <path
              fill="#FFFFFF"
              d="M1515.8,555.8c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.4,0.1-0.6,0.3-0.8c0.2-0.2,0.5-0.3,0.9-0.3h1l0,0.6h-1.1
				c-0.2,0-0.3,0-0.4,0.1c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.2,0.1,0.3,0.2,0.4s0.3,0.1,0.5,0.1c0.3,0,0.5,0,0.6-0.1
				c0.1-0.1,0.2-0.1,0.2-0.3l0.1,0.5c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.3,0-0.4,0
				C1516.3,556.1,1516,556,1515.8,555.8z M1517.6,553.8c0-0.2-0.1-0.4-0.2-0.5s-0.3-0.2-0.5-0.2c-0.1,0-0.3,0-0.4,0.1
				c-0.1,0-0.3,0.1-0.4,0.2l-0.5-0.3c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.5-0.1,0.7-0.1c0.3,0,0.5,0.1,0.8,0.2
				c0.2,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.2,0.4,0.2,0.7v2.3h-0.7V553.8z"
            />
            <path
              fill="#FFFFFF"
              d="M1519.3,552.5h0.7v3.6h-0.7V552.5z M1521.5,553.9c0-0.3-0.1-0.4-0.2-0.6c-0.1-0.1-0.3-0.2-0.5-0.2
				c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5l-0.1-0.7c0.1-0.2,0.3-0.4,0.4-0.5s0.4-0.2,0.6-0.2c0.4,0,0.7,0.1,0.9,0.4
				c0.2,0.3,0.3,0.6,0.3,1.1v2.2h-0.7V553.9z"
            />
            <path
              fill="#FFFFFF"
              d="M1523.3,551.1h0.7v5h-0.7V551.1z M1523.6,554.7l1.8-2.2h0.8l-2.7,3L1523.6,554.7z M1524.4,554l0.4-0.5
				l1.6,2.5h-0.8L1524.4,554z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M1475.9,608.1c-0.3-0.2-0.5-0.4-0.6-0.7c-0.1-0.3-0.2-0.6-0.2-1v-1.4c0-0.4,0.1-0.7,0.2-1
				c0.1-0.3,0.3-0.5,0.6-0.7c0.3-0.2,0.6-0.2,0.9-0.2c0.3,0,0.5,0.1,0.8,0.2c0.2,0.1,0.4,0.3,0.6,0.5c0.2,0.2,0.3,0.5,0.3,0.8h-0.7
				c0-0.2-0.1-0.3-0.2-0.4s-0.2-0.2-0.4-0.3c-0.1-0.1-0.3-0.1-0.4-0.1c-0.2,0-0.4,0-0.5,0.1c-0.2,0.1-0.3,0.2-0.4,0.4
				c-0.1,0.2-0.1,0.4-0.1,0.6v1.4c0,0.2,0,0.4,0.1,0.6c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.3,0.1,0.5,0.1c0.1,0,0.3,0,0.4-0.1
				s0.3-0.2,0.4-0.3c0.1-0.1,0.2-0.3,0.2-0.4h0.7c-0.1,0.3-0.2,0.6-0.3,0.8s-0.4,0.4-0.6,0.5c-0.2,0.1-0.5,0.2-0.8,0.2
				C1476.5,608.3,1476.2,608.3,1475.9,608.1z"
            />
            <path
              fill="#FFFFFF"
              d="M1479.6,608.1c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.4,0.1-0.6,0.3-0.8c0.2-0.2,0.5-0.3,0.9-0.3h1l0,0.6h-1.1
				c-0.2,0-0.3,0-0.4,0.1c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.2,0.1,0.3,0.2,0.4s0.3,0.1,0.5,0.1c0.3,0,0.5,0,0.6-0.1
				c0.1-0.1,0.2-0.1,0.2-0.3l0.1,0.5c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.3,0-0.4,0
				C1480.1,608.3,1479.8,608.3,1479.6,608.1z M1481.5,606c0-0.2-0.1-0.4-0.2-0.5s-0.3-0.2-0.5-0.2c-0.1,0-0.3,0-0.4,0.1
				c-0.1,0-0.3,0.1-0.4,0.2l-0.5-0.3c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.5-0.1,0.7-0.1c0.3,0,0.5,0.1,0.8,0.2
				c0.2,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.2,0.4,0.2,0.7v2.3h-0.7V606z"
            />
            <path
              fill="#FFFFFF"
              d="M1483.2,604.7h0.7v3.6h-0.7V604.7z M1485.3,606.1c0-0.3-0.1-0.4-0.2-0.6c-0.1-0.1-0.3-0.2-0.5-0.2
				c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5l-0.1-0.7c0.1-0.2,0.3-0.4,0.4-0.5s0.4-0.2,0.6-0.2c0.4,0,0.7,0.1,0.9,0.4
				c0.2,0.3,0.3,0.6,0.3,1.1v2.2h-0.7V606.1z"
            />
            <path
              fill="#FFFFFF"
              d="M1487.1,604.7h0.7v3.6h-0.7V604.7z M1489.3,606.1c0-0.3-0.1-0.4-0.2-0.6c-0.1-0.1-0.3-0.2-0.5-0.2
				c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5l-0.1-0.7c0.1-0.2,0.3-0.4,0.4-0.5s0.4-0.2,0.6-0.2c0.4,0,0.7,0.1,0.9,0.4
				c0.2,0.3,0.3,0.6,0.3,1.1v2.2h-0.7V606.1z"
            />
            <path
              fill="#FFFFFF"
              d="M1491.6,608.2c-0.2-0.1-0.4-0.3-0.5-0.5s-0.2-0.5-0.2-0.8v-0.6c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
				c0.2-0.1,0.5-0.2,0.8-0.2s0.6,0.1,0.8,0.2c0.2,0.1,0.4,0.3,0.5,0.5s0.2,0.5,0.2,0.8v0.6c0,0.3-0.1,0.6-0.2,0.8s-0.3,0.4-0.5,0.5
				s-0.5,0.2-0.8,0.2S1491.8,608.3,1491.6,608.2z M1492.9,607.5c0.1-0.2,0.2-0.4,0.2-0.6v-0.6c0-0.3-0.1-0.5-0.2-0.6
				c-0.1-0.2-0.3-0.2-0.6-0.2s-0.4,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.2,0.6v0.6c0,0.3,0.1,0.5,0.2,0.6c0.1,0.2,0.3,0.2,0.6,0.2
				S1492.8,607.6,1492.9,607.5z"
            />
            <path
              fill="#FFFFFF"
              d="M1494.8,604.7h0.7v3.6h-0.7V604.7z M1497,606.1c0-0.3-0.1-0.4-0.2-0.6c-0.1-0.1-0.3-0.2-0.5-0.2
				c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5l-0.1-0.7c0.1-0.2,0.3-0.4,0.4-0.5s0.4-0.2,0.6-0.2c0.4,0,0.7,0.1,0.9,0.4
				c0.2,0.3,0.3,0.6,0.3,1.1v2.2h-0.7V606.1z"
            />
            <path
              fill="#FFFFFF"
              d="M1501.5,608.3c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.1-0.4-0.2-0.5-0.3l0.4-0.5c0.2,0.2,0.4,0.3,0.6,0.4
				s0.5,0.1,0.7,0.1c0.4,0,0.6-0.1,0.8-0.2s0.3-0.3,0.3-0.6v0c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.3-0.2s-0.3-0.1-0.6-0.1
				c0,0,0,0,0,0c0,0,0,0,0,0l-0.1,0c-0.3-0.1-0.6-0.1-0.8-0.2s-0.4-0.2-0.5-0.4c-0.1-0.2-0.2-0.5-0.2-0.8v0c0-0.3,0.1-0.6,0.2-0.8
				c0.1-0.2,0.3-0.4,0.6-0.5s0.6-0.2,1-0.2c0.2,0,0.4,0,0.5,0.1c0.2,0,0.3,0.1,0.5,0.2s0.3,0.2,0.5,0.3l-0.4,0.6
				c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.4-0.1-0.6-0.1c-0.3,0-0.6,0.1-0.8,0.2s-0.3,0.3-0.3,0.6v0c0,0.2,0,0.3,0.1,0.4
				c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.3,0.1,0.6,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0c0.3,0.1,0.6,0.1,0.8,0.2
				c0.2,0.1,0.4,0.2,0.5,0.4s0.2,0.4,0.2,0.8v0c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.2-0.4,0.4-0.6,0.5c-0.3,0.1-0.6,0.2-1,0.2
				C1502,608.3,1501.7,608.3,1501.5,608.3z"
            />
            <path
              fill="#FFFFFF"
              d="M1504.7,604.7h1.6v0.6h-1.6V604.7z M1505.3,608.1c-0.1-0.2-0.2-0.4-0.2-0.7v-3.7h0.7v3.7
				c0,0.1,0,0.2,0.1,0.2s0.1,0.1,0.2,0.1h0.3v0.7h-0.4C1505.6,608.3,1505.4,608.2,1505.3,608.1z"
            />
            <path
              fill="#FFFFFF"
              d="M1507.2,604.7h0.7v3.6h-0.7V604.7z M1509,605.4c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0-0.4,0.1-0.5,0.2
				s-0.2,0.3-0.2,0.5l-0.1-0.7c0.1-0.2,0.3-0.4,0.4-0.5c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.3,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2
				l-0.5,0.6C1509.1,605.5,1509.1,605.5,1509,605.4z"
            />
            <path
              fill="#FFFFFF"
              d="M1510.9,608.2c-0.2-0.1-0.4-0.3-0.5-0.5c-0.1-0.2-0.2-0.5-0.2-0.9v-0.4c0-0.4,0.1-0.7,0.2-0.9
				c0.1-0.2,0.3-0.4,0.5-0.6s0.5-0.2,0.8-0.2c0.3,0,0.6,0.1,0.8,0.2s0.4,0.4,0.5,0.6c0.1,0.3,0.2,0.6,0.2,1v0.2h-2.4v-0.6h1.7
				c0-0.3-0.1-0.5-0.2-0.7s-0.3-0.2-0.5-0.2c-0.3,0-0.5,0.1-0.6,0.3c-0.1,0.2-0.2,0.4-0.2,0.7v0.5c0,0.3,0.1,0.5,0.2,0.7
				c0.2,0.2,0.4,0.2,0.7,0.2c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.3-0.1,0.4-0.2l0.5,0.5c-0.2,0.2-0.4,0.3-0.6,0.4
				c-0.2,0.1-0.4,0.1-0.7,0.1C1511.4,608.3,1511.1,608.3,1510.9,608.2z"
            />
            <path
              fill="#FFFFFF"
              d="M1514.6,608.2c-0.2-0.1-0.4-0.3-0.5-0.5c-0.1-0.2-0.2-0.5-0.2-0.9v-0.4c0-0.4,0.1-0.7,0.2-0.9
				c0.1-0.2,0.3-0.4,0.5-0.6s0.5-0.2,0.8-0.2c0.3,0,0.6,0.1,0.8,0.2s0.4,0.4,0.5,0.6c0.1,0.3,0.2,0.6,0.2,1v0.2h-2.4v-0.6h1.7
				c0-0.3-0.1-0.5-0.2-0.7s-0.3-0.2-0.5-0.2c-0.3,0-0.5,0.1-0.6,0.3c-0.1,0.2-0.2,0.4-0.2,0.7v0.5c0,0.3,0.1,0.5,0.2,0.7
				c0.2,0.2,0.4,0.2,0.7,0.2c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.3-0.1,0.4-0.2l0.5,0.5c-0.2,0.2-0.4,0.3-0.6,0.4
				c-0.2,0.1-0.4,0.1-0.7,0.1C1515.1,608.3,1514.8,608.3,1514.6,608.2z"
            />
            <path
              fill="#FFFFFF"
              d="M1517.5,604.7h1.6v0.6h-1.6V604.7z M1518.1,608.1c-0.1-0.2-0.2-0.4-0.2-0.7v-3.7h0.7v3.7
				c0,0.1,0,0.2,0.1,0.2s0.1,0.1,0.2,0.1h0.3v0.7h-0.4C1518.4,608.3,1518.2,608.2,1518.1,608.1z"
            />
          </g>
        </g>
        <g>
          <polygon
            fill="#E2E0E0"
            points="1549.8,723.1 1545.2,698 1546.1,693.9 		"
          />
          <g>
            <path
              d="M1551,730L1551,730l-1.2-6.7l-4.6-25.2l-0.2-0.9l-1.4-7.5l-7.1,40.6l14.5-0.1L1551,730z M1542.3,729.8h-0.4l-4.9,0
				l6.6-38.1l1.8,10.1v0l0.1,0.4l5,27.6L1542.3,729.8z"
            />
            <polygon
              fill="#E2E0E0"
              points="1545.5,701.8 1545.5,701.8 1543.7,703.4 1541.9,729.8 1537,729.9 1543.7,691.7 			"
            />
            <polygon
              fill="#E2E0E0"
              points="1550.6,729.8 1542.3,729.8 1544.1,703.6 1545.6,702.2 			"
            />
          </g>
          <g>
            <polygon
              points="1551,729.9 1551,729.9 1550.6,729.9 1549.8,723.1 1546.1,693.9 1545.2,698 1545.2,698 1544.8,698 1545,697 
				1546.2,691.8 			"
            />
          </g>
          <g>
            <polygon
              points="1545.8,701.9 1545.6,702.2 1544.1,703.5 1542.3,729.8 1542.3,729.9 1541.9,729.9 1541.9,729.8 1543.7,703.3 
				1545.5,701.7 1545.5,701.7 1545.6,701.7 			"
            />
          </g>

          <rect
            x="1535.7"
            y="729.6"
            fill="#E2E0E0"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            width="16.3"
            height="1.9"
          />
        </g>
        <g>
          <g>
            <rect
              x="1341.6"
              y="678.2"
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              width="36.2"
              height="7.9"
            />

            <rect
              x="1353.8"
              y="679.1"
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              width="11.5"
              height="1.1"
            />

            <rect
              x="1353.8"
              y="681"
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              width="11.5"
              height="1.6"
            />

            <rect
              x="1353.4"
              y="683.4"
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              width="11.8"
              height="1.9"
            />

            <rect
              x="1341.6"
              y="679.1"
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              width="12.5"
              height="7"
            />

            <rect
              x="1365.3"
              y="679.1"
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              width="12.5"
              height="7"
            />

            <rect
              x="1357"
              y="678.2"
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              width="5"
              height="7.9"
            />

            <rect
              x="1358.2"
              y="660.4"
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              width="2.8"
              height="17.8"
            />

            <rect
              x="1358.4"
              y="657.4"
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              width="2.3"
              height="3"
            />

            <rect
              x="1350.2"
              y="680.2"
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              width="1.4"
              height="5.1"
            />

            <rect
              x="1347.1"
              y="680.2"
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              width="1.4"
              height="5.1"
            />

            <rect
              x="1351.7"
              y="680.2"
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              width="2.8"
              height="5.9"
            />

            <rect
              x="1341"
              y="680.2"
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              width="2.7"
              height="5.9"
            />

            <rect
              x="1375.5"
              y="680.2"
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              width="2.8"
              height="5.9"
            />

            <rect
              x="1365.1"
              y="680.2"
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              width="2.5"
              height="5.9"
            />

            <rect
              x="1343.8"
              y="680.2"
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              width="1.4"
              height="5.1"
            />

            <rect
              x="1374.1"
              y="680.2"
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              width="1.4"
              height="5.1"
            />

            <rect
              x="1371"
              y="680.2"
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              width="1.4"
              height="5.1"
            />

            <rect
              x="1367.6"
              y="680.2"
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              width="1.4"
              height="5.1"
            />

            <rect
              x="1372.4"
              y="680.2"
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              width="1.7"
              height="5.9"
            />

            <rect
              x="1368.9"
              y="680.2"
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              width="2"
              height="5.9"
            />

            <rect
              x="1348.6"
              y="680.2"
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              width="1.7"
              height="5.9"
            />

            <rect
              x="1345.1"
              y="680.2"
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              width="2"
              height="5.9"
            />
          </g>
        </g>
        <g>
          <g>
            <circle
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              cx="1079.7"
              cy="774.3"
              r="14.3"
            />
            <circle
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              cx="1079.7"
              cy="774.3"
              r="1.8"
            />
            <path
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              d="M1094.4,774.3c0,8.2-6.6,14.8-14.8,14.8
				c-8.2,0-14.8-6.6-14.8-14.8c0-8.2,6.6-14.8,14.8-14.8C1087.8,759.5,1094.4,766.1,1094.4,774.3z M1079.7,760.8
				c-7.4,0-13.4,6-13.4,13.4c0,7.4,6,13.4,13.4,13.4c7.4,0,13.4-6,13.4-13.4C1093.1,766.9,1087.1,760.8,1079.7,760.8z"
            />
            <g>
              <circle fill="#E2E0E0" cx="1079.7" cy="790.2" r="1" />
              <path
                d="M1079.9,791.4c-0.6,0.1-1.3-0.3-1.4-1c-0.1-0.6,0.3-1.3,1-1.4s1.3,0.3,1.4,1C1081,790.7,1080.5,791.3,1079.9,791.4z
					 M1079.5,789.4c-0.5,0.1-0.8,0.5-0.7,1c0.1,0.5,0.5,0.8,1,0.7c0.5-0.1,0.8-0.5,0.7-1C1080.4,789.6,1080,789.3,1079.5,789.4z"
              />
            </g>
            <g>
              <circle fill="#E2E0E0" cx="1079.7" cy="758.3" r="1" />
              <path
                d="M1079.9,759.5c-0.6,0.1-1.3-0.3-1.4-1c-0.1-0.6,0.3-1.3,1-1.4s1.3,0.3,1.4,1C1081,758.8,1080.5,759.4,1079.9,759.5z
					 M1079.5,757.5c-0.5,0.1-0.8,0.5-0.7,1c0.1,0.5,0.5,0.8,1,0.7c0.5-0.1,0.8-0.5,0.7-1C1080.4,757.7,1080,757.4,1079.5,757.5z"
              />
            </g>
            <g>
              <circle fill="#E2E0E0" cx="1095.6" cy="774.3" r="1" />
              <path
                d="M1096.8,774.1c0.1,0.6-0.3,1.3-1,1.4s-1.3-0.3-1.4-1c-0.1-0.6,0.3-1.3,1-1.4C1096.1,773,1096.7,773.4,1096.8,774.1z
					 M1094.8,774.4c0.1,0.5,0.5,0.8,1,0.7c0.5-0.1,0.8-0.5,0.7-1s-0.5-0.8-1-0.7C1095,773.5,1094.7,774,1094.8,774.4z"
              />
            </g>
            <g>
              <circle fill="#E2E0E0" cx="1063.7" cy="774.3" r="1" />
              <path
                d="M1064.9,774.1c0.1,0.6-0.3,1.3-1,1.4s-1.3-0.3-1.4-1c-0.1-0.6,0.3-1.3,1-1.4S1064.8,773.4,1064.9,774.1z M1062.9,774.4
					c0.1,0.5,0.5,0.8,1,0.7c0.5-0.1,0.8-0.5,0.7-1s-0.5-0.8-1-0.7C1063.1,773.5,1062.8,774,1062.9,774.4z"
              />
            </g>
            <g>
              <circle fill="#E2E0E0" cx="1091" cy="785.6" r="1" />
              <path
                d="M1091.9,786.3c-0.4,0.5-1.1,0.7-1.7,0.3c-0.5-0.4-0.7-1.1-0.3-1.7c0.4-0.5,1.1-0.7,1.7-0.3
					C1092.2,785,1092.3,785.7,1091.9,786.3z M1090.3,785.1c-0.3,0.4-0.2,0.9,0.2,1.2c0.4,0.3,0.9,0.2,1.2-0.2
					c0.3-0.4,0.2-0.9-0.2-1.2C1091.1,784.6,1090.5,784.7,1090.3,785.1z"
              />
            </g>
            <g>
              <circle fill="#E2E0E0" cx="1068.4" cy="763" r="1" />
              <path
                d="M1069.4,763.7c-0.4,0.5-1.1,0.7-1.7,0.3s-0.7-1.1-0.3-1.7c0.4-0.5,1.1-0.7,1.7-0.3S1069.7,763.2,1069.4,763.7z
					 M1067.7,762.5c-0.3,0.4-0.2,0.9,0.2,1.2c0.4,0.3,0.9,0.2,1.2-0.2s0.2-0.9-0.2-1.2C1068.5,762.1,1068,762.2,1067.7,762.5z"
              />
            </g>
            <g>
              <circle fill="#E2E0E0" cx="1068.4" cy="785.6" r="1" />
              <path
                d="M1067.7,786.5c-0.5-0.4-0.7-1.1-0.3-1.7c0.4-0.5,1.1-0.7,1.7-0.3s0.7,1.1,0.3,1.7C1069,786.8,1068.3,786.9,1067.7,786.5
					z M1068.9,784.9c-0.4-0.3-0.9-0.2-1.2,0.2c-0.3,0.4-0.2,0.9,0.2,1.2c0.4,0.3,0.9,0.2,1.2-0.2
					C1069.3,785.7,1069.3,785.2,1068.9,784.9z"
              />
            </g>
            <g>
              <circle fill="#E2E0E0" cx="1091" cy="763" r="1" />
              <path
                d="M1090.3,764c-0.5-0.4-0.7-1.1-0.3-1.7c0.4-0.5,1.1-0.7,1.7-0.3c0.5,0.4,0.7,1.1,0.3,1.7
					C1091.6,764.2,1090.8,764.4,1090.3,764z M1091.4,762.3c-0.4-0.3-0.9-0.2-1.2,0.2c-0.3,0.4-0.2,0.9,0.2,1.2
					c0.4,0.3,0.9,0.2,1.2-0.2C1091.9,763.1,1091.8,762.6,1091.4,762.3z"
              />
            </g>
            <g>
              <circle fill="#E2E0E0" cx="1073.6" cy="789" r="1" />
              <path
                d="M1073.3,790.2c-0.6-0.1-1-0.8-0.9-1.4c0.1-0.6,0.8-1,1.4-0.9c0.6,0.1,1,0.8,0.9,1.4
					C1074.6,789.9,1074,790.3,1073.3,790.2z M1073.8,788.2c-0.5-0.1-0.9,0.2-1,0.6c-0.1,0.5,0.2,0.9,0.6,1c0.5,0.1,0.9-0.2,1-0.6
					C1074.5,788.8,1074.2,788.3,1073.8,788.2z"
              />
            </g>
            <g>
              <circle fill="#E2E0E0" cx="1085.8" cy="759.5" r="1" />
              <path
                d="M1085.5,760.7c-0.6-0.1-1-0.8-0.9-1.4c0.1-0.6,0.8-1,1.4-0.9c0.6,0.1,1,0.8,0.9,1.4
					C1086.8,760.4,1086.2,760.8,1085.5,760.7z M1086,758.7c-0.5-0.1-0.9,0.2-1,0.6c-0.1,0.5,0.2,0.9,0.6,1c0.5,0.1,0.9-0.2,1-0.6
					C1086.7,759.3,1086.4,758.8,1086,758.7z"
              />
            </g>
            <g>
              <circle fill="#E2E0E0" cx="1094.4" cy="780.4" r="1" />
              <path
                d="M1095.6,780.6c-0.1,0.6-0.8,1-1.4,0.9c-0.6-0.1-1-0.8-0.9-1.4c0.1-0.6,0.8-1,1.4-0.9
					C1095.3,779.4,1095.7,780,1095.6,780.6z M1093.6,780.2c-0.1,0.5,0.2,0.9,0.6,1c0.5,0.1,0.9-0.2,1-0.6c0.1-0.5-0.2-0.9-0.6-1
					C1094.2,779.5,1093.7,779.8,1093.6,780.2z"
              />
            </g>
            <g>
              <circle fill="#E2E0E0" cx="1064.9" cy="768.2" r="1" />
              <path
                d="M1066.1,768.5c-0.1,0.6-0.8,1-1.4,0.9c-0.6-0.1-1-0.8-0.9-1.4c0.1-0.6,0.8-1,1.4-0.9
					C1065.8,767.2,1066.2,767.8,1066.1,768.5z M1064.1,768c-0.1,0.5,0.2,0.9,0.6,1c0.5,0.1,0.9-0.2,1-0.6s-0.2-0.9-0.6-1
					C1064.7,767.3,1064.2,767.6,1064.1,768z"
              />
            </g>
            <g>
              <circle fill="#E2E0E0" cx="1085.8" cy="789" r="1" />
              <path
                d="M1086.4,790c-0.6,0.4-1.3,0.2-1.6-0.4c-0.4-0.6-0.2-1.3,0.4-1.6c0.6-0.4,1.3-0.2,1.6,0.4
					C1087.2,788.9,1087,789.7,1086.4,790z M1085.4,788.3c-0.4,0.2-0.5,0.8-0.3,1.2c0.2,0.4,0.8,0.5,1.2,0.3c0.4-0.2,0.5-0.8,0.3-1.2
					C1086.3,788.2,1085.7,788.1,1085.4,788.3z"
              />
            </g>
            <g>
              <circle fill="#E2E0E0" cx="1073.6" cy="759.6" r="1" />
              <path
                d="M1074.2,760.6c-0.6,0.4-1.3,0.2-1.6-0.4c-0.4-0.6-0.2-1.3,0.4-1.6c0.6-0.4,1.3-0.2,1.6,0.4
					C1074.9,759.5,1074.8,760.2,1074.2,760.6z M1073.1,758.9c-0.4,0.2-0.5,0.8-0.3,1.2c0.2,0.4,0.8,0.5,1.2,0.3
					c0.4-0.2,0.5-0.8,0.3-1.2C1074,758.7,1073.5,758.6,1073.1,758.9z"
              />
            </g>
            <g>
              <circle fill="#E2E0E0" cx="1064.9" cy="780.4" r="1" />
              <path
                d="M1063.9,781.1c-0.4-0.6-0.2-1.3,0.4-1.6c0.6-0.4,1.3-0.2,1.6,0.4s0.2,1.3-0.4,1.6C1065,781.8,1064.3,781.6,1063.9,781.1
					z M1065.7,780c-0.2-0.4-0.8-0.5-1.2-0.3c-0.4,0.2-0.5,0.8-0.3,1.2c0.2,0.4,0.8,0.5,1.2,0.3
					C1065.8,780.9,1065.9,780.4,1065.7,780z"
              />
            </g>
            <g>
              <circle fill="#E2E0E0" cx="1094.4" cy="768.2" r="1" />
              <path
                d="M1093.4,768.8c-0.4-0.6-0.2-1.3,0.4-1.6c0.6-0.4,1.3-0.2,1.6,0.4c0.4,0.6,0.2,1.3-0.4,1.6
					C1094.5,769.5,1093.8,769.4,1093.4,768.8z M1095.1,767.7c-0.2-0.4-0.8-0.5-1.2-0.3c-0.4,0.2-0.5,0.8-0.3,1.2
					c0.2,0.4,0.8,0.5,1.2,0.3C1095.3,768.6,1095.4,768.1,1095.1,767.7z"
              />
            </g>
          </g>

          <line
            fill="#E2E0E0"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            x1="1064.9"
            y1="774.3"
            x2="1094.3"
            y2="774.3"
          />

          <line
            fill="#E2E0E0"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            x1="1079.6"
            y1="759.6"
            x2="1079.6"
            y2="789"
          />

          <line
            fill="#E2E0E0"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            x1="1069.2"
            y1="763.9"
            x2="1090"
            y2="784.7"
          />

          <line
            fill="#E2E0E0"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            x1="1090"
            y1="763.9"
            x2="1069.2"
            y2="784.7"
          />

          <line
            fill="#E2E0E0"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            x1="1066"
            y1="768.8"
            x2="1093.3"
            y2="779.7"
          />

          <line
            fill="#E2E0E0"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            x1="1066.1"
            y1="780"
            x2="1093.2"
            y2="768.6"
          />

          <line
            fill="#E2E0E0"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            x1="1074"
            y1="787.9"
            x2="1085.2"
            y2="760.7"
          />

          <line
            fill="#E2E0E0"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            x1="1074"
            y1="760.7"
            x2="1085.2"
            y2="787.9"
          />
          <polygon
            fill="#E2E0E0"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            points="1071.6,793.3 1079.7,775.4 
			1087.8,793.3 1090,793.3 1079.7,774.3 1069.3,793.3 		"
          />
        </g>
        <g>
          <path
            fill="#E2E0E0"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            d="M1283.3,658.2l3.3-22.4l-4.2-12.2
			l-4.8,3.2c0,0-2.3,8.9-2.1,14.5c0.2,5.6,1.7,16.8,1.7,16.8L1283.3,658.2z"
          />
          <path
            fill="none"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            d="M1278.3,658c0,0-1.6-11.6-1.8-17.2
			s2.1-14.5,2.1-14.5"
          />
          <polyline
            fill="#E2E0E0"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            points="1280.8,624.4 1283.2,637 
			1281.4,658.2 		"
          />
        </g>
        <g>
          <rect
            x="1192.7"
            y="627.8"
            fill="#E2E0E0"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            width="31.8"
            height="10.1"
          />

          <rect
            x="1206.1"
            y="614.2"
            fill="#E2E0E0"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            width="5"
            height="10.1"
          />

          <rect
            x="1207.2"
            y="613.3"
            fill="#E2E0E0"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            width="2.8"
            height="10.6"
          />

          <rect
            x="1192.7"
            y="624"
            fill="#E2E0E0"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            width="31.8"
            height="10.1"
          />
          <g>
            <rect
              x="1192.7"
              y="623.1"
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              width="1.4"
              height="14.9"
            />

            <rect
              x="1196.1"
              y="623.1"
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              width="1.4"
              height="14.9"
            />

            <rect
              x="1199.5"
              y="623.1"
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              width="1.4"
              height="14.9"
            />

            <rect
              x="1202.9"
              y="623.1"
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              width="1.4"
              height="14.9"
            />

            <rect
              x="1206.2"
              y="623.1"
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              width="1.4"
              height="14.9"
            />

            <rect
              x="1209.6"
              y="623.1"
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              width="1.4"
              height="14.9"
            />

            <rect
              x="1213"
              y="623.1"
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              width="1.4"
              height="14.9"
            />

            <rect
              x="1216.4"
              y="623.1"
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              width="1.4"
              height="14.9"
            />

            <rect
              x="1219.8"
              y="623.1"
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              width="1.4"
              height="14.9"
            />

            <rect
              x="1223.2"
              y="623.1"
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              width="1.4"
              height="14.9"
            />
          </g>
          <rect x="1194.6" y="625" width="1" height="1.9" />
          <rect x="1194.6" y="628.1" width="1" height="1.9" />
          <rect x="1194.6" y="631.1" width="1" height="1.9" />
          <rect x="1194.2" y="634.3" width="1.8" height="1.9" />
          <rect x="1197.6" y="634.3" width="1.8" height="1.9" />
          <rect x="1201" y="634.3" width="1.8" height="1.9" />
          <rect x="1204.3" y="634.3" width="1.8" height="1.9" />
          <rect x="1207.8" y="634.3" width="1.8" height="1.9" />
          <rect x="1211.2" y="634.3" width="1.8" height="1.9" />
          <rect x="1214.4" y="634.3" width="1.8" height="1.9" />
          <rect x="1218" y="634.3" width="1.8" height="1.9" />
          <rect x="1221.3" y="634.3" width="1.8" height="1.9" />
          <rect x="1198" y="625" width="1" height="1.9" />
          <rect x="1198" y="628.1" width="1" height="1.9" />
          <rect x="1198" y="631.1" width="1" height="1.9" />
          <rect x="1201.4" y="625" width="1" height="1.9" />
          <rect x="1201.4" y="628.1" width="1" height="1.9" />
          <rect x="1201.4" y="631.1" width="1" height="1.9" />
          <rect x="1204.8" y="625" width="1" height="1.9" />
          <rect x="1204.8" y="628.1" width="1" height="1.9" />
          <rect x="1204.8" y="631.1" width="1" height="1.9" />
          <rect x="1208.1" y="625" width="1" height="1.9" />
          <rect x="1208.1" y="628.1" width="1" height="1.9" />
          <rect x="1208.1" y="631.1" width="1" height="1.9" />
          <rect x="1211.6" y="625" width="1" height="1.9" />
          <rect x="1211.6" y="628.1" width="1" height="1.9" />
          <rect x="1211.6" y="631.1" width="1" height="1.9" />
          <rect x="1214.9" y="625" width="1" height="1.9" />
          <rect x="1214.9" y="628.1" width="1" height="1.9" />
          <rect x="1214.9" y="631.1" width="1" height="1.9" />
          <rect x="1218.3" y="625" width="1" height="1.9" />
          <rect x="1218.3" y="628.1" width="1" height="1.9" />
          <rect x="1218.3" y="631.1" width="1" height="1.9" />
          <rect x="1221.7" y="625" width="1" height="1.9" />
          <rect x="1221.7" y="628.1" width="1" height="1.9" />
          <rect x="1221.7" y="631.1" width="1" height="1.9" />
          <polygon
            fill="#E2E0E0"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            points="1208.7,611.4 1207.9,613.3 
			1209.5,613.3 		"
          />
          <polygon
            fill="#E2E0E0"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            points="1208.7,610.6 1208.4,611.4 
			1208.9,611.4 		"
          />
          <circle
            fill="#E2E0E0"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            cx="1208.6"
            cy="615.2"
            r="0.8"
          />
          <circle
            fill="#E2E0E0"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            cx="1208.6"
            cy="619.6"
            r="0.8"
          />

          <line
            fill="#E2E0E0"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            x1="1207.8"
            y1="618.3"
            x2="1209.5"
            y2="616.6"
          />

          <line
            fill="#E2E0E0"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            x1="1207.8"
            y1="616.6"
            x2="1209.5"
            y2="618.3"
          />
        </g>
        {/* <g>
          <rect
            x="1386.5"
            y="672.5"
            fill="#E2E0E0"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            width="23.6"
            height="15"
          />
          <polygon
            fill="#E2E0E0"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeLinejoin="bevel"
            strokeMiterlimit="10"
            points="
			1408.3,668.4 1388.5,668.4 1386.5,672.4 1410.1,672.4 		"
          />
          <g>
            <line
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              x1="1389.1"
              y1="669"
              x2="1387.8"
              y2="671.6"
            />

            <line
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              x1="1390.3"
              y1="669"
              x2="1389.2"
              y2="671.6"
            />

            <line
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              x1="1391.4"
              y1="669"
              x2="1390.5"
              y2="671.6"
            />

            <line
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              x1="1392.6"
              y1="669"
              x2="1391.8"
              y2="671.6"
            />

            <line
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              x1="1393.8"
              y1="669"
              x2="1393.1"
              y2="671.6"
            />

            <line
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              x1="1394.9"
              y1="669"
              x2="1394.4"
              y2="671.6"
            />

            <line
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              x1="1396.1"
              y1="669"
              x2="1395.8"
              y2="671.6"
            />

            <line
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              x1="1397.2"
              y1="669"
              x2="1397.1"
              y2="671.6"
            />

            <line
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              x1="1398.4"
              y1="669"
              x2="1398.4"
              y2="671.6"
            />

            <line
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              x1="1399.6"
              y1="669"
              x2="1399.7"
              y2="671.6"
            />

            <line
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              x1="1400.7"
              y1="669"
              x2="1401.1"
              y2="671.6"
            />

            <line
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              x1="1401.9"
              y1="669"
              x2="1402.4"
              y2="671.6"
            />

            <line
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              x1="1403.1"
              y1="669"
              x2="1403.7"
              y2="671.6"
            />

            <line
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              x1="1404.2"
              y1="669"
              x2="1405"
              y2="671.6"
            />

            <line
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              x1="1405.4"
              y1="669"
              x2="1406.3"
              y2="671.6"
            />

            <line
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              x1="1406.5"
              y1="669"
              x2="1407.7"
              y2="671.6"
            />

            <line
              fill="#E2E0E0"
              stroke="#000000"
              strokeWidth="0.3507"
              strokeMiterlimit="10"
              x1="1407.7"
              y1="669"
              x2="1409"
              y2="671.6"
            />
          </g>
          <polygon
            fill="#E2E0E0"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            points="1398.1,667.4 1394.5,673.7 
			1401.7,673.7 		"
          />
          <polygon
            fill="#E2E0E0"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            points="1391.8,662.8 1388.5,668.4 
			1395.1,668.4 		"
          />
          <rect x="1395.9" y="681.8" width="4.5" height="5.7" />
          <rect x="1397" y="670" width="2.2" height="2.7" />
          <rect x="1390.9" y="664.9" width="1.7" height="2" />
          <line
            fill="none"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            x1="1386.5"
            y1="684"
            x2="1395.3"
            y2="684"
          />
          <line
            fill="none"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            x1="1388.5"
            y1="684"
            x2="1388.5"
            y2="687.5"
          />
          <line
            fill="none"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            x1="1393.2"
            y1="684"
            x2="1393.2"
            y2="687.5"
          />
          <line
            fill="none"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            x1="1403.3"
            y1="684"
            x2="1403.3"
            y2="687.5"
          />
          <line
            fill="none"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            x1="1408"
            y1="684"
            x2="1408"
            y2="687.5"
          />

          <line
            fill="none"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            x1="1405.6"
            y1="672.4"
            x2="1405.6"
            y2="687.5"
          />

          <line
            fill="none"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            x1="1390.8"
            y1="672.4"
            x2="1390.8"
            y2="687.5"
          />

          <line
            fill="none"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            x1="1388.5"
            y1="676.7"
            x2="1388.5"
            y2="681.1"
          />

          <line
            fill="none"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            x1="1393.2"
            y1="676.7"
            x2="1393.2"
            y2="681.1"
          />

          <line
            fill="none"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            x1="1403.3"
            y1="676.7"
            x2="1403.3"
            y2="681.1"
          />

          <line
            fill="none"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            x1="1398.1"
            y1="675.4"
            x2="1398.1"
            y2="681.1"
          />
          <line
            fill="none"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            x1="1408"
            y1="676.7"
            x2="1408"
            y2="681.1"
          />

          <line
            fill="none"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeLinejoin="bevel"
            strokeMiterlimit="10"
            x1="1386.5"
            y1="681.1"
            x2="1395.3"
            y2="681.1"
          />
          <line
            fill="none"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            x1="1401.1"
            y1="684"
            x2="1410.1"
            y2="684"
          />
          <polyline
            fill="none"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            points="1400.9,687.5 1400.9,681.1 
			1395.3,681.1 1395.3,687.5 		"
          />
          <polyline
            fill="none"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeLinejoin="bevel"
            strokeMiterlimit="10"
            points="
			1400.9,673.7 1400.9,681.1 1403.3,684 1405.6,681.1 1408,684 1410.1,681.1 1401.1,681.1 		"
          />

          <line
            fill="#E2E0E0"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            x1="1395.3"
            y1="681.1"
            x2="1395.3"
            y2="673.7"
          />

          <line
            fill="#E2E0E0"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            x1="1389.4"
            y1="666.7"
            x2="1394.2"
            y2="666.7"
          />

          <line
            fill="#E2E0E0"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            x1="1390.6"
            y1="664.9"
            x2="1393"
            y2="664.9"
          />

          <line
            fill="#E2E0E0"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            x1="1391.8"
            y1="662.8"
            x2="1391.8"
            y2="664.9"
          />
          <line
            fill="#E2E0E0"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            x1="1393"
            y1="666.7"
            x2="1393"
            y2="668.4"
          />

          <line
            fill="#E2E0E0"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            x1="1390.6"
            y1="666.7"
            x2="1390.6"
            y2="668.4"
          />
          <polyline
            fill="none"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeLinejoin="bevel"
            strokeMiterlimit="10"
            points="
			1400.9,676.7 1403.3,672.4 1405.6,676.7 1408,672.4 1410.1,676.7 1386.5,676.7 1388.8,672.4 1390.8,676.7 1393.2,672.5 
			1395.3,676.7 		"
          />
          <polyline
            fill="none"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeLinejoin="bevel"
            strokeMiterlimit="10"
            points="
			1386.5,681.1 1388.5,684 1390.8,681.1 		"
          />
          <polyline
            fill="none"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeLinejoin="bevel"
            strokeMiterlimit="10"
            points="
			1390.8,681.1 1393.2,684 1395.2,681.1 		"
          />

          <line
            fill="none"
            stroke="#000000"
            strokeWidth="0.3507"
            strokeMiterlimit="10"
            x1="1395.3"
            y1="675.4"
            x2="1400.9"
            y2="675.4"
          />
        </g> */}

        <g>
          <g transform="translate(0, -20)">
            <path
              fill="#FFFFFF"
              d="M1357.1,634.4h0.5v4h-0.5v-3l0,0.2l-1,2.3h-0.4l-1-2.2l0-0.2v3h-0.5v-4h0.5l1.2,2.6L1357.1,634.4z"
            />
            <path
              fill="#FFFFFF"
              d="M1358.6,634.4h0.6v0.6h-0.6V634.4z M1358.6,635.6h0.6v2.8h-0.6V635.6z"
            />
            <path
              fill="#FFFFFF"
              d="M1360.5,637.7c0,0.1,0,0.1,0,0.2c0,0,0.1,0.1,0.1,0.1h0.3v0.5h-0.3c-0.2,0-0.4-0.1-0.5-0.2
				s-0.2-0.3-0.2-0.6v-3.2h0.6V637.7z"
            />
            <path
              fill="#FFFFFF"
              d="M1362.1,637.7c0,0.1,0,0.1,0,0.2c0,0,0.1,0.1,0.1,0.1h0.3v0.5h-0.3c-0.2,0-0.4-0.1-0.5-0.2
				s-0.2-0.3-0.2-0.6v-3.2h0.6V637.7z"
            />
            <path
              fill="#FFFFFF"
              d="M1363.7,638.3c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.1-0.4-0.1-0.7v-0.3c0-0.3,0-0.5,0.1-0.7
				c0.1-0.2,0.2-0.4,0.4-0.5c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.5,0.1,0.8v0.2
				h-1.9v-0.4h1.4c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.4,0.1-0.5,0.2s-0.2,0.3-0.2,0.6v0.4
				c0,0.2,0.1,0.4,0.2,0.5s0.3,0.2,0.5,0.2c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2-0.1,0.3-0.2l0.4,0.4c-0.1,0.1-0.3,0.2-0.5,0.3
				c-0.2,0.1-0.4,0.1-0.5,0.1C1364.1,638.4,1363.9,638.4,1363.7,638.3z"
            />
            <path
              fill="#FFFFFF"
              d="M1366.2,635.6h0.6v2.8h-0.6V635.6z M1367.9,636.7c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.2-0.2-0.4-0.2
				c-0.2,0-0.3,0-0.4,0.1s-0.1,0.2-0.1,0.4l-0.1-0.5c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.3,0,0.5,0.1,0.7,0.3
				c0.2,0.2,0.3,0.5,0.3,0.9v1.7h-0.6V636.7z"
            />
            <path
              fill="#FFFFFF"
              d="M1369.4,634.4h0.6v0.6h-0.6V634.4z M1369.4,635.6h0.6v2.8h-0.6V635.6z"
            />
            <path
              fill="#FFFFFF"
              d="M1371.3,637.3c0,0.2,0.1,0.4,0.2,0.5s0.2,0.2,0.4,0.2c0.2,0,0.3,0,0.4-0.1s0.2-0.2,0.2-0.4l0,0.6
				c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.1-0.5,0.1c-0.3,0-0.6-0.1-0.7-0.3s-0.3-0.5-0.3-0.9v-1.7h0.6V637.3z M1372.5,635.6h0.6
				v2.8h-0.6V635.6z"
            />
            <path
              fill="#FFFFFF"
              d="M1373.9,635.6h0.6v2.8h-0.6V635.6z M1375.6,636.7c0-0.2,0-0.4-0.1-0.5c-0.1-0.1-0.2-0.2-0.4-0.2
				c-0.2,0-0.3,0-0.4,0.1s-0.1,0.2-0.1,0.4l-0.1-0.5c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0,0.4,0,0.5,0.1
				c0.1,0.1,0.2,0.2,0.3,0.4s0.1,0.4,0.1,0.6v1.7h-0.6V636.7z M1377.3,636.7c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.2-0.2-0.4-0.2
				c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.1,0.3-0.1,0.4l-0.1-0.5c0.1-0.2,0.2-0.3,0.4-0.4c0.1-0.1,0.3-0.2,0.5-0.2
				c0.2,0,0.4,0,0.5,0.1c0.1,0.1,0.3,0.2,0.3,0.4c0.1,0.2,0.1,0.4,0.1,0.6v1.7h-0.6V636.7z"
            />
            <path
              fill="#FFFFFF"
              d="M1380.3,634.4h0.6v4h-0.6V634.4z M1380.6,637.9h1.1c0.3,0,0.5-0.1,0.6-0.2c0.1-0.1,0.2-0.3,0.2-0.5v0
				c0-0.1,0-0.3-0.1-0.3c0-0.1-0.1-0.2-0.2-0.2s-0.2-0.1-0.4-0.1h-1.2v-0.5h1.2c0.2,0,0.4,0,0.5-0.1c0.1-0.1,0.2-0.2,0.2-0.4
				c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.1-0.5-0.1h-1.1v-0.5h1.2c0.3,0,0.5,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.4s0.1,0.4,0.1,0.6
				c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.4,0.2c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.3,0.3
				c0.1,0.1,0.1,0.3,0.1,0.4v0c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.1-0.6,0.1h-1.3V637.9z"
            />
            <path
              fill="#FFFFFF"
              d="M1383.8,635.6h0.6v2.8h-0.6V635.6z M1385.2,636.1c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3,0-0.4,0.1
				s-0.1,0.2-0.1,0.4l-0.1-0.5c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2
				l-0.4,0.5C1385.3,636.2,1385.3,636.1,1385.2,636.1z"
            />
            <path
              fill="#FFFFFF"
              d="M1386.2,634.4h0.6v0.6h-0.6V634.4z M1386.2,635.6h0.6v2.8h-0.6V635.6z"
            />
            <path
              fill="#FFFFFF"
              d="M1388,638.3c-0.1-0.1-0.3-0.2-0.3-0.4c-0.1-0.2-0.1-0.4-0.1-0.6v-0.6c0-0.2,0-0.5,0.1-0.6
				c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0,0.3,0,0.5,0.1s0.3,0.2,0.3,0.4l-0.1,0.6c0-0.1,0-0.2-0.1-0.3
				c0-0.1-0.1-0.2-0.2-0.2s-0.2-0.1-0.3-0.1c-0.2,0-0.3,0.1-0.4,0.2s-0.2,0.3-0.2,0.5v0.6c0,0.2,0.1,0.4,0.2,0.5s0.2,0.2,0.4,0.2
				c0.1,0,0.2,0,0.3-0.1s0.1-0.1,0.2-0.2s0.1-0.2,0.1-0.3l0,0.6c-0.1,0.1-0.1,0.3-0.3,0.4c-0.1,0.1-0.3,0.1-0.5,0.1
				C1388.3,638.4,1388.1,638.4,1388,638.3z M1389.2,634.4h0.6v4h-0.6V634.4z"
            />
            <path
              fill="#FFFFFF"
              d="M1391,638.3c-0.1-0.1-0.3-0.2-0.3-0.4c-0.1-0.2-0.1-0.4-0.1-0.6v-0.6c0-0.2,0-0.5,0.1-0.6
				c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0,0.3,0,0.5,0.1s0.3,0.2,0.3,0.4l-0.1,0.6c0-0.1,0-0.2-0.1-0.3
				c0-0.1-0.1-0.2-0.2-0.2s-0.2-0.1-0.3-0.1c-0.2,0-0.3,0.1-0.4,0.2s-0.2,0.3-0.2,0.5v0.6c0,0.2,0.1,0.4,0.2,0.5s0.2,0.2,0.4,0.2
				c0.1,0,0.2,0,0.3-0.1s0.1-0.1,0.2-0.2s0.1-0.2,0.1-0.3l0,0.6c-0.1,0.1-0.1,0.3-0.3,0.4c-0.1,0.1-0.3,0.1-0.5,0.1
				C1391.3,638.4,1391.2,638.4,1391,638.3z M1391,639.4c-0.2-0.1-0.3-0.2-0.4-0.3l0.4-0.3c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.1
				c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5v-2.8h0.6v2.8c0,0.3,0,0.5-0.1,0.7c-0.1,0.2-0.2,0.3-0.4,0.4s-0.4,0.1-0.7,0.1
				C1391.4,639.6,1391.2,639.5,1391,639.4z"
            />
            <path
              fill="#FFFFFF"
              d="M1394.2,638.3c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.1-0.4-0.1-0.7v-0.3c0-0.3,0-0.5,0.1-0.7
				c0.1-0.2,0.2-0.4,0.4-0.5c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.5,0.1,0.8v0.2
				h-1.9v-0.4h1.4c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.4,0.1-0.5,0.2s-0.2,0.3-0.2,0.6v0.4
				c0,0.2,0.1,0.4,0.2,0.5s0.3,0.2,0.5,0.2c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2-0.1,0.3-0.2l0.4,0.4c-0.1,0.1-0.3,0.2-0.5,0.3
				c-0.2,0.1-0.4,0.1-0.5,0.1C1394.6,638.4,1394.4,638.4,1394.2,638.3z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M1518.5,670.7h0.6v4h-0.6V670.7z M1518.8,674.1h2.3v0.5h-2.3V674.1z"
            />
            <path
              fill="#FFFFFF"
              d="M1522.1,674.6c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.1-0.4-0.1-0.7V673c0-0.3,0-0.5,0.1-0.7
				c0.1-0.2,0.2-0.3,0.4-0.4c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.5,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.4c0.1,0.2,0.1,0.4,0.1,0.7v0.5
				c0,0.3,0,0.5-0.1,0.7c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.1-0.6,0.1C1522.5,674.7,1522.3,674.7,1522.1,674.6z M1523.2,674
				c0.1-0.1,0.2-0.3,0.2-0.5V673c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.3,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5
				v0.5c0,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.3,0.2,0.5,0.2C1522.9,674.2,1523.1,674.1,1523.2,674z"
            />
            <path
              fill="#FFFFFF"
              d="M1524.7,671.8h0.6v2.8h-0.6V671.8z M1526.4,673c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.2-0.2-0.4-0.2
				c-0.2,0-0.3,0-0.4,0.1s-0.1,0.2-0.1,0.4l-0.1-0.5c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.3,0,0.5,0.1,0.7,0.3
				c0.2,0.2,0.3,0.5,0.3,0.9v1.7h-0.6V673z"
            />
            <path
              fill="#FFFFFF"
              d="M1528.2,674.6c-0.1-0.1-0.3-0.2-0.3-0.4c-0.1-0.2-0.1-0.4-0.1-0.6v-0.6c0-0.2,0-0.5,0.1-0.6
				c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0,0.3,0,0.5,0.1s0.3,0.2,0.3,0.4l-0.1,0.6c0-0.1,0-0.2-0.1-0.3
				c0-0.1-0.1-0.2-0.2-0.2s-0.2-0.1-0.3-0.1c-0.2,0-0.3,0.1-0.4,0.2s-0.2,0.3-0.2,0.5v0.6c0,0.2,0.1,0.4,0.2,0.5s0.2,0.2,0.4,0.2
				c0.1,0,0.2,0,0.3-0.1s0.1-0.1,0.2-0.2s0.1-0.2,0.1-0.3l0,0.6c-0.1,0.1-0.1,0.3-0.3,0.4c-0.1,0.1-0.3,0.1-0.5,0.1
				C1528.5,674.7,1528.3,674.7,1528.2,674.6z M1529.4,670.7h0.6v4h-0.6V670.7z"
            />
            <path
              fill="#FFFFFF"
              d="M1531.3,674.6c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.1-0.4-0.1-0.7V673c0-0.3,0-0.5,0.1-0.7
				c0.1-0.2,0.2-0.3,0.4-0.4c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.5,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.4c0.1,0.2,0.1,0.4,0.1,0.7v0.5
				c0,0.3,0,0.5-0.1,0.7c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.1-0.6,0.1C1531.7,674.7,1531.5,674.7,1531.3,674.6z M1532.4,674
				c0.1-0.1,0.2-0.3,0.2-0.5V673c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.3,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5
				v0.5c0,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.3,0.2,0.5,0.2C1532.1,674.2,1532.3,674.1,1532.4,674z"
            />
            <path
              fill="#FFFFFF"
              d="M1533.9,671.8h0.6v2.8h-0.6V671.8z M1535.6,673c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.2-0.2-0.4-0.2
				c-0.2,0-0.3,0-0.4,0.1s-0.1,0.2-0.1,0.4l-0.1-0.5c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.3,0,0.5,0.1,0.7,0.3
				c0.2,0.2,0.3,0.5,0.3,0.9v1.7h-0.6V673z"
            />
            <path
              fill="#FFFFFF"
              d="M1538.6,670.7h0.6v4h-0.6V670.7z M1538.9,674.2h1.1c0.3,0,0.5-0.1,0.6-0.2c0.1-0.1,0.2-0.3,0.2-0.5v0
				c0-0.1,0-0.3-0.1-0.3c0-0.1-0.1-0.2-0.2-0.2s-0.2-0.1-0.4-0.1h-1.2v-0.5h1.2c0.2,0,0.4,0,0.5-0.1c0.1-0.1,0.2-0.2,0.2-0.4
				c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.1-0.5-0.1h-1.1v-0.5h1.2c0.3,0,0.5,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.4s0.1,0.4,0.1,0.6
				c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.4,0.2c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.3,0.3
				c0.1,0.1,0.1,0.3,0.1,0.4v0c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.1-0.6,0.1h-1.3V674.2z"
            />
            <path
              fill="#FFFFFF"
              d="M1542.2,671.8h0.6v2.8h-0.6V671.8z M1543.5,672.4c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3,0-0.4,0.1
				s-0.1,0.2-0.1,0.4l-0.1-0.5c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2
				l-0.4,0.5C1543.7,672.4,1543.6,672.4,1543.5,672.4z"
            />
            <path
              fill="#FFFFFF"
              d="M1544.6,670.7h0.6v0.6h-0.6V670.7z M1544.6,671.8h0.6v2.8h-0.6V671.8z"
            />
            <path
              fill="#FFFFFF"
              d="M1546.3,674.6c-0.1-0.1-0.3-0.2-0.3-0.4c-0.1-0.2-0.1-0.4-0.1-0.6v-0.6c0-0.2,0-0.5,0.1-0.6
				c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0,0.3,0,0.5,0.1s0.3,0.2,0.3,0.4l-0.1,0.6c0-0.1,0-0.2-0.1-0.3
				c0-0.1-0.1-0.2-0.2-0.2s-0.2-0.1-0.3-0.1c-0.2,0-0.3,0.1-0.4,0.2s-0.2,0.3-0.2,0.5v0.6c0,0.2,0.1,0.4,0.2,0.5s0.2,0.2,0.4,0.2
				c0.1,0,0.2,0,0.3-0.1s0.1-0.1,0.2-0.2s0.1-0.2,0.1-0.3l0,0.6c-0.1,0.1-0.1,0.3-0.3,0.4c-0.1,0.1-0.3,0.1-0.5,0.1
				C1546.7,674.7,1546.5,674.7,1546.3,674.6z M1547.6,670.7h0.6v4h-0.6V670.7z"
            />
            <path
              fill="#FFFFFF"
              d="M1549.4,674.6c-0.1-0.1-0.3-0.2-0.3-0.4c-0.1-0.2-0.1-0.4-0.1-0.6v-0.6c0-0.2,0-0.5,0.1-0.6
				c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0,0.3,0,0.5,0.1s0.3,0.2,0.3,0.4l-0.1,0.6c0-0.1,0-0.2-0.1-0.3
				c0-0.1-0.1-0.2-0.2-0.2s-0.2-0.1-0.3-0.1c-0.2,0-0.3,0.1-0.4,0.2s-0.2,0.3-0.2,0.5v0.6c0,0.2,0.1,0.4,0.2,0.5s0.2,0.2,0.4,0.2
				c0.1,0,0.2,0,0.3-0.1s0.1-0.1,0.2-0.2s0.1-0.2,0.1-0.3l0,0.6c-0.1,0.1-0.1,0.3-0.3,0.4c-0.1,0.1-0.3,0.1-0.5,0.1
				C1549.7,674.7,1549.5,674.7,1549.4,674.6z M1549.4,675.7c-0.2-0.1-0.3-0.2-0.4-0.3l0.4-0.3c0.1,0.1,0.2,0.2,0.3,0.2
				s0.2,0.1,0.3,0.1c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5v-2.8h0.6v2.8c0,0.3,0,0.5-0.1,0.7c-0.1,0.2-0.2,0.3-0.4,0.4
				s-0.4,0.1-0.7,0.1C1549.7,675.8,1549.5,675.8,1549.4,675.7z"
            />
            <path
              fill="#FFFFFF"
              d="M1552.5,674.6c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.1-0.4-0.1-0.7v-0.3c0-0.3,0-0.5,0.1-0.7
				c0.1-0.2,0.2-0.4,0.4-0.5c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.5,0.1,0.8v0.2
				h-1.9V673h1.4c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.4,0.1-0.5,0.2s-0.2,0.3-0.2,0.6v0.4
				c0,0.2,0.1,0.4,0.2,0.5s0.3,0.2,0.5,0.2c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2-0.1,0.3-0.2l0.4,0.4c-0.1,0.1-0.3,0.2-0.5,0.3
				c-0.2,0.1-0.4,0.1-0.5,0.1C1552.9,674.7,1552.7,674.7,1552.5,674.6z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M1418.2,654c-0.2,0-0.3-0.1-0.5-0.2c-0.1-0.1-0.3-0.2-0.4-0.3l0.3-0.4c0.2,0.1,0.3,0.2,0.5,0.3
				c0.2,0.1,0.4,0.1,0.6,0.1c0.3,0,0.5-0.1,0.7-0.2c0.2-0.1,0.2-0.3,0.2-0.5v0c0-0.1,0-0.2-0.1-0.3s-0.2-0.1-0.3-0.2
				c-0.1,0-0.3-0.1-0.4-0.1c0,0,0,0,0,0c0,0,0,0,0,0l0,0c-0.3,0-0.5-0.1-0.7-0.2c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.2-0.2-0.4-0.2-0.7
				v0c0-0.2,0.1-0.5,0.2-0.6c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.5-0.1,0.8-0.1c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0.1,0.4,0.1
				c0.1,0.1,0.3,0.1,0.4,0.2l-0.3,0.4c-0.2-0.1-0.3-0.2-0.5-0.3s-0.3-0.1-0.5-0.1c-0.3,0-0.5,0.1-0.6,0.2c-0.2,0.1-0.2,0.3-0.2,0.5
				v0c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0,0.3,0.1,0.5,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
				c0.2,0,0.4,0.1,0.6,0.2c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.2,0.2,0.4,0.2,0.6v0c0,0.2-0.1,0.5-0.2,0.6c-0.1,0.2-0.3,0.3-0.5,0.4
				c-0.2,0.1-0.5,0.1-0.8,0.1C1418.5,654.1,1418.3,654.1,1418.2,654z"
            />
            <path
              fill="#FFFFFF"
              d="M1421.4,653.9c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.1-0.4-0.1-0.7v-0.5c0-0.3,0-0.5,0.1-0.7
				c0.1-0.2,0.2-0.3,0.4-0.4c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.5,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.4c0.1,0.2,0.1,0.4,0.1,0.7v0.5
				c0,0.3,0,0.5-0.1,0.7c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.1-0.6,0.1C1421.7,654.1,1421.5,654,1421.4,653.9z M1422.4,653.4
				c0.1-0.1,0.2-0.3,0.2-0.5v-0.5c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.3,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5
				v0.5c0,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.3,0.2,0.5,0.2C1422.2,653.6,1422.3,653.5,1422.4,653.4z"
            />
            <path
              fill="#FFFFFF"
              d="M1424.5,652.9c0,0.2,0.1,0.4,0.2,0.5s0.2,0.2,0.4,0.2c0.2,0,0.3,0,0.4-0.1s0.2-0.2,0.2-0.4l0,0.6
				c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.1-0.5,0.1c-0.3,0-0.6-0.1-0.7-0.3s-0.3-0.5-0.3-0.9v-1.7h0.6V652.9z M1425.7,651.2h0.6
				v2.8h-0.6V651.2z"
            />
            <path
              fill="#FFFFFF"
              d="M1426.9,651.2h1.3v0.5h-1.3V651.2z M1427.3,653.9c-0.1-0.1-0.2-0.3-0.2-0.6v-2.9h0.6v2.9
				c0,0.1,0,0.1,0,0.2c0,0,0.1,0.1,0.1,0.1h0.3v0.5h-0.3C1427.6,654.1,1427.4,654,1427.3,653.9z"
            />
            <path
              fill="#FFFFFF"
              d="M1428.9,650.1h0.6v4h-0.6V650.1z M1430.6,652.3c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.2-0.2-0.4-0.2
				c-0.2,0-0.3,0-0.4,0.1s-0.1,0.2-0.1,0.4l-0.1-0.5c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.3,0,0.5,0.1,0.7,0.3
				c0.2,0.2,0.3,0.5,0.3,0.9v1.7h-0.6V652.3z"
            />
            <path
              fill="#FFFFFF"
              d="M1431.8,651.2h0.6l0.5,2l0.7-2h0.4l0.7,2l0.5-2h0.6l-0.9,2.8h-0.4l-0.7-2l-0.7,2h-0.4L1431.8,651.2z"
            />
            <path
              fill="#FFFFFF"
              d="M1436.5,653.9c-0.2-0.1-0.3-0.4-0.3-0.7c0-0.3,0.1-0.5,0.2-0.6c0.2-0.1,0.4-0.2,0.7-0.2h0.8l0,0.4h-0.8
				c-0.1,0-0.3,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.3,0.1,0.3c0.1,0.1,0.2,0.1,0.4,0.1c0.2,0,0.4,0,0.5-0.1
				c0.1,0,0.2-0.1,0.2-0.2l0.1,0.4c0,0.1-0.1,0.1-0.2,0.2c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.2,0-0.3,0
				C1436.9,654.1,1436.7,654,1436.5,653.9z M1438,652.2c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1,0-0.2,0-0.3,0.1
				c-0.1,0-0.2,0.1-0.3,0.1l-0.4-0.3c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.4
				c0.1,0.2,0.1,0.3,0.1,0.6v1.8h-0.5V652.2z"
            />
            <path
              fill="#FFFFFF"
              d="M1439.4,651.2h0.6v2.8h-0.6V651.2z M1440.7,651.7c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3,0-0.4,0.1
				s-0.1,0.2-0.1,0.4l-0.1-0.5c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2
				l-0.4,0.5C1440.9,651.8,1440.8,651.8,1440.7,651.7z"
            />
            <path
              fill="#FFFFFF"
              d="M1441.8,650.1h0.6v4h-0.6V650.1z M1442,653l1.5-1.8h0.7l-2.2,2.4L1442,653z M1442.7,652.4l0.4-0.4l1.2,2
				h-0.6L1442.7,652.4z"
            />
            <path
              fill="#FFFFFF"
              d="M1446.3,650.1h0.6v4h-0.6V650.1z M1446.6,653.5h1.1c0.3,0,0.5-0.1,0.6-0.2c0.1-0.1,0.2-0.3,0.2-0.5v0
				c0-0.1,0-0.3-0.1-0.3c0-0.1-0.1-0.2-0.2-0.2s-0.2-0.1-0.4-0.1h-1.2v-0.5h1.2c0.2,0,0.4,0,0.5-0.1c0.1-0.1,0.2-0.2,0.2-0.4
				c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.1-0.5-0.1h-1.1v-0.5h1.2c0.3,0,0.5,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.4s0.1,0.4,0.1,0.6
				c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.4,0.2c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.3,0.3
				c0.1,0.1,0.1,0.3,0.1,0.4v0c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.1-0.6,0.1h-1.3V653.5z"
            />
            <path
              fill="#FFFFFF"
              d="M1449.9,651.2h0.6v2.8h-0.6V651.2z M1451.2,651.7c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3,0-0.4,0.1
				s-0.1,0.2-0.1,0.4l-0.1-0.5c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2
				l-0.4,0.5C1451.4,651.8,1451.3,651.8,1451.2,651.7z"
            />
            <path
              fill="#FFFFFF"
              d="M1452.3,650.1h0.6v0.6h-0.6V650.1z M1452.3,651.2h0.6v2.8h-0.6V651.2z"
            />
            <path
              fill="#FFFFFF"
              d="M1454,654c-0.1-0.1-0.3-0.2-0.3-0.4c-0.1-0.2-0.1-0.4-0.1-0.6v-0.6c0-0.2,0-0.5,0.1-0.6
				c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0,0.3,0,0.5,0.1s0.3,0.2,0.3,0.4l-0.1,0.6c0-0.1,0-0.2-0.1-0.3
				c0-0.1-0.1-0.2-0.2-0.2s-0.2-0.1-0.3-0.1c-0.2,0-0.3,0.1-0.4,0.2s-0.2,0.3-0.2,0.5v0.6c0,0.2,0.1,0.4,0.2,0.5s0.2,0.2,0.4,0.2
				c0.1,0,0.2,0,0.3-0.1s0.1-0.1,0.2-0.2s0.1-0.2,0.1-0.3l0,0.6c-0.1,0.1-0.1,0.3-0.3,0.4c-0.1,0.1-0.3,0.1-0.5,0.1
				C1454.4,654.1,1454.2,654,1454,654z M1455.3,650.1h0.6v4h-0.6V650.1z"
            />
            <path
              fill="#FFFFFF"
              d="M1457.1,654c-0.1-0.1-0.3-0.2-0.3-0.4c-0.1-0.2-0.1-0.4-0.1-0.6v-0.6c0-0.2,0-0.5,0.1-0.6
				c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0,0.3,0,0.5,0.1s0.3,0.2,0.3,0.4l-0.1,0.6c0-0.1,0-0.2-0.1-0.3
				c0-0.1-0.1-0.2-0.2-0.2s-0.2-0.1-0.3-0.1c-0.2,0-0.3,0.1-0.4,0.2s-0.2,0.3-0.2,0.5v0.6c0,0.2,0.1,0.4,0.2,0.5s0.2,0.2,0.4,0.2
				c0.1,0,0.2,0,0.3-0.1s0.1-0.1,0.2-0.2s0.1-0.2,0.1-0.3l0,0.6c-0.1,0.1-0.1,0.3-0.3,0.4c-0.1,0.1-0.3,0.1-0.5,0.1
				C1457.4,654.1,1457.2,654,1457.1,654z M1457.1,655.1c-0.2-0.1-0.3-0.2-0.4-0.3l0.4-0.3c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.1
				c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5v-2.8h0.6v2.8c0,0.3,0,0.5-0.1,0.7c-0.1,0.2-0.2,0.3-0.4,0.4s-0.4,0.1-0.7,0.1
				C1457.4,655.2,1457.2,655.2,1457.1,655.1z"
            />
            <path
              fill="#FFFFFF"
              d="M1460.2,653.9c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.1-0.4-0.1-0.7v-0.3c0-0.3,0-0.5,0.1-0.7
				c0.1-0.2,0.2-0.4,0.4-0.5c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.5,0.1,0.8v0.2
				h-1.9v-0.4h1.4c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.4,0.1-0.5,0.2s-0.2,0.3-0.2,0.6v0.4
				c0,0.2,0.1,0.4,0.2,0.5s0.3,0.2,0.5,0.2c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2-0.1,0.3-0.2l0.4,0.4c-0.1,0.1-0.3,0.2-0.5,0.3
				c-0.2,0.1-0.4,0.1-0.5,0.1C1460.6,654.1,1460.4,654,1460.2,653.9z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M1277.5,598.9h0.6v4h-0.6V598.9z M1277.9,602.4h1.1c0.3,0,0.5-0.1,0.6-0.2c0.1-0.1,0.2-0.3,0.2-0.5v0
				c0-0.1,0-0.3-0.1-0.3c0-0.1-0.1-0.2-0.2-0.2s-0.2-0.1-0.4-0.1h-1.2v-0.5h1.2c0.2,0,0.4,0,0.5-0.1c0.1-0.1,0.2-0.2,0.2-0.4
				c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.1-0.5-0.1h-1.1v-0.5h1.2c0.3,0,0.5,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.4s0.1,0.4,0.1,0.6
				c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.4,0.2c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.3,0.3
				c0.1,0.1,0.1,0.3,0.1,0.4v0c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.1-0.6,0.1h-1.3V602.4z"
            />
            <path
              fill="#FFFFFF"
              d="M1281.7,602.1c0,0.1,0,0.1,0,0.2c0,0,0.1,0.1,0.1,0.1h0.3v0.5h-0.3c-0.2,0-0.4-0.1-0.5-0.2
				s-0.2-0.3-0.2-0.6v-3.2h0.6V602.1z"
            />
            <path
              fill="#FFFFFF"
              d="M1282.8,602.7c-0.2-0.1-0.3-0.4-0.3-0.7c0-0.3,0.1-0.5,0.2-0.6c0.2-0.1,0.4-0.2,0.7-0.2h0.8l0,0.4h-0.8
				c-0.1,0-0.3,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.3,0.1,0.3c0.1,0.1,0.2,0.1,0.4,0.1c0.2,0,0.4,0,0.5-0.1
				c0.1,0,0.2-0.1,0.2-0.2l0.1,0.4c0,0.1-0.1,0.1-0.2,0.2c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.2,0-0.3,0
				C1283.3,602.9,1283,602.8,1282.8,602.7z M1284.3,601.1c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1,0-0.2,0-0.3,0.1
				c-0.1,0-0.2,0.1-0.3,0.1l-0.4-0.3c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.4
				c0.1,0.2,0.1,0.3,0.1,0.6v1.8h-0.5V601.1z"
            />
            <path
              fill="#FFFFFF"
              d="M1286.2,602.8c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.1-0.4-0.1-0.7v-0.5c0-0.3,0-0.5,0.1-0.7
				c0.1-0.2,0.2-0.3,0.4-0.4s0.4-0.1,0.7-0.1c0.1,0,0.3,0,0.4,0.1c0.1,0,0.2,0.1,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2l-0.4,0.4
				c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5v0.5c0,0.2,0.1,0.4,0.2,0.5
				s0.3,0.2,0.5,0.2c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.2-0.1,0.3-0.2l0.4,0.4c-0.1,0.1-0.2,0.2-0.3,0.2s-0.2,0.1-0.4,0.2
				c-0.1,0-0.3,0.1-0.4,0.1C1286.6,602.9,1286.4,602.9,1286.2,602.8z"
            />
            <path
              fill="#FFFFFF"
              d="M1288.5,598.9h0.6v4h-0.6V598.9z M1288.7,601.8l1.5-1.8h0.7l-2.2,2.4L1288.7,601.8z M1289.4,601.2
				l0.4-0.4l1.2,2h-0.6L1289.4,601.2z"
            />
            <path
              fill="#FFFFFF"
              d="M1291.3,600h1.4v0.5h-1.4V600z M1291.6,599.6c0-0.3,0.1-0.4,0.2-0.6s0.3-0.2,0.5-0.2h0.4v0.5h-0.4
				c-0.1,0-0.1,0-0.1,0.1c0,0,0,0.1,0,0.2v3.2h-0.6V599.6z"
            />
            <path
              fill="#FFFFFF"
              d="M1293.2,600h0.6v2.8h-0.6V600z M1294.6,600.6c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3,0-0.4,0.1s-0.1,0.2-0.1,0.4
				l-0.1-0.5c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2l-0.4,0.5
				C1294.8,600.6,1294.7,600.6,1294.6,600.6z"
            />
            <path
              fill="#FFFFFF"
              d="M1295.6,598.9h0.6v0.6h-0.6V598.9z M1295.6,600h0.6v2.8h-0.6V600z"
            />
            <path
              fill="#FFFFFF"
              d="M1297.2,602.7c-0.2-0.1-0.3-0.4-0.3-0.7c0-0.3,0.1-0.5,0.2-0.6c0.2-0.1,0.4-0.2,0.7-0.2h0.8l0,0.4h-0.8
				c-0.1,0-0.3,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.3,0.1,0.3c0.1,0.1,0.2,0.1,0.4,0.1c0.2,0,0.4,0,0.5-0.1
				c0.1,0,0.2-0.1,0.2-0.2l0.1,0.4c0,0.1-0.1,0.1-0.2,0.2c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.2,0-0.3,0
				C1297.6,602.9,1297.3,602.8,1297.2,602.7z M1298.7,601.1c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1,0-0.2,0-0.3,0.1
				c-0.1,0-0.2,0.1-0.3,0.1l-0.4-0.3c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.4
				c0.1,0.2,0.1,0.3,0.1,0.6v1.8h-0.5V601.1z"
            />
            <path
              fill="#FFFFFF"
              d="M1300,600h0.6v2.8h-0.6V600z M1301.4,600.6c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3,0-0.4,0.1s-0.1,0.2-0.1,0.4
				l-0.1-0.5c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2l-0.4,0.5
				C1301.6,600.6,1301.5,600.6,1301.4,600.6z"
            />
            <path
              fill="#FFFFFF"
              d="M1303,602.9c-0.1,0-0.3-0.1-0.4-0.1s-0.2-0.1-0.4-0.2l0.3-0.4c0.2,0.1,0.3,0.2,0.5,0.3
				c0.2,0.1,0.3,0.1,0.5,0.1c0.2,0,0.4,0,0.5-0.1c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1,0-0.2-0.1-0.2c-0.1,0-0.1-0.1-0.2-0.1
				c-0.1,0-0.2,0-0.4,0c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.2,0-0.3,0-0.5-0.1s-0.2-0.1-0.3-0.2s-0.1-0.3-0.1-0.5
				s0-0.4,0.1-0.5c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.4-0.1,0.6-0.1c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0.1,0.4,0.1
				c0.1,0.1,0.2,0.1,0.4,0.2l-0.3,0.4c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.3-0.1-0.4-0.1c-0.2,0-0.3,0-0.4,0.1
				c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.1,0.1,0.2c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.4,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
				c0,0,0,0,0,0c0.2,0,0.4,0,0.5,0.1c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.4,0.3
				c-0.2,0.1-0.4,0.1-0.7,0.1C1303.3,602.9,1303.1,602.9,1303,602.9z"
            />
            <path
              fill="#FFFFFF"
              d="M1283.2,605.6h0.6v4h-0.6V605.6z M1283.6,609.1h1.1c0.3,0,0.5-0.1,0.6-0.2c0.1-0.1,0.2-0.3,0.2-0.5v0
				c0-0.1,0-0.3-0.1-0.3c0-0.1-0.1-0.2-0.2-0.2s-0.2-0.1-0.4-0.1h-1.2v-0.5h1.2c0.2,0,0.4,0,0.5-0.1c0.1-0.1,0.2-0.2,0.2-0.4
				c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.1-0.5-0.1h-1.1v-0.5h1.2c0.3,0,0.5,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.4s0.1,0.4,0.1,0.6
				c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.4,0.2c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.3,0.3
				c0.1,0.1,0.1,0.3,0.1,0.4v0c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.1-0.6,0.1h-1.3V609.1z"
            />
            <path
              fill="#FFFFFF"
              d="M1286.8,606.8h0.6v2.8h-0.6V606.8z M1288.2,607.3c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3,0-0.4,0.1
				s-0.1,0.2-0.1,0.4l-0.1-0.5c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2
				l-0.4,0.5C1288.3,607.4,1288.2,607.3,1288.2,607.3z"
            />
            <path
              fill="#FFFFFF"
              d="M1289.2,605.6h0.6v0.6h-0.6V605.6z M1289.2,606.8h0.6v2.8h-0.6V606.8z"
            />
            <path
              fill="#FFFFFF"
              d="M1291,609.5c-0.1-0.1-0.3-0.2-0.3-0.4c-0.1-0.2-0.1-0.4-0.1-0.6v-0.6c0-0.2,0-0.5,0.1-0.6
				c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0,0.3,0,0.5,0.1s0.3,0.2,0.3,0.4l-0.1,0.6c0-0.1,0-0.2-0.1-0.3
				c0-0.1-0.1-0.2-0.2-0.2s-0.2-0.1-0.3-0.1c-0.2,0-0.3,0.1-0.4,0.2s-0.2,0.3-0.2,0.5v0.6c0,0.2,0.1,0.4,0.2,0.5s0.2,0.2,0.4,0.2
				c0.1,0,0.2,0,0.3-0.1s0.1-0.1,0.2-0.2s0.1-0.2,0.1-0.3l0,0.6c-0.1,0.1-0.1,0.3-0.3,0.4c-0.1,0.1-0.3,0.1-0.5,0.1
				C1291.3,609.7,1291.1,609.6,1291,609.5z M1292.2,605.6h0.6v4h-0.6V605.6z"
            />
            <path
              fill="#FFFFFF"
              d="M1294,609.5c-0.1-0.1-0.3-0.2-0.3-0.4c-0.1-0.2-0.1-0.4-0.1-0.6v-0.6c0-0.2,0-0.5,0.1-0.6
				c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0,0.3,0,0.5,0.1s0.3,0.2,0.3,0.4l-0.1,0.6c0-0.1,0-0.2-0.1-0.3
				c0-0.1-0.1-0.2-0.2-0.2s-0.2-0.1-0.3-0.1c-0.2,0-0.3,0.1-0.4,0.2s-0.2,0.3-0.2,0.5v0.6c0,0.2,0.1,0.4,0.2,0.5s0.2,0.2,0.4,0.2
				c0.1,0,0.2,0,0.3-0.1s0.1-0.1,0.2-0.2s0.1-0.2,0.1-0.3l0,0.6c-0.1,0.1-0.1,0.3-0.3,0.4c-0.1,0.1-0.3,0.1-0.5,0.1
				C1294.3,609.7,1294.1,609.6,1294,609.5z M1294,610.6c-0.2-0.1-0.3-0.2-0.4-0.3l0.4-0.3c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.1
				c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5v-2.8h0.6v2.8c0,0.3,0,0.5-0.1,0.7c-0.1,0.2-0.2,0.3-0.4,0.4s-0.4,0.1-0.7,0.1
				C1294.4,610.8,1294.2,610.7,1294,610.6z"
            />
            <path
              fill="#FFFFFF"
              d="M1297.1,609.5c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.1-0.4-0.1-0.7V608c0-0.3,0-0.5,0.1-0.7
				c0.1-0.2,0.2-0.4,0.4-0.5c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.5,0.1,0.8v0.2
				h-1.9V608h1.4c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.4,0.1-0.5,0.2s-0.2,0.3-0.2,0.6v0.4
				c0,0.2,0.1,0.4,0.2,0.5s0.3,0.2,0.5,0.2c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2-0.1,0.3-0.2l0.4,0.4c-0.1,0.1-0.3,0.2-0.5,0.3
				c-0.2,0.1-0.4,0.1-0.5,0.1C1297.5,609.6,1297.3,609.6,1297.1,609.5z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M1296.3,619.4h0.6v4h-0.6V619.4z M1296.7,622.8h1.1c0.3,0,0.5-0.1,0.6-0.2c0.1-0.1,0.2-0.3,0.2-0.5v0
				c0-0.1,0-0.3-0.1-0.3c0-0.1-0.1-0.2-0.2-0.2s-0.2-0.1-0.4-0.1h-1.2V621h1.2c0.2,0,0.4,0,0.5-0.1c0.1-0.1,0.2-0.2,0.2-0.4
				c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.1-0.5-0.1h-1.1v-0.5h1.2c0.3,0,0.5,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.4s0.1,0.4,0.1,0.6
				c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.4,0.2c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.3,0.3
				c0.1,0.1,0.1,0.3,0.1,0.4v0c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.1-0.6,0.1h-1.3V622.8z"
            />
            <path
              fill="#FFFFFF"
              d="M1300.5,622.6c0,0.1,0,0.1,0,0.2c0,0,0.1,0.1,0.1,0.1h0.3v0.5h-0.3c-0.2,0-0.4-0.1-0.5-0.2
				s-0.2-0.3-0.2-0.6v-3.2h0.6V622.6z"
            />
            <path
              fill="#FFFFFF"
              d="M1301.7,623.2c-0.2-0.1-0.3-0.4-0.3-0.7c0-0.3,0.1-0.5,0.2-0.6c0.2-0.1,0.4-0.2,0.7-0.2h0.8l0,0.4h-0.8
				c-0.1,0-0.3,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.3,0.1,0.3c0.1,0.1,0.2,0.1,0.4,0.1c0.2,0,0.4,0,0.5-0.1
				c0.1,0,0.2-0.1,0.2-0.2l0.1,0.4c0,0.1-0.1,0.1-0.2,0.2c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.2,0-0.3,0
				C1302.1,623.4,1301.8,623.3,1301.7,623.2z M1303.1,621.5c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1,0-0.2,0-0.3,0.1
				c-0.1,0-0.2,0.1-0.3,0.1l-0.4-0.3c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.4
				c0.1,0.2,0.1,0.3,0.1,0.6v1.8h-0.5V621.5z"
            />
            <path
              fill="#FFFFFF"
              d="M1305,623.2c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.1-0.4-0.1-0.7v-0.5c0-0.3,0-0.5,0.1-0.7
				c0.1-0.2,0.2-0.3,0.4-0.4s0.4-0.1,0.7-0.1c0.1,0,0.3,0,0.4,0.1c0.1,0,0.2,0.1,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2l-0.4,0.4
				c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5v0.5c0,0.2,0.1,0.4,0.2,0.5
				s0.3,0.2,0.5,0.2c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.2-0.1,0.3-0.2l0.4,0.4c-0.1,0.1-0.2,0.2-0.3,0.2s-0.2,0.1-0.4,0.2
				c-0.1,0-0.3,0.1-0.4,0.1C1305.4,623.4,1305.2,623.3,1305,623.2z"
            />
            <path
              fill="#FFFFFF"
              d="M1307.3,619.4h0.6v4h-0.6V619.4z M1307.6,622.3l1.5-1.8h0.7l-2.2,2.4L1307.6,622.3z M1308.2,621.7
				l0.4-0.4l1.2,2h-0.6L1308.2,621.7z"
            />
            <path
              fill="#FFFFFF"
              d="M1310.1,620.5h1.4v0.5h-1.4V620.5z M1310.4,620.1c0-0.3,0.1-0.4,0.2-0.6s0.3-0.2,0.5-0.2h0.4v0.5h-0.4
				c-0.1,0-0.1,0-0.1,0.1c0,0,0,0.1,0,0.2v3.2h-0.6V620.1z"
            />
            <path
              fill="#FFFFFF"
              d="M1312.1,620.5h0.6v2.8h-0.6V620.5z M1313.4,621c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3,0-0.4,0.1
				s-0.1,0.2-0.1,0.4l-0.1-0.5c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2
				l-0.4,0.5C1313.6,621.1,1313.5,621.1,1313.4,621z"
            />
            <path
              fill="#FFFFFF"
              d="M1314.5,619.4h0.6v0.6h-0.6V619.4z M1314.5,620.5h0.6v2.8h-0.6V620.5z"
            />
            <path
              fill="#FFFFFF"
              d="M1316,623.2c-0.2-0.1-0.3-0.4-0.3-0.7c0-0.3,0.1-0.5,0.2-0.6c0.2-0.1,0.4-0.2,0.7-0.2h0.8l0,0.4h-0.8
				c-0.1,0-0.3,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.3,0.1,0.3c0.1,0.1,0.2,0.1,0.4,0.1c0.2,0,0.4,0,0.5-0.1
				c0.1,0,0.2-0.1,0.2-0.2l0.1,0.4c0,0.1-0.1,0.1-0.2,0.2c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.2,0-0.3,0
				C1316.4,623.4,1316.2,623.3,1316,623.2z M1317.5,621.5c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1,0-0.2,0-0.3,0.1
				c-0.1,0-0.2,0.1-0.3,0.1l-0.4-0.3c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.4
				c0.1,0.2,0.1,0.3,0.1,0.6v1.8h-0.5V621.5z"
            />
            <path
              fill="#FFFFFF"
              d="M1318.8,620.5h0.6v2.8h-0.6V620.5z M1320.2,621c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3,0-0.4,0.1
				s-0.1,0.2-0.1,0.4l-0.1-0.5c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2
				l-0.4,0.5C1320.4,621.1,1320.3,621.1,1320.2,621z"
            />
            <path
              fill="#FFFFFF"
              d="M1321.8,623.3c-0.1,0-0.3-0.1-0.4-0.1s-0.2-0.1-0.4-0.2l0.3-0.4c0.2,0.1,0.3,0.2,0.5,0.3
				c0.2,0.1,0.3,0.1,0.5,0.1c0.2,0,0.4,0,0.5-0.1c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1,0-0.2-0.1-0.2c-0.1,0-0.1-0.1-0.2-0.1
				c-0.1,0-0.2,0-0.4,0c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.2,0-0.3,0-0.5-0.1s-0.2-0.1-0.3-0.2s-0.1-0.3-0.1-0.5
				s0-0.4,0.1-0.5c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.4-0.1,0.6-0.1c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0.1,0.4,0.1
				c0.1,0.1,0.2,0.1,0.4,0.2l-0.3,0.4c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.3-0.1-0.4-0.1c-0.2,0-0.3,0-0.4,0.1
				c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.1,0.1,0.2c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.4,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
				c0,0,0,0,0,0c0.2,0,0.4,0,0.5,0.1c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.4,0.3
				c-0.2,0.1-0.4,0.1-0.7,0.1C1322.1,623.4,1321.9,623.4,1321.8,623.3z"
            />
            <path
              fill="#FFFFFF"
              d="M1291.4,626.1h0.6v4h-0.6V626.1z M1291.7,627.9h1.4c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.1-0.1,0.2-0.2
				c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.2-0.2-0.2c-0.1-0.1-0.2-0.1-0.3-0.1h-1.4v-0.5h1.3c0.2,0,0.4,0,0.6,0.1
				c0.2,0.1,0.3,0.2,0.4,0.4c0.1,0.2,0.1,0.4,0.1,0.6s0,0.4-0.1,0.6s-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.1-0.6,0.1h-1.3V627.9z
				 M1292.8,628.3l0.6-0.1l1,1.9h-0.7L1292.8,628.3z"
            />
            <path
              fill="#FFFFFF"
              d="M1295.1,629.9c-0.2-0.1-0.3-0.4-0.3-0.7c0-0.3,0.1-0.5,0.2-0.6c0.2-0.1,0.4-0.2,0.7-0.2h0.8l0,0.4h-0.8
				c-0.1,0-0.3,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.3,0.1,0.3c0.1,0.1,0.2,0.1,0.4,0.1c0.2,0,0.4,0,0.5-0.1
				c0.1,0,0.2-0.1,0.2-0.2l0.1,0.4c0,0.1-0.1,0.1-0.2,0.2c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.2,0-0.3,0
				C1295.6,630.1,1295.3,630,1295.1,629.9z M1296.6,628.3c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1,0-0.2,0-0.3,0.1
				c-0.1,0-0.2,0.1-0.3,0.1l-0.4-0.3c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.4
				c0.1,0.2,0.1,0.3,0.1,0.6v1.8h-0.5V628.3z"
            />
            <path
              fill="#FFFFFF"
              d="M1298,626.1h0.6v0.6h-0.6V626.1z M1298,627.2h0.6v2.8h-0.6V627.2z"
            />
            <path
              fill="#FFFFFF"
              d="M1300,629.3c0,0.1,0,0.1,0,0.2c0,0,0.1,0.1,0.1,0.1h0.3v0.5h-0.3c-0.2,0-0.4-0.1-0.5-0.2
				s-0.2-0.3-0.2-0.6v-3.2h0.6V629.3z"
            />
            <path
              fill="#FFFFFF"
              d="M1300.8,627.2h0.6l0.5,2l0.7-2h0.4l0.7,2l0.5-2h0.6l-0.9,2.8h-0.4l-0.7-2l-0.7,2h-0.4L1300.8,627.2z"
            />
            <path
              fill="#FFFFFF"
              d="M1305.5,629.9c-0.2-0.1-0.3-0.4-0.3-0.7c0-0.3,0.1-0.5,0.2-0.6c0.2-0.1,0.4-0.2,0.7-0.2h0.8l0,0.4h-0.8
				c-0.1,0-0.3,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.3,0.1,0.3c0.1,0.1,0.2,0.1,0.4,0.1c0.2,0,0.4,0,0.5-0.1
				c0.1,0,0.2-0.1,0.2-0.2l0.1,0.4c0,0.1-0.1,0.1-0.2,0.2c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.2,0-0.3,0
				C1305.9,630.1,1305.7,630,1305.5,629.9z M1307,628.3c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1,0-0.2,0-0.3,0.1
				c-0.1,0-0.2,0.1-0.3,0.1l-0.4-0.3c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.4
				c0.1,0.2,0.1,0.3,0.1,0.6v1.8h-0.5V628.3z"
            />
            <path
              fill="#FFFFFF"
              d="M1308.1,627.2h0.6l0.9,2.5l-0.3,0.6L1308.1,627.2z M1310.6,627.2l-1.2,3.5c0,0.1-0.1,0.2-0.2,0.3
				c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.2,0-0.4,0h-0.1v-0.5h0.1c0.1,0,0.2,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.2l1.1-3.2H1310.6z"
            />
            <path
              fill="#FFFFFF"
              d="M1312.6,626.1h0.6v4h-0.6V626.1z M1313,629.6h1.1c0.3,0,0.5-0.1,0.6-0.2c0.1-0.1,0.2-0.3,0.2-0.5v0
				c0-0.1,0-0.3-0.1-0.3c0-0.1-0.1-0.2-0.2-0.2s-0.2-0.1-0.4-0.1h-1.2v-0.5h1.2c0.2,0,0.4,0,0.5-0.1c0.1-0.1,0.2-0.2,0.2-0.4
				c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.1-0.5-0.1h-1.1v-0.5h1.2c0.3,0,0.5,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.4s0.1,0.4,0.1,0.6
				c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.4,0.2c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.3,0.3
				c0.1,0.1,0.1,0.3,0.1,0.4v0c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.1-0.6,0.1h-1.3V629.6z"
            />
            <path
              fill="#FFFFFF"
              d="M1316.2,627.2h0.6v2.8h-0.6V627.2z M1317.6,627.8c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3,0-0.4,0.1
				s-0.1,0.2-0.1,0.4l-0.1-0.5c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2
				l-0.4,0.5C1317.7,627.8,1317.7,627.8,1317.6,627.8z"
            />
            <path
              fill="#FFFFFF"
              d="M1318.6,626.1h0.6v0.6h-0.6V626.1z M1318.6,627.2h0.6v2.8h-0.6V627.2z"
            />
            <path
              fill="#FFFFFF"
              d="M1320.4,630c-0.1-0.1-0.3-0.2-0.3-0.4c-0.1-0.2-0.1-0.4-0.1-0.6v-0.6c0-0.2,0-0.5,0.1-0.6
				c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0,0.3,0,0.5,0.1s0.3,0.2,0.3,0.4l-0.1,0.6c0-0.1,0-0.2-0.1-0.3
				c0-0.1-0.1-0.2-0.2-0.2s-0.2-0.1-0.3-0.1c-0.2,0-0.3,0.1-0.4,0.2s-0.2,0.3-0.2,0.5v0.6c0,0.2,0.1,0.4,0.2,0.5s0.2,0.2,0.4,0.2
				c0.1,0,0.2,0,0.3-0.1s0.1-0.1,0.2-0.2s0.1-0.2,0.1-0.3l0,0.6c-0.1,0.1-0.1,0.3-0.3,0.4c-0.1,0.1-0.3,0.1-0.5,0.1
				C1320.7,630.1,1320.5,630.1,1320.4,630z M1321.6,626.1h0.6v4h-0.6V626.1z"
            />
            <path
              fill="#FFFFFF"
              d="M1323.4,630c-0.1-0.1-0.3-0.2-0.3-0.4c-0.1-0.2-0.1-0.4-0.1-0.6v-0.6c0-0.2,0-0.5,0.1-0.6
				c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0,0.3,0,0.5,0.1s0.3,0.2,0.3,0.4l-0.1,0.6c0-0.1,0-0.2-0.1-0.3
				c0-0.1-0.1-0.2-0.2-0.2s-0.2-0.1-0.3-0.1c-0.2,0-0.3,0.1-0.4,0.2s-0.2,0.3-0.2,0.5v0.6c0,0.2,0.1,0.4,0.2,0.5s0.2,0.2,0.4,0.2
				c0.1,0,0.2,0,0.3-0.1s0.1-0.1,0.2-0.2s0.1-0.2,0.1-0.3l0,0.6c-0.1,0.1-0.1,0.3-0.3,0.4c-0.1,0.1-0.3,0.1-0.5,0.1
				C1323.7,630.1,1323.6,630.1,1323.4,630z M1323.4,631.1c-0.2-0.1-0.3-0.2-0.4-0.3l0.4-0.3c0.1,0.1,0.2,0.2,0.3,0.2
				s0.2,0.1,0.3,0.1c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5v-2.8h0.6v2.8c0,0.3,0,0.5-0.1,0.7c-0.1,0.2-0.2,0.3-0.4,0.4
				s-0.4,0.1-0.7,0.1C1323.8,631.2,1323.6,631.2,1323.4,631.1z"
            />
            <path
              fill="#FFFFFF"
              d="M1326.6,630c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.1-0.4-0.1-0.7v-0.3c0-0.3,0-0.5,0.1-0.7
				c0.1-0.2,0.2-0.4,0.4-0.5c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.5,0.1,0.8v0.2
				h-1.9v-0.4h1.4c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.4,0.1-0.5,0.2s-0.2,0.3-0.2,0.6v0.4
				c0,0.2,0.1,0.4,0.2,0.5s0.3,0.2,0.5,0.2c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2-0.1,0.3-0.2l0.4,0.4c-0.1,0.1-0.3,0.2-0.5,0.3
				c-0.2,0.1-0.4,0.1-0.5,0.1C1327,630.1,1326.7,630.1,1326.6,630z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M1065.2,623.1h0.4l0.8,3.1l0.7-3.1h0.6l-1,4h-0.6l-0.7-2.8l-0.8,2.8h-0.6l-1-4h0.6l0.7,3.1L1065.2,623.1z
				"
            />
            <path
              fill="#FFFFFF"
              d="M1068.4,626.9c-0.2-0.1-0.3-0.4-0.3-0.7c0-0.3,0.1-0.5,0.2-0.6c0.2-0.1,0.4-0.2,0.7-0.2h0.8l0,0.4h-0.8
				c-0.1,0-0.3,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.3,0.1,0.3c0.1,0.1,0.2,0.1,0.4,0.1c0.2,0,0.4,0,0.5-0.1
				c0.1,0,0.2-0.1,0.2-0.2l0.1,0.4c0,0.1-0.1,0.1-0.2,0.2c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.2,0-0.3,0
				C1068.8,627.1,1068.5,627,1068.4,626.9z M1069.9,625.3c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1,0-0.2,0-0.3,0.1
				c-0.1,0-0.2,0.1-0.3,0.1l-0.4-0.3c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.4
				c0.1,0.2,0.1,0.3,0.1,0.6v1.8h-0.5V625.3z"
            />
            <path
              fill="#FFFFFF"
              d="M1071,624.2h1.3v0.5h-1.3V624.2z M1071.5,626.9c-0.1-0.1-0.2-0.3-0.2-0.6v-2.9h0.6v2.9c0,0.1,0,0.1,0,0.2
				c0,0,0.1,0.1,0.1,0.1h0.3v0.5h-0.3C1071.7,627.1,1071.6,627,1071.5,626.9z"
            />
            <path
              fill="#FFFFFF"
              d="M1073.6,627c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.1-0.4-0.1-0.7v-0.3c0-0.3,0-0.5,0.1-0.7
				c0.1-0.2,0.2-0.4,0.4-0.5c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.5,0.1,0.8v0.2
				h-1.9v-0.4h1.4c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.4,0.1-0.5,0.2s-0.2,0.3-0.2,0.6v0.4
				c0,0.2,0.1,0.4,0.2,0.5s0.3,0.2,0.5,0.2c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2-0.1,0.3-0.2l0.4,0.4c-0.1,0.1-0.3,0.2-0.5,0.3
				c-0.2,0.1-0.4,0.1-0.5,0.1C1074,627.1,1073.7,627.1,1073.6,627z"
            />
            <path
              fill="#FFFFFF"
              d="M1076.1,624.2h0.6v2.8h-0.6V624.2z M1077.4,624.8c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3,0-0.4,0.1
				s-0.1,0.2-0.1,0.4l-0.1-0.5c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2
				l-0.4,0.5C1077.6,624.8,1077.5,624.8,1077.4,624.8z"
            />
            <path
              fill="#FFFFFF"
              d="M1079,626.3c0,0.1,0,0.1,0,0.2c0,0,0.1,0.1,0.1,0.1h0.3v0.5h-0.3c-0.2,0-0.4-0.1-0.5-0.2
				s-0.2-0.3-0.2-0.6v-3.2h0.6V626.3z"
            />
            <path
              fill="#FFFFFF"
              d="M1080.5,627c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.1-0.4-0.1-0.7v-0.5c0-0.3,0-0.5,0.1-0.7
				c0.1-0.2,0.2-0.3,0.4-0.4c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.5,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.4c0.1,0.2,0.1,0.4,0.1,0.7v0.5
				c0,0.3,0,0.5-0.1,0.7c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.1-0.6,0.1C1080.9,627.1,1080.7,627.1,1080.5,627z M1081.6,626.4
				c0.1-0.1,0.2-0.3,0.2-0.5v-0.5c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.3,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5
				v0.5c0,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.3,0.2,0.5,0.2C1081.4,626.6,1081.5,626.5,1081.6,626.4z"
            />
            <path
              fill="#FFFFFF"
              d="M1083.6,627c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.1-0.4-0.1-0.7v-0.5c0-0.3,0-0.5,0.1-0.7
				c0.1-0.2,0.2-0.3,0.4-0.4c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.5,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.4c0.1,0.2,0.1,0.4,0.1,0.7v0.5
				c0,0.3,0,0.5-0.1,0.7c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.1-0.6,0.1C1084,627.1,1083.8,627.1,1083.6,627z M1084.7,626.4
				c0.1-0.1,0.2-0.3,0.2-0.5v-0.5c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.3,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5
				v0.5c0,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.3,0.2,0.5,0.2C1084.4,626.6,1084.6,626.5,1084.7,626.4z"
            />
            <path
              fill="#FFFFFF"
              d="M1087.8,623.1h0.6v4h-0.6V623.1z M1088.1,626.5h1.1c0.3,0,0.5-0.1,0.6-0.2c0.1-0.1,0.2-0.3,0.2-0.5v0
				c0-0.1,0-0.3-0.1-0.3c0-0.1-0.1-0.2-0.2-0.2s-0.2-0.1-0.4-0.1h-1.2v-0.5h1.2c0.2,0,0.4,0,0.5-0.1c0.1-0.1,0.2-0.2,0.2-0.4
				c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.1-0.5-0.1h-1.1v-0.5h1.2c0.3,0,0.5,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.4s0.1,0.4,0.1,0.6
				c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.4,0.2c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.3,0.3
				c0.1,0.1,0.1,0.3,0.1,0.4v0c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.1-0.6,0.1h-1.3V626.5z"
            />
            <path
              fill="#FFFFFF"
              d="M1091.3,624.2h0.6v2.8h-0.6V624.2z M1092.7,624.8c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3,0-0.4,0.1
				s-0.1,0.2-0.1,0.4l-0.1-0.5c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2
				l-0.4,0.5C1092.8,624.8,1092.8,624.8,1092.7,624.8z"
            />
            <path
              fill="#FFFFFF"
              d="M1093.7,623.1h0.6v0.6h-0.6V623.1z M1093.7,624.2h0.6v2.8h-0.6V624.2z"
            />
            <path
              fill="#FFFFFF"
              d="M1095.5,627c-0.1-0.1-0.3-0.2-0.3-0.4c-0.1-0.2-0.1-0.4-0.1-0.6v-0.6c0-0.2,0-0.5,0.1-0.6
				c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0,0.3,0,0.5,0.1s0.3,0.2,0.3,0.4l-0.1,0.6c0-0.1,0-0.2-0.1-0.3
				c0-0.1-0.1-0.2-0.2-0.2s-0.2-0.1-0.3-0.1c-0.2,0-0.3,0.1-0.4,0.2s-0.2,0.3-0.2,0.5v0.6c0,0.2,0.1,0.4,0.2,0.5s0.2,0.2,0.4,0.2
				c0.1,0,0.2,0,0.3-0.1s0.1-0.1,0.2-0.2s0.1-0.2,0.1-0.3l0,0.6c-0.1,0.1-0.1,0.3-0.3,0.4c-0.1,0.1-0.3,0.1-0.5,0.1
				C1095.8,627.1,1095.7,627.1,1095.5,627z M1096.8,623.1h0.6v4h-0.6V623.1z"
            />
            <path
              fill="#FFFFFF"
              d="M1098.5,627c-0.1-0.1-0.3-0.2-0.3-0.4c-0.1-0.2-0.1-0.4-0.1-0.6v-0.6c0-0.2,0-0.5,0.1-0.6
				c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0,0.3,0,0.5,0.1s0.3,0.2,0.3,0.4l-0.1,0.6c0-0.1,0-0.2-0.1-0.3
				c0-0.1-0.1-0.2-0.2-0.2s-0.2-0.1-0.3-0.1c-0.2,0-0.3,0.1-0.4,0.2s-0.2,0.3-0.2,0.5v0.6c0,0.2,0.1,0.4,0.2,0.5s0.2,0.2,0.4,0.2
				c0.1,0,0.2,0,0.3-0.1s0.1-0.1,0.2-0.2s0.1-0.2,0.1-0.3l0,0.6c-0.1,0.1-0.1,0.3-0.3,0.4c-0.1,0.1-0.3,0.1-0.5,0.1
				C1098.9,627.1,1098.7,627.1,1098.5,627z M1098.5,628.1c-0.2-0.1-0.3-0.2-0.4-0.3l0.4-0.3c0.1,0.1,0.2,0.2,0.3,0.2
				s0.2,0.1,0.3,0.1c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5v-2.8h0.6v2.8c0,0.3,0,0.5-0.1,0.7c-0.1,0.2-0.2,0.3-0.4,0.4
				s-0.4,0.1-0.7,0.1C1098.9,628.2,1098.7,628.2,1098.5,628.1z"
            />
            <path
              fill="#FFFFFF"
              d="M1101.7,627c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.1-0.4-0.1-0.7v-0.3c0-0.3,0-0.5,0.1-0.7
				c0.1-0.2,0.2-0.4,0.4-0.5c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.5,0.1,0.8v0.2
				h-1.9v-0.4h1.4c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.4,0.1-0.5,0.2s-0.2,0.3-0.2,0.6v0.4
				c0,0.2,0.1,0.4,0.2,0.5s0.3,0.2,0.5,0.2c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2-0.1,0.3-0.2l0.4,0.4c-0.1,0.1-0.3,0.2-0.5,0.3
				c-0.2,0.1-0.4,0.1-0.5,0.1C1102.1,627.1,1101.9,627.1,1101.7,627z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M1027.2,701.8h0.6v4h-0.6V701.8z M1027.5,703.5h2.2v0.5h-2.2V703.5z M1029.4,701.8h0.6v4h-0.6V701.8z"
            />
            <path
              fill="#FFFFFF"
              d="M1031.5,704.6c0,0.2,0.1,0.4,0.2,0.5s0.2,0.2,0.4,0.2c0.2,0,0.3,0,0.4-0.1s0.2-0.2,0.2-0.4l0,0.6
				c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.1-0.5,0.1c-0.3,0-0.6-0.1-0.7-0.3s-0.3-0.5-0.3-0.9v-1.7h0.6V704.6z M1032.6,702.9h0.6
				v2.8h-0.6V702.9z"
            />
            <path
              fill="#FFFFFF"
              d="M1034,702.9h0.6v2.8h-0.6V702.9z M1035.8,704c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.2-0.2-0.4-0.2
				c-0.2,0-0.3,0-0.4,0.1s-0.1,0.2-0.1,0.4l-0.1-0.5c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.3,0,0.5,0.1,0.7,0.3
				c0.2,0.2,0.3,0.5,0.3,0.9v1.7h-0.6V704z"
            />
            <path
              fill="#FFFFFF"
              d="M1037.6,705.7c-0.1-0.1-0.3-0.2-0.3-0.4c-0.1-0.2-0.1-0.4-0.1-0.6V704c0-0.2,0-0.5,0.1-0.6
				c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0,0.3,0,0.5,0.1s0.3,0.2,0.3,0.4l-0.1,0.6c0-0.1,0-0.2-0.1-0.3
				c0-0.1-0.1-0.2-0.2-0.2s-0.2-0.1-0.3-0.1c-0.2,0-0.3,0.1-0.4,0.2s-0.2,0.3-0.2,0.5v0.6c0,0.2,0.1,0.4,0.2,0.5s0.2,0.2,0.4,0.2
				c0.1,0,0.2,0,0.3-0.1s0.1-0.1,0.2-0.2s0.1-0.2,0.1-0.3l0,0.6c-0.1,0.1-0.1,0.3-0.3,0.4c-0.1,0.1-0.3,0.1-0.5,0.1
				C1037.9,705.8,1037.7,705.7,1037.6,705.7z M1037.6,706.8c-0.2-0.1-0.3-0.2-0.4-0.3l0.4-0.3c0.1,0.1,0.2,0.2,0.3,0.2
				s0.2,0.1,0.3,0.1c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5v-2.8h0.6v2.8c0,0.3,0,0.5-0.1,0.7c-0.1,0.2-0.2,0.3-0.4,0.4
				s-0.4,0.1-0.7,0.1C1037.9,706.9,1037.7,706.9,1037.6,706.8z"
            />
            <path
              fill="#FFFFFF"
              d="M1040.7,705.6c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.1-0.4-0.1-0.7v-0.3c0-0.3,0-0.5,0.1-0.7
				c0.1-0.2,0.2-0.4,0.4-0.5c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.5,0.1,0.8v0.2
				h-1.9v-0.4h1.4c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.4,0.1-0.5,0.2s-0.2,0.3-0.2,0.6v0.4
				c0,0.2,0.1,0.4,0.2,0.5s0.3,0.2,0.5,0.2c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2-0.1,0.3-0.2l0.4,0.4c-0.1,0.1-0.3,0.2-0.5,0.3
				c-0.2,0.1-0.4,0.1-0.5,0.1C1041.1,705.8,1040.9,705.7,1040.7,705.6z"
            />
            <path
              fill="#FFFFFF"
              d="M1043.2,702.9h0.6v2.8h-0.6V702.9z M1044.6,703.4c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3,0-0.4,0.1
				s-0.1,0.2-0.1,0.4l-0.1-0.5c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2
				l-0.4,0.5C1044.7,703.5,1044.7,703.5,1044.6,703.4z"
            />
            <path
              fill="#FFFFFF"
              d="M1045.4,702.9h1.4v0.5h-1.4V702.9z M1045.7,702.5c0-0.3,0.1-0.4,0.2-0.6s0.3-0.2,0.5-0.2h0.4v0.5h-0.4
				c-0.1,0-0.1,0-0.1,0.1c0,0,0,0.1,0,0.2v3.2h-0.6V702.5z"
            />
            <path
              fill="#FFFFFF"
              d="M1047.8,705.6c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.1-0.4-0.1-0.7v-0.5c0-0.3,0-0.5,0.1-0.7
				c0.1-0.2,0.2-0.3,0.4-0.4c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.5,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.4c0.1,0.2,0.1,0.4,0.1,0.7v0.5
				c0,0.3,0,0.5-0.1,0.7c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.1-0.6,0.1C1048.2,705.8,1048,705.7,1047.8,705.6z M1048.9,705.1
				c0.1-0.1,0.2-0.3,0.2-0.5v-0.5c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.3,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5
				v0.5c0,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.3,0.2,0.5,0.2C1048.7,705.3,1048.8,705.2,1048.9,705.1z"
            />
            <path
              fill="#FFFFFF"
              d="M1050.4,702.9h0.6v2.8h-0.6V702.9z M1051.8,703.4c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3,0-0.4,0.1
				s-0.1,0.2-0.1,0.4l-0.1-0.5c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2
				l-0.4,0.5C1052,703.5,1051.9,703.5,1051.8,703.4z"
            />
            <path
              fill="#FFFFFF"
              d="M1053.2,705.7c-0.1-0.1-0.3-0.2-0.3-0.4c-0.1-0.2-0.1-0.4-0.1-0.6V704c0-0.2,0-0.5,0.1-0.6
				c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0,0.3,0,0.5,0.1s0.3,0.2,0.3,0.4l-0.1,0.6c0-0.1,0-0.2-0.1-0.3
				c0-0.1-0.1-0.2-0.2-0.2s-0.2-0.1-0.3-0.1c-0.2,0-0.3,0.1-0.4,0.2s-0.2,0.3-0.2,0.5v0.6c0,0.2,0.1,0.4,0.2,0.5s0.2,0.2,0.4,0.2
				c0.1,0,0.2,0,0.3-0.1s0.1-0.1,0.2-0.2s0.1-0.2,0.1-0.3l0,0.6c-0.1,0.1-0.1,0.3-0.3,0.4c-0.1,0.1-0.3,0.1-0.5,0.1
				C1053.5,705.8,1053.4,705.7,1053.2,705.7z M1054.5,701.8h0.6v4h-0.6V701.8z"
            />
            <path
              fill="#FFFFFF"
              d="M1057.4,701.8h0.6v4h-0.6V701.8z M1057.8,705.2h1.1c0.3,0,0.5-0.1,0.6-0.2c0.1-0.1,0.2-0.3,0.2-0.5v0
				c0-0.1,0-0.3-0.1-0.3c0-0.1-0.1-0.2-0.2-0.2s-0.2-0.1-0.4-0.1h-1.2v-0.5h1.2c0.2,0,0.4,0,0.5-0.1c0.1-0.1,0.2-0.2,0.2-0.4
				c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.1-0.5-0.1h-1.1v-0.5h1.2c0.3,0,0.5,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.4s0.1,0.4,0.1,0.6
				c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.4,0.2c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.3,0.3
				c0.1,0.1,0.1,0.3,0.1,0.4v0c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.1-0.6,0.1h-1.3V705.2z"
            />
            <path
              fill="#FFFFFF"
              d="M1061,702.9h0.6v2.8h-0.6V702.9z M1062.4,703.4c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3,0-0.4,0.1
				s-0.1,0.2-0.1,0.4l-0.1-0.5c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2
				l-0.4,0.5C1062.5,703.5,1062.4,703.5,1062.4,703.4z"
            />
            <path
              fill="#FFFFFF"
              d="M1063.4,701.8h0.6v0.6h-0.6V701.8z M1063.4,702.9h0.6v2.8h-0.6V702.9z"
            />
            <path
              fill="#FFFFFF"
              d="M1065.2,705.7c-0.1-0.1-0.3-0.2-0.3-0.4c-0.1-0.2-0.1-0.4-0.1-0.6V704c0-0.2,0-0.5,0.1-0.6
				c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0,0.3,0,0.5,0.1s0.3,0.2,0.3,0.4l-0.1,0.6c0-0.1,0-0.2-0.1-0.3
				c0-0.1-0.1-0.2-0.2-0.2s-0.2-0.1-0.3-0.1c-0.2,0-0.3,0.1-0.4,0.2s-0.2,0.3-0.2,0.5v0.6c0,0.2,0.1,0.4,0.2,0.5s0.2,0.2,0.4,0.2
				c0.1,0,0.2,0,0.3-0.1s0.1-0.1,0.2-0.2s0.1-0.2,0.1-0.3l0,0.6c-0.1,0.1-0.1,0.3-0.3,0.4c-0.1,0.1-0.3,0.1-0.5,0.1
				C1065.5,705.8,1065.3,705.7,1065.2,705.7z M1066.4,701.8h0.6v4h-0.6V701.8z"
            />
            <path
              fill="#FFFFFF"
              d="M1068.2,705.7c-0.1-0.1-0.3-0.2-0.3-0.4c-0.1-0.2-0.1-0.4-0.1-0.6V704c0-0.2,0-0.5,0.1-0.6
				c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0,0.3,0,0.5,0.1s0.3,0.2,0.3,0.4l-0.1,0.6c0-0.1,0-0.2-0.1-0.3
				c0-0.1-0.1-0.2-0.2-0.2s-0.2-0.1-0.3-0.1c-0.2,0-0.3,0.1-0.4,0.2s-0.2,0.3-0.2,0.5v0.6c0,0.2,0.1,0.4,0.2,0.5s0.2,0.2,0.4,0.2
				c0.1,0,0.2,0,0.3-0.1s0.1-0.1,0.2-0.2s0.1-0.2,0.1-0.3l0,0.6c-0.1,0.1-0.1,0.3-0.3,0.4c-0.1,0.1-0.3,0.1-0.5,0.1
				C1068.5,705.8,1068.3,705.7,1068.2,705.7z M1068.2,706.8c-0.2-0.1-0.3-0.2-0.4-0.3l0.4-0.3c0.1,0.1,0.2,0.2,0.3,0.2
				s0.2,0.1,0.3,0.1c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5v-2.8h0.6v2.8c0,0.3,0,0.5-0.1,0.7c-0.1,0.2-0.2,0.3-0.4,0.4
				s-0.4,0.1-0.7,0.1C1068.6,706.9,1068.4,706.9,1068.2,706.8z"
            />
            <path
              fill="#FFFFFF"
              d="M1071.3,705.6c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.1-0.4-0.1-0.7v-0.3c0-0.3,0-0.5,0.1-0.7
				c0.1-0.2,0.2-0.4,0.4-0.5c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.5,0.1,0.8v0.2
				h-1.9v-0.4h1.4c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.4,0.1-0.5,0.2s-0.2,0.3-0.2,0.6v0.4
				c0,0.2,0.1,0.4,0.2,0.5s0.3,0.2,0.5,0.2c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2-0.1,0.3-0.2l0.4,0.4c-0.1,0.1-0.3,0.2-0.5,0.3
				c-0.2,0.1-0.4,0.1-0.5,0.1C1071.7,705.8,1071.5,705.7,1071.3,705.6z"
            />
            <path
              fill="#FFFFFF"
              d="M1020.3,710.9c-0.1,0.1-0.2,0.3-0.2,0.5c0,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.3,0.2,0.6,0.2
				c0.2,0,0.4-0.1,0.6-0.2c0.2-0.1,0.3-0.3,0.4-0.5s0.1-0.5,0.1-0.8h0.5c0,0.4-0.1,0.8-0.2,1.1c-0.1,0.3-0.3,0.5-0.6,0.7
				c-0.3,0.2-0.6,0.2-0.9,0.2c-0.2,0-0.5,0-0.6-0.1c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.1-0.4-0.1-0.6c0-0.3,0.1-0.6,0.3-0.8
				c0.2-0.2,0.4-0.3,0.8-0.3l0.2,0.5C1020.6,710.7,1020.5,710.8,1020.3,710.9z M1020.6,710.9c-0.1-0.1-0.2-0.3-0.3-0.4
				c-0.1-0.1-0.2-0.3-0.2-0.4s-0.1-0.3-0.1-0.5c0-0.3,0.1-0.6,0.3-0.8c0.2-0.2,0.4-0.3,0.8-0.3c0.3,0,0.6,0.1,0.8,0.3
				s0.3,0.4,0.3,0.8v0.1h-0.6v-0.1c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.1-0.4-0.1c-0.2,0-0.3,0-0.4,0.1c-0.1,0.1-0.1,0.2-0.1,0.4
				c0,0.1,0,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.3s0.1,0.2,0.2,0.3l1.8,1.9h-0.7L1020.6,710.9z"
            />
            <path
              fill="#FFFFFF"
              d="M1027.6,710.3v0.7c0,0.3-0.1,0.6-0.2,0.8s-0.3,0.4-0.5,0.5c-0.2,0.1-0.5,0.2-0.7,0.2
				c-0.3,0-0.5-0.1-0.7-0.2s-0.4-0.3-0.5-0.5c-0.1-0.2-0.2-0.5-0.2-0.7v-1.2c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5
				s0.5-0.2,0.7-0.2c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.2,0.2,0.4,0.3,0.6h-0.6c0-0.1-0.1-0.2-0.2-0.3
				c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0-0.3,0-0.4,0.1c-0.1,0.1-0.2,0.2-0.3,0.3s-0.1,0.3-0.1,0.5v1.2
				c0,0.2,0,0.3,0.1,0.5s0.2,0.2,0.3,0.3s0.3,0.1,0.4,0.1c0.2,0,0.3,0,0.4-0.1s0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.3,0.1-0.5v-0.2h-0.8
				v-0.5H1027.6z"
            />
            <path
              fill="#FFFFFF"
              d="M1028.9,712.4c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.1-0.4-0.1-0.7v-0.5c0-0.3,0-0.5,0.1-0.7
				c0.1-0.2,0.2-0.3,0.4-0.4c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.5,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.4c0.1,0.2,0.1,0.4,0.1,0.7v0.5
				c0,0.3,0,0.5-0.1,0.7c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.1-0.6,0.1C1029.3,712.5,1029.1,712.5,1028.9,712.4z M1030,711.8
				c0.1-0.1,0.2-0.3,0.2-0.5v-0.5c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.3,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5
				v0.5c0,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.3,0.2,0.5,0.2C1029.8,712,1029.9,711.9,1030,711.8z"
            />
            <path
              fill="#FFFFFF"
              d="M1032.1,711.7c0,0.1,0,0.1,0,0.2c0,0,0.1,0.1,0.1,0.1h0.3v0.5h-0.3c-0.2,0-0.4-0.1-0.5-0.2
				s-0.2-0.3-0.2-0.6v-3.2h0.6V711.7z"
            />
            <path
              fill="#FFFFFF"
              d="M1033.5,712.4c-0.1-0.1-0.3-0.2-0.3-0.4c-0.1-0.2-0.1-0.4-0.1-0.6v-0.6c0-0.2,0-0.5,0.1-0.6
				c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0,0.3,0,0.5,0.1s0.3,0.2,0.3,0.4l-0.1,0.6c0-0.1,0-0.2-0.1-0.3
				c0-0.1-0.1-0.2-0.2-0.2s-0.2-0.1-0.3-0.1c-0.2,0-0.3,0.1-0.4,0.2s-0.2,0.3-0.2,0.5v0.6c0,0.2,0.1,0.4,0.2,0.5s0.2,0.2,0.4,0.2
				c0.1,0,0.2,0,0.3-0.1s0.1-0.1,0.2-0.2s0.1-0.2,0.1-0.3l0,0.6c-0.1,0.1-0.1,0.3-0.3,0.4c-0.1,0.1-0.3,0.1-0.5,0.1
				C1033.9,712.5,1033.7,712.5,1033.5,712.4z M1034.8,708.5h0.6v4h-0.6V708.5z"
            />
            <path
              fill="#FFFFFF"
              d="M1036.7,712.4c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.1-0.4-0.1-0.7v-0.3c0-0.3,0-0.5,0.1-0.7
				c0.1-0.2,0.2-0.4,0.4-0.5c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.5,0.1,0.8v0.2
				h-1.9v-0.4h1.4c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.4,0.1-0.5,0.2s-0.2,0.3-0.2,0.6v0.4
				c0,0.2,0.1,0.4,0.2,0.5s0.3,0.2,0.5,0.2c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2-0.1,0.3-0.2l0.4,0.4c-0.1,0.1-0.3,0.2-0.5,0.3
				c-0.2,0.1-0.4,0.1-0.5,0.1C1037.1,712.5,1036.9,712.5,1036.7,712.4z"
            />
            <path
              fill="#FFFFFF"
              d="M1039.2,709.6h0.6v2.8h-0.6V709.6z M1040.9,710.8c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.2-0.2-0.4-0.2
				c-0.2,0-0.3,0-0.4,0.1s-0.1,0.2-0.1,0.4l-0.1-0.5c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.3,0,0.5,0.1,0.7,0.3
				c0.2,0.2,0.3,0.5,0.3,0.9v1.7h-0.6V710.8z"
            />
            <path
              fill="#FFFFFF"
              d="M1043.9,712.4c-0.2-0.1-0.3-0.2-0.4-0.4l0.5-0.3c0.1,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1
				c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.6v-2.7h0.6v2.7c0,0.3,0,0.5-0.1,0.7s-0.2,0.3-0.4,0.4s-0.4,0.2-0.7,0.2
				C1044.3,712.5,1044.1,712.5,1043.9,712.4z"
            />
            <path
              fill="#FFFFFF"
              d="M1047.2,711.4c0,0.2,0.1,0.4,0.2,0.5s0.2,0.2,0.4,0.2c0.2,0,0.3,0,0.4-0.1s0.2-0.2,0.2-0.4l0,0.6
				c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.1-0.5,0.1c-0.3,0-0.6-0.1-0.7-0.3s-0.3-0.5-0.3-0.9v-1.7h0.6V711.4z M1048.4,709.6h0.6
				v2.8h-0.6V709.6z"
            />
            <path
              fill="#FFFFFF"
              d="M1049.8,708.5h0.6v4h-0.6V708.5z M1050.6,712.4c-0.1-0.1-0.2-0.2-0.3-0.4l0-0.6c0,0.1,0,0.2,0.1,0.3
				c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.5v-0.6c0-0.2-0.1-0.4-0.2-0.5
				c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.2c0,0.1-0.1,0.2-0.1,0.3l-0.1-0.5c0.1-0.2,0.2-0.3,0.3-0.4
				c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0,0.4,0,0.5,0.1c0.1,0.1,0.3,0.2,0.3,0.4c0.1,0.2,0.1,0.4,0.1,0.6v0.6c0,0.2,0,0.4-0.1,0.6
				c-0.1,0.2-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.1-0.5,0.1C1050.9,712.5,1050.7,712.5,1050.6,712.4z"
            />
            <path
              fill="#FFFFFF"
              d="M1052.8,708.5h0.6v0.6h-0.6V708.5z M1052.8,709.6h0.6v2.8h-0.6V709.6z"
            />
            <path
              fill="#FFFFFF"
              d="M1054.8,711.7c0,0.1,0,0.1,0,0.2c0,0,0.1,0.1,0.1,0.1h0.3v0.5h-0.3c-0.2,0-0.4-0.1-0.5-0.2
				s-0.2-0.3-0.2-0.6v-3.2h0.6V711.7z"
            />
            <path
              fill="#FFFFFF"
              d="M1056.4,712.4c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.1-0.4-0.1-0.7v-0.3c0-0.3,0-0.5,0.1-0.7
				c0.1-0.2,0.2-0.4,0.4-0.5c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.5,0.1,0.8v0.2
				h-1.9v-0.4h1.4c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.4,0.1-0.5,0.2s-0.2,0.3-0.2,0.6v0.4
				c0,0.2,0.1,0.4,0.2,0.5s0.3,0.2,0.5,0.2c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2-0.1,0.3-0.2l0.4,0.4c-0.1,0.1-0.3,0.2-0.5,0.3
				c-0.2,0.1-0.4,0.1-0.5,0.1C1056.8,712.5,1056.6,712.5,1056.4,712.4z"
            />
            <path
              fill="#FFFFFF"
              d="M1059.4,712.4c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.1-0.4-0.1-0.7v-0.3c0-0.3,0-0.5,0.1-0.7
				c0.1-0.2,0.2-0.4,0.4-0.5c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.5,0.1,0.8v0.2
				h-1.9v-0.4h1.4c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.4,0.1-0.5,0.2s-0.2,0.3-0.2,0.6v0.4
				c0,0.2,0.1,0.4,0.2,0.5s0.3,0.2,0.5,0.2c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2-0.1,0.3-0.2l0.4,0.4c-0.1,0.1-0.3,0.2-0.5,0.3
				c-0.2,0.1-0.4,0.1-0.5,0.1C1059.8,712.5,1059.6,712.5,1059.4,712.4z"
            />
            <path
              fill="#FFFFFF"
              d="M1063.5,708.5h0.6v4h-0.6V708.5z M1063.8,712h1.1c0.3,0,0.5-0.1,0.6-0.2c0.1-0.1,0.2-0.3,0.2-0.5v0
				c0-0.1,0-0.3-0.1-0.3c0-0.1-0.1-0.2-0.2-0.2s-0.2-0.1-0.4-0.1h-1.2v-0.5h1.2c0.2,0,0.4,0,0.5-0.1c0.1-0.1,0.2-0.2,0.2-0.4
				c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.1-0.5-0.1h-1.1v-0.5h1.2c0.3,0,0.5,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.4s0.1,0.4,0.1,0.6
				c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.4,0.2c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.3,0.3
				c0.1,0.1,0.1,0.3,0.1,0.4v0c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.1-0.6,0.1h-1.3V712z"
            />
            <path
              fill="#FFFFFF"
              d="M1067,709.6h0.6v2.8h-0.6V709.6z M1068.4,710.2c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3,0-0.4,0.1
				s-0.1,0.2-0.1,0.4l-0.1-0.5c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2
				l-0.4,0.5C1068.5,710.2,1068.5,710.2,1068.4,710.2z"
            />
            <path
              fill="#FFFFFF"
              d="M1069.4,708.5h0.6v0.6h-0.6V708.5z M1069.4,709.6h0.6v2.8h-0.6V709.6z"
            />
            <path
              fill="#FFFFFF"
              d="M1071.2,712.4c-0.1-0.1-0.3-0.2-0.3-0.4c-0.1-0.2-0.1-0.4-0.1-0.6v-0.6c0-0.2,0-0.5,0.1-0.6
				c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0,0.3,0,0.5,0.1s0.3,0.2,0.3,0.4l-0.1,0.6c0-0.1,0-0.2-0.1-0.3
				c0-0.1-0.1-0.2-0.2-0.2s-0.2-0.1-0.3-0.1c-0.2,0-0.3,0.1-0.4,0.2s-0.2,0.3-0.2,0.5v0.6c0,0.2,0.1,0.4,0.2,0.5s0.2,0.2,0.4,0.2
				c0.1,0,0.2,0,0.3-0.1s0.1-0.1,0.2-0.2s0.1-0.2,0.1-0.3l0,0.6c-0.1,0.1-0.1,0.3-0.3,0.4c-0.1,0.1-0.3,0.1-0.5,0.1
				C1071.5,712.5,1071.3,712.5,1071.2,712.4z M1072.4,708.5h0.6v4h-0.6V708.5z"
            />
            <path
              fill="#FFFFFF"
              d="M1074.2,712.4c-0.1-0.1-0.3-0.2-0.3-0.4c-0.1-0.2-0.1-0.4-0.1-0.6v-0.6c0-0.2,0-0.5,0.1-0.6
				c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0,0.3,0,0.5,0.1s0.3,0.2,0.3,0.4l-0.1,0.6c0-0.1,0-0.2-0.1-0.3
				c0-0.1-0.1-0.2-0.2-0.2s-0.2-0.1-0.3-0.1c-0.2,0-0.3,0.1-0.4,0.2s-0.2,0.3-0.2,0.5v0.6c0,0.2,0.1,0.4,0.2,0.5s0.2,0.2,0.4,0.2
				c0.1,0,0.2,0,0.3-0.1s0.1-0.1,0.2-0.2s0.1-0.2,0.1-0.3l0,0.6c-0.1,0.1-0.1,0.3-0.3,0.4c-0.1,0.1-0.3,0.1-0.5,0.1
				C1074.5,712.5,1074.4,712.5,1074.2,712.4z M1074.2,713.5c-0.2-0.1-0.3-0.2-0.4-0.3l0.4-0.3c0.1,0.1,0.2,0.2,0.3,0.2
				s0.2,0.1,0.3,0.1c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5v-2.8h0.6v2.8c0,0.3,0,0.5-0.1,0.7c-0.1,0.2-0.2,0.3-0.4,0.4
				s-0.4,0.1-0.7,0.1C1074.6,713.6,1074.4,713.6,1074.2,713.5z"
            />
            <path
              fill="#FFFFFF"
              d="M1077.4,712.4c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.1-0.4-0.1-0.7v-0.3c0-0.3,0-0.5,0.1-0.7
				c0.1-0.2,0.2-0.4,0.4-0.5c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.5,0.1,0.8v0.2
				h-1.9v-0.4h1.4c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.4,0.1-0.5,0.2s-0.2,0.3-0.2,0.6v0.4
				c0,0.2,0.1,0.4,0.2,0.5s0.3,0.2,0.5,0.2c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2-0.1,0.3-0.2l0.4,0.4c-0.1,0.1-0.3,0.2-0.5,0.3
				c-0.2,0.1-0.4,0.1-0.5,0.1C1077.8,712.5,1077.6,712.5,1077.4,712.4z"
            />
            <path
              fill="#FFFFFF"
              d="M1080.4,712.5c-0.1,0-0.3-0.1-0.4-0.1s-0.2-0.1-0.4-0.2l0.3-0.4c0.2,0.1,0.3,0.2,0.5,0.3
				c0.2,0.1,0.3,0.1,0.5,0.1c0.2,0,0.4,0,0.5-0.1c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1,0-0.2-0.1-0.2c-0.1,0-0.1-0.1-0.2-0.1
				c-0.1,0-0.2,0-0.4,0c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.2,0-0.3,0-0.5-0.1s-0.2-0.1-0.3-0.2s-0.1-0.3-0.1-0.5
				s0-0.4,0.1-0.5c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.4-0.1,0.6-0.1c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0.1,0.4,0.1
				c0.1,0.1,0.2,0.1,0.4,0.2l-0.3,0.4c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.3-0.1-0.4-0.1c-0.2,0-0.3,0-0.4,0.1
				c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.1,0.1,0.2c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.4,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
				c0,0,0,0,0,0c0.2,0,0.4,0,0.5,0.1c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.4,0.3
				c-0.2,0.1-0.4,0.1-0.7,0.1C1080.7,712.5,1080.6,712.5,1080.4,712.5z"
            />
          </g>
        </g>
        <g transform="translate(5, 80)">
          <g>
            <g>
              <path
                fill="#FFFFFF"
                d="M1285.5,581h0.7v5h-0.7V581z M1285.9,585.4h1.3c0.4,0,0.6-0.1,0.8-0.2s0.2-0.3,0.2-0.6v0
				c0-0.2,0-0.3-0.1-0.4s-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.1-0.5-0.1h-1.5v-0.6h1.5c0.3,0,0.5-0.1,0.6-0.2c0.1-0.1,0.2-0.3,0.2-0.5
				c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.1-0.4-0.2-0.7-0.2h-1.4V581h1.6c0.3,0,0.6,0.1,0.8,0.2s0.4,0.3,0.5,0.5s0.2,0.4,0.2,0.7
				c0,0.2,0,0.3-0.1,0.5c-0.1,0.2-0.2,0.3-0.3,0.4s-0.3,0.2-0.5,0.2c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.3,0.3,0.3,0.4
				c0.1,0.2,0.1,0.4,0.1,0.5v0c0,0.3-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.4-0.5,0.5c-0.2,0.1-0.5,0.2-0.8,0.2h-1.6V585.4z"
              />
              <path
                fill="#FFFFFF"
                d="M1290.7,585.1c0,0.1,0,0.2,0.1,0.2c0,0,0.1,0.1,0.2,0.1h0.3v0.7h-0.4c-0.3,0-0.5-0.1-0.6-0.2
				c-0.1-0.2-0.2-0.4-0.2-0.7V581h0.7V585.1z"
              />
              <path
                fill="#FFFFFF"
                d="M1292.2,585.8c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.4,0.1-0.6,0.3-0.8c0.2-0.2,0.5-0.3,0.9-0.3h1l0,0.6h-1.1
				c-0.2,0-0.3,0-0.4,0.1c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.2,0.1,0.3,0.2,0.4s0.3,0.1,0.5,0.1c0.3,0,0.5,0,0.6-0.1
				c0.1-0.1,0.2-0.1,0.2-0.3l0.1,0.5c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.3,0-0.4,0
				C1292.7,586.1,1292.4,586,1292.2,585.8z M1294,583.8c0-0.2-0.1-0.4-0.2-0.5s-0.3-0.2-0.5-0.2c-0.1,0-0.3,0-0.4,0.1
				c-0.1,0-0.3,0.1-0.4,0.2l-0.5-0.3c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.5-0.1,0.7-0.1c0.3,0,0.5,0.1,0.8,0.2
				c0.2,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.2,0.4,0.2,0.7v2.3h-0.7V583.8z"
              />
              <path
                fill="#FFFFFF"
                d="M1296.4,585.9c-0.2-0.1-0.4-0.3-0.5-0.5s-0.2-0.5-0.2-0.8v-0.6c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
				c0.2-0.1,0.5-0.2,0.8-0.2c0.2,0,0.4,0,0.5,0.1c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.3,0.3l-0.5,0.5c-0.1-0.1-0.2-0.2-0.4-0.3
				s-0.3-0.1-0.4-0.1c-0.3,0-0.5,0.1-0.6,0.2s-0.2,0.4-0.2,0.6v0.6c0,0.3,0.1,0.5,0.2,0.7c0.1,0.2,0.4,0.2,0.6,0.2
				c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.3-0.2,0.3-0.3l0.5,0.5c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.1-0.4,0.2s-0.3,0.1-0.5,0.1
				C1296.9,586.1,1296.6,586,1296.4,585.9z"
              />
              <path
                fill="#FFFFFF"
                d="M1299.2,581h0.7v5h-0.7V581z M1299.5,584.7l1.8-2.2h0.8l-2.7,3L1299.5,584.7z M1300.4,584l0.4-0.5
				l1.6,2.5h-0.8L1300.4,584z"
              />
              <path
                fill="#FFFFFF"
                d="M1302.7,582.5h1.8v0.6h-1.8V582.5z M1303.1,582c0-0.3,0.1-0.6,0.2-0.7s0.4-0.2,0.7-0.2h0.5v0.7h-0.5
				c-0.1,0-0.1,0-0.2,0.1c0,0.1-0.1,0.1-0.1,0.2v4h-0.7V582z"
              />
              <path
                fill="#FFFFFF"
                d="M1305.2,582.5h0.7v3.6h-0.7V582.5z M1306.9,583.1c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0-0.4,0.1-0.5,0.2
				s-0.2,0.3-0.2,0.5l-0.1-0.7c0.1-0.2,0.3-0.4,0.4-0.5c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.3,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2
				l-0.5,0.6C1307.1,583.2,1307,583.2,1306.9,583.1z"
              />
              <path
                fill="#FFFFFF"
                d="M1308.2,581h0.7v0.7h-0.7V581z M1308.2,582.5h0.7v3.6h-0.7V582.5z"
              />
              <path
                fill="#FFFFFF"
                d="M1310.1,585.8c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.4,0.1-0.6,0.3-0.8c0.2-0.2,0.5-0.3,0.9-0.3h1l0,0.6h-1.1
				c-0.2,0-0.3,0-0.4,0.1c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.2,0.1,0.3,0.2,0.4s0.3,0.1,0.5,0.1c0.3,0,0.5,0,0.6-0.1
				c0.1-0.1,0.2-0.1,0.2-0.3l0.1,0.5c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.3,0-0.4,0
				C1310.6,586.1,1310.3,586,1310.1,585.8z M1311.9,583.8c0-0.2-0.1-0.4-0.2-0.5s-0.3-0.2-0.5-0.2c-0.1,0-0.3,0-0.4,0.1
				c-0.1,0-0.3,0.1-0.4,0.2l-0.5-0.3c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.5-0.1,0.7-0.1c0.3,0,0.5,0.1,0.8,0.2
				c0.2,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.2,0.4,0.2,0.7v2.3h-0.7V583.8z"
              />
              <path
                fill="#FFFFFF"
                d="M1313.7,582.5h0.7v3.6h-0.7V582.5z M1315.4,583.1c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0-0.4,0.1-0.5,0.2
				s-0.2,0.3-0.2,0.5l-0.1-0.7c0.1-0.2,0.3-0.4,0.4-0.5c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.3,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2
				l-0.5,0.6C1315.6,583.2,1315.5,583.2,1315.4,583.1z"
              />
              <path
                fill="#FFFFFF"
                d="M1317.3,586c-0.2,0-0.4-0.1-0.5-0.2c-0.2-0.1-0.3-0.2-0.4-0.3l0.4-0.5c0.2,0.1,0.4,0.3,0.6,0.3
				c0.2,0.1,0.4,0.1,0.6,0.1c0.3,0,0.5,0,0.6-0.1c0.1-0.1,0.2-0.2,0.2-0.4c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.1
				c-0.1,0-0.3,0-0.5-0.1c0,0,0,0-0.1,0s0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.3-0.1-0.4-0.3
				s-0.2-0.3-0.2-0.6s0.1-0.5,0.2-0.6c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.5-0.1,0.8-0.1c0.2,0,0.4,0,0.5,0.1c0.2,0,0.3,0.1,0.5,0.2
				c0.2,0.1,0.3,0.2,0.4,0.3l-0.4,0.5c-0.2-0.1-0.4-0.2-0.5-0.3c-0.2-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.4,0-0.5,0.1
				c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0,0.2,0.1,0.2s0.2,0.1,0.3,0.1s0.2,0,0.4,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0
				c0.2,0,0.5,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.4,0.2,0.6c0,0.3-0.1,0.5-0.2,0.6c-0.1,0.2-0.3,0.3-0.5,0.4
				c-0.2,0.1-0.5,0.1-0.8,0.1C1317.7,586.1,1317.5,586,1317.3,586z"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                fill="#FFFFFF"
                d="M1285.5,581h0.7v5h-0.7V581z M1285.9,585.4h1.3c0.4,0,0.6-0.1,0.8-0.2s0.2-0.3,0.2-0.6v0
				c0-0.2,0-0.3-0.1-0.4s-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.1-0.5-0.1h-1.5v-0.6h1.5c0.3,0,0.5-0.1,0.6-0.2c0.1-0.1,0.2-0.3,0.2-0.5
				c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.1-0.4-0.2-0.7-0.2h-1.4V581h1.6c0.3,0,0.6,0.1,0.8,0.2s0.4,0.3,0.5,0.5s0.2,0.4,0.2,0.7
				c0,0.2,0,0.3-0.1,0.5c-0.1,0.2-0.2,0.3-0.3,0.4s-0.3,0.2-0.5,0.2c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.3,0.3,0.3,0.4
				c0.1,0.2,0.1,0.4,0.1,0.5v0c0,0.3-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.4-0.5,0.5c-0.2,0.1-0.5,0.2-0.8,0.2h-1.6V585.4z"
              />
              <path
                fill="#FFFFFF"
                d="M1290.7,585.1c0,0.1,0,0.2,0.1,0.2c0,0,0.1,0.1,0.2,0.1h0.3v0.7h-0.4c-0.3,0-0.5-0.1-0.6-0.2
				c-0.1-0.2-0.2-0.4-0.2-0.7V581h0.7V585.1z"
              />
              <path
                fill="#FFFFFF"
                d="M1292.2,585.8c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.4,0.1-0.6,0.3-0.8c0.2-0.2,0.5-0.3,0.9-0.3h1l0,0.6h-1.1
				c-0.2,0-0.3,0-0.4,0.1c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.2,0.1,0.3,0.2,0.4s0.3,0.1,0.5,0.1c0.3,0,0.5,0,0.6-0.1
				c0.1-0.1,0.2-0.1,0.2-0.3l0.1,0.5c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.3,0-0.4,0
				C1292.7,586.1,1292.4,586,1292.2,585.8z M1294,583.8c0-0.2-0.1-0.4-0.2-0.5s-0.3-0.2-0.5-0.2c-0.1,0-0.3,0-0.4,0.1
				c-0.1,0-0.3,0.1-0.4,0.2l-0.5-0.3c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.5-0.1,0.7-0.1c0.3,0,0.5,0.1,0.8,0.2
				c0.2,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.2,0.4,0.2,0.7v2.3h-0.7V583.8z"
              />
              <path
                fill="#FFFFFF"
                d="M1296.4,585.9c-0.2-0.1-0.4-0.3-0.5-0.5s-0.2-0.5-0.2-0.8v-0.6c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
				c0.2-0.1,0.5-0.2,0.8-0.2c0.2,0,0.4,0,0.5,0.1c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.3,0.3l-0.5,0.5c-0.1-0.1-0.2-0.2-0.4-0.3
				s-0.3-0.1-0.4-0.1c-0.3,0-0.5,0.1-0.6,0.2s-0.2,0.4-0.2,0.6v0.6c0,0.3,0.1,0.5,0.2,0.7c0.1,0.2,0.4,0.2,0.6,0.2
				c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.3-0.2,0.3-0.3l0.5,0.5c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.1-0.4,0.2s-0.3,0.1-0.5,0.1
				C1296.9,586.1,1296.6,586,1296.4,585.9z"
              />
              <path
                fill="#FFFFFF"
                d="M1299.2,581h0.7v5h-0.7V581z M1299.5,584.7l1.8-2.2h0.8l-2.7,3L1299.5,584.7z M1300.4,584l0.4-0.5
				l1.6,2.5h-0.8L1300.4,584z"
              />
              <path
                fill="#FFFFFF"
                d="M1302.7,582.5h1.8v0.6h-1.8V582.5z M1303.1,582c0-0.3,0.1-0.6,0.2-0.7s0.4-0.2,0.7-0.2h0.5v0.7h-0.5
				c-0.1,0-0.1,0-0.2,0.1c0,0.1-0.1,0.1-0.1,0.2v4h-0.7V582z"
              />
              <path
                fill="#FFFFFF"
                d="M1305.2,582.5h0.7v3.6h-0.7V582.5z M1306.9,583.1c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0-0.4,0.1-0.5,0.2
				s-0.2,0.3-0.2,0.5l-0.1-0.7c0.1-0.2,0.3-0.4,0.4-0.5c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.3,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2
				l-0.5,0.6C1307.1,583.2,1307,583.2,1306.9,583.1z"
              />
              <path
                fill="#FFFFFF"
                d="M1308.2,581h0.7v0.7h-0.7V581z M1308.2,582.5h0.7v3.6h-0.7V582.5z"
              />
              <path
                fill="#FFFFFF"
                d="M1310.1,585.8c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.4,0.1-0.6,0.3-0.8c0.2-0.2,0.5-0.3,0.9-0.3h1l0,0.6h-1.1
				c-0.2,0-0.3,0-0.4,0.1c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.2,0.1,0.3,0.2,0.4s0.3,0.1,0.5,0.1c0.3,0,0.5,0,0.6-0.1
				c0.1-0.1,0.2-0.1,0.2-0.3l0.1,0.5c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.3,0-0.4,0
				C1310.6,586.1,1310.3,586,1310.1,585.8z M1311.9,583.8c0-0.2-0.1-0.4-0.2-0.5s-0.3-0.2-0.5-0.2c-0.1,0-0.3,0-0.4,0.1
				c-0.1,0-0.3,0.1-0.4,0.2l-0.5-0.3c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.5-0.1,0.7-0.1c0.3,0,0.5,0.1,0.8,0.2
				c0.2,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.2,0.4,0.2,0.7v2.3h-0.7V583.8z"
              />
              <path
                fill="#FFFFFF"
                d="M1313.7,582.5h0.7v3.6h-0.7V582.5z M1315.4,583.1c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0-0.4,0.1-0.5,0.2
				s-0.2,0.3-0.2,0.5l-0.1-0.7c0.1-0.2,0.3-0.4,0.4-0.5c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.3,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2
				l-0.5,0.6C1315.6,583.2,1315.5,583.2,1315.4,583.1z"
              />
              <path
                fill="#FFFFFF"
                d="M1317.3,586c-0.2,0-0.4-0.1-0.5-0.2c-0.2-0.1-0.3-0.2-0.4-0.3l0.4-0.5c0.2,0.1,0.4,0.3,0.6,0.3
				c0.2,0.1,0.4,0.1,0.6,0.1c0.3,0,0.5,0,0.6-0.1c0.1-0.1,0.2-0.2,0.2-0.4c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.1
				c-0.1,0-0.3,0-0.5-0.1c0,0,0,0-0.1,0s0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.3-0.1-0.4-0.3
				s-0.2-0.3-0.2-0.6s0.1-0.5,0.2-0.6c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.5-0.1,0.8-0.1c0.2,0,0.4,0,0.5,0.1c0.2,0,0.3,0.1,0.5,0.2
				c0.2,0.1,0.3,0.2,0.4,0.3l-0.4,0.5c-0.2-0.1-0.4-0.2-0.5-0.3c-0.2-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.4,0-0.5,0.1
				c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0,0.2,0.1,0.2s0.2,0.1,0.3,0.1s0.2,0,0.4,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0
				c0.2,0,0.5,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.4,0.2,0.6c0,0.3-0.1,0.5-0.2,0.6c-0.1,0.2-0.3,0.3-0.5,0.4
				c-0.2,0.1-0.5,0.1-0.8,0.1C1317.7,586.1,1317.5,586,1317.3,586z"
              />
            </g>
          </g>
        </g>
      </g>

      <g className="walk">
        <ellipse
          fill="none"
          strokeWidth={1}
          stroke={globalStyles.colors.white}
          strokeDasharray={3}
          rx={146}
          ry={153}
          cx={1410}
          cy={718}
        />
        <g transform="translate(-155, -110)">
          <rect x="1388" y="816" fill="#FFFFFF" width="60" height="20" />
          <text
            x={1403}
            y={823}
            fill={globalStyles.colors.lightBlack}
            style={{
              fontFamily: "main",
              fontSize: 5,
            }}
          >
            10 min. Walk
          </text>
          <text
            x={1412}
            y={828}
            fill={globalStyles.colors.lightBlack}
            style={{
              fontFamily: "main",
              fontSize: 5,
            }}
          >
            from
          </text>
          <text
            x={1400}
            y={833}
            fill={globalStyles.colors.lightBlack}
            style={{
              fontFamily: "main",
              fontSize: 5,
            }}
          >
            Red Lion Court
          </text>
        </g>
      </g>
    </DetailWrapper>
  )
}

const DetailWrapper = styled.g``
