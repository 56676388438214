import { useRef } from "react"
import styled from "styled-components"

import CloseIcon from "@components/SVG/CloseIcon/CloseIcon"

import TheForgeCopy from "./Copy/TheForgeCopy"
import TimberCopy from "./Copy/TimberCopy"
import LibertyCopy from "./Copy/LibertyCopy"
import BridgeCopy from "./Copy/BridgeCopy"
import LionCopy from "./Copy/LionCopy"

import globalStyles from "@data/globalStyles"
import { useStore } from "@state/store"

function SitesCopy() {
  const isMobile = useStore(s => s.isMobile)
  const activeSite = useStore((s) => s.activeSite)
  const siteCopyVisible = useStore((s) => s.siteCopyVisible)
  const setSiteCopyVisible = useStore((s) => s.setSiteCopyVisible)
  const wrapperRef = useRef<HTMLDivElement>()
  const elements = [
    TheForgeCopy,
    TimberCopy,
    LibertyCopy,
    BridgeCopy,
    LionCopy,
  ]
  const CurrentElement = elements[activeSite]

  function handleClose() {
    wrapperRef.current.style.opacity = "0"
    setTimeout(() => {
      setSiteCopyVisible(false)
    }, 800)
  }

  return (
    siteCopyVisible && (
      <CopyWrapper
        ref={(node) => {
          if (node) {
            wrapperRef.current = node
            setTimeout(() => {
              node.style.opacity = "1"
            }, 100)
          }
        }}
      >
        <CloseIcon
          className="close"
          style={{
            width: isMobile ? "25rem" : "4rem"
          }}
          strokeWidth={4}
          strokeColor={globalStyles.colors.white}
          color={globalStyles.colors.white}
          bgColor="none"
          onClick={() => {
            handleClose()
          }}
        />
        <CurrentElement />
      </CopyWrapper>
    )
  )
}

export default SitesCopy

const CopyWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  touch-action: pan-y;
  top: 0;
  z-index: 15;
  transition: opacity 0.8s ease-in-out;
  opacity: 0;
  background-color: ${globalStyles.colors.lightBlack};
  
  .close {
    position: fixed;
    z-index: 1;
    top: 1vw;
    right: 1vw;
    width: 4rem;
    aspect-ratio: 1/1;
    pointer-events: all;
    cursor: pointer;
    mix-blend-mode: difference;
  }

  ::-webkit-scrollbar{
    display: none;
  }

  *{
    touch-action: pan-y;
  }
`
