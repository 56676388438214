import styled from "styled-components"

function River({cue}) {
  return (
    <RiverWrapper>
      <path
        fill="#6FA8B5"
        d="M500.9,1080.6c36.9-16.6,75.1-30,114.1-41.1c30.9-8.7,62.1-16.1,94.4-17.8c3.7-0.1,7.5-0.3,11.2-0.4
	c32.9-1.1,64.8,6.9,97.3,9.8c19.9,1.8,40.4,3.1,59.9,0.1c30.3-4.5,50.3-25.7,65-51.1c26.2-45.3,41.4-94.7,52.4-145.6
	c3.6-16.9,4.5-33.9,3.8-51.3c-1-25.1-0.5-50.4,2.1-75.4c1.6-15.5,8-30.5,12.3-45.8c0.6-2.4,1.1-4.8,1.7-7.1c2.7-3.7,6-7.1,8.1-11.1
	c9.7-18,22.3-33.3,39.6-44.5c2.6-1.7,4.5-4.6,6.7-6.9c0,0,1.1,0.2,1.1,0.2c3.1-1,6.3-1.7,9.2-3.1c30.8-14.5,63.4-21.5,97.3-22.4
	c15.8-0.4,31.7-0.3,47.5-0.4c2.1,0.1,4.2,0.3,6.3,0.4c24.5,5.2,49.4,9,73.5,16c45.1,13,89.5,28.2,135.9,36.9
	c34.2,6.4,68,15.2,102,22.9c1.6,0.1,3.3,0.1,4.9,0.2c4.6,2.1,9.2,4.3,13.8,6.4c18.3,8.2,36.8,15.8,54.7,24.6
	c20.9,10.3,40.9,22.4,61.9,32.4c27.3,12.9,51.8,7.6,72.4-14.5c15-16,29.4-32.5,44.5-48.4c15.5-16.3,32.2-31.2,54-38.8
	c13.1-4.6,26.6-8.2,39.9-12.2c1.5-0.1,3-0.1,4.6-0.2c11.4-0.5,16.3,0.4,27.1,2.1l0,67.5c-29.6-6.5-51.1,2.2-72,24.5
	c-17.2,18.4-34.6,36.6-52,54.9c-38.5,40.4-92.7,50.2-142.6,28.2c-26-11.4-50.3-26.4-75.7-39.2c-7.7-3.9-16.4-5.8-24.7-8.6
	c-11.6-10.7-26.8-13.1-41-16.6c-31.7-7.9-63.6-15.1-95.6-21.3c-47.3-9.2-92.9-24.8-138.9-38.7c-50-15.1-100.3-15.7-150.5-2
	c-15.9,4.4-31.3,11.2-40.4,25.9c-12.6,20.5-21.6,42.4-21.2,67.2c0.3,21,0.7,42.1,0.5,63.1c-0.1,11.6-0.1,23.5-2.1,34.9
	c-11.3,63.9-32.2,124.6-62.9,181.9c-0.1,1.7-0.2,3.3-0.3,5c-3.3,3.1-6.8,6.1-9.9,9.4c-9.8,10.6-18.7,22.1-29.3,31.8
	c-7.9,7.2-14.7,12.6-23.8,17.9L500.9,1080.6z"
      />
    </RiverWrapper>
  )
}

export default River

const RiverWrapper = styled.g`
`
