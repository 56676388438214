import styled from "styled-components"

import Square from "@components/Square/Square"

function ErrorMessage() {
   return (
      <Wrapper>
         <Square text={"ERROR"}/>
      </Wrapper>
   )
}

export default ErrorMessage

const Wrapper = styled.div`
   position: relative;
   width: 100%;
   height: 100%;
`
