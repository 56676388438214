const data = [
  // {
  //   name: ["THE", "FORGE"],
  //   // bg: "/assets/images/sites/forge/header.webp",
  //   bg: "/assets/images/sites/forge/2.webp",
  //   gallery: [
  //     "/assets/images/sites/forge/header.webp",
  //     "/assets/images/sites/forge/1.webp",
  //     "/assets/images/sites/forge/2.webp",
  //     "/assets/images/sites/forge/3.webp",
  //     "/assets/images/sites/forge/4.webp",
  //     "/assets/images/sites/forge/5.webp",
  //     "/assets/images/sites/forge/6.webp",
  //     // "/assets/images/sites/forge/7.webp",
  //     "/assets/images/sites/forge/8.webp",
  //     "/assets/images/sites/forge/9.webp",
  //     // "/assets/images/sites/forge/10.webp",
  //     "/assets/images/sites/forge/11.webp",
  //     "/assets/images/sites/forge/12.webp",
  //     "/assets/images/sites/forge/13.webp",
  //     // "/assets/images/sites/forge/14.webp",
  //     // "/assets/images/sites/forge/15.webp",
  //     // "/assets/images/sites/forge/16.webp",
  //   ],
  //   galleryCopy: [
  //     "/assets/images/sites/forge/1.webp",
  //     "/assets/images/sites/forge/2.webp",
  //     "/assets/images/sites/forge/3.webp",
  //     "/assets/images/sites/forge/4.webp",
  //     "/assets/images/sites/forge/5.webp",
  //     "/assets/images/sites/forge/6.webp",
  //     "/assets/images/sites/forge/7.webp",
  //     "/assets/images/sites/forge/8.webp",
  //     "/assets/images/sites/forge/9.webp",
  //     "/assets/images/sites/forge/10.webp",
  //     "/assets/images/sites/forge/11.webp",
  //     "/assets/images/sites/forge/12.webp",
  //     "/assets/images/sites/forge/13.webp",
  //     "/assets/images/sites/forge/14.webp",
  //     "/assets/images/sites/forge/15.webp",
  //     "/assets/images/sites/forge/16.webp",
  //   ],
  //   headerData: {
  //     tdc: null,
  //     sqf: "140k",
  //     delivery: "MARCH 2023",
  //     gdv: "£190M",
  //     usage: "42%",
  //     yield: "5,8 %",
  //     carbon: "863kg CO2/m2",
  //     target: "5 STARS",
  //     status: "COMPLETED",
  //     floor: "3k - 15k sqft",
  //     certifications: [
  //       {
  //         caption: "EPC RATING A",
  //         src: "/assets/images/sites/certifications/epc.svg",
  //       },
  //       {
  //         caption: "BREEAM OUTSTANDING",
  //         src: "/assets/images/sites/certifications/breeam.svg",
  //       },
  //       {
  //         caption: "WELL GOLD",
  //         src: "/assets/images/sites/certifications/wellGold.svg",
  //       },
  //       {
  //         caption: "NABERS 5 STARS",
  //         src: "/assets/images/sites/certifications/nabers.svg",
  //       },
  //     ],
  //     description:
  //       "The UK’s first commercial development to commit to net zero carbon, surrounded by some of London’s most iconic landmarks, generating significant leasing traction",
  //   },
  //   video: "/assets/videos/forge.mp4",
  //   copyData: {
  //     headerImage: "/assets/images/sites/forge/1.webp",
  //     subtitle:
  //       "The UK’s first commercial development to commit to being built and operated in line with the UKGBC’s framework definition of net zero.",
  //     copyImage: "/assets/images/sites/forge/10.webp",
  //     copy: [
  //       "Two distinct office buildings located in the heart of Bankside,",
  //       "just metres away from landmark cultural offerings and iconic cultural",
  //       "institutions, including the Tate Modern.",
  //     ],
  //     flexTitle: "NET ZERO CARBON DEVELOPMENT",
  //     flex: [
  //       "Completed in March 2023, the asset is currently 42% let or under offer, including 35% to Myo, Landsec’s flexible workspace provider.",
  //       "Extensive rooftop space designed with sustainability in mind, featuring 107 photo voltaic panels and ~300 sqft of green space      resulting in significant biodiversity net gain for the area.",
  //       "Includes carefully curated outdoor and indoor spaces that meet the  well-being needs of a modern workspace: fresh air, sunlight, green      spaces, end-of-trip facilities and more.",
  //       "As the world’s first large-scale office scheme built using a      standardised “kit of parts”, The Forge received award funding from      Innovate UK in recognition of its leading construction techniques.",
  //     ],
  //   },
  // },
  {
    name: ["TIMBER SQUARE", "PHASE 1"],
    bg: "/assets/images/sites/timber/header.webp",
    gallery: [
      "/assets/images/sites/timber/header.webp",
      "/assets/images/sites/timber/1.webp",
      "/assets/images/sites/timber/2.webp",
      "/assets/images/sites/timber/3.webp",
      "/assets/images/sites/timber/4.webp",
      "/assets/images/sites/timber/5.webp",
      "/assets/images/sites/timber/6.webp",
      "/assets/images/sites/timber/7.webp",
      "/assets/images/sites/timber/8.webp",
      "/assets/images/sites/timber/9.webp",
      "/assets/images/sites/timber/10.webp",
      "/assets/images/sites/timber/11.webp",
      "/assets/images/sites/timber/12.webp",
      "/assets/images/sites/timber/13.webp",
      "/assets/images/sites/timber/14.webp",
    ],
    galleryCopy: [
      "/assets/images/sites/timber/1.webp",
      "/assets/images/sites/timber/2.webp",
      "/assets/images/sites/timber/3.webp",
      "/assets/images/sites/timber/4.webp",
      "/assets/images/sites/timber/5.webp",
      "/assets/images/sites/timber/6.webp",
      "/assets/images/sites/timber/7.webp",
      "/assets/images/sites/timber/8.webp",
      "/assets/images/sites/timber/9.webp",
      "/assets/images/sites/timber/10.webp",
      "/assets/images/sites/timber/11.webp",
      "/assets/images/sites/timber/12.webp",
      "/assets/images/sites/timber/13.webp",
      "/assets/images/sites/timber/14.webp",
    ],
    headerData: {
      tdc: "£430M",
      sqf: "380k",
      delivery: "NOV 2025",
      gdv: "£670M",
      usage: null,
      yield: "7,0 %",
      carbon: "535kg CO2/m2",
      target: "5 STARS",
      status: "UNDER CONSTRUCTION",
      floor: "10k - 25k sqft",
      certifications: [
        {
          caption: "EPC RATING A",
          src: "/assets/images/sites/certifications/epc.svg",
        },
        {
          caption: "BREEAM OUTSTANDING",
          src: "/assets/images/sites/certifications/breeam.svg",
        },
        {
          caption: "WELL PLATINUM",
          src: "/assets/images/sites/certifications/wellPlatinum.svg",
        },
        {
          caption: "NABERS 5 STARS",
          src: "/assets/images/sites/certifications/nabers.svg",
        },
      ],
      description:
        "Highly sustainable, amenity rich scheme, developed using pioneering construction methods and partial retention of existing building to deliver industrial design aesthetics, set to attract a diverse occupier base",
    },

    video: "/assets/videos/timber.mp4",
    copyData: {
      headerImage: "/assets/images/sites/timber/8.webp",
      subtitle: null,
      copyImage: "/assets/images/sites/timber/8.webp",
      copy: [
        "Timber Square is a part new build, part refurbishment and extension to provide 362k sqft of office accommodation and 14,5k sqft of retail / leisure space, set around a new public realm which includes a new connection to the Southwark ‘Low Line’.,",
        "It is located just to the south of Landsec’ first Southwark office developments, Bluefin Building and Bankside 2 & 3 (2006).",
      ],
      flexTitle:
        "Leveraged innovative and sustainable design including modular construction, off-site manufacture with unitised design to enhance programme, cost efficiencies and quality.",
      flex: [
        "Timber Square will have market leading ESG credentials: Net Zero Carbon in operation - first building in the UK to be pre-certified for NABERS UK 5 star in design and embodied carbon intensity (535kg CO2e/m2) in line with Landsec’s corporate aspirations (<600kg CO2e/m2).",

        "The new public realm between the buildings, and future potential to open up access to the railway arches with the future  acquisition at Ewer Street to the south, provides an opportunity      to curate a new destination linked to the low line, similar to Flat Iron Square, where an iconic food, drink and event space has been created utilising disused railway arches, and former light industrial land.",

        "The design has customer flexibility at its core; the buildings can satisfy pre-let opportunities with large 20k sqft+ floorplates in the Print building or a broad range of multi-let occupiers, with scope to roll out either or all of the Landsec office products (Blank Canvas, Customised and Myo).",

        "The industrial design aesthetic, is attuned to the Southwark market and could attract pre-let interest from a diverse range of sectors. The Southbank is already a thriving office submarket, attracting a young workforce from diverse sectors. Migrant occupiers from other London submarkets (News Corporation, Omnicom, Boodle Hatfield, Lewis Silkin, HSBC) have all taken space whilst existing, local, occupiers have consolidated and grown their footprints (IBM, WPP, Shell). Southbank is also an Emerging Life Science cluster underpinned by the newly formed SC1 Life Sciences Innovation Cluster.",
      ],
    },
  },
  {
    name: ["TIMBER SQUARE", "PHASE 2"],
    bg: "/assets/images/sites/timber2/header.webp",
    gallery: [
      "/assets/images/sites/timber2/header.webp",
      "/assets/images/sites/timber2/1.webp",
      "/assets/images/sites/timber2/2.webp",
    ],
    galleryCopy: [
      "/assets/images/sites/timber2/1.webp",
      "/assets/images/sites/timber2/2.webp",
    ],
    headerData: {
      tdc: "£430M",
      sqf: "290k",
      delivery: "NOV 2025",
      gdv: "£560M",
      usage: null,
      yield: "7,0 %",
      carbon: "535kg CO2/m2",
      target: "5 STARS",
      status: "ADVANCED PLANNING DISCUSSIONS",
      floor: "10k - 25k sqft",
      certifications: [
        {
          caption: "EPC RATING A",
          src: "/assets/images/sites/certifications/epc.svg",
        },
        {
          caption: "BREEAM OUTSTANDING",
          src: "/assets/images/sites/certifications/breeam.svg",
        },
        {
          caption: "WELL PLATINUM",
          src: "/assets/images/sites/certifications/wellPlatinum.svg",
        },
        {
          caption: "NABERS 5 STARS",
          src: "/assets/images/sites/certifications/nabers.svg",
        },
      ],
      description:
        "Adjacent to Timber Square Phase 1, strategically located site with the potential to create a unique ~660k sqft campus in Central London with extensive amenities and public realm enhancements",
    },

    video: "/assets/videos/timber2.mp4",
    copyData: {
      headerImage: "/assets/images/sites/timber/8.webp",
      subtitle: null,
      copyImage: "/assets/images/sites/timber/8.webp",
      copy: [
        "Timber Square is a part new build, part refurbishment and extension to provide 362k sqft of office accommodation and 14,5k sqft of retail / leisure space, set around a new public realm which includes a new connection to the Southwark ‘Low Line’.,",
        "It is located just to the south of Landsec’ first Southwark office developments, Bluefin Building and Bankside 2 & 3 (2006).",
      ],
      flexTitle:
        "Leveraged innovative and sustainable design including modular construction, off-site manufacture with unitised design to enhance programme, cost efficiencies and quality.",
      flex: [
        "Timber Square will have market leading ESG credentials: Net Zero Carbon in operation - first building in the UK to be pre-certified for NABERS UK 5 star in design and embodied carbon intensity (535kg CO2e/m2) in line with Landsec’s corporate aspirations (<600kg CO2e/m2).",

        "The new public realm between the buildings, and future potential to open up access to the railway arches with the future  acquisition at Ewer Street to the south, provides an opportunity      to curate a new destination linked to the low line, similar to Flat Iron Square, where an iconic food, drink and event space has been created utilising disused railway arches, and former light industrial land.",

        "The design has customer flexibility at its core; the buildings can satisfy pre-let opportunities with large 20k sqft+ floorplates in the Print building or a broad range of multi-let occupiers, with scope to roll out either or all of the Landsec office products (Blank Canvas, Customised and Myo).",

        "The industrial design aesthetic, is attuned to the Southwark market and could attract pre-let interest from a diverse range of sectors. The Southbank is already a thriving office submarket, attracting a young workforce from diverse sectors. Migrant occupiers from other London submarkets (News Corporation, Omnicom, Boodle Hatfield, Lewis Silkin, HSBC) have all taken space whilst existing, local, occupiers have consolidated and grown their footprints (IBM, WPP, Shell). Southbank is also an Emerging Life Science cluster underpinned by the newly formed SC1 Life Sciences Innovation Cluster.",
      ],
    },
  },
  {
    name: ["LIBERTY OF", "SOUTHWARK"],
    bg: "/assets/images/sites/liberty/header.webp",
    gallery: [
      "/assets/images/sites/liberty/header.webp",
      "/assets/images/sites/liberty/1.webp",
      "/assets/images/sites/liberty/2.webp",
      "/assets/images/sites/liberty/3.webp",
      "/assets/images/sites/liberty/4.webp",
      "/assets/images/sites/liberty/5.webp",
      "/assets/images/sites/liberty/6.webp",
    ],
    galleryCopy: [
      "/assets/images/sites/liberty/1.webp",
      "/assets/images/sites/liberty/2.webp",
      "/assets/images/sites/liberty/3.webp",
      "/assets/images/sites/liberty/4.webp",
      "/assets/images/sites/liberty/5.webp",
      "/assets/images/sites/liberty/6.webp",
    ],
    headerData: {
      tdc: "£250M",
      sqf: "220k",
      delivery: "OCT 2026",
      gdv: "£370M",
      usage: null,
      yield: "6,6 %",
      carbon: "600kg CO2/m2",
      target: "5 STARS",
      status: "PLANNING APPROVED",
      floor: "21k sqft",
      certifications: [
        {
          caption: "EPC RATING A",
          src: "/assets/images/sites/certifications/epc.svg",
        },
        {
          caption: "BREEAM OUTSTANDING",
          src: "/assets/images/sites/certifications/breeam.svg",
        },
        {
          caption: "WELL PLATINUM",
          src: "/assets/images/sites/certifications/wellPlatinum.svg",
        },
        {
          caption: "NABERS 5 STARS",
          src: "/assets/images/sites/certifications/nabers.svg",
        },
      ],
      description: "Mixed use office led campus immediately proximate to the SC1 life sciences cluster and Borough Market, providing a highly attractive “campus” feel to prospective occupiers"
    },

    video: "/assets/videos/liberty.mp4",
    copyData: {
      headerImage: "/assets/images/sites/liberty/1.webp",
      subtitle:
        "Prime mixed-use office-led campus proximate to the SC1 life-sciences cluster, Borough Market and London Bridge Station.",
      copyImage: "/assets/images/sites/liberty/2.webp",
      copy: [
        "Delivering [190k] sqft of Grade A office space, with a full suite of amenities including ground floor retail, restaurants and cafes, providing a highly-attractive “campus” feel to prospective occupiers",
      ],
      flexTitle:
        "Ideal space for corporate HQs with large floorplates ranging from ~14k – 25k sqft, providing significant pre-leasing potential",
      flex: [
        "Mixed-use scheme with office, retail, and residential space in a preeminent location adjacent to Borough Market, London Bridge Station and Flat Iron Square",

        "Targeting the highest sustainability certifications (i.e., BREEAM Outstanding, UK Nabers 5 stars, WELL Platinum), the development will deliver a lively sustainable hub at the heart of Bankside",

        "The asset has ~25k sqft of residential space due to be sold upon completion, as well as a garden and marketplace providing a highly amenitised setting for tenants and the local community",
      ],
    },
  },
  // {
  //   name: ["SOUTHWARK", "BRIDGE ROAD"],
  //   bg: "/assets/images/sites/bridge/header.webp",
  //   gallery: [
  //     "/assets/images/sites/bridge/header.webp",
  //     "/assets/images/sites/bridge/1.webp",
  //     "/assets/images/sites/bridge/2.webp",
  //     "/assets/images/sites/bridge/3.webp",
  //     "/assets/images/sites/bridge/4.webp",
  //     "/assets/images/sites/bridge/5.webp",
  //   ],
  //   galleryCopy: [
  //     "/assets/images/sites/bridge/1.webp",
  //     "/assets/images/sites/bridge/2.webp",
  //     "/assets/images/sites/bridge/3.webp",
  //     "/assets/images/sites/bridge/4.webp",
  //     "/assets/images/sites/bridge/5.webp",
  //   ],
  //   headerData: {
  //     tdc: "£190M",
  //     sqf: "150k",
  //     delivery: "OCT 2027",
  //     gdv: "£310M",
  //     usage: null,
  //     yield: "7,4 %",
  //     carbon: "600kg CO2/m2",
  //     target: "5 STARS",
  //     status: "PRE-PLANNING",
  //     floor: "14,5k sqft",
  //     certifications: [
  //       {
  //         caption: "EPC RATING A",
  //         src: "/assets/images/sites/certifications/epc.svg",
  //       },
  //       {
  //         caption: "BREEAM OUTSTANDING",
  //         src: "/assets/images/sites/certifications/breeam.svg",
  //       },
  //       {
  //         caption: "WELL PLATINUM",
  //         src: "/assets/images/sites/certifications/wellPlatinum.svg",
  //       },
  //       {
  //         caption: "NABERS 5 STARS",
  //         src: "/assets/images/sites/certifications/nabers.svg",
  //       },
  //     ],
  //     description: "Redevelopment of two buildings into a single low carbon asset with eye catching arches enhancing the public realm, expansive communal spaces and iconic views over London’s landmarks"
  //   },

  //   video: "/assets/videos/bridge.mp4",
  //   copyData: {
  //     headerImage: "/assets/images/sites/bridge/1.webp",
  //     subtitle:
  //       "An office led major net zero refurbishment with retention of existing structure at it’s core.",
  //     copyImage: "/assets/images/sites/bridge/2.webp",
  //     copy: null,
  //     flexTitle:
  //       "22 and 24 Southwark Bridge Road currently comprise of 53,872 sqft and 23,562 sqft respectively, with both buildings providing accommodation over LG, Ground + 6 upper storeys.",
  //     flex: [
  //       "The buildings are situated to the East of our Forge development on the prominent Southwark Bridge Road. The sites are connected via a small “pocket park” and service yard. The site benefits from views of the Shard and the City of London to the East and the Tate Modern to the West.",

  //       "We are progressing design and planning application for a major Net Zero Carbon refurbishment, partial demolition and extension to provide a c.140k sqft office scheme.",

  //       "Our plans include retaining structure where possible, to create a new consolidated single building providing typical floor plates of c.14.5k sqft across levels 1-6, with smaller plates across levels 7 – 11. The plans will introduce an abundance of private and shared terracing.",
  //     ],
  //   },
  // },
  {
    name: ["RED LION", "COURT"],
    bg: "/assets/images/sites/lion/header.webp",
    gallery: [
      "/assets/images/sites/lion/header.webp",
      "/assets/images/sites/lion/1.webp",
      "/assets/images/sites/lion/2.webp",
      "/assets/images/sites/lion/3.webp",
      "/assets/images/sites/lion/4.webp",
      "/assets/images/sites/lion/5.webp",
      "/assets/images/sites/lion/6.webp",
      "/assets/images/sites/lion/7.webp",
      "/assets/images/sites/lion/8.webp",
      "/assets/images/sites/lion/9.webp",
      "/assets/images/sites/lion/10.webp",
      "/assets/images/sites/lion/11.webp",
      "/assets/images/sites/lion/12.webp",
      "/assets/images/sites/lion/13.webp",
    ],
    galleryCopy: [
      "/assets/images/sites/lion/1.webp",
      "/assets/images/sites/lion/2.webp",
      "/assets/images/sites/lion/3.webp",
      "/assets/images/sites/lion/4.webp",
      "/assets/images/sites/lion/5.webp",
      "/assets/images/sites/lion/6.webp",
      "/assets/images/sites/lion/7.webp",
      "/assets/images/sites/lion/8.webp",
      "/assets/images/sites/lion/9.webp",
      "/assets/images/sites/lion/10.webp",
      "/assets/images/sites/lion/11.webp",
      "/assets/images/sites/lion/12.webp",
      "/assets/images/sites/lion/13.webp",
    ],
    headerData: {
      tdc: "£280M",
      sqf: "250k",
      delivery: "OCT 2027",
      gdv: "£520M",
      usage: null,
      yield: "8,3 %",
      carbon: "600kg CO2/m2",
      target: "5 STARS",
      status: "PLANNING APPROVED",
      floor: "25k sqft",
      certifications: [
        {
          caption: "EPC RATING A",
          src: "/assets/images/sites/certifications/epc.svg",
        },
        {
          caption: "BREEAM OUTSTANDING",
          src: "/assets/images/sites/certifications/breeam.svg",
        },
        {
          caption: "WELL PLATINUM",
          src: "/assets/images/sites/certifications/wellPlatinum.svg",
        },
        {
          caption: "NABERS 5 STARS",
          src: "/assets/images/sites/certifications/nabers.svg",
        },
      ],
      description: "Landmark office led redevelopment in a prominent waterfront location with outdoor space on every floor boasting impressive views on the City, providing the optimal setting for an HQ type office"
    },

    video: "/assets/videos/lion.mp4",
    copyData: {
      headerImage: "/assets/images/sites/lion/4.webp",
      subtitle:
        "Office-led redevelopment delivering prime waterfront workspace with large floorplates suitable for significant corporate HQ pre-let",
      copyImage: "/assets/images/sites/lion/12.webp",
      copy: [
        "Major redevelopment in a prominent waterfront location adjacent to Borough Market, delivering a landmark office with impressive views of London",
        "Including extensive outdoor space on every floor, communal roof terrace, and a wellness centre enhancing occupier wellness and employee satisfaction.",
      ],
      flexTitle:
        "Strong sustainability credentials and biodiversity enhancements, including 35-40% retention of the existing basement, ground source heat pumps, the creation of a public garden, and implementation of  green roofs.",
      flex: [
        "Large floorplates (18k - 27k sqft) set to attract strong pre-leasing demand. Majority of floors will benefit from views to the City, St Paul’s, The Shard and Southwark.",

        "Targeting the highest sustainability certifications (i.e., BREEAM Outstanding, UK NABERS 5 stars, WELL Platinum)",

        "High-quality sophisticated office space built with innovative construction methods, including varying window sizes optimizing the building’s orientation to maximise natural light.",

        "The large plates and prominent riverside location, along with Landsec’s development track record and credibility, make this scheme highly pre-lettable. We anticipate interest from large cross-sector occupiers seeking the very best in class office accommodation.",
      ],
    },
  },
]

export default data
