import styled from "styled-components"

import Placeholder from "@components/Placeholder/Placeholder"
import Map from "@components/SVG/Map/Map"

import { useStore } from "@state/store"
import globalStyles from "@data/globalStyles"

function Overview() {
  const isMobile = useStore((s) => s.isMobile)
  const zoomed = useStore((s) => s.mapZoomed)
  const setZoomed = useStore((s) => s.setMapZoomed)

  return (
    <OverviewWrapper
      style={{
        overflowX: isMobile ? "scroll" : "scroll",
        touchAction: isMobile ? "pan-x" : "none",
      }}
    >
      <MapWrapper>
        <Map isAnimated />
      </MapWrapper>
      <Button
        style={{
          opacity: zoomed ? 1 : 0,
          pointerEvents: zoomed ? "all" : "none",
          transitionDelay: zoomed ? "0.8s" : "0s",
          cursor: "pointer",
        }}
        onClick={() => {
          setZoomed(false)
        }}
      >
        BACK
      </Button>
      {!isMobile && (
        <h3>
          {"^^^ "}
          <span>CLICK TO EXPLORE</span>
          {" ^^^"}
        </h3>
      )}
    </OverviewWrapper>
  )
}

export default Overview

const OverviewWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  touch-action: pan-x;
  & h3 {
    position: fixed;
    bottom: 3%;
    left: 50%;
    transform: translateX(-50%);
    color: ${globalStyles.colors.white};
    font-size: 2rem;
    letter-spacing: 0.3rem;
    opacity: 0.5;
  }

  & * {
    touch-action: pan-x;
  }
`

const Prompt = styled.div`
  position: fixed;
  z-index: 1;
  width: 40%;
  height: 3%;
  bottom: 3%;
  right: 2%;
  font-size: 6rem;
  font-family: main-bold;
  text-align: right;
  color: ${globalStyles.colors.white};
  mix-blend-mode: exclusion;

  & span {
    margin: 0 2%;
  }
`

const MapWrapper = styled.div`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 85%;
  width: 90%;
  max-width: 95vw;
  overflow: scroll hidden;

  & svg {
    border-bottom: 0.3rem solid ${globalStyles.colors.blue};
  }

  ::-webkit-scrollbar {
    display: unset;
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${globalStyles.colors.black}CC;
  }

  ::-webkit-scrollbar-track {
    background-color: ${globalStyles.colors.white};
  }
`

const Button = styled.div`
  position: absolute;
  bottom: 10%;
  left: 6%;
  width: 5%;
  aspect-ratio: 1/1;
  background-color: ${globalStyles.colors.white};
  border-radius: 100%;
  display: grid;
  place-content: center;
  font-size: 2rem;
  transition: opacity 0.8s ease-in;
`
