import { useState, useRef } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

import { useStore } from "@state/store"
import globalStyles from "@data/globalStyles"
import Logo from "@components/SVG/Logo/Logo"

function Login() {
  const [inputValue, setInputValue] = useState({
    email: "landsec@southbank.com",
    password: "",
  })
  const [promptMsg, setPromptMsg] = useState(null)

  const setTokenIsValid = useStore((s) => s.setTokenIsValid)

  const navigate = useNavigate()
  const wrapperRef = useRef<HTMLDivElement>()

  const beenAnHour = () => {
    const date = Date.now()
    const tknTime = Number(sessionStorage.getItem("landsecTknTime"))
    if (!isNaN(tknTime)) {
      const hours = Math.floor(((date - tknTime) / 1000 / 60 / 60) % 24)

      return hours >= 1
    } else {
      return false
    }
  }

  async function handleSubmit(e) {
    e.preventDefault()
    setPromptMsg(null)
    try {
      const request = await fetch(
        "https://api.lunaplatform.app/api/auth/login",
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "x-client-id": "landsec",
          },
          body: JSON.stringify({
            email: inputValue.email,
            password: inputValue.password,
          }),
        }
      )
      const response = await request.json()
      const token = response.token
      const message = response.message
      if (token) {
        sessionStorage.setItem("landsecTkn", token)
        sessionStorage.setItem("landsecTknTime", String(Date.now()))
        setTokenIsValid(true)
        wrapperRef.current.style.animationName = "fadeOut"
        setTimeout(() => {
          navigate("/")
        }, 800)
        return
      }
      if (message) {
        setPromptMsg(message + ".")
        return
      }
    } catch (err) {
      alert(err)
      setPromptMsg("We are having problems. Please try again later.")
      return
    }
  }

  return (
    <LoginWrapper ref={wrapperRef}>
      <Form>
        <Logo className="logo" />
        <form>
          <input
            type="password"
            name="password"
            id="password"
            placeholder={"Password:"}
            onChange={(e) => {
              setPromptMsg(null)
              setInputValue({ ...inputValue, password: e.target.value })
            }}
          />
          <button onClick={handleSubmit}>
            <h1>Log in</h1>
          </button>
        </form>
      </Form>
      {promptMsg && (
        <Prompt
          ref={(node) => {
            if (node) {
              setTimeout(() => {
                node.style.opacity = "0"
              }, 4000)
            }
          }}
        >
          {promptMsg}
        </Prompt>
      )}
      <CookieMessage>
        We use strictly necessary cookies to ensure the proper functioning of
        our website.
        <br />
        By logging in, you acknowledge and agree to the use of these cookies.
        <br />
        These cookies are essential for the website to function and cannot be
        switched off in our systems.
      </CookieMessage>
    </LoginWrapper>
  )
}

export default Login

const LoginWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${globalStyles.colors.lightBlack};
  background-image: url(/assets/images/story/picadilly.webp);
  background-position: 50% 100%;
  background-size: cover;
  opacity: 0;
  animation: 0.8s ease-out forwards;
  animation-name: fadeIn;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`

const Form = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: ${globalStyles.colors.darkBlue}CC;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5%;

  .logo {
    height: 20%;
  }

  & form {
    width: 30%;
    padding: 5%;
    border-left: 0.2rem solid ${globalStyles.colors.white};
    color: ${globalStyles.colors.white};
    display: flex;
    flex-direction: column;

    & input {
      margin-bottom: 5%;
      font-size: 1.2rem;
      height: 3.5rem;
      border: none;
      padding-left: 5%;

      ::placeholder {
        opacity: 0.5;
        font-size: 1rem;
      }
    }

    & button {
      background: none;
      border: none;
      border: 0.3rem solid ${globalStyles.colors.white};
      cursor: pointer;

      & h1 {
        width: 100%;
        font-size: 1.5rem;
        color: ${globalStyles.colors.white};
        padding: 3%;
      }
    }
  }

  @media (max-width: 800px) {
    flex-direction: column;

    & form {
      width: 80%;
      border-left: none;
      & input {
        height: 25rem;
        font-size: 20rem;
        ::placeholder {
          font-size: 18rem;
        }
      }
      & button {
        border: none;
        & h1 {
          border: 0.8rem solid ${globalStyles.colors.white};
          font-size: 12rem;
          width: 50%;
          margin: 0 auto;
        }
      }
    }
  }
`

const Prompt = styled.div`
  position: absolute;
  width: 100%;
  bottom: 20%;
  text-align: center;
  font-size: 2.5rem;
  color: ${globalStyles.colors.white};
  animation: fadeIn 0.8s ease-out;
  transition: opacity 0.8s ease-out;

  @media (max-width: 800px) {
    font-size: 5rem;
    bottom: 5%;
  }
`

const CookieMessage = styled.div`
  position: absolute;
  width: 60%;
  height: auto;
  text-align: center;
  font-size: 2.5rem;
  line-height: 3rem;
  color: ${globalStyles.colors.white};
  left: 50%;
  bottom: 5%;
  transform: translate(-50%);

  @media (max-width: 1205px) {
    font-size: 9rem;
    line-height: 10rem;
  }
  @media (max-width: 1205px) and (orientation: landscape) {
    font-size: 2.5rem;
    line-height: 3rem;
  }
`
