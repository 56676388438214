import { BrowserRouter } from "react-router-dom"

import MobileContent from "./MobileContent"
import Nav from "@components/Nav/Nav"

import { useStore } from "@state/store"

function MobileLayout() {
  const tokenIsValid = useStore(s => s.tokenIsValid)
  return (
    <BrowserRouter>
      {tokenIsValid && <Nav />}
      <MobileContent />
    </BrowserRouter>
  )
}

export default MobileLayout
