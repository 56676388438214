import styled from "styled-components"
import { useNavigate } from "react-router-dom"

import VideoElement from "@components/VideoElement/VideoElement"

import { useStore } from "@state/store"
import globalStyles from "@data/globalStyles"
import verify from "@utils/verify"

function Landing() {
  const setTokenIsValid = useStore(s =>s.setTokenIsValid)
  const isMobile = useStore((s) => s.isMobile)
  const navigate = useNavigate()

  function handleNav(path){
    verify(
      () => { navigate(path) },
      () => { 
        setTokenIsValid(false)
        navigate("/login") 
      }
    )
  }

  return (
    <LandingWrapper $ismobile={isMobile}>
      <VideoElement
        url="/assets/videos/landing.mp4"
        className="video"
        onClick={() => {
          handleNav("/landsec")
        }}
        loop={false}
        onEnded={() => {
          handleNav("/landsec")
        }}
        prompt={isMobile ? "TAP TO SKIP VIDEO" : "CLICK TO SKIP VIDEO"}
      />
    </LandingWrapper>
  )
}

export default Landing

type TMobile = {
  $ismobile: boolean
}

const LandingWrapper = styled.div<TMobile>`
  position: relative;
  width: 100%;
  height: 100%;

  .video {
    & video {
      height: ${(p) => (p.$ismobile ? "auto" : "100%")};
      object-fit: ${(p) => (p.$ismobile ? "contain" : "cover")};
    }
  }
`

const Prompt = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 2%;
  right: 2%;
  color: ${globalStyles.colors.blue};
  font-size: 2rem;
  mix-blend-mode: exclusion;
`
