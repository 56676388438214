import styled from "styled-components"

function WestEnd({ cue }) {
  return (
    <WestEndWrapper
      style={{
        opacity: cue ? 0.3 : 1,
      }}
    >
			"#215762"
      <path
        fill="#215762"
        stroke="#1b606b50"
        strokeWidth={15}
        d="M1013.25,662.25l-42.11-22.83c-3.42-2.1-7.09-3.91-9.97-6.59c-6.56-6.12-12.74-5.94-20.18-1.4
				c-5.4,3.29-11.24,5.86-17.24,8.61c-2.93,1.35-6.41,0.09-7.82-2.82c-2.83-5.84-5.55-11.44-8.27-17.05l-0.01-0.02l-0.03-0.06
				l-5.19-9.72l-6.34-11.87c4.78-4.06,9.39-8.37,14.15-12.47c10.56-9.09,20.65-18.85,31.95-26.92c6.41-4.57,8.72-8.76,6.81-16.18
				c-3.27-12.65-5.97-25.44-9.16-38.11c-0.37-1.45-0.9-2.86-1.51-4.3l-1.38-15.88L758.72,137.4L591.43,270.85
				c-20.19,11.32-24.59,12.08-18,49.61c-9.11,6.65-19.05,13.9-29.59,21.59c0.77,1.49,1.67,3.54,2.81,5.44
				c10.59,17.65,5.71,27.57-14.51,28.57c-5.56,0.27-9.85,1.82-13.53,5.88c-3.69,4.08-7.68,7.88-13.09,13.39
				c-1.08,1.09-5.08,2.78-11.38,4.84c-0.06-0.08-0.13-0.15-0.19-0.23c-12.33-14.57-24.66-29.16-37.27-43.49
				c-5.91-6.73-8.5-6.62-15.16-0.86c-7.72,6.67-15.3,13.53-23.11,20.09c-8.89,7.46-18.09,14.55-26.94,22.07
				c-4.9,4.16-9.7,4.39-15.5,1.98c-13.32-5.53-26.96-10.31-40.21-16c-12.63-5.41-24.99-6.11-37.97-1.28
				c-22.65,8.43-45.44,16.5-68.15,24.76l-0.01-0.01c-15.76,5.7-30.77,9.11-33.39,9.69c-0.07,0.02-0.15,0.03-0.22,0.04
				c-0.03,0.01-0.06,0.02-0.08,0.02c-0.07,0.01-0.11,0.02-0.11,0.02c-10.45,1.84-21.46,2.65-29.15,11.62
				c-6.32,7.37-12.52,14.85-18.41,22.56c-9.41,12.32-19.9,23.05-34.64,29.16c-9.8,4.07-19,9.55-28.68,13.93
				c-15.99,7.25-31.6,14.93-50.17,14.72l-29.94,3.31L0,537.4l0.11,19.91v285.94c10.44,8.6,21.47,17.5,31.7,26.36
				c7.95,6.88,15.71,13.97,24.9,22.17c-11.17,4.7-20.29,8.55-30.28,12.76c7.03,12.48,13.33,23.37,19.35,34.42
				c3.08,5.65,7.7,7.32,13.83,7.58c3.37,0.15,8.94,1.57,9.61,3.67c4.22,13.27,7.35,26.9,10.84,40.4c-6.43,9.5,0.49,16.69,4.42,24.2
				c11.47,21.95,32.07,30.64,54.89,22.12c21.43-7.99,42.35-17.36,63.57-25.94c7.12-2.88,10.76-8.24,9.26-15.32
				c-1.59-7.56-4.64-14.92-7.8-22.02c-5.49-12.33-11.87-24.27-17.19-36.66c-1.51-3.52-1.97-8.54-0.65-12.02
				c3.98-10.52,9.36-20.42,18.91-27.23c10.66-7.58,21.11-15.48,32.09-22.56c4.12-2.66,9.39-4.43,14.27-4.79
				c9.76-0.73,19.61,0.04,29.42-0.27c7.17-0.23,11.07,3.27,13.88,9.44c5.82,12.75,12.12,25.28,17.97,38.01
				c1.49,3.23,2.06,6.88,3.21,10.88c-8.54,3.97-16.3,7.57-25.31,11.75c14.04,24.87,27.38,48.1,43.32,71.66
				c16.29-9.67,31.31-18.94,46.68-27.62c21.16-11.95,17.25-16.28,33.48,6.26c5.01,6.96,12.2,12.76,14.05,22.28
				c-7.22,5.05-13.93,9.74-21.57,15.08c11.9,17.74,23.47,35.01,34.95,52.14h59.61c36.52-16.33,74.28-29.42,112.87-40.33
				c30.89-8.72,62.12-16.26,94.38-17.98l11.25-0.41c32.88-1.12,64.84,6.86,97.29,9.76c19.86,1.77,40.36,3.07,59.9,0.15
				c28.82-3.47,50.48-24.96,65.15-50.33c26.19-45.28,41.25-92.5,52.19-143.36c3.63-16.87,5.14-29.59,4.46-54.38
				c-1.44-23.9,0.44-60.02,2.07-75.39C1002.74,691.3,1008.85,678.14,1013.25,662.25z"
      />

      {/* <path
        fill="#a2b1a6"
        d="M640.9,609.9c6.6,12,21.5,26.8,28.6,38.5c12.3,20.1,28,45,43.1,66.5c3,4.3,0.4,10.3-4.8,11
		c-2.9,0.4-6.5,0.8-10.7,1.6c-21.7,4.1-43.7,6.9-65.4,10.9c-45.4,8.5-91.3,4.8-136.9,5.5c-26.8,0.4-53.7-0.8-80.5,0.4
		c-22.2,1-43.1-3.8-64-9.6c-5-1.4-9.7-3.1-11.5-8.2c-1.9-5.4-6.8-9-12.4-10.2c-4-0.8-6.1-3.9-7.7-7.7c-3.6-9-7.2-18-10.5-27.2
		c-1.8-4.9,0.5-8,5.4-9.3c5.3-1.4,10.6-2.8,16.5-4.4c4.1-1.1,6.3-5.5,4.7-9.4c-5.5-13.7-10.8-27-16.4-40.7c-1.6-4,0.8-8.6,5.1-9.5
		c9.2-2,17.8-3.8,26.4-5.4c63.6-11.2,127.1-22.2,190.7-33.2c16.8-2.9,33.6-6.1,50.5-8.1c6.2-0.8,12.7,1.3,19.1,2
		c0,0-0.1-0.1-0.1-0.1c1,1.9,2,3.8,3.1,5.7l-0.1-0.2c1,1.7,2,3.4,3,5.1c0.1,0.2,0.2,0.4,0.3,0.5c3.3,4.7,6.7,9.5,10,14.2
		c1.2,1.8,2.4,3.6,3.5,5.3l0-0.3c1.9,2.9,3.7,5.7,5.6,8.6l0-0.3c1.9,2.9,3.7,5.7,5.6,8.6L640.9,609.9z"
      /> */}

      {/* <path
        fill="#a2b1a6"
        d="M758.7,137.4c-12.8,17.9-12.8,17.9-5.5,39.9c3.1,9.3,5.7,18.8,9.3,27.9c7.9,20.3,11.6,41.1,10.9,63
		c-0.4,13.4,1.3,27,2.6,40.4c0.8,8.8-2.8,13.8-11.1,16.8c-26.8,9.6-54,17.9-82.2,22.2c-14.6,2.2-37.2-10-40.7-24.1
		c-6.1-24.6-23.2-39.8-41.8-54.3c-9.5-7.4-10.5-7.2-20,1.4c-11.2,10.2-25.7,9.7-33.7-2.4c-3.6-5.5-6.5-13.3-5.7-19.6
		c4.4-36,17.2-69,43.6-94.5c23.5-22.7,52-36.2,85.4-36.1c20.5,0,41,1,61.4,3C741.9,122.1,751.3,129.2,758.7,137.4z"
      /> */}

      {/* <path
        fill="#a2b1a6"
        d="M948.3,695.1c5,17,3.9,33.4-5,49c-1.8,3.1-2.7,6.8-4.1,10.2c-4.5,10.5-10.2,12.6-21.8,12.1
		c-24.6-1.2-49.3-1.8-74-1.7c-9.1,0-17.9,3.2-24,11.3c-9.8,13.1-25,18-38.8,25.1c-5.1,2.6-10,2.2-13.7-2.3
		c-11.5-14.2-23.1-28.3-34.2-42.9c-4.3-5.7-3.3-11.1,3-17c12.6-12,25.6-23.5,38.6-35c6.9-6,14-11.8,21.4-17.1
		c10-7,14.4-7.1,22.8,1.8c9.4,9.9,17.6,21,26.1,31.7c2.3,2.8,3.9,6.2,6.1,9.1c5.8,7.5,8,8.2,15.7,2.7c10.8-7.7,21.2-15.9,31.6-24.2
		c6.8-5.4,13.3-11.2,19.8-16.9c11.4-10,17.1-9.6,26.9,2c0.8,1,2.2,1.5,3.4,2.3C948.4,695.3,948.3,695.1,948.3,695.1z"
      /> */}

      {/* <path
        fill="#a2b1a6"
        d="M207.4,764.3c-2.3,2.4-4.9,5-7.7,7.8c-2.3,2.4-6,2.7-8.7,0.8c-1-0.7-1.8-1.4-2.5-2.2
		c-4.9-5.6-10.6-8.2-17.8-5.4c-6.4,2.5-10.6-0.6-12.6-5.9c-4.6-11.6-8.7-23.3-13.2-35.6c-1.3-3.6,0.7-7.6,4.3-8.7
		c7.3-2.3,14-4.4,20.8-6.6c0.7-0.2,1.3-0.5,1.9-1c4.2-3,3.2-4.1,0.5-21c0.2-3.1,4-4.7,6.2-2.6c0.1,0.1,0.2,0.2,0.3,0.3
		c6,8.7,11.8,17.4,17.5,26.3c1.7,2.7,3,5.6,3.7,8.7c3,12.3,5.8,24.7,9.1,38.8C209.7,760.3,209,762.6,207.4,764.3z"
      /> */}
    </WestEndWrapper>
  )
}

export default WestEnd

const WestEndWrapper = styled.g``
