import styled from "styled-components"

import TSVGProps from "../SVGTypes"

type TLogoProps = TSVGProps & {
  color?: string
  className?: string
  bgColor?: string
  bgStroke?: string
  [x: string]: any
}

function CloseIcon({
  color = "none",
  strokeWidth,
  strokeColor,
  bgColor,
  bgStroke,
  ...props
}: TLogoProps) {
  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="100%"
      y="100%"
      viewBox="0 0 128 128"
      {...props}
    >
      <circle
        fill={bgColor}
        strokeWidth={strokeWidth}
        stroke={strokeColor}
        cx="64"
        cy="64"
        r="60.7"
      />
      <line
        x1="40"
        y1="40"
        x2="88"
        y2="88"
        strokeWidth={strokeWidth}
        stroke={strokeColor}
      />
      <line
        x1="40"
        y1="88"
        x2="88"
        y2="40"
        strokeWidth={strokeWidth}
        stroke={strokeColor}
      />
    </SVG>
  )
}

export default CloseIcon

const SVG = styled.svg`
  pointer-events: none;
`
