import { useRef, useEffect, useCallback, useState } from "react"
import styled from "styled-components"
import useEmblaCarousel from "embla-carousel-react"

import Placeholder from "@components/Placeholder/Placeholder"
import CloseIcon from "@components/SVG/CloseIcon/CloseIcon"
import ArrowIcon from "@components/SVG/ArrowIcon/ArrowIcon"

import globalStyles from "@data/globalStyles"
import { useStore } from "@state/store"
import data from "@data/sitesData"

function SitesGallery({index, setIndex}) {
  // const [activeIndex, setActiveIndex] = useState(0)
  const isMobile = useStore((s) => s.isMobile)
  const activeSite = useStore((s) => s.activeSite)
  // const siteGalleryVisible = useStore((s) => s.siteGalleryVisible)
  // const setSiteGalleryVisible = useStore((s) => s.setSiteGalleryVisible)
  const wrapperRef = useRef<HTMLDivElement>()
  const images = data[activeSite]?.gallery
  // const copy = data[activeSite]?.galleryCopy

  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: true,
    dragThreshold: 125
  })

  const onSelect = useCallback((emblaApi) => {
    const currentId = emblaApi.selectedScrollSnap()
    setIndex(currentId)
  }, [])

  // function handleClose() {
  //   wrapperRef.current.style.opacity = "0"
  //   setTimeout(() => {
  //     setSiteGalleryVisible(false)
  //   }, 800)
  // }

  // function handleClick(dir) {
  //   const max = images.length - 1
  //   if (dir) {
  //     setActiveIndex(activeIndex === max ? 0 : activeIndex + 1)
  //   } else {
  //     setActiveIndex(activeIndex === 0 ? max : activeIndex - 1)
  //   }
  // }
  useEffect(() => {
    if (emblaApi) {
      if (index !== emblaApi.selectedScrollSnap()) {
        emblaApi.scrollTo(index)
      }
    }
  }, [index])

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on("select", onSelect)
    }
  }, [emblaApi])

  return (
    // siteGalleryVisible && (
    activeSite !== null && (
      <GalleryWrapper
        ref={(node) => {
          if (node) {
            wrapperRef.current = node
            setTimeout(() => {
              node.style.opacity = "1"
            }, 100)
          }
        }}
      >
        <SwiperWrapper ref={emblaRef}>
          <Swiper>
            {images.map((src, i) => {
              return (
                <SwiperSlide key={`img${i}`}>
                  <img
                    src={src}
                    alt=""
                    style={{
                      width: isMobile ? "initial" : "auto",
                      height: isMobile ? "initial" : "95%",
                      maxWidth: isMobile ? "95%" : "90%",
                      maxHeight: isMobile ? "auto" : "95%",
                    }}
                  />
                  {/* <Caption ismobile={isMobile}> 
                    <p>
                      {copy[i]} 
                    </p>
                  </Caption> */}
                </SwiperSlide>
              )
            })}
          </Swiper>
        </SwiperWrapper>
        {/* <CloseIcon
          className="close"
          style={{
            width: isMobile ? "12rem" : "4rem",
          }}
          strokeWidth={4}
          strokeColor={globalStyles.colors.white}
          color={globalStyles.colors.white}
          bgColor="none"
          onClick={() => {
            handleClose()
          }}
        /> */}
        {/* {!isMobile && (
          <Buttons ismobile={isMobile}>
            <button
              onClick={() => {
                handleClick(false)
              }}
            >
              <ArrowIcon
                dir="left"
                strokeColor={globalStyles.colors.white}
                bgColor={globalStyles.colors.blue}
                strokeWidth={10}
              />
            </button>

            <button
              onClick={() => {
                handleClick(true)
              }}
            >
              <ArrowIcon
                dir="right"
                strokeColor={globalStyles.colors.white}
                bgColor={globalStyles.colors.blue}
                strokeWidth={10}
              />
            </button>
          </Buttons>
        )} */}
      </GalleryWrapper>
    )
  )
}

export default SitesGallery

type Tmobile = {
  ismobile: boolean
}

const GalleryWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  transition: opacity 0.8s ease-in-out;
  opacity: 0;
  background-color: ${globalStyles.colors.lightBlack};

  .close {
    position: absolute;
    z-index: 1;
    top: 1vw;
    right: 1vw;
    width: 4rem;
    aspect-ratio: 1/1;
    pointer-events: all;
    cursor: pointer;
    mix-blend-mode: difference;
  }
`

const SwiperWrapper = styled.div`
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const Swiper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
`

const SwiperSlide = styled.div`
  position: relative;
  flex: 0 0 100%;
  min-width: 0;
  width: 100%;
  height: 100%;

  & img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    object-fit: cover;
    cursor: grab;
  }
`
const Caption = styled.p<Tmobile>`
  position: absolute;
  bottom:  ${p => p.ismobile ? "0%" : "5%"};
  left: ${p => p.ismobile ? "10%" : "0%"};
  width: ${p => p.ismobile ? "80%" : "30%"};
  max-width: 80%;
  height: 25%;
  background-color: ${globalStyles.colors.black}CC;
  border-top: ${p => p.ismobile ? `0.5rem solid ${globalStyles.colors.blue}` : `none`};
  border-right: ${p => p.ismobile ? `none` : `0.5rem solid ${globalStyles.colors.blue}`};
  color: ${globalStyles.colors.white};

  & p {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 90%;
    height: auto;
    font-size: ${p => p.ismobile ? "6rem" : "1.8rem"};
    text-align: center;
    white-space: normal;
    word-wrap: break-word;

  }
`

const Buttons = styled.div<Tmobile>`
  position: absolute;
  z-index: 5;
  top: 50%;
  left: 2.5%;
  transform: translate(0%, -50%);
  width: 95%;
  height: 6rem;
  display: flex;
  justify-content: space-between;
  pointer-events: none;

  & button {
    height: 100%;
    aspect-ratio: 1/1;
    background: none;
    border: none;
    cursor: pointer;
    pointer-events: all;
  }
`
