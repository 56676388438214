import { Fragment } from "react"
import { Canvas } from "@react-three/fiber"

import CameraControls from "./components/CameraControls"
import Sphere from "./components/Sphere"

function Transition360({ initialTexture, finalTexture, blend, shaderRef, meshProps }) {
  return (
    <Fragment>
      <Canvas>
        <CameraControls />
        <Sphere
          shaderRef={shaderRef}
          initialTexture={initialTexture}
          finalTexture={finalTexture}
          blend={blend}
          meshProps={meshProps}
        />
      </Canvas>
    </Fragment>
  )
}

export default Transition360
