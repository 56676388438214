import { create } from "zustand"

import verify from "@utils/verify"

interface InitialState {
  isMobile: boolean
  setIsMobile: (v: boolean) => void
  menuOpen: boolean
  setMenuOpen: (v: boolean) => void
  mapZoomed: boolean
  setMapZoomed: (v: boolean) => void
  activeSite: number
  setActiveSite: (v:number) => void
  siteTourVisible: boolean
  setSiteTourVisible: (v: boolean) => void
  siteVideoVisible: boolean
  setSiteVideoVisible: (v: boolean) => void
  siteGalleryVisible: boolean
  setSiteGalleryVisible: (v: boolean) => void
  siteCopyVisible: boolean
  setSiteCopyVisible: (v: boolean) => void
  tokenIsValid: any
  setTokenIsValid: (v: boolean) => void
}

export const useStore = create<InitialState>()((set, get) => ({
  activeSite: null,
  setActiveSite: (v) => { set({activeSite: v}) },
  siteTourVisible: false,
  setSiteTourVisible: (v) => { set({siteTourVisible: v}) },
  siteVideoVisible: false,
  setSiteVideoVisible: (v) => { set({siteVideoVisible: v}) },
  menuOpen: false,
  setMenuOpen: (v) => { set({menuOpen: v}) },
  mapZoomed: false,
  setMapZoomed: (v) => { set({mapZoomed: v}) },
  siteGalleryVisible: false,
  setSiteGalleryVisible: (v) => { set({siteGalleryVisible: v}) },
  siteCopyVisible: false,
  setSiteCopyVisible: (v) => { set({siteCopyVisible: v}) },
  isMobile: false,
  setIsMobile: (v) => { set({isMobile: v}) },
  tokenIsValid: verify(
    () => true,
    () => false
  ),
  setTokenIsValid: (v) => { set({tokenIsValid: v}) }
}))
