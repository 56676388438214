import styled from "styled-components"

import CopyLayout from "./Layout/Layout"

import { useStore } from "@state/store"
import data from "@data/sitesData"

function LionCopy() {
  const activeSite = useStore((s) => s.activeSite)
  const isMobile = useStore((s) => s.isMobile)

  return (
    <CopyWrapper ismobile={isMobile}>
      <CopyLayout data={data[4]} />
    </CopyWrapper>
  )
}

export default LionCopy

type Tmobile = {
  ismobile: boolean
}

const CopyWrapper = styled.div<Tmobile>`
  #copy {
    width: ${p => p.ismobile ? "80%" : "60%"};
  }

  #flexTitle {
    font-size: ${(p) => (p.ismobile ? "18rem" : "4rem")};
    
    & h1 {
      font-size: ${(p) => (p.ismobile ? "16rem" : "4rem")};
      line-height:${(p) => (p.ismobile ? "18rem" : "4.5rem")} ;
    }
  }

  #flex{
    & div{
      font-size: ${(p) => (p.ismobile ? "16rem" : "1.5rem")};
      line-height:${(p) => (p.ismobile ? "15.5rem" : "1.8rem")};
    }
  }
`