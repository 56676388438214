import styled from "styled-components"

import globalStyles from "@data/globalStyles"

function SouthBank({ cue, ...props }) {
  return (
    <SouthBankWrapper {...props}>
      <path
        id="bg"
        fill={cue ? "#999999" : "#ffffff"}
        strokeWidth={5}
        stroke={globalStyles.colors.blue}
        d="M937.6,1080c8.4-5.1,14.8-10,22.3-16.8c10.6-9.7,19.5-21.2,29.3-31.7c1.4-1.5,2.9-3,4.5-4.4l10.4-19
	c28.1-54.6,47.5-112.3,58.1-172.9c2-11.4,2-23.2,2.1-34.9c0.2-21-0.2-42.1-0.5-63.1c-0.4-24.8,8.6-46.7,21.2-67.2
	c9.1-14.7,24.5-21.6,40.4-26c50.3-13.8,100.6-13.1,150.5,2c46,13.9,91.5,29.4,138.9,38.7c32,6.3,63.9,13.5,95.6,21.4
	c14.3,3.5,29.5,5.9,41,16.6c9.3,12.1,18.7,24.3,27.9,36.3c-3.1,6.4-6.5,12.3-8.9,18.6c-1.2,3.3-1.4,7.3-0.8,10.8
	c2.8,17-8,30.6-25.4,32.1c-7.3,0.6-14.5,2.6-22.6,4.1c6.7,20-6.5,32.5-18,47.5c5.2,0.6,8.7,1.3,12.1,1.5c7,0.2,14.1-0.4,21,0.3
	c9,0.9,11.3,5.4,6.7,13.4c-4.3,7.6-9.5,14.7-14.7,22.7c2.6,1.3,4.1,2.7,5.7,2.8c21.3,2,42.7,4.5,64.1,5.6c9.1,0.5,18.3-1.9,28.8-3.1
	c7.7,13.1-1.4,29.5,5.9,45.2c10.5-3.5,20.8-7,31-10.4c3.7,12,3.1,14.1-6.3,17.8c-7.8,3.1-15.9,5.2-23.8,8c-4.8,1.7-9.3,3.8-15,6.1
	c4.1,25.4-7.1,47.8-11.9,71.3c-3,15-14.1,23.9-29.5,24.9c-3.5,0.2-8.7-1.5-11.4-3.8c-9.7-8.2-18.1-17.6-20.5-30.8
	c-2.1-11.4-9.6-16.1-20.2-18.3c-13.1-2.8-12.7-3.2-13.6-17.1c-0.2-2.9-3.3-7.2-5.9-7.9c-10-2.9-15.5-9.1-19.2-18.6
	c-1.4-3.7-5.8-7.5-9.7-8.8c-15.8-5-26.3-14.9-32.5-30.2c-1.8-4.3-6.1-7.8-9.7-11.2c-8.9-8.2-18.4-15.8-27-24.2
	c-4.9-4.7-10.1-5.9-16.5-5.4c-5.1,0.4-10.3-0.4-15.4-0.3c-5.5,0.2-10.4-0.7-13.3-5.9c-3.6-6.4-8.5-5.9-14.3-3.4
	c-3.8,1.6-7.9,2.9-12,3.4c-7.5,0.8-14.3-0.2-20.5-6.3c-7.4-7.3-16.8-11.9-27.7-10c-4.7,0.8-9.2,3.3-13.5,4.9
	c1.9,12.5,3.5,23.4,5.4,36.6c-9.9,0.1-18.1,0.8-26.2,0.2c-7.1-0.5-10.5-5.6-10.9-12.4c-0.4-7-0.9-14.1,0-21
	c1.6-13.4-6.1-21.1-15.8-27.3c-5.8-3.7-12.6-5.8-19.1-8.3c-4.9-1.9-8.4,0.5-9.7,5.1c-2,7.7-7.6,8-14,8.6
	c-14.8,1.4-27.4-2.8-38.7-12.3c-8.7-7.3-8.9-6-18.1-1c-9.3,5-19.4,8.8-29.6,11.5c-9.5,2.5-17.6,5.4-22.2,15c-1.5,3.2-4.6,6.3-7.7,8
	c-11.1,6.1-13.6,16.5-16.1,27.8c-7.3,33.8-16,67.3-28.5,99.7l-8.5,22.5l-0.5,1.4c-0.7,1.9-1.8,4-3.1,6.7c-2,4.2-4.6,8.2-6.2,12.6
	c-3.2,9-6.8,15.5-12.5,22.1H937.6z M1004.9,1022.5l0.2,0.1c0,0,0,0,0,0C1005,1022.6,1005,1022.6,1004.9,1022.5z"
      />
      <image
        href="/assets/images/click.gif"
        x={1260}
        y={768}
        width={80}
        style={{
          transition: "opacity",
          transitionDuration: "800ms",
          transitionDelay: cue ? "0ms" : "800ms",
          opacity: cue ? 0 : 1,
        }}
      />
    </SouthBankWrapper>
  )
}

export default SouthBank

const SouthBankWrapper = styled.g`
  & path {
    transition: fill 0.8s ease-in, filter 0.8s ease-in;
  }
`
