import { BrowserRouter } from "react-router-dom"

import Content from "./Content"
import Nav from "@components/Nav/Nav"

import { useStore } from "@state/store"

function DefaultLayout() {
  const tokenIsValid = useStore(s => s.tokenIsValid)
  return (
    <BrowserRouter>
      {tokenIsValid && <Nav />}
      <Content />
    </BrowserRouter>
  )
}

export default DefaultLayout
