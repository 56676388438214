const globalStyles = {
  colors: {
    black: "#1c1c1c",
    lightBlack: "#282827",
    white: "#FFFFFF",
    offWhite: "#f2f2f2",
    darkRed: "#7F4534",
    red: "#B9654D",
    darkBlue: "#215762",
    blue: "#53d6c5",
    lightBlue: "#94A2B6"
  }
}

export default globalStyles