import { useRef, useEffect } from "react"
import styled from "styled-components"

import Placeholder from "@components/Placeholder/Placeholder"
import CloseIcon from "@components/SVG/CloseIcon/CloseIcon"

import globalStyles from "@data/globalStyles"
import { useStore } from "@state/store"
import data from "@data/sitesData"
import VideoElement from "@components/VideoElement/VideoElement"

function SitesVideo() {
  const isMobile = useStore((s) => s.isMobile)
  const activeSite = useStore((s) => s.activeSite)
  const siteVideoVisible = useStore((s) => s.siteVideoVisible)
  const setSiteVideoVisible = useStore((s) => s.setSiteVideoVisible)
  const url = data[activeSite]?.video

  function handleClose() {
    setSiteVideoVisible(false)
  }

  return (
    siteVideoVisible && (
      <VideoWrapper $ismobile={isMobile}>
        <VideoElement
          url={url}
          onClick={() => {
            handleClose()
          }}
          prompt={isMobile ? "TAP TO CLOSE" : "CLICK TO CLOSE"}
          loop={false}
          onEnded={() => {
            setSiteVideoVisible(false)
          }}
          className="video"
        />
      </VideoWrapper>
    )
  )
}

export default SitesVideo

type TMobile = {
  $ismobile: boolean
}

const VideoWrapper = styled.div<TMobile>`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 15;
  background-color: ${globalStyles.colors.black};

  .video {
    & video {
      height: ${(p) => (p.$ismobile ? "auto" : "100%")};
      object-fit: ${(p) => (p.$ismobile ? "contain" : "cover")};
    }
  }
`

const Video = styled.video<TMobile>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: ${(p) => (p.$ismobile ? "auto" : "100%")};
  object-fit: ${(p) => (p.$ismobile ? "contain" : "cover")};
  z-index: 0;
  opacity: 0;
  transition: opacity 0.8s ease-in-out;
`
