import styled from "styled-components"

import CopyLayout from "./Layout/Layout"

import data from "@data/sitesData"

function TheForgeCopy() {

  return (
    <CopyWrapper>
      <CopyLayout data={data[0]} />
    </CopyWrapper>
  )
}

export default TheForgeCopy

const CopyWrapper = styled.div``
