import { useEffect, useRef, Fragment } from "react"
import styled from "styled-components"
import { useParams, useNavigate } from "react-router-dom"
import useEmblaCarousel from "embla-carousel-react"

import verify from "@utils/verify"
import { useStore } from "@state/store"
import globalStyles from "@data/globalStyles"
import data from "@data/sitesData"

function MobileSitesMenu() {
  const activeSite = useStore((s) => s.activeSite)
  const setActiveSite = useStore((s) => s.setActiveSite)
  const setTokenIsValid = useStore((s) => s.setTokenIsValid)
  const siteCopyVisible = useStore((s) => s.siteCopyVisible)
  const siteVideoVisible = useStore((s) => s.siteVideoVisible)
  const siteGalleryVisible = useStore((s) => s.siteGalleryVisible)
  const navigate = useNavigate()
  const sitesMaxIndex = data.length
  const xStart = useRef(null)
  const xCurrent = useRef(null)
  const timeStart = useRef(null)
  const currentCount = useRef(0)
  const dir = useRef(null)
  const menuRef = useRef<HTMLDivElement>()
  const clickDelta = useRef(null)
  const { id } = useParams()
  const labels = [["SEED", "PORTFOLIO"], ...data.map((obj) => obj.name)]
  const prev =
    activeSite === null ? labels.length - 1 : activeSite === 0 ? 0 : activeSite

  const current = activeSite === null ? 0 : activeSite + 1

  const next =
    activeSite === null
      ? 1
      : activeSite + 2 > labels.length - 1
      ? 0
      : activeSite + 2

  function handleSwipe(e) {
    if (siteCopyVisible || siteGalleryVisible || siteVideoVisible) {
      return
    } else {
      switch (e.type) {
        case "touchstart":
          handleDown(e)
          break
        case "touchmove":
          handleMove(e)
          break
        case "touchend":
          handleUp()
          break
        default:
          return
      }
    }
  }

  function handleDown(e) {
    timeStart.current = e.timeStamp
    xStart.current = e.touches[0].clientX
  }

  function handleMove(e) {
    xCurrent.current = e.touches[0].clientX
  }

  function handleUp() {
    const distance = Math.abs(Math.round(xCurrent.current - xStart.current))
    dir.current = xCurrent.current < xStart.current
    if (distance >= 50 && xCurrent.current) {
      verify(handleSucces, handleFail)
    } else {
      return
    }
    xStart.current = null
    xCurrent.current = null
    timeStart.current = null
  }

  function getNextIndex(dir) {
    if (dir) {
      if (currentCount.current === sitesMaxIndex) {
        currentCount.current = 0
      } else {
        currentCount.current++
      }
    } else {
      if (currentCount.current === 0) {
        currentCount.current = sitesMaxIndex
      } else {
        currentCount.current--
      }
    }
    return currentCount.current
  }

  function handleSucces() {
    const nextIndex = getNextIndex(dir.current)
    if (nextIndex === 0) {
      setActiveSite(null)
      navigate("/sites")
    } else {
      setActiveSite(nextIndex - 1)
      navigate(`/sites/${nextIndex - 1}`)
      if (history) {
        history.pushState(
          {},
          "id",
          `${window.location.origin}/sites/${nextIndex - 1}`
        )
      }
    }
  }

  function handleFail() {
    setTokenIsValid(false)
    setActiveSite(null)
    navigate("/login")
  }

  function handlePointerDown(e) {
    clickDelta.current = e.timeStamp
  }

  function handlePointerUp(e, site) {
    const diff = Math.round(e.timeStamp - clickDelta.current)
    diff < 100 && handleClick(site)
  }

  function handleClick(site) {
    verify(() => {
      setActiveSite(site < 0 ? null : site)
      navigate(site < 0 ? `/sites` : `/sites/${site}`)
    }, handleFail)
    clickDelta.current = null
  }

  useEffect(() => {
    if (id) {
      setActiveSite(Number(id))
    }
  }, [id])

  useEffect(() => {
    if (menuRef.current) {
      menuRef.current.addEventListener("touchstart", handleSwipe)
      menuRef.current.addEventListener("touchmove", handleSwipe)
      menuRef.current.addEventListener("touchend", handleSwipe)
    }
    return () => {
      if (menuRef.current) {
        menuRef.current.removeEventListener("touchstart", handleSwipe)
        menuRef.current.removeEventListener("touchmove", handleSwipe)
        menuRef.current.removeEventListener("touchend", handleSwipe)
      }
    }
  }, [])

  return (
    <Fragment>
      <MenuWrapper ref={menuRef}>
        <Button
          className="rest"
          onPointerDown={handlePointerDown}
          onPointerUp={(e) => {
            handlePointerUp(e, prev - 1)
          }}
        >
          {labels.map((arr, i) => {
            return (
              <div
                key={i}
                style={{
                  opacity: prev === i ? 1 : 0,
                }}
              >
                {arr.map((txt, i) => {
                  return <p key={`btnP${i}`}>{txt}</p>
                })}
              </div>
            )
          })}
        </Button>
        <Button className="active">
          {labels.map((arr, i) => {
            return (
              <div
                key={i}
                style={{
                  opacity: current === i ? 1 : 0,
                }}
              >
                {arr.map((txt, i) => {
                  return <p key={`btnP${i}`}>{txt}</p>
                })}
              </div>
            )
          })}
        </Button>
        <Button
          className="rest"
          onPointerDown={handlePointerDown}
          onPointerUp={(e) => {
            handlePointerUp(e, next - 1)
          }}
        >
          {labels.map((arr, i) => {
            return (
              <div
                key={i}
                style={{
                  opacity: next === i ? 1 : 0,
                }}
              >
                {arr.map((txt, i) => {
                  return <p key={`btnP${i}`}>{txt}</p>
                })}
              </div>
            )
          })}
        </Button>
        <Prompt>SWIPE TO CHANGE SITE</Prompt>
      </MenuWrapper>
    </Fragment>
  )
}

export default MobileSitesMenu

const MenuWrapper = styled.div`
  position: relative;
  width: fit-content;
  max-width: 100%;
  min-width: 100%;
  height: 12%;
  background-color: ${globalStyles.colors.lightBlack};
  border-top: 0.2vh solid ${globalStyles.colors.blue};
  overflow: hidden;
  white-space: nowrap;

  .rest {
    opacity: 0.5;
    & p {
      transform: scale(0.6);
      line-height: 8rem;
    }
  }
`

const Button = styled.button`
  position: relative;
  display: inline-block;
  height: 100%;
  width: calc(100% / 3);
  max-width: calc(100% / 3);
  background: none;
  border: none;
  color: ${globalStyles.colors.blue};
  cursor: pointer;

  & div {
    display: grid;
    place-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    transition: opacity 0.5s ease-out;
  }
`

const Prompt = styled.div`
  position: absolute;
  z-index: 5;
  width: 50%;
  bottom: 2%;
  left: 2%;
  font-size: 12rem;
  font-weight: 100;
  text-align: left;
  color: ${globalStyles.colors.white};
  opacity: 0.8;
  transition: opacity 0.8s ease-in-out;
  pointer-events: none;
`
