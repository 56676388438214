import styled from "styled-components"
import { useStore } from "@state/store"

function Square({ text }: { text: string }) {

  return (
    <Wrapper>
      {text}
    </Wrapper>
  )
}

export default Square

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1rem;
  background-color: teal;
  color: purple;
  display: grid;
  place-content: center;
  text-align: center;
`
