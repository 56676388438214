import styled from "styled-components"

import TSVGProps from "../SVGTypes"

type TLogoProps = TSVGProps & {
  color?: string
  className?: string
  bgColor?: string
  bgStroke?: string
  dir: "left" | "right"
  [x: string]: any
}

function ArrowIcon({
  color = "none",
  strokeWidth,
  strokeColor,
  bgColor,
  bgStroke,
  dir,
  ...props
}: TLogoProps) {
  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="100%"
      y="100%"
      viewBox="0 0 128 128"
      {...props}
    >
      <circle
        fill={bgColor}
        strokeWidth={strokeWidth}
        stroke={bgStroke}
        cx="64"
        cy="64"
        r="60.7"
      />
      {dir === "right" ? (
        <>
          <line
            x1="40"
            y1="40"
            x2="88"
            y2="64"
            strokeWidth={strokeWidth}
            stroke={strokeColor}
          />
          <line
            x1="40"
            y1="88"
            x2="88"
            y2="64"
            strokeWidth={strokeWidth}
            stroke={strokeColor}
          />
        </>
      ) : (
        <>
          <line
            x1="88"
            y1="88"
            x2="40"
            y2="64"
            strokeWidth={strokeWidth}
            stroke={strokeColor}
          />
          <line
            x1="88"
            y1="40"
            x2="40"
            y2="64"
            strokeWidth={strokeWidth}
            stroke={strokeColor}
          />
        </>
      )}
    </SVG>
  )
}

export default ArrowIcon

const SVG = styled.svg`
  pointer-events: none;
`
