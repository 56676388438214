import { useRef } from "react"
import styled from "styled-components"
import { useSpring, a } from "@react-spring/web"

import TSVGProps from "../SVGTypes"

type TLogoProps = TSVGProps & {
  color?: string
  className?: string
  bgColor?: string
  bgStroke?: string
  onClick?: () => void
  [x: string]: any
}

function MuteIcon({
  color = "none",
  strokeWidth,
  strokeColor,
  bgColor = "transparent",
  bgStroke,
  onClick,
  ...props
}: TLogoProps) {
  const muted = useRef(true)

  const [lineStyle, lineAPI] = useSpring(() => ({
    x: 0,
    y: 20,
  }))
  
  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="100%"
      y="100%"
      viewBox="0 0 20 20"
      onClick={(e) => {
        e.stopPropagation()
        if(onClick){
          onClick()
        }
        muted.current = !muted.current
        lineAPI.start({
          x: muted.current ? 0 : 20,
          y: muted.current ? 20 : 0
        })
      }}
      {...props}
    >
      <circle
        fill={bgColor}
        strokeWidth={strokeWidth}
        stroke={strokeColor}
        cx="10"
        cy="10"
        r="8"
      />
      <g>
        <path
          d="M11.9971 3.00588C11.9971 2.13286 10.9568 1.67903 10.3168 2.27288L6.44467 5.86626C6.3522 5.95208 6.23071 5.99976 6.10456 5.99976H3.49707C2.66864 5.99976 1.99707 6.67134 1.99707 7.49976V12.4998C1.99707 13.3282 2.66864 13.9998 3.49707 13.9998H6.10456C6.23071 13.9998 6.3522 14.0474 6.44467 14.1333L10.3168 17.7266C10.9568 18.3205 11.9971 17.8667 11.9971 16.9936V3.00588Z"
          fill={color}
        />
      </g>
      <a.line
        x1={20}
        y1={0}
        x2={lineStyle.x}
        y2={lineStyle.y}
        strokeWidth={strokeWidth}
        stroke={strokeColor}
      />
    </SVG>
  )
}

export default MuteIcon

const SVG = styled.svg`
  pointer-events: all;
  & g {
    transform: translateX(2px) scale(0.7);
    transform-origin: center;
    transform-box: fill-box;
  }
`
