import { useEffect, useRef } from "react"
import styled from "styled-components"
import { useLoader } from "@react-three/fiber"
import * as THREE from "three"

import MobileSitesMenu from "@components/MobileSitesMenu/MobileSitesMenu"
import SitesContent from "@components/SitesContent/SitesContent"
import SitesVideo from "@components/SitesVideo/SitesVideo"
import Tours from "@components/Tours"
import SitesCopy from "@components/SitesCopy/SitesCopy"

import verify from "@utils/verify"
import globalStyles from "@data/globalStyles"
import { useStore } from "@state/store"
import data from "@data/sitesData"

function MobileSites() {
  const activeSite = useStore((s) => s.activeSite)

  const base = "/assets/textures"
  const paths = [
    `${base}/timber.webp`,
    `${base}/timber_int.webp`,
    `${base}/liberty.webp`,
    `${base}/bridge_plaza.webp`,
    `${base}/bridge.webp`,
    `${base}/lion_court.webp`,
    `${base}/lion_terrace.webp`,
  ]

  const loaded = paths.map((path) => {
    const texture = useLoader(THREE.TextureLoader, path)
    texture.minFilter = THREE.LinearFilter
    texture.wrapS = THREE.RepeatWrapping
    texture.repeat.x = 1
    texture.flipX = true

    return texture
  })
  return (
    <SitesWrapper>
      <SitesContent />
      <MobileSitesMenu />
      {activeSite !== null && (
        <>
          <SitesVideo />
          <Tours loaded={loaded} /> 
          <SitesCopy />
        </>
      )}
    </SitesWrapper>
  )
}

export default MobileSites

const SitesWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: ${globalStyles.colors.lightBlack};
`
