import { useState, useEffect } from "react"
import styled from "styled-components"

import SitesHeader from "@components/SitesHeader/SitesHeader"
import MobileSitesHeader from "@components/MobileSitesHeader/MobileSitesHeader"
import SitesGallery from "@components/SitesGallery/SitesGallery"
import PlayIcon from "@components/SVG/PlayIcon"
import ArrowIcon from "@components/SVG/ArrowIcon"

import { useStore } from "@state/store"
import globalStyles from "@data/globalStyles"
import data from "@data/sitesData"

function SitesContent() {
  const isMobile = useStore((s) => s.isMobile)
  const setSiteTourVisible = useStore((s) => s.setSiteTourVisible)
  const setSiteVideoVisible = useStore((s) => s.setSiteVideoVisible)
  const [visibleSection, setVisibleSection] = useState(0)
  const [imgIndex, setImgIndex] = useState(0)
  const activeSite = useStore((s) => s.activeSite)

  function handleImgClick(dir) {
    const maxIndex = data[activeSite].gallery.length - 1
    if (dir) {
      setImgIndex(imgIndex === maxIndex ? 0 : imgIndex + 1)
    } else {
      setImgIndex(imgIndex === 0 ? maxIndex : imgIndex - 1)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setVisibleSection(activeSite)
    }, 800)
  }, [activeSite])

  return (
    <SitesContentWrapper>
      {activeSite === null && (
        <Section
          className={
            activeSite === null || visibleSection === null ? "display" : "rest"
          }
          style={{
            color: "white",
            display: "grid",
            placeContent: "center",
            fontSize: "6rem",
          }}
        >
          <img
            src={"/assets/images/aerial.webp"}
            className="aerial"
            alt=""
            style={{
              width: isMobile ? "initial" : "auto",
              height: isMobile ? "initial" : "95%",
              maxWidth: isMobile ? "95%" : "90%",
              maxHeight: isMobile ? "auto" : "95%",
            }}
          />
        </Section>
      )}
      {data.map((obj, i) => {
        return (
          (visibleSection === i || activeSite === i) && (
            <Section
              key={`sec${i}`}
              className={activeSite === i ? "display" : "rest"}
            >
              <Background>
                <SitesGallery index={imgIndex} setIndex={setImgIndex} />
              </Background>
              {isMobile ? (
                <MobileSitesHeader
                  data={{ name: obj.name, ...obj.headerData }}
                />
              ) : (
                // <MobileSitesHeader data={{name: obj.name, ...obj.headerData}}/>
                <SitesHeader data={{ name: obj.name, ...obj.headerData }} />
              )}
            </Section>
          )
        )
      })}
      {activeSite !== null && !isMobile && (
        <Buttons>
          <button
            onClick={() => {
              handleImgClick(false)
            }}
          >
            <ArrowIcon
              dir="left"
              strokeColor={globalStyles.colors.blue}
              bgColor="transparent"
              strokeWidth={7}
            />
          </button>
          <button
            onClick={() => {
              setSiteVideoVisible(true)
            }}
          >
            <PlayIcon
              bgColor="transparent"
              color={globalStyles.colors.blue}
              strokeColor="transparent"
              strokeWidth={5}
            />
          </button>
          {activeSite !== null && activeSite !== 1 && (
            <button
              onClick={() => {
                setSiteTourVisible(true)
              }}
              style={{
                display: "grid",
                placeContent: "center",
                color: globalStyles.colors.blue,
              }}
            >
              <p>
                360<sup>o</sup>
              </p>
            </button>
          )}
          <button
            onClick={() => {
              handleImgClick(true)
            }}
          >
            <ArrowIcon
              dir="right"
              strokeColor={globalStyles.colors.blue}
              bgColor="transparent"
              strokeWidth={7}
            />
          </button>
        </Buttons>
      )}
    </SitesContentWrapper>
  )
}

export default SitesContent

const SitesContentWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 88%;
  background-color: ${globalStyles.colors.lightBlack};

  .display {
    animation: fadeIn 0.8s ease-in-out;
    opacity: 1;
  }

  .rest {
    animation: fadeOut 0.8s ease-in-out;
    opacity: 0;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      /* transform: translateX(-100%); */
    }
    to {
      opacity: 1;
      /* transform: translateX(0%); */
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
      /* transform: translateX(0%); */
    }
    to {
      opacity: 0;
      /* transform: translateX(100%); */
    }
  }
`

const Section = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  .aerial {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    object-fit: cover;
    cursor: grab;
  }
`

const Background = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
`

const Buttons = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 2%;
  width: 25%;
  height: 8%;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 5%;

  & button {
    height: 100%;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    background-color: ${globalStyles.colors.black}66;
    border-radius: 100%;
    aspect-ratio: 1/1;
    display: grid;

    & svg {
      width: 100%;
      height: 100%;
      max-width: 100%;
    }
  }
`
