import { useState, useEffect, useRef } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import styled from "styled-components"

import { useStore } from "@state/store"
import globalStyles from "@data/globalStyles"
import routes from "../../../routes/routes"
import verify from "@utils/verify"

function NavMenu({ open, setOpen }) {
  const setTokenIsValid = useStore(s =>s.setTokenIsValid)
  const isMobile = useStore(s =>s.isMobile)
  const [mounted, setMounted] = useState(true)
  const menuRef = useRef<HTMLDivElement>()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  function handleNav(path){
    verify(
      () => { navigate(path) },
      () => { 
        setTokenIsValid(false)
        navigate("/login") 
      }
    )
  }

  useEffect(() => {
    if (!open && menuRef.current) {
      menuRef.current.classList.add("hidden")
      setTimeout(() => {
        setMounted(false)
      }, 800)
    } else {
      setMounted(true)
    }
  }, [open])
  return (
    mounted && (
      <NavMenuWrapper>
        <Menu
          style={{
            opacity: pathname === "/" ? 0 : 1,
          }}
          ref={(node) => {
            if (node) {
              menuRef.current = node
              setTimeout(() => {
                node.classList.add("visible")
              }, 100)
            }
          }}
        >
          <Buttons>
            {routes.map((obj, i) => {
              return (
                obj.onNav && (
                  <Button
                    key={`navBtn${i}`}
                    style={{
                      opacity:
                        obj.name === "Seed Portfolio"
                          ? pathname.includes("/sites")
                            ? 1
                            : 0.4
                          : pathname === obj.path
                          ? 1
                          : 0.4,
                        fontSize: isMobile ? "15rem" : "5rem"
                    }}
                    onClick={() => {
                      if (pathname === obj.path) {
                        setOpen(false)
                      } else {
                        obj.name === "Seed Portfolio"
                          ? handleNav(`sites`)
                          : handleNav(`${obj.path}`)
                      }
                    }}
                  >
                    {obj.name}
                  </Button>
                )
              )
            })}
          </Buttons>
        </Menu>
      </NavMenuWrapper>
    )
  )
}

export default NavMenu

const NavMenuWrapper = styled.div`
  .visible {
    transform: translate(-50%, -50%);
  }
  .hidden {
    transform: translate(100%, -100%);
  }
`

const Menu = styled.div`
  position: absolute;
  z-index: 10;
  width: 250%;
  aspect-ratio: 1/1;
  top: 50%;
  left: 50%;
  transform: translate(100%, -100%);
  transition: transform ease-in-out 1s, opacity ease-in-out 0.3s;
  background: linear-gradient(
    45deg,
    ${globalStyles.colors.lightBlack},
    ${globalStyles.colors.lightBlack} 40%,
    ${globalStyles.colors.black} 40%,
    ${globalStyles.colors.black}
  );
  border: 1rem solid ${globalStyles.colors.white}66;
  border-radius: 0 0 0 100%;

  @media (max-width: 800px) {
    width: 500%;
  }
`

const Buttons = styled.div`
  width: 50%;
  height: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5%;
`
const Button = styled.button`
  background: none;
  border: none;
  color: ${globalStyles.colors.blue};
  font-size: 5rem;
  font-family: main-bold;
  transition: opacity 0.5s ease-in-out;
  cursor: pointer;
`
