import { Fragment, useRef, useEffect } from "react"
import styled from "styled-components"

import EyeIcon from "@components/SVG/EyeIcon/EyeIcon"
import PlayIcon from "@components/SVG/PlayIcon/PlayIcon"
import CloseIcon from "@components/SVG/CloseIcon/CloseIcon"

import globalStyles from "@data/globalStyles"
import { useStore } from "@state/store"

function MobileSitesHeader({ data }) {
  const activeSite = useStore((s) => s.activeSite)
  const menuOpen = useStore((s) => s.menuOpen)
  const setSiteVideoVisible = useStore((s) => s.setSiteVideoVisible)
  const setMenuOpen = useStore((s) => s.setMenuOpen)
  const setSiteTourVisible = useStore((s) => s.setSiteTourVisible)

  const wrapperRef = useRef<HTMLDivElement>()
  const buttonRef = useRef<SVGElement>()

  const btnProps = {
    strokeWidth: 4,
    strokeColor: globalStyles.colors.white,
    color: "none",
    bgColor: "none",
  }

  const buttons = [
    // {
    //   Element: CloseIcon,
    //   props: {
    //     ...btnProps,
    //     className: "plus",
    //   },
    //   onClick: () => {
    //     setSiteCopyVisible(true)
    //     menutToggle()
    //   },
    // },
    {
      Element: PlayIcon,
      props: btnProps,
      onClick: () => {
        setSiteVideoVisible(true)
        setMenuOpen(!menuOpen)
      },
    },
  ]

  return (
    <MobileSitesHeaderWrapper
      ref={wrapperRef}
      style={{
        transform: menuOpen ? "translateX(0%)" : "translateX(100%)",
      }}
    >
      <Opener
        onClick={() => {
          setMenuOpen(!menuOpen)
        }}
      >
        <button>
          <CloseIcon
            strokeWidth={4}
            strokeColor={globalStyles.colors.white}
            color={globalStyles.colors.white}
            bgColor="none"
            id="openerBtn"
            className="plus"
            style={{
              transform: menuOpen ? "rotate(0deg)" : "rotate(45deg)",
            }}
          />
        </button>
      </Opener>
      <Content>
        <Title>
          {data.name.map((line, i) => {
            return (
              <Fragment key={`ln${i}`}>
                {line} <br />
              </Fragment>
            )
          })}
        </Title>
        <Data>
          <DataLine>
            <p>{data.description}</p>
          </DataLine>
          <DataLine>
            <h6>
              <span>{data.sqf}</span>
              <br />
              SQFT
            </h6>
            <h6>
              <span>{data.status}</span>
              <br />
              STATUS
            </h6>
            <h6>
              <span>{data.gdv}</span>
              <br />
              GDV AT STABILISATION
            </h6>
          </DataLine>
          <DataLine>
            {data.certifications.map((obj, i) => {
              return (
                <Certification key={`cert${i}`}>
                  <img alt="" src={obj.src} />
                  <h3>{obj.caption}</h3>
                </Certification>
              )
            })}
          </DataLine>
        </Data>
        <Buttons>
          {buttons.map((obj, i) => {
            return (
              <button key={`btn${i}`} onClick={obj.onClick}>
                <obj.Element {...obj.props} />
              </button>
            )
          })}
          {activeSite !== null && activeSite !== 1 && (
            <button
              onClick={() => {
                console.log("clicked")
                setSiteTourVisible(true)
                setMenuOpen(!menuOpen)
              }}
              style={{
                display: "grid",
                placeContent: "center",
                color: globalStyles.colors.white,
              }}
            >
              <p>
                360<sup>o</sup>
              </p>
            </button>
          )}
        </Buttons>
      </Content>
    </MobileSitesHeaderWrapper>
  )
}

export default MobileSitesHeader

const MobileSitesHeaderWrapper = styled.div`
  position: absolute;
  bottom: 3%;
  right: 0;
  z-index: 4;
  width: 80%;
  height: 40%;
  transform: translate(100%, 0%);
  transition: transform 0.8s cubic-bezier(0.65, 0.04, 0.38, 0.93);
  background-color: ${globalStyles.colors.lightBlack};
  color: ${globalStyles.colors.white};
  border: 0.2vh solid ${globalStyles.colors.blue};
  border-left: none;
  border-right: none;

  .plus {
    transform-origin: center;
    transform: rotate(45deg);
  }
`
const Opener = styled.div`
  position: absolute;
  z-index: 1;
  width: 15%;
  height: 101%;
  left: -15%;
  top: -0.5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(83, 214, 197, 0.3);
  backdrop-filter: blur(1.2rem);
  border-radius: 12rem 0 0 12rem;
  cursor: pointer;

  & button {
    width: 95%;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;

    & svg {
      transition: transform 0.8s cubic-bezier(0.58, -0.28, 0.38, 1.34);
    }
  }

  @media (min-width: 700px) {
    width: 10%;
    left: -9.8%;
  }
`

const Content = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2%;
  padding: 2% 0%;
  font-size: 12rem;
`

const Title = styled.div`
  text-align: center;
  display: grid;
  place-content: center;
  font-size: 1.2em;
  line-height: 1.2em;
  letter-spacing: 0.5em;
  font-weight: 100;
  flex: 0.2;
  max-height: 20%;
`

const Data = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0.6;
  max-height: 60%;
  gap: 15rem;
`

const DataLine = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10%;
  padding: 0 5%;

  & h6 {
    font-size: 0.4em;
    font-weight: 100;
    text-align: center;
    line-height: 1.5em;

    & span {
      font-size: 1.5em;
      color: ${globalStyles.colors.blue};
    }
  }
  & p {
    text-align: center;
    font-size: 0.7em;
    margin-bottom: 2rem;
  }
`

const Certification = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;

  & img {
    width: 25rem;
    max-width: 25rem;
    height: 25rem;
    height: 25rem;
  }

  & h3 {
    width: 100%;
    font-size: 0.5em;
    font-weight: 100;
    text-align: center;
    line-height: 1em;
    color: ${globalStyles.colors.blue};
  }
`

const Buttons = styled.div`
  position: relative;
  width: 100%;
  flex: 0.2;
  max-height: 20%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 5%;

  & button {
    width: 15%;
    height: 100%;
    max-width: 80px;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;

    & svg {
      max-width: 95%;
    }
  }
`
