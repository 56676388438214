import styled from "styled-components"

import globalStyles from "@data/globalStyles"
import { useStore } from "@state/store"

function Labels({ cue }) {
  const zoomed = useStore((s) => s.mapZoomed)
  const setZoomed = useStore((s) => s.setMapZoomed)

  const labelsArr = [
    {
      label: "WEST END",
      color: globalStyles.colors.lightBlack,
      pos: [500, 860],
    },
    {
      label: "MIDTOWN",
      color: globalStyles.colors.lightBlack,
      pos: [925, 320],
    },
    {
      label: "CITY",
      color: globalStyles.colors.lightBlack,
      pos: [1500, 500],
    },
    {
      label: "SOUTHBANK",
      color: globalStyles.colors.white,
      pos: [1170, 645],
    },
  ]

  function handleClick() {
    if (!zoomed) {
      setZoomed(true)
    }
  }
  return (
    <LabelsWrapper
      style={{
        opacity: cue ? 0 : 1,
        transitionDelay: cue ? "0s" : "0.8s",
      }}
    >
      {labelsArr.map((obj, i) => {
        return obj.label === "SOUTHBANK" ? (
          <g
            key={`label${i}`}
            style={{
              cursor: cue ? "initial" : "pointer",
            }}
          >
            <g
              style={{
                transformBox: "fill-box",
                transformOrigin: "center",
                transform: "scale(6.5) translate(-15%, -30%)",
              }}
            >
              <polygon
                fill={globalStyles.colors.lightBlack}
                points="1349.98,749.57 1345.61,753.93 1341.25,749.57 1341.25,749.57 1332.52,749.57 1345.62,762.67 
			1358.71,749.57 1349.98,749.57 		"
                onClick={handleClick}
              />
              <polygon
                fill={globalStyles.colors.lightBlack}
                points="1347.07,746.18 1345.61,747.63 1344.16,746.18 1344.16,746.18 1341.25,746.18 1345.61,750.54 
			1349.98,746.18 1347.07,746.18 		"
                onClick={handleClick}
              />
              <rect
                x="1319.44"
                y="735.3"
                fill={globalStyles.colors.lightBlack}
                width="52.35"
                height="11.18"
                onClick={handleClick}
              />
              <ellipse
                fill={globalStyles.colors.lightBlack}
                opacity={0}
                cx="1345.61"
                cy="764.75"
                rx="2.07"
                ry="0.51"
              />
            </g>
            <text
              key={`label${i}`}
              x={obj.pos[0]}
              y={obj.pos[1]}
              fill={obj.color}
              onClick={handleClick}
              style={{
                fontFamily: "main-bold",
                fontSize: 40,
              }}
            >
              {obj.label}
            </text>
          </g>
        ) : (
          <text
            key={`label${i}`}
            x={obj.pos[0]}
            y={obj.pos[1]}
            fill={obj.color}
            style={{
              fontFamily: "main-bold",
              fontSize: 40,
            }}
          >
            {obj.label}
          </text>
        )
      })}
    </LabelsWrapper>
  )
}

export default Labels

const LabelsWrapper = styled.g`
  & text {
    pointer-events: none;
  }
`
