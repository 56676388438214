import styled from "styled-components"

import TSVGProps from "../SVGTypes"

type TLogoProps = TSVGProps & {
  iconColor?: string
  textColor?: string
  hasText?: boolean,
  [x: string]: any
}

function Logo({
  iconColor = "#FFFFFF",
  textColor = "#FFFFFF",
  hasText = true,
  ...props
}: TLogoProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="100%"
      y="100%"
      viewBox="0 0 128 128"
      {...props}
    >
      <g>
        <polygon
          fill={iconColor}
          points="71.83,63.67 42.47,63.67 42.47,34.31 42.47,34.31 13.11,4.94 13.11,93.04 101.19,93.03 
			71.83,63.66 		"
        />
      </g>
      {hasText && (
        <g >
          <path
            fill={textColor}
            d="M13.11,102.99h3.75v16.1h8.19v3.33H13.11V102.99z"
          />
          <path
            fill={textColor}
            d="M40.31,122.42h-3.47v-1.08c-1.11,0.94-2.39,1.36-3.75,1.36c-2,0-3.89-0.89-5.08-2.28
			c-1.06-1.22-1.72-2.89-1.72-4.72c0-3.97,2.94-7.02,6.61-7.02c1.53,0,2.89,0.5,3.94,1.36v-1.08h3.47V122.42z M36.71,113.67
			c-0.75-1-1.94-1.67-3.33-1.67c-2.11,0-3.47,1.75-3.47,3.69c0,2.11,1.53,3.66,3.55,3.66c1.36,0,2.55-0.67,3.25-1.67V113.67z"
          />
          <path
            fill={textColor}
            d="M42.92,122.42v-13.46h3.47v1.08c1.03-0.83,2.3-1.36,3.8-1.36c1.86,0,3.55,0.83,4.66,2.17
			c0.89,1.05,1.39,2.42,1.39,4.72v6.86h-3.61v-7.13c0-1.33-0.31-2.03-0.81-2.53c-0.47-0.47-1.19-0.75-2.03-0.75
			c-1.53,0-2.78,0.97-3.28,1.67v8.74H42.92z"
          />
          <path
            fill={textColor}
            d="M68.82,122.42v-1.08c-1.11,0.94-2.39,1.36-3.75,1.36c-2,0-3.89-0.89-5.08-2.28
			c-1.05-1.22-1.72-2.89-1.72-4.72c0-3.97,2.94-7.02,6.61-7.02c1.47,0,2.75,0.42,3.8,1.25v-6.94h3.61v19.43H68.82z M68.69,113.67
			c-0.75-1-1.94-1.67-3.33-1.67c-2.11,0-3.47,1.75-3.47,3.69c0,2.11,1.53,3.66,3.55,3.66c1.36,0,2.55-0.67,3.25-1.67V113.67z"
          />
          <path
            fill={textColor}
            d="M84.82,118.48c0,2.64-2.39,4.22-5.14,4.22c-2.39,0-4.3-0.72-5.69-2.22l2.08-2.08
			c0.89,0.83,1.97,1.3,3.41,1.3c0.97,0,1.72-0.53,1.72-1.19c0-2.03-6.52-1.3-6.52-5.64c0-2.58,2.5-4.19,5.14-4.19
			c2.22,0,3.97,0.94,4.86,1.94l-2.08,2.08c-0.64-0.61-1.5-1.03-2.64-1.03c-0.83,0-1.67,0.36-1.67,1.05
			C78.29,114.4,84.82,113.76,84.82,118.48z"
          />
          <path
            fill={textColor}
            d="M99.03,120.25c-1.31,1.58-3.39,2.44-5.55,2.44c-3.97,0-6.94-2.8-6.94-7c0-3.75,2.47-7.02,6.55-7.02
			c3.86,0,6.36,3.14,6.36,6.88c0,0.36-0.03,0.72-0.08,1.03h-9.36c0.19,1.89,1.75,3.05,3.66,3.05c1.53,0,2.64-0.75,3.3-1.44
			L99.03,120.25z M95.84,114.09c-0.11-1.28-1.14-2.44-2.75-2.44c-1.64,0-2.8,1.14-2.97,2.44H95.84z"
          />
          <path
            fill={textColor}
            d="M114.38,119.78c-1.53,1.92-3.47,2.91-5.91,2.91c-4.33,0-7.27-3.16-7.27-7c0-3.86,3.08-7.02,7.13-7.02
			c2.22,0,4.16,0.92,5.64,2.69l-2.22,2.22c-0.89-0.92-1.83-1.58-3.28-1.58c-1.94,0-3.66,1.5-3.66,3.69c0,2.17,1.58,3.66,3.8,3.66
			c1.8,0,2.89-1.06,3.55-1.8L114.38,119.78z"
          />
        </g>
      )}
    </svg>
  )
}

export default Logo

const SVG = styled.svg``
