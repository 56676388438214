import { useState, Fragment, useEffect, useRef } from "react"
import styled from "styled-components"
import { useSpring, a } from "@react-spring/web"

type TWrapperProps = {
  color?: string
  width?: string
  [x: string]: any
}

type TIconProps =
  | (TWrapperProps & {
      onClick?: () => void
      isAnimted?: undefined | false
    })
  | (TWrapperProps & {
      onClick?: () => void
      onClickDelay?: number
      isAnimted?: true
      cue: boolean
    })

function BurgerIcon({
  color = "white",
  width = "1vw",
  onClick,
  onClickDelay = 500,
  isAnimated = false,
  cue,
  ...props
}: TIconProps) {
  const animDir = useRef(false)

  function handleClick() {
    if (onClick) {
      setTimeout(() => {
        onClick()
      }, onClickDelay)
    }
  }

  const top = {
    init: "translate(-50%, -400%) rotate(0deg)",
    mid: "translate(-50%, -50%) rotate(0deg)",
    final: "translate(-50%, -50%) rotate(45deg)",
  }

  const [topBar, topBarAPI] = useSpring(() => ({
    transform: top.init,
  }))

  const middle = {
    init: "translate(-50%, -50%)",
    final: "translate(100%, -50%)",
  }

  const [middleBar, middleBarAPI] = useSpring(() => ({
    transform: middle.init,
  }))

  const bottom = {
    init: "translate(-50%, 350%) rotate(0deg)",
    mid: "translate(-50%, -50%) rotate(0deg)",
    final: "translate(-50%, -50%) rotate(-45deg)",
  }

  const [bottomBar, bottomBarAPI] = useSpring(() => ({
    transform: bottom.init,
  }))

  function animate() {
    topBarAPI.start({
      from: {
        transform: animDir.current ? top.init : top.final,
      },
      to: [
        {
          transform: top.mid,
        },
        {
          transform: animDir.current ? top.final : top.init,
        },
      ],
    })
    middleBarAPI.start({
      from: {
        transform: animDir.current ? middle.init : middle.final,
      },
      to: {
        transform: animDir.current ? middle.final : middle.init,
      },
    })
    bottomBarAPI.start({
      from: {
        transform: animDir.current ? bottom.init : bottom.final,
      },
      to: [
        {
          transform: bottom.mid,
        },
        {
          transform: animDir.current ? bottom.final : bottom.init,
        },
      ],
    })
    animDir.current = !animDir.current
  }
  useEffect(() => {
    isAnimated && animate()
  }, [cue])

  return (
    <BurgerWrapper onClick={handleClick} color={color} width={width} {...props}>
      {isAnimated ? (
        <Fragment>
          <a.hr style={topBar} />
          <a.hr style={middleBar} />
          <a.hr style={bottomBar} />
        </Fragment>
      ) : (
        <Fragment>
          <a.hr />
          <a.hr />
          <a.hr />
        </Fragment>
      )}
    </BurgerWrapper>
  )
}

export default BurgerIcon

const BurgerWrapper = styled.div<TWrapperProps>`
  position: fixed;
  z-index: 10;
  cursor: pointer;
  width: ${(p) => p.width};
  aspect-ratio: 1/1;
  gap: 10%;
  overflow: hidden;
  mix-blend-mode: exclusion;

  & hr {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 5%;
    border: none;
    outline: none;
    border-radius: 50px;
    background-color: ${(p) => p.color};
    transform-origin: center;

    :nth-of-type(2) {
      width: 60%;
    }
  }
`
