import styled from "styled-components"

function Roads({ cue }) {
  return (
    <RoadsWrapper style={{
			opacity: cue ? 0 : 0.2
		}}>
      <g>
        <defs>
          <rect id="SVGID_1_" width="1920" height="1080" />
        </defs>
        <clipPath id="SVGID_00000103234970948660896670000015254258010368308634_">
          <use xlinkHref="#SVGID_1_" overflow="visible" />
        </clipPath>
        <g clipPath="url(#SVGID_00000103234970948660896670000015254258010368308634_)">
          <path
            fill="none"
            stroke="#fefefe50"
            strokeWidth="4"
            strokeMiterlimit="10"
            d="M978.2,841.36c-0.38,16.28-7.27,40.37-12.73,57.85
			s-36.82,84.98-36.82,84.98"
          />
          <line
            fill="none"
            stroke="#fefefe50"
            strokeWidth="4"
            strokeMiterlimit="10"
            x1="900.78"
            y1="854"
            x2="957.43"
            y2="838.86"
          />
          <g>
            <path
              fill="#fefefe50"
              d="M930.46,986.24c-4.98,9.91-9.96,19.79-17.09,28.43c-18.18,19.92-46.26,24.26-71.86,23.58
				c-18.27-0.19-36.51-1.3-54.62-3.78c-18.74-2.99-37.47-6.66-56.43-7.38c-18.81-0.22-38.08-1.69-56.66,1.65
				c-8.93,1.51-18.08,2.68-26.92,4.17c-49.82,9.48-97.95,26.7-145.12,45.02c-13.59,4.97-27.55,9.02-40.54,15.33
				c-25.8,12.75-48.08,32.14-65.08,55.27c-2.75,3.5-5.24,7.24-7.59,11.03c0,0-0.7,1.15-0.7,1.15c-1.43,2.87-4.97,4.52-8.07,3.54
				c-1.12-0.29-2.12-1.01-3-1.67c-8.19-6.08-15.83-13.08-21.52-21.64c-4.07-6.31-23.07-40.76-26.97-47.34
				c-9.09-12.73-17.96-25.8-28.25-37.58c0,0,1-0.99,1-0.99c5.54,5.69,10.32,11.94,15.21,18.15c4.83,6.24,9.58,12.53,14.26,18.89
				c-0.01,0.07,22.35,37.42,22.36,37.5c0,0,2.78,4.68,2.78,4.68c5.86,10.43,14.92,18.76,24.59,25.66c1.19,0.8,2.96,0.38,3.68-0.84
				c0.09-0.14,0.91-1.49,1.05-1.72c2.44-3.92,5-7.77,7.85-11.4c17.39-23.66,40.2-43.49,66.56-56.51
				c13.19-6.42,27.26-10.51,40.94-15.52c47.45-18.45,95.71-35.72,145.85-45.28c10.82-1.73,21.61-3.33,32.39-4.99
				c18.05-2.47,36.68-1.06,54.8-0.81c18.26,1,36.2,4.42,54.18,7.29c10.72,1.51,21.53,2.44,32.34,3.09
				c14.36,0.78,28.92,1.27,43.22,0.01c14.05-1.34,28.04-5.87,39.45-14.28c5.83-4.16,10.23-9.43,13.93-15.55
				c3.79-6.06,7.23-12.45,10.38-18.89C926.85,984.52,930.46,986.24,930.46,986.24L930.46,986.24z"
            />
          </g>
          <g>
            <path
              fill="#fefefe50"
              d="M1595,648.05c8.15,3.4,15.95,7.52,23.48,12.09c7.49,4.57,14.78,9.48,21.73,14.78
				c1.72,1.29,3.52,2.46,5.37,3.54c13.37,7.25,26.94,14.33,40.76,20.69c7.84,3.72,16.08,6.42,24.75,7.04
				c8.42,0.63,17.48-0.06,24.87-4.36c7.09-4.93,12.94-11.5,19.09-17.54c6.1-6.22,12.1-12.56,18.02-18.95c0,0,1.47,1.36,1.47,1.36
				c-5.98,6.37-12.02,12.67-18.18,18.88c-6.3,6.09-12.25,12.72-19.5,17.73c-11.79,6.55-26.33,5.6-38.93,2.19
				c-12.57-3.98-24.13-10.42-35.91-16.2c-7.72-4.15-15.83-7.66-23.01-12.76c-13.95-10.21-28.94-19.45-45.23-25.29
				C1593.78,651.26,1595,648.05,1595,648.05L1595,648.05z"
            />
          </g>
          <path
            fill="#fefefe50"
            d="M1594.39,618.11c6.05,2.95,22.68,8.54,29.27,5.39s0.72-2.23,0.72-2.23s-18.06-2.24-28.42-7.03
			C1585.6,609.45,1594.39,618.11,1594.39,618.11z"
          />
          <path
            fill="none"
            stroke="#fefefe50"
            strokeWidth="4"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M1635.15,673.13
			l-21.45,95.34l-25.52,107.01l-41.36,62.99l-54.54-54.28l-36.86-20.51l-13.29,21.67l-41.35,61.28l-16.84,25.36
			c0,0,72.12-3.11,96.37-7.42c24.26-4.31,66.5-26.09,66.5-26.09"
          />
          <path
            fill="none"
            stroke="#fefefe50"
            strokeWidth="4"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M1877.6,701.06
			c20.25-33.29,29.88-100.47,29.35-116.38"
          />
          <g>
            <path
              fill="#fefefe50"
              d="M981.82,966.15l0.06-0.09c-0.02,0.02-0.03,0.05-0.05,0.07C981.82,966.14,981.82,966.14,981.82,966.15z"
            />
            <path
              fill="#fefefe50"
              d="M659.99,1070.69l0.11-0.06c-0.01,0-0.01,0-0.02,0C660.05,1070.65,660.02,1070.67,659.99,1070.69z"
            />
            <path
              fill="#fefefe50"
              d="M979.01,972.06l0.06-0.07c-0.02,0.02-0.03,0.04-0.05,0.06C979.01,972.05,979.01,972.05,979.01,972.06z"
            />
            <path
              fill="#fefefe50"
              d="M890.3,59.77l-0.03-0.05c0,0.02,0.01,0.02,0.02,0.04C890.29,59.77,890.3,59.77,890.3,59.77z"
            />
            <path
              fill="#fefefe50"
              d="M1017.68,707.79l0.04-0.17c-0.01,0.03-0.04,0.04-0.06,0.06C1017.67,707.72,1017.67,707.75,1017.68,707.79
				z"
            />
            <path
              fill="#fefefe50"
              d="M887.37,56.1l-0.18-0.18c-0.01,0.07,0.01,0.13,0.01,0.2C887.26,56.11,887.31,56.11,887.37,56.1z"
            />
            <path
              fill="#fefefe50"
              d="M487.72,303.44c0.03,0,0.06,0,0.08,0l-0.12-0.04C487.7,303.41,487.71,303.42,487.72,303.44z"
            />
            <path
              fill="#fefefe50"
              d="M1873.71,197.82c-0.01,0.07,0.01,0.11,0.01,0.19c0.04-0.01,0.07-0.01,0.11-0.04L1873.71,197.82z"
            />
            <path
              fill="#fefefe50"
              d="M742.06,796.45c-0.01,0.03-0.01,0.07-0.01,0.11c0.04,0.01,0.09,0.04,0.14,0.06L742.06,796.45z"
            />
            <path
              fill="#fefefe50"
              d="M977.08,975.99l0.06-0.08c-0.02,0.02-0.04,0.04-0.06,0.07C977.08,975.98,977.08,975.98,977.08,975.99z"
            />
            <path
              fill="#fefefe50"
              d="M128.97,876.4c1.38,2.21,2.76,4.34,4.14,6.25c6.62,9.15,13.89,16.92,20.78,25.35
				C145.93,897.07,137.6,886.71,128.97,876.4z"
            />
          </g>
          <path
            fill="none"
            stroke="#fefefe50"
            strokeWidth="4"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M1083.88,846.16
			c0,0-5.8,36.71-14.05,60.48c-8.25,23.77-26.08,29.73-33.76,46.53c-7.68,16.81-32.27,78.45-32.27,78.45"
          />
          <path
            fill="none"
            stroke="#fefefe50"
            strokeWidth="4"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M928.15,987.31
			l84.17,51.02l18.93,10.92l52.3,30.17c5.06,3.06,7.89,8.9,6.84,14.72c-1.02,5.66,0.07,13.21,9.41,19.64
			c10.21,7.03,19.23,3.7,25-0.31c4.72-3.29,10.89-3.58,15.84-0.65l124.17,74.6c22.78,13.68,47.77,23.29,73.84,28.42
			c47.97,9.44,88.2,16.22,156.52,13.94c86.01-2.87,428.45,4.99,428.45,4.99l-376.79-296.3"
          />
          <path
            fill="none"
            stroke="#fefefe50"
            strokeWidth="2"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M968.86,886.44
			l89.71,37.26c0,0,17.13,9.7,35.76,7.09s86.8-22.21,86.8-22.21l-18.35,112.57l23.7,63.68"
          />
          <path
            fill="none"
            stroke="#fefefe50"
            strokeWidth="4"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M1013.19,1042.1
			l-28.93,52.93l-34.99-7.55c0,0-48.79,35.26-68.13,45.06c-19.35,9.79-46.16,18.73-62.11,23.2c-15.96,4.47-19.11,15.32-23.6,23.62
			c-4.49,8.3-9.29,12.13-25.72,15.96c-16.43,3.83-66.76,28.09-66.76,28.09s-58.76,10.54-83.68,22.98
			c-28.13,14.04-86.84,72.32-105.84,93.45c-19,21.13-122.89,112.09-155.2,130.28s-55.28,25.85-42.84,64.15
			c6.7,19.15,16.28,27.77,0,33.51c-16.28,5.74-69.81,28.72-117.25,26.81c-47.44-1.91-228.4-19.15-290.64-37.34
			c-62.24-18.19-196.28-76.6-196.28-76.6"
          />
          <path
            fill="none"
            stroke="#fefefe50"
            strokeWidth="4"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M696.6,1025.28
			l23.96,156.55l4.77,31.14c0,0,11.79,14.9,22.1,33.09c10.31,18.19,23.96,36.38,22.88,64.15c-1.08,27.77-22.88,125.45-22.88,125.45"
          />
          <path
            fill="none"
            stroke="#fefefe50"
            strokeWidth="4"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M1281.6,948.76
			l-115.32,70.37c0,0-44.22,17.71-56.19,22.96c-11.97,5.25-78.85,7.15-78.85,7.15l-11.09,38.31c-4.61,15.92-6.49,32.5-5.58,49.05
			l48.75,127.82l37.11,22.43l27.56,16.65"
          />
          <g>
            <path
              fill="#fefefe50"
              d="M1147.18,885.82c13.42,7.49,26.94,15.05,40.81,21.64c6.93,3.14,14.31,6.16,21.17,9.41
				c27.6,12.64,56.08,26.96,85.33,35.13c7.39,2.04,14.88,3.73,22.43,5.2l-0.75,3.93c-42.13-6.98-80.65-28.09-119.22-45.37
				c-17.93-7.49-34.71-17.36-51.26-27.41C1145.69,888.34,1147.18,885.82,1147.18,885.82L1147.18,885.82z"
            />
          </g>
          <path
            fill="none"
            stroke="#fefefe50"
            strokeWidth="4"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M1030.49,966.06
			c3.48,0.89,132.29,55.09,132.29,55.09"
          />
          <path
            fill="none"
            stroke="#fefefe50"
            strokeWidth="2"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M1280.73,955.83
			c0,0,0.33,51.05,12.99,65.32c12.66,14.26,28.45,36.67,36.83,52.76s33.44,146.74,33.44,146.74s-9.98,71.11-38.83,108.58
			c-28.84,37.48-63.31,96.36-76.24,122.21s-37.34,127.16-37.34,127.16"
          />
          <path
            fill="none"
            stroke="#fefefe50"
            strokeWidth="2"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M1341.02,1110.77
			c0,0,92.71-1.82,157.81-31.89c65.1-30.07,119.99-83.79,119.99-83.79"
          />

          <line
            fill="none"
            stroke="#fefefe50"
            strokeWidth="2"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            x1="1212.78"
            y1="1046.68"
            x2="1278.85"
            y2="1114.79"
          />
          <path
            fill="none"
            stroke="#fefefe50"
            strokeWidth="2"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M1713.1,1069.23
			l-40.64,69.06c-4.88,8.29-13.28,13.91-22.81,15.26l-17.74,2.5"
          />
          <path
            fill="none"
            stroke="#fefefe50"
            strokeWidth="2"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M1613.25,1157.49
			c0,0-9.1-56.97-8.28-83.59s26.95-68.52,26.95-68.52s68.9-56.73,91.42-66.49c22.52-9.76,114.44-51.81,114.44-51.81l-2.87,64.06"
          />
          <path
            fill="none"
            stroke="#fefefe50"
            strokeWidth="2"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M1289.52,1014.23
			c0,0,54.74-15.37,78.88-9.54c24.14,5.83,117.99,79.48,117.99,79.48"
          />
          <path
            fill="none"
            stroke="#fefefe50"
            strokeWidth="2"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M1709.58,946.67
			c0,0,40.11,12.14,55.43,11.18s39.32-1.6,64.5-5.74c25.18-4.15,59.53-15.3,59.53-15.3"
          />
          <path
            fill="none"
            stroke="#fefefe50"
            strokeWidth="2"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M1882.14,938.47
			c0.57,0.32-4.78,56.16,1.92,64.9c6.7,8.74,12.13,11.94,21.38,11.3c9.26-0.64,51.1-1.29,51.1-1.29"
          />
          <path
            fill="none"
            stroke="#fefefe50"
            strokeWidth="2"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M1881.56,995.09
			c1.91,15.96,9.19,40.32,14.62,70.96c5.43,30.64,25,166.81,25,166.81"
          />
          <path
            fill="none"
            stroke="#fefefe50"
            strokeWidth="2"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M293.68,1290.34
			c0,0-44.35,22.74-75.47,34.62c-31.12,11.88-91.92,14.45-111.55,16.77c-19.63,2.33-86.26-16.77-86.26-16.77
			s-15.87-24.33-26.09-82.42c-10.21-58.09-20.43-133.07-33.19-171.86s-26.81-53.54-36.38-77.44s-17.97-38.9-8.03-77.04"
          />
          <path
            fill="none"
            stroke="#fefefe50"
            strokeWidth="4.2007"
            strokeMiterlimit="10"
            d="M-270.59,923.86
			c50.27,2.87,88.57,8.27,123.99,21.37s79.47,27.94,119.68,26.51s130.92-17.71,130.92-17.71"
          />
          <g>
            <path
              fill="#fefefe50"
              d="M-311.29,829.06c30.96-0.48,62.26-6.48,90.14-20.22c1.92-0.94,13.6-6.42,15.83-7.47
				c64.43-30.04,236.97-110,303.59-140.87c0,0,1.1,2.35,1.1,2.35c-67.39,31.6-237.97,111.59-302.93,142.24
				c-4.09,1.94-14.9,7.08-18.85,9.04c-27.7,12.91-58.38,18.83-88.84,19.12C-311.27,833.26-311.29,829.06-311.29,829.06
				L-311.29,829.06z"
            />
          </g>
          <path
            fill="none"
            stroke="#fefefe50"
            strokeWidth="4.2007"
            strokeMiterlimit="10"
            d="M-209.79,1127.33
			c0,0,68.94-200.68,82.34-207.58c13.4-6.9,20.33-8.81,44.16-3.55S4.79,911.72,27,898.88s80.81-48.25,80.81-48.25"
          />
          <path
            fill="none"
            stroke="#fefefe50"
            strokeWidth="2"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M-342.88,658.13
			c0,0,135.2,10.06,185.37,18.45S8.59,701.73,8.59,701.73s32.48,17.71,41.57,32.08c9.1,14.36,70.14,105.44,70.14,105.44"
          />
          <g>
            <path
              fill="#fefefe50"
              d="M-40.93,1062.51c10.81,5.64,22.16,10.29,33.79,13.99c11.58,3.62,23.57,6.56,35.72,6.99
				c24.45,0,48.94-2.21,73.3-4.26c0,0,0.24,2.84,0.24,2.84c-6.12,0.45-12.24,0.85-18.37,1.24c-18.4,1.11-36.77,2.46-55.22,2.18
				c-12.4-0.46-24.48-3.45-36.25-7.15c-11.72-3.79-23.16-8.43-34.13-14.06C-41.86,1064.28-40.93,1062.51-40.93,1062.51
				L-40.93,1062.51z"
            />
          </g>
          <path
            fill="#fefefe50"
            d="M2789.94,648.27l-141.96-5.74l-103.57-9.57c-6.33-0.95-23.71-4.93-29.38-12.02
			c-5.66-7.08-14.51-29.93-20.97-46.62c-1.88-4.87-3.66-9.46-5.1-13c-5-12.24-17.72-40.54-22.96-52.13c-0.1-0.69-0.2-1.39-0.34-2.05
			c-0.34-1.71-0.32-3.35,1.78-3.86c3.06-0.76,3.86-7.01,3.86-7.01s-2.71,0.65-5.83,0.85c0.75-2.99,0.65-5.16,6.08-8.98
			c-1.91-0.17-7.01-0.33-8.98-0.09c-5.08,0.65-4.84,2.18-6.98-4.94c-0.62-2.76-1.82-5.1-3.3-7.28c-2.5-3.69-5.82-6.76-9.57-9.16
			c-6.52-4.15-12.32-9.03-14.58-17.55c-1.61-4.38-2.86-8.86-3.16-13.57c-2.31-6.42-2.11-13.21-2.36-19.86
			c-0.39-11.43-3.27-22.65-1.51-34.3c4.94-32.65,5.03-65.58,4.24-98.5c-0.29-11.45,0.85-22.79,1.26-34.18
			c0.1-2.92-1.55-5.18-1.88-7.97c-0.33-2.77,0.14-4.64,2.08-6.51c6.25-5.96,12.24-12.22,18.58-18.07c6.55-6.02,10-13.56,11.79-22.3
			c3.13-15.22,6.52-30.38,9.84-45.56c0.23-1.03,0.9-1.98,1.36-2.93c1.48-2.99,2.44-5.97-1.34-8.59c-6.64-4.58-13.54-8.8-18.15-16.1
			c-1.92-3.06-3.16-6.33-4.71-9.51c-0.55-1.13-0.59-2.23,0.7-3.4c8.59-7.84,11.43-8.27,21.83-2.86c0.65-0.14,1.26-0.22,1.71,0.45
			c1.87-1.59,0.72-3.72,0.89-5.62c-2.71-0.1-5.33-0.78-7.7-2.03c-4.71-2.48-8.69-1.18-12.57,1.94c-7.6,6.12-7.84,6.16-10.77-3.33
			c-2.83-9.16-8.01-16.79-13.37-24.37c-5.95-8.26-12.41-16.03-20.15-22.55c-1.38-1.16-2.26-2.79-2.27-4.68
			c-0.07-4.29-1.68-7.94-4.04-11.3c-0.76-1.09-1.82-2.21-0.29-3.29c8.6-6.02,15.54-14.95,26.7-16.55
			c9.32-1.32,18.61-2.86,27.93-4.25c4.21-0.62,8.23-1.75,12.18-3.5c3.75-1.65,7.41,1.05,5.63-3.73c-11.68,8.73-27.6,2.01-40.59,5.23
			c-3.96,0.99-8.04,1.46-11.98,2.51c-2.15,0.57-2.67-0.26-3.23-2.13c-1.74-5.9-0.75-11.36,2.18-16.52
			c2.11-3.69,3.59-7.63,4.51-11.79c0.06-1.16,0.13-2.33,0.19-3.48c-0.3-0.69-0.6-1.38-0.91-2.05c-1.09-9.11-0.06-18.24-0.42-27.35
			c-0.1-2.38,0.62-4.91-1.01-7.07c-2.24,4.6-1.54,9.59-1.65,14.46c-0.2,8.32,0.46,16.7-2.21,24.77c-0.52,1.55-0.55,4.34-2.97,3.37
			c-10.67-4.25-19.04,2.37-27.98,6.16c-6.26,2.66-6.18,2.89-10.1-2.8c-1.09-1.58-2.17-3.2-3.36-4.71c-2.67-3.33-4.71-5.9-0.33-10.25
			c3.92-3.86,2.14-9.81,0.03-14.29c-3.92-8.26-6.45-16.96-9.01-25.69h-4.71c3.35,9.52,5.96,19.3,10.24,28.49
			c1.85,3.99,2.48,8.53-2.4,11.62c-3.13,1.98-2.74,4.78-0.73,7.58c2.71,3.81,5.34,7.7,8.1,11.48c1.58,2.15,1.85,3.42-1.22,4.35
			c-3.6,1.09-7.17,2.48-10.51,4.25c-10.14,5.33-13.54,14.33-11.91,27.22c1.46,11.66,6.91,21.3,12.57,30.91
			c1.97,3.35,1.81,5.7-0.36,8.8c-15.64,22.4-32.87,43.46-49.85,64.74c-6.41,8.04-12.12,16.7-18.18,25.06
			c-0.46,0.63-1.11,1.12-1.67,1.67l-0.03-0.17c-2.51,6.35-8.36,10.07-11.53,15.9c-1.25,1.84-2.56,3.6-4.38,4.9
			c-6.36,8.07-12.11,16.82-19.25,24.07c-10.04,10.24-19.93,20.8-31.8,28.9c-1.69,1.15-3.6,1.94-5.4,2.9
			c-4.24,1.81-7.44,0.06-10.9-2.61c-10.4-8.04-22.42-13.53-32.5-22.1c-0.36-0.3-0.62-0.7-0.93-1.06
			c-20.88-21.87-42.68-42.73-65.18-62.78c-15.64-13.93-18.5-32.69-20.31-52.45c-2.1-22.82-5.26-45.54-11.26-67.69
			c-0.93-3.43-2.23-6.95-4.14-9.87c-10.47-15.88-18.34-33.58-30.69-48.13c-2-2.34-2.74-4.25-2.08-7.71
			c0.82-4.19-0.14-8.78-0.39-13.18c-0.3-5.37,2.08-11.25,4.96-17.11l-5.39,0.78c-4.14,8.72-2.01,19.5-3.98,28.67
			c-13-8.93-17.01-17.51-14.51-27.72l-4.61,0.18c-0.39,11.43,3.94,20.82,13.42,27.54c-5.5,1.01-10.83,3.39-15.58-1.77
			c-1.51-1.62-3.59-4.27-4.72-6.2c-4.24-7.25-6.6-10.97-4.31-19.29l-3.98-0.52c-1.95,2.83-0.9,6.25-1.34,9.74
			c-0.65,5.06-3.66,8.92-6.12,13.07c-0.73,1.24-1.98,1.36-3.3,1.38c-6.69,0.1-10.56-2.71-13.63-9.21c-1.51-3.19-3.83-5.95-5.72-8.95
			c-1.16-1.87-2.65-4.54-4.57-8.31l-3.83,1.56c2.51,6.07,5.33,10.77,8.64,14.59c1.16,1.34,1.71,3.06,2.28,4.81
			c0.83,2.57,0.56,3.45-2.24,3.39c-14.62-0.23-29.28,0.27-43.86-0.65c-11.78-0.75-23.12,0.2-34.43,3.56
			c-8.46,2.5-17.22,0.42-25.81,1.24c-1.19,0.12-1.88-0.52-1.9-1.61c-0.17-8.62-5.9-15.05-8.13-22.85c-0.11-0.43,0.01-0.93,0.03-1.41
			h-4.71c-0.44,2.2,1.19,3.63,1.85,5.41c2.74,7.6,6.55,14.66,7.77,22.99c0.76,5.21,4.74,9.39,9.06,12.7
			c7.96,6.08,15.8,12.32,24.4,19.06c-9.36,3.76-18.07,7.28-26.79,10.77c-10.97,4.41-21.95,8.8-32.92,13.18
			c-2.08,0.82-4.06,1.61-5.03,4.05c-4.78,12.27-15.08,16.8-26.15,20.26c-5.06,1.58-10.07,3.27-15.11,4.93
			c-1.09,0.8-2.33,1.06-3.63,1.01c-7.37,1.35-14.89,1.68-22.18,3.69c-3.71,1.02-3.73-1.67-2.44-3.94c3.49-6.1,3.6-12.7,3.23-19.43
			c-1.29-6.02-0.06-12.14-0.59-18.18c-0.69-19.02-1.59-38.02,0.23-57.03c0.32-3.38,0.69-6.23,4.81-6.29
			c0.57-0.01,1.23-0.36,1.69-0.78c5.86-5.23,9.57-10.54,16.62-13.73l-6.46,0.06c-4.41,1.75-10.41,6.87-14.87,9.96
			c0.25-2.01,0.79-4.13,0.51-9.66l-5.58,0.96c-1.02,19-2.61,38-1.8,57.03c0.57,13.46,0.4,26.93,1.28,40.34
			c0.69,10.7-4.75,18.74-8.99,27.39c-3.48,7.11-7.4,13.99-11.07,20.98c-0.45,0.85-0.99,1.94-0.83,2.79c1.25,6.82-3.29,8-7.94,9.31
			c-2.71,0.75-5.27,2.5-8,2.81c-6.99,0.82-9.64,5.39-10.53,11.91c-0.95,6.81-2.96,13.47-2.82,20.41c0.06,2.89-0.85,3.75-3.52,3.36
			c-4.81-0.69-9.77-0.57-14.42-2.23c-4.27-1.51-7.67-1.51-9.16,3.91c-0.2,0.72-0.98,1.25-1.49,1.87c0.06,1.41-0.92,2.31-1.51,3.4
			c-6.92,7.11-3.86,15.55-2.31,23.4c1.65,8.32,5.21,16.07,9.34,23.45c2.46,4.38,4.34,9.03,6.1,13.76c1.03,2.76,0.68,3.65-2.34,4.65
			c-16.2,5.37-32.86,8.2-49.58,10.93c-4.78,0.78-9.58,1.55-14.36,2.33c-5.9,0.47-11.82,0.85-17.71,1.46
			c-2.76,0.29-4.21-0.43-3.96-3.58c0.47-6.28-1.58-12.54-0.22-18.83c-1.46-1.77-1.32-4.02-1.34-6.05
			c-0.1-13.89-2.61-27.63-2.31-41.55c-1.12-4.97-0.96-10.02-1.09-15.07c-0.91-1.69-0.91-3.53-0.68-5.37
			c-2.57-34.37,0.86-68.61,2.15-102.89c0.86-22.98,1.59-45.97,2.47-68.95c0.11-3.43,0.93-6.86,0.85-10.28
			c-0.11-5.16,2.41-5.54,6.19-4.42c8.79,2.59,17.78,3.96,26.83,5.08c9.35,1.16,18.71,2.33,28.08,3.42
			c7.78,0.9,14.76-2.38,21.87-4.84c15.27-5.29,31-9.78,31-9.78s2.97-3.76,4.97-4.11l-25.31,4.97c-11.03,4.05-16.52,7.38-28.06,9.29
			c-5.4,0.89-11.19-0.79-16.79-1.45c-14.32-1.68-28.65-3.23-42.67-6.89c-1.52-0.4-3.09-0.57-4.16-1.97h-6.61
			c-1.06,2.14-2.76,2.92-5.03,2.73c-17.51-1.45-35.06-0.32-52.58-0.7c-1.41-0.03-3.75,1.13-3.73-2.02h-2.84
			c0.62,3.42-0.96,4.44-4.05,4.98c-5.24,0.93-10.24,0.2-15.35-0.8c-7.97-1.55-24.05-4.95-32.06-6.33l-25.92,2.15
			c5.64,2.81,11.85,1.82,17.78,2.76c14.88,2.34,29.92,3.52,44.68,6.76c3.55,0.78,7.21,0.7,10.76-0.24c3.71-0.98,3.89,1.03,3.33,3.92
			c-3.12,16.3-3.42,32.92-4.87,49.41c-2.04,23.34-3.86,46.72-5.62,70.09c-0.23,2.93-1.12,3.49-3.72,3.2
			c-24.34-2.67-48.72-5.23-73.07-7.81c-3.07-0.13-6.22,0.53-9.22-0.73c-9.28-0.49-9.39-0.52-7.27-9.85
			c6.55-28.93,13.16-57.85,19.83-86.75c1.92-8.39,0.56-16.62-0.42-24.9c-0.23-1.95-0.47-3.89-0.7-5.85h-4.71
			c2.34,11.88,3.82,23.57,0.5,35.75c-4.65,17.11-8.33,34.53-12.21,51.85c-3.94,17.65-7.93,35.29-12.08,52.88
			c-1.31,6.43-2.61,12.87-3.91,19.3c-0.33,4.6-2.57,6.85-6.88,8.55c-9.38,3.68-16.34,11.42-24.1,17.72
			c-3.22,2.61-4.5,2.43-6.64-1.09c-5.56-9.11-11.91-17.69-17.23-26.94c-9.48-16.44-23.77-28.18-36.22-41.72
			c-1.08-1.19-2.15-2.38-3.26-3.53c-5.36-5.62-5.57-5.95,1.06-10.4c25.22-16.95,45.79-39.08,64.9-62.85
			c4.83-6,9.48-12.15,14.2-18.24c4.83-6.23,8.13-16.03,8.8-24.14h-4.71c0.5,7.24-3.1,15.74-7.04,20.95
			c-14.3,18.87-29.47,36.98-45.6,54.17c-9.15,9.77-20.7,16.42-30.94,24.79c-1.72,1.42-3.94,2.46-5.14,4.24
			c-2.89,4.24-5.21,2.97-8.14,0.09c-11.6-11.48-24.79-21.07-36.19-32.82c-3.43-3.53-5.66-7.67-6.12-12.75
			c-1.67-1.22-2.07-3.36-3.23-4.95c-2.44-12.95-12.18-21.69-17.49-32.98c-3.38-7.15-2.2-9.48,5.17-9.97
			c9.06-0.62,11.39-0.69,18.66-7.63l-6.18-0.27c-3.29,3-7.79,3.26-11.67,3.25c-5.11,0-9.71,3.92-15.05,1.69
			c-2.66-1.11-5.1-2.27-6.59-4.94h-4.71c2.53,3.07,5.46,5.62,9.01,7.3c1.81,0.47,3.71,0.73,5.4,1.49c2.28,1.02,2.28,3.42,2,5.49
			c-0.34,2.36-2.44,1.49-3.85,1.59c-1.54,0.11-3.1-0.12-4.67-0.19c-3.43,4.67-2.93,10.34-3.1,15.61
			c-0.62,18.91,1.78,37.64,4.09,56.34c2.23,18.05,4.68,36.06,6.85,54.12c0.8,6.76-1.77,12.93-4.47,18.83
			c-5.56,12.15-8.34,25.32-13.11,37.76c-4.91,12.84-12.51,24.14-19.13,35.99c-1.28,2.3-1.68,7.68-3.98,6.51
			c-3.46-1.75-6.02-5.97-7.05-10.48c-2.6-11.29-2.69-22.71-1.91-34.21c1.28-19,1.9-38.06,3.83-56.97
			c1.03-10.17,3.96-20.19,6.52-30.13c2.13-8.22,0.03-15.94-1.38-23.8c-4.97-27.78-12.67-54.69-23.27-80.7
			c-0.59-1.44-1.71-2.8-0.86-4.54h-5.67c8.49,20.26,15.97,40.89,20.7,62.5c2.34,10.71,4.14,21.57,6.72,32.21
			c1.49,6.13,0.27,11.92-1.38,17.42c-6.36,21.11-7.2,42.9-8.11,64.72c-0.33,7.54-0.37,15.15-1.35,22.62
			c-1.46,11.32,0.45,22.26,2.37,33.23c0.75,4.24,3.78,7.17,6.41,10.13c2.36,2.64,3.32,5.46,2.94,8.86
			c-0.49,4.57-1.13,9.11-1.65,13.67c-0.34,3-2.47,2.93-4.55,3.03c-9.57,0.52-19.2,0.59-28.71,1.69c-11.99,1.39-23.9,3.72-35.86,5.46
			c-5.59,0.82-11.23,1.21-16.85,1.8c-0.88,0.65-1.81,0.65-2.76,0.2c-1.95,0.78-3.96,0.92-6.02,0.57c-0.89,0.4-1.81,0.69-2.8,0.32
			c-11.62,2.63-23.44,3.96-35.23,5.49c-7.43,0.96-14.91,1.48-22.35,2.31c-2.79,0.32-3.43-0.62-3.12-3.56
			c0.75-7.15,2.73-13.47,7.27-19.19c10.77-13.56,21.08-27.5,31.67-41.23c1.88-2.43,3.42-5.06,4.9-7.73c-0.2-0.8-0.04-1.46,0.7-1.9
			c-0.33-7.94,1.26-15.73,2.63-23.41c2.8-15.88,2.21-31.57,0.4-47.5c-3.48-30.46-6.02-61.05-9.38-91.54
			c-1.72-15.64-13.64-158.1-14.97-173.79c-0.09-1.11-0.65-2.27,0.33-3.26h-4.73c1.25,12.87,12.33,152.62,13.65,165.49
			c3.12,30.12,6.28,60.23,9.44,90.34c1.18,11.25,2.66,22.45,3.46,33.72c0.7,9.87-1.72,19.53-2.86,29.28
			c-0.79,6.81-1.92,13.57-2.92,20.37c-4.18,9.29-11.12,16.39-17.55,23.87c-0.82,0.96-2.08,0.55-3.13,0.55
			c-10.07,0.04-20.12-0.06-30.19,0.11c-2.89,0.04-3.99-0.86-3.66-3.91c1.55-14.55,0.65-29.14,0.32-43.69
			c-0.24-11.65-1.94-23.18-5.11-34.41c-1.92-6.87-5.89-12.27-11.23-16.57c-9.25-7.45-13.8-17.05-12.97-29.41
			c0.43-6.32-0.79-12.45-3.48-18.15c-5.01-10.69-5.96-22.39-7.32-33.85c-1.57-13.04-3.75-25.94-5.64-38.91
			c-0.88-5.97-1.64-11.96-2.46-17.95h-4.71c0.72,2.36,1.08,4.74,0.76,7.88c-3.3-2.81-6.29-5.36-9.26-7.88h-5.66
			c3.99,5.34,9.68,8.7,14.35,13.26c1.57,1.54,2.5,2.99,2.83,5.31c2.96,20.41,6.12,40.8,9.08,61.23c0.43,2.99,1.35,5.79,2.59,8.36
			c3.81,8,5.31,16.42,5.03,25.23c-0.29,9.26,2.41,17.92,9.32,23.18c14.59,11.13,17.65,26.99,19.12,43.96
			c0.17,2.13,0.39,4.25,0.59,6.36c0.69,6.56,0.79,13.1-0.47,19.6c1.26,25.1-1.87,50.14-1.49,75.19c0.13,9.23-4.18,13.1-10.31,17.03
			c-1.8,1.16-2.94,1.18-4.44-0.45c-2.07-2.25-4.21-4.35-5.29-7.4c-2.93-8.33-8.29-15.45-9.05-25c-0.43-5.4-5.9-9.75-8.04-15.25
			c-2.86-7.41-7.41-14.07-8.93-22.09c-0.89-0.26-1.8-0.53-2.69-0.8c-4.95,2.71-9.95,5.34-14.84,8.17c-1.75,1.02-2.63,0.65-3.78-0.96
			c-2.51-3.52-5.26-6.82-7.91-10.23c-6.74-5.06-13.46-10.1-20.19-15.15c-2.77-1.82-5.6-3.58-8.3-5.5c-2.14-1.55-3.15-3.63-3.22-6.59
			c-0.27-10.97-1.25-21.87-3.03-32.72c-2.04-12.34-8.93-21.72-16.46-30.78c-1.01-1.22-2.6-1.91-3.03-3.65
			c-0.67-0.01-1.16-0.3-1.34-1.05c-1.16-1.09-3.16-1.45-3.09-3.63c-2.53,0.55-2.54-2.28-3.89-3.32c-2.04-1.39-2.86-3.33-2.36-5.85
			c0.16-5.92,1.46-11.68,2.76-17.38c1.74-7.68-0.79-13.43-6.28-18.21c-4.08-3.56-8.06-7.3-12.38-10.51c-3.1-2.3-3.03-4.19-0.73-6.94
			c10.8-12.93,21.4-26.04,32.31-38.85c6.35-7.44,12.24-15.32,19.27-22.1c0.67-0.65,1.45-1.32,0.88-2.48h-5.66
			c-1.44,2.54-3.06,4.91-5.4,6.64c-5.77,8.62-12.8,16.16-19.66,23.71c-19.13,21.08-35.01,44.44-47.65,70.26
			c-0.29,0.57-0.57,1.16-0.92,1.72c-1.12,1.78-2.25,4.28-4.27,4.15c-1.77-0.11-2.25-2.9-3.1-4.6c-5.4-10.71-10.76-21.46-15.97-32.29
			c-2.69-5.54-0.89-11.35,0.32-16.73c2.84-12.68,3.6-25.15,0.16-37.83c-1.32-4.85-7.26-134.89-5.85-139.99h-5.66
			c1.82,5.46,6.71,136.22,7.29,141.81c1.26,12.06,4.14,24.27-0.37,36.42c-1.09,2.93-0.82,6.49-0.82,9.75
			c0.01,3.02-0.79,4.05-3.86,3.49c-6.79-1.22-13.63-2.4-20.55-2.5c-4.39,2.2-6.12,7.14-9.68,10.2c-1.03,0.89-1.45,2.54-3.07,2.7
			c-0.42,0.88-0.79,1.78-1.26,2.63c-2.11,3.75-4.11,4.42-7.5,0.89c-7.27-7.57-16.6-11.65-26.15-15.01c-2.54-0.9-3.4-2.01-3.55-4.74
			c-0.49-8.36-1.36-16.7-1.95-25.05c-0.65-9.28-3.82-17.15-11.63-23.04c3.58-1.35,6.85-0.93,10.01-1.06
			c6.33-0.26,12.48-1.21,18.31-3.95c5.44-2.57,11.12-4.44,16.99-5.63c1.15-0.23,2.04-0.69,2.36-1.97h-13.21
			c-6.13,2.6-11.92,6.25-18.77,6.55c-5.29,0.39-10.58,0.52-15.83,1.25c-4.22,0.6-6.85-0.52-7.71-5.13
			c-0.17-0.93-53.77-143.01-54.14-143.9h-3.78c0.01,0.65,52.94,142.56,53.12,143.14c2.04,6.33,2.04,6.12-4.61,6.74
			c-14.86,1.39-29.83,0.85-44.64,2.89c-1.57,0.22-2.33-0.56-3.23-1.41c-3.62-3.36-128.53-150.85-132.13-154.23h-8.5
			C532.34-203.2,663.81-39.73,679.77-26.89c0.59,0.47,1.01,1.19,2.1,2.5c-3.88,0-7.07-0.16-10.24,0.01
			c-23.19,1.25-46.48,0.86-69.61,3.09c-17.05,1.65-33.72,5.8-50.47,9.34c-16,3.37-31.9,7.27-47.94,10.33
			c-7.1,1.36-12.34,5.8-18.01,9.57c-5.72,3.78-12.39,2.82-18.41,4.98c-1.74,0.63-3.09-0.89-3.99-2.54
			c-4.6-8.5-9.91-16.63-12.57-26.14c-0.3-1.11-0.88-2.33-0.66-3.35c2.31-11.19-2.76-20.51-7.07-29.9
			c-3.03-6.61-4.04-13.36-3.69-20.51h-4.71c-0.11,12.52,4.8,23.47,9.85,34.37c1.11,2.38,0.7,4.8,0.32,7.24
			c-0.33,0.03-0.82,0.16-0.88,0.04c-5.53-11.69-15.96-17.59-26.01-23.9c-6.75-4.22-11.85-9.85-14.43-17.75h-5.66
			c4.57,9.81,10.81,17.91,20.45,22.78c17.59,8.89,26.73,23.08,25.13,44.01c-0.37,4.9-0.23,9.82-0.4,14.74
			c-0.1,2.81-1.49,4.41-4.25,4.95c-5.14,1.03-10.14,2.59-13.27-4.06c-1.21-2.54-3.76-2.9-6.61-2.08c-4.08,1.16-8.3,1.77-12.47,2.57
			c-3.04,0.57-5.74,1.08-8.73-1.49c-2.41-2.07-6.02-0.76-6.36,2.96c-0.32,3.43-2.17,4.12-4.52,5.16
			c-19.23,8.39-38.36,17.03-58.31,23.47l-8.62,3.27c-7.31,4.62-12.93,1.68-18.51-3.85c-4.51-4.5-9.81-8.11-14.68-12.22
			c-3.12-2.63-6.45-3.65-10.48-2.89c-3.85,0.72-7.8,0.79-11.69,1.29c-3.95,0.52-6.45-1.19-7.15-5.21
			c-1.05-5.96-3.17-11.2-7.54-15.41c-1.95-1.87-1.52-4.6-0.88-6.97c1.18-4.41,2.48-8.79,4.01-13.07c1.18-3.33,1.46-6.61,1.11-10.11
			c-0.55-5.29,0.3-10.11,5.16-13.2c3.72-2.37,5.31-6.19,6.48-10.38c2.2-8,4.27-16.04,5.66-24.24h-4.71
			c-0.45,9.12-3.65,17.56-5.97,26.21c-0.57,2.14-2.01,3.78-3.82,4.83c-5.72,3.33-7.61,9.09-7.04,15.02
			c0.75,7.8-2.08,14.51-4.19,21.43c-1.78,5.86-1.95,10.99,2.69,15.27c4.18,3.83,3.91,9.48,5.74,14.25c0.69,1.78-1.03,2.27-1.91,2.93
			c-8.79,6.58-17.65,13.04-26.47,19.59c-1.85,1.36-3.19,2.03-4.97-0.47c-18.46-25.82-37.23-51.43-55.5-77.4
			c-8.72-12.38-16.57-25.41-24.79-38.17c-0.65-1.01-1.82-1.94-1.19-3.48h-5.66c5.87,11.25,13.9,20.98,20.34,31.83
			c10.27,17.28,22.52,33,34.37,49.05c1.9,2.59,1.62,3.42-1.06,4.78c-3.35,1.71-6.62,3.79-9.55,6.2c-7.2,5.96-15.5,8.26-24.42,8.73
			c-4.12,0.23-8.09,1.09-11.94,2.53c-6.49,2.41-12.45,1.59-18.41-2.08c-8.56-5.29-16.17-11.71-23.11-19.1
			c-1.12-1.21-1.92-3.09-3.98-3.03v5.92c0.55,0.22,1.28,0.24,1.61,0.65c7.27,8.53,16.42,14.58,25.25,21.04
			c1.97-0.22,3.33,1.31,5,1.95c6.31,0.55,12.47,0.11,18.53-2.13c2.48-0.9,5.23-1.32,7.86-1.42c10.94-0.36,20.08-5,28.97-11.45
			c11.73-8.53,9.28-7.15,16.11,2.57c16.13,22.97,31.68,46.35,47.57,69.5c7.34,10.7,14.41,21.66,22.49,31.73
			c5.17,6.42,12.38,11.03,17.95,17.15c6.35,6.97,11.68,14.88,16.37,23.19c1.03,1.82,0.19,2.48-1.02,3.5
			c-14.06,11.81-29.11,21.28-47.83,21.82c-10.83,0.3-21.59-0.99-32.37-1.91c-23.01-1.94-45.92-5.3-69-6.49
			c-7.41-0.39-24.83-11.83-27.37-22.13c-6.06-24.44-17.23-46.66-28.31-68.9c-0.37-0.76-0.47-2-1.81-1.74v8.88
			c5.54,9.62,10.3,19.66,14.88,29.8c5.21,11.59,7.91,24.11,12.28,36.02c0.65,1.74,0.66,3.1-0.75,4.41
			c-0.68,0.65-1.65,1.59-1.59,2.33c0.49,6.19-3.56,5.2-7.04,4.95c-5.77-0.43-106.7-17.41-138.03-17.5c-1.28,0-2.31,1.04-2.31,2.32
			v2.58c0,0.01,0.01,0.02,0.02,0.02c14.09-3.04,150.32,15.36,163.59,19.22c14.2,4.15,28.71,4.17,42.99,6.68
			c6.33,0.75,12.75,0.49,19.02,1.95c15.96,1.35,31.9,3.03,47.87,3.95c15.73,0.89,30.52-2.84,43.79-12.05
			c4.57-3.16,9.05-6.46,13.37-9.98c2.36-1.91,3.91-1.9,5.69,0.66c6.74,9.65,13.57,19.22,20.35,28.84c2.51,3.56,4.88,7.21,7.43,10.74
			c2.48,3.45,2.4,5.67-1.41,8.59c-24.49,18.76-49.15,37.33-72.99,56.97c-28.98,23.88-61.41,40.19-96.41,51.52
			c-3.69,1.19-7.68,1.95-10.7,4.8c-1.97,1.84-3.15,0.1-4.47-0.88c-9.34-6.94-18.21-14.51-26.28-22.97
			c-7.21-7.54-14.14-13.07-24.79-18.33c-1.11-0.55-99.68-50.13-100.9-50.43v4.94c1.02-0.49,99.2,49.18,100.05,49.97
			c9.26,5.88,16.57,10.59,23.4,17.64c7.7,7.93,15.97,15.18,24.85,21.64c2.48,1.81,3.6,3.69,2.81,6.87
			c-1.69,6.71-0.92,13.3,1.22,19.78c1.12,3.59,0.93,7.32,1.21,11c0.89,3.48,1.8,6.95,2.77,10.74c-11.36,2.87-21.74,7.74-31.07,15.31
			c-8.43,6.84-17.56,21.22-24.31,29.53c-2.51,3.09-5.78,4.79-5.78,4.79l2.23,4.23c6.06-3.31,9.62-9.95,14.28-16.29
			c9.84-13.34,21.08-24.66,36.87-30.05c9.61-3.27,9.68-3.46,13.79,5.85c6.13,13.89,14.39,26.48,21.6,39.7
			c1.22,2.23,2.33,3.76-0.95,5.21c-28.62,12.54-57.51,24.36-86.59,35.65v4.94c30.33-12.47,60.92-24.27,90.84-37.82
			c6.48-2.94,13-5.69,19.68-8.06c3.04-1.08,4.34-0.47,4.62,2.89c0.2,2.44,0.7,4.85,1.08,7.27c0.8,0.78,0.73,1.78,0.65,2.77
			c3.68,18.79,7.31,37.59,11.09,56.34c0.46,2.3-0.66,3.19-2.08,4.38c-9.97,8.32-17.61,18.47-22.48,30.89
			c-5.31,13.53-6.19,27.24-2.92,41.46c1.38,6.03,3.2,12.55,2.27,18.4c-0.85,5.26,0.5,9.28,1.8,13.72c0.73,2.5-0.69,3.12-2.54,3.65
			c-6.38,1.81-12.91,3.46-19.43,3.55c-12.11,0.19-22.25,6.08-32.87,10.74c-0.43,0.2-0.79,0.55-1.22,0.75
			c-5.83,2.84-6.46,2.69-8.83-3.4c-3.32-8.47-7.97-16.37-10.05-25.38c-2.99-4.02-4.61-8.78-6.61-13.34
			c-6.16-13.85-17.95-41.79-17.95-41.79l-1.92-4.54l0.04-1.96l-5.43,3.03c4.75,7.3,9.93,21.04,13.42,28.98
			c8.65,19.6,17.09,39.31,25.61,58.97c0.72,1.67,2.41,3.45-0.56,4.73c-12.31,5.26-23.55,12.88-36.19,17.38v4.93
			c15.32-6.89,30.66-13.79,45.99-20.68c1.91-1.25,3.81-2.5,5.7-3.73c4.58-2.15,9.11-4.47,13.76-6.41
			c5.96-2.44,12.38-1.39,18.57-2.28c12.49-1.8,24.63-5.07,36.68-8.75c3.95-1.19,5.18-0.67,5.95,3.99
			c4.31,26.28,7.51,52.85,17.19,77.86c2.01,5.16,5.04,8.82,10.61,8.95c3.94,0.09,5.97,2.14,7.28,5.67
			c3.27,8.86,6.36,17.82,10.05,26.48c1.95,4.57-0.09,6.49-3.2,8.62c-12.45,8.44-24.9,16.89-37.96,24.33
			c-9.08,5.16-17.97,10.71-26.9,16.17c-4.12,2.51-7.44,6.53-12.51,7.31c-0.93,1.82-2.2,2.87-4.29,2.07
			c-0.16,0.4-0.23,1.08-0.47,1.16c-17.59,5.56-32.49,16.63-48.27,25.88c-3.13,1.82-6.26,3.68-9.34,5.59
			c-2.14,1.34-3.53,1.08-4.9-1.34c-2.79-4.91-6.12-9.51-8.76-14.49c-3.6-6.79-13.56-16.89-13.56-16.89l3.04,6.42
			c3.99,1.02,2.31,2.63,4.52,6.12c4.14,6.56,8.06,13.28,12.28,19.79c1.75,2.71,1.28,3.88-1.39,5.54c-9.06,5.64-8.99,5.79-16.3-2.37
			c-1.29-1.44-2.51-2.92-3.78-4.38v4.94c1.13,1.11,2.34,2.15,3.4,3.33c5.72,6.35,5.29,7.65-3.4,10.47v4.94
			c3.09-0.37,5.4-2.44,7.87-4.08c4.02-2.67,8.26,0.6,11.52,4.41c4.71,5.52,9.36,10.97,13.93,16.44c-3.99-6.39-8-13.56-12.24-18.89
			c-1.81-2.28-2.86-5.46-5.57-6.19c0.36-0.62,0.42-0.82,0.53-0.89c2.8-1.78,5.5-4.74,8.45-5.01c3.1-0.29,3.59,4.55,5.82,6.71
			c4.62,4.47,7.94,10.13,11.35,15.58c6.18,9.91,15.28,14.15,25.97,15.6c3.42,0.46,6.89,0.52,10.18,1.77c2.11,0.79,3.96,0,5.82-1.08
			c8.23-4.75,16.55-9.34,24.72-14.19c2.56-1.52,3.99-1.49,5.67,1.32c2.69,4.44,5.13,8.98,7.53,13.59c1.55,2.99,0.63,3.83-1.65,5.17
			c-15.7,9.21-31.37,18.46-46.95,27.85c-3.27,1.95-5.14,2.97-6.66,0.78c-2.57-3.71-5.27-7.17-8.03-10.51
			c2.36,3.23,4.68,6.51,6.97,9.84c1.74,2.56,1.31,5.27,0.13,7.44c-4.78,8.82-11.92,15.15-21.49,16.85
			c-13.23,2.34-25.97,6.1-38.52,10.83c-1.65,0.63-3.46,1.98-5.33,0.37v14.79c2.4,5.8,4.95,11.53,7.15,17.41
			c2.61,6.94,6.78,12.01,13.69,14.55c5.47,2.01,8.67,6.08,10.73,11.96c4.87,14.02,8.52,28.42,13.08,42.54
			c0.53,1.62-0.23,2.5-1.08,3.42c-6.99,7.45-14.76,13.64-24.6,16.46c-4.73,1.52-9.46,3.04-14.19,4.57c-0.85,3.81,0.57,4.31,3.88,3.4
			c13.33-3.6,26.04-8.34,35.73-19.27c1.8-2.01,2.53-1.16,3.52,0.93c9.13,19.39,18.25,38.78,27.63,58.04
			c2.73,5.6,7.18,10.33,7.37,17.13c0.04,1.72,0.96,3.94-1.85,3.95c-5.34,0.01-10.14,1.65-14.79,3.76c-0.13,0.06-0.26,0.11-0.4,0.19
			c-0.17,0.09-0.36,0.17-0.56,0.27c-0.2,0.09-0.4,0.17-0.6,0.27c-0.26,0.13-0.55,0.26-0.83,0.4c-3.49,1.61-9.08,4.14-11.29,5.23
			c-2.69,1.35-4.61,2.71-7.87,3.4c-0.03,0.01-0.07,0.01-0.11,0.03c-4.14,0.76-8.62,0.66-12.91,0.85
			c-25.79,0.16-51.82-0.95-77.47-3.53l-0.39,2.84c17.28,2.13,34.53,3.09,51.88,3.96c8.66,0.37,17.32,0.66,26.04,0.62
			c5.57-0.2,11.27,0.2,16.86-1.52c0.93-0.4,1.97-0.82,3.03-1.26h0.01c1.54-0.46,4.31-1.34,6.25-2.27c2.47-1.18,6.95-3.98,9.78-5.36
			c0.19-0.1,0.39-0.19,0.59-0.27c0.17-0.09,0.36-0.16,0.52-0.23c0.1-0.04,0.19-0.09,0.27-0.13c3.69-1.57,7.54-2.69,11.73-2.9
			c2.96-0.16,4.09,0.76,4.08,3.65c0,2.33,0.17,4.62,1.54,6.58c1.26,1.81,0.17,2.99-0.83,4.31c-0.19,0.24-0.36,0.5-0.53,0.76
			c-0.14,0.23-0.27,0.46-0.4,0.7c-0.8,1.49-1.35,3.1-2.41,4.6l-0.03,0.03l-31.7,60.26l2.53,1.35l32.21-59.29v-0.01
			c0.14-0.2,0.33-0.53,0.6-0.99c2.21-3.78,8.22-11.71,11.09-14.98c2.69-3.06,5.34-3.39,8.79-1.68c7.44,3.69,15.57,6.25,20.45,13.99
			c1.48,2.34,3.6,1.36,5.89,1.44c-1.01-3.16-3.82-4.77-5.36-7.86c6.1-0.98,12.08-1.38,17.72-2.97c9.74-2.76,19.14-1.16,28.8,0.39
			c12.48,2.01,22.74-1.92,30.89-13c6.92-9.36,13.37-18.9,18.92-29.2c2.7-5,6.39-9.28,10.43-13.21c1.72-1.68,2.92-2.3,4.52,0.55
			c5.66,10.01,11.59,19.83,17.51,29.67c1.03,1.71,1.22,2.6-0.75,3.65c-3.75,2.03-7.35,4.34-11.03,6.53
			c-2.18,1.34-4.38,2.67-6.56,4.01c-8.63,5.72-16.07,12.85-22.98,20.77c4.44,1.15,4.57,1.28,7.22-1.75
			c10.17-11.53,23.47-18.31,36.05-26.2c2.13-1.34,2.44,0.34,3.42,1.65c4.27,5.73,19.65,20.72,25.71,24.06l2.83-2.36
			c-6.72-3.3-25.03-19.46-26.71-24.03c-0.68-1.81,0.24-2.36,1.41-2.93c3.66-1.84,7.37-3.56,10.99-5.47c2.15-1.13,3.3-0.57,4.45,1.61
			c2.48,4.68,9.74,16.79,12.67,21.18l2.23-1.85c-2.73-4.11-9.94-15.81-12.28-20.18c-1.28-2.37-0.99-3.09,1.58-3.92
			c15.4-4.94,28.03-15.15,41.25-24.19c3.99-2.73,6.2-7.02,6.59-12.42c0.39-5.49,5.11-7.9,8.76-10.89c1.36-1.11,1.9,0.47,2.44,1.36
			c3.03,5.03,6.03,10.08,9.05,15.12l3.66-2.3c-2.81-4.7-5.63-9.38-8.55-14c-1.75-2.76-1.46-4.25,0.89-6.45
			c16.13-15.01,34.44-26.71,52.61-38.68c2.14-1.41,3.1-1.35,4.58,0.93c6.28,9.61,11.79,21.43,16.43,31.9l4.04-1.39
			c-4.64-10.66-10.23-22.63-16.75-32.27c-0.93-1.38-2.31-2.96,0.22-4.39c16.26-9.29,46.55-31.45,46.55-31.45
			s32.54-24.36,47.54-38.29c9.36-8.72,19.35-16.65,29.56-24.26c0.75-0.55,1.62-0.96,2.47-1.35c3.82-1.71,5.8-0.85,6.39,3.95
			c0.98,7.96,2.97,15.78,3.78,23.75c0.67,6.62,4.84,10.4,8.82,14.52c4.31,4.45,4.38,4.54-0.13,8.93
			c-4.68,4.57-43.92,46.15-45.92,47.9c-1.65,1.45-6.15,6.69-7.65,8.24l7.24-1.49l2.38-1.51c6.48-6.48,15.35-15.97,21.39-22.88
			c4.51-5.18,9.48-9.92,14.23-14.88c4.12-4.54,8.5-8.86,12.29-13.67c3.2-4.05,3.83-2.53,6.87,0.96c2.7,2.7,5.41,5.39,8.13,8.09
			c10.5,11,17.56,17.95,28.08,28.97l6.09-0.4c-7.25-7.6-11.5-10.69-18.54-18.5c-6.87-7.63-14.06-14.95-21.37-22.09
			c-2.82-2.74-2.66-4.57,0.1-6.82c1.46-1.18,2.41-3.02,4.37-3.58c11.42-3.32,21.77-9.92,33.71-11.66c3.39-0.49,6.81-1.39,10-2.7
			c4.81-1.95,8.98-0.89,12.74,2.21c22.05,18.24,42.05,39.08,66.77,56.14c-5.37,1.28-9.32,2.44-13.34,3.12
			c-3.56,0.59-5.31,2.93-5.56,6.15c-0.01,0.26-0.04,0.52-0.07,0.78l3.49,0.2v-0.1c-0.06-1.35,1.98-2.54,3.95-2.94
			c12.01-2.4,23.93-5.23,35.96-7.53c27.93-5.34,55.27-13.1,82.19-22.43c3.29-1.13,6.35-1.67,9.61-0.47
			c5.79,2.13,10.37,0.67,13.89-4.73c1.95-2.99,4.32-5.06,7.57-2.61l3.39-3.32c-0.93-0.76-1.87-1.52-2.8-2.27
			c-14.59-11.68-29.2-23.34-43.82-34.97c-17.58-14-35.02-28.21-52.84-41.85c-8.93-6.84-13.79-16.6-19.25-26.02
			c-3.49-6.05-5.89-12.64-7.78-19.43c-0.78-2.77-0.32-4.61,2.69-4.83c11.03-0.82,21.26-5.33,32.01-7.47
			c11.39-2.25,22.65-5.26,33.89-8.22c3.32-0.88,3.86,0.3,3.49,3.23c-0.13,0.96,0.14,2.05-0.17,2.93c-3.02,8.44,3.1,12.09,7.91,16.29
			c1.58,1.39,3.46,0.5,5.21-0.6c8.13-5.14,8.07-5.06,11.68,3.92c2.05,5.14,4.39,10.15,6.65,15.21c1.7,3.81,4.52,5.43,8.63,5.5
			c13.18,0.23,26.38,0.7,39.57,1.09c3.03,0.01,9.21,0.07,12.25-0.06l1.31-4.08c-15.58-0.29-34.3-0.96-49.88-1.01
			c-4.94-0.01-7.91-1.57-9.69-6.45c-2.61-7.14-6.53-13.73-7.87-21.43c-0.57-3.25-2.73-3.65-5.23-1.67
			c-4.11,3.22-8.49,4.71-13.49,2.31c-0.43-0.2-0.99-0.2-1.32-0.5c-3.85-3.43-0.99-7.87-1.48-11.82c-0.24-2.05-0.69-4.75,2.43-5.57
			c0.8-0.22,1.59-0.45,2.38-0.7l0.01,0.01l0.23-0.09c0.17-0.06,0.34-0.11,0.52-0.16l52.64-17.32c6.59,0.62,13.16,2.04,19.76,2.48
			c1.58,0.1,1.85,1.25,2.1,2.67c1.31,7.61-0.34,10.69-7.3,13.63c0,0.01,0,0.01,0,0.01l2.64,5.07c3.09-2.23,6.71-3.09,10.28-4.04
			c6.52-1.8,7.68-1.31,8.36,4.52l5.92-0.43c0.13-2.74,0.24-5.47-0.03-8.2c-0.83-5.57-1.19-8.13,0.22-9.34l93.47,20.71l17.95,4.83
			c3.59,2.2,8.65,3.49,11.48,4.87c3.52,2.4,8.14,10.04,8.14,10.04c1.77,2.71,3.45,5.39,5.74,7.74c3.26,2.6,6.85,4.42,10.25,6.55
			c5.96,3.53,11.93,6.98,17.94,10.43l0.89-1.54l1.28-2.2c-5.95-3.5-11.88-7.02-17.75-10.61c-2.9-1.78-5.92-3.6-8.6-5.46
			c-2.13-1.57-4.05-5.07-5.62-7.45c0,0-5-7.68-8.59-10.76c-3.62-2.5-9.44-3.45-13.01-5.89c-0.63-0.42-1.18-0.9-1.41-1.24
			c-0.1-0.16-0.14-0.26-0.14-0.29c0.01-0.06-0.03,0.03,0.11-0.47c3.69-10.34,9.39-20.34,14.15-30.23
			c7.02-13.17,14.41-26.44,22.48-38.95c0.92-1.41,2.11-3.09,3.15-4.34c0.96-0.99,1.95-1.97,2.97-2.92c3.91-3.52,8.67-2.38,8.67-2.38
			s5.85,1.51,10.96,3.33c0.27,0.16,4.42,1.72,5.69,2.44c2.8,1.48,4.54,4.44,6.2,7.11c3.03,4.88,17.88,29.17,31.32,51.2
			c-6.1,5.93-7.51,11.1-7.6,11.42c-1.55,5.99-10.3,63.85-10.38,64.6l2.87,0.32c0.01-0.19,8.79-58.4,10.3-64.2
			c0.01-0.06,1.22-4.41,6.38-9.59c9.65,15.8,69.71,109.71,72.34,114.01l2.47-1.46c-6.76-11.85-64.97-103.44-71.81-115.26
			c2.28-1.87,5.08-3.78,8.56-5.56c6.49-3.32,13.76-7.32,20.81-11.2c10.11-5.56,19.66-10.83,24.55-12.75
			c6.55-2.6,20.55-6.78,25.92-8.36l-4.78,157.75l2.87,0.16l10.27-248.85c8.09,5.85,15.3,13.18,24.32,17.91l4.85,2.89
			c13.06,7.7,25.98,15.47,39.63,22.2c6.88,3.33,14.25,5.62,21.43,8.1c12.77,4.22,25.52,8.56,38.49,12.12
			c-0.3,1.8-0.47,3.07-0.49,3.66c-0.03,1.75-0.5,9.59-0.95,17.19c-0.36,5.76-0.7,11.53-0.86,14.82c-0.23,4.39-1.42,7.37-2.58,10.25
			c-0.78,1.92-1.58,3.89-2.04,6.2c-0.86,4.45,0.23,6.81,2.86,12.51c0.95,2.08,2.14,4.7,3.56,8.07
			c5.39,12.85,22.65,22.98,23.41,23.42l9.88,5.34c-3.13,5-12.64,20.24-12.68,20.31l2.37,1.61c0.06-0.07,9.62-15.41,12.84-20.52
			l35.81,19.49l1.69-2.08l-35.98-19.86c3.06-4.97,6.46-10.79,8.86-15.78c3.58-7.44,6.72-15.88,9.22-22.68
			c1.39-3.75,2.59-6.98,3.65-9.45c2.97-7.08,12.21-25.31,17.69-32.63c1.82-2.43,4.39-5.46,7.04-8.44c2.18-2.47,4.51-4.84,7.12-6.87
			c1.54-1.18,2.9-2.54,4.11-3.95c10.01-11.81,17.71-25.21,23.15-39.61c4.64-12.62,7.8-30.89,11.03-44.15
			c2.64-12.11,6.02-26.79,9.55-41.51c3.1,1.05,31.65,12.49,34.77,13.53l0.78-2.36l1.08-2.14c-3.23-1.08-32.21-12.85-35.45-13.95
			c2.86-11.83,5.36-20.34,7.51-28.71l0.98,0.33c2.04-2.27,3.78-2.4,6.69-2.79c9.71-1.31,30.58-7.21,39.47-9.8
			c5.3,7.76,5.06,17.59,8.4,26.08c0.91,2.28,0.04,3.12-1.77,4.14c-10.07,5.69-20.91,8.8-32.07,10.87c-0.76,0.14-1.51,0.22-2.26,0.22
			c0.39,0.69,0.78,1.38,1.18,2.07c6.55,0.22,13.21-1.39,20.02-4.02c4.39-1.69,8.72-3.72,12.84-6.03c2.71-1.52,3.89-0.79,4.87,1.9
			c1.45,4.04,3.66,7.58,6.55,10.67c1.85,1.98,2.51,4.42,2.76,7.14c0.62,7.21,0.9,14.32-0.56,21.54c-0.52,2.57-0.32,3.66,0.52,5.95
			c2.67,1.45,5.5,4.29,8.4,5.79c0.01-0.07,0.03-0.14,0.04-0.22c-0.39-0.26-0.78-0.52-1.18-0.79c-0.45,0.17-0.57-0.04-0.53-0.47
			c-3.04-3.82-2.89-7.9-2.53-12.8c0.82-11.06,1.91-21.93-5.89-32.19c-7.76-10.21-7.55-10.6,4.77-14.05
			c3.09-0.86,6.25-0.55,9.38-0.24c19.17,1.8,38.27-0.23,57.38-1.45c3.72-0.23,7.67-1.41,11.45-2.41c3.79-1.02,5.62,0.46,5.95,4.37
			c0.22,2.46,0.5,4.9,0.66,7.35c1.09,15.9,1.45,44.72,1.54,52.94c0.01,1.48,0.43,2.92,1.21,4.18l0.88,1.42
			c0.99,1.61,1.91,3.25,2.77,4.93c1.78,3.46,5.24,9.91,7.9,13.18c1.22-0.45,2.21-1.39,3.59-2.24c-2.17-2.51-3.73-4.98-4.98-8.29
			c-1.74-3.02-3.42-5.97-5.16-9.01c-1.64-1.88-2.57-4.24-2.77-6.69c-0.47-5.64-0.43-11.29,0.3-16.92
			c-0.82-14.74,0.17-29.59-2.46-44.19c-0.39-2.14,0.53-2.6,2.08-2.87c10.17-1.75,20.31-1.97,30.49,0.01
			c1.87,0.36,2.14,1.21,2.27,2.9c0.83,12.28,1.77,24.54,2.7,36.82c0.23,2.94,0.65,5.87,2.02,8.52c1.84,3.53,2.74,7.31,3.42,11.15
			c1.15-1.35,2.26-2.69,3.33-3.98c-1.78-5.6-4.32-10.91-4.62-16.92c-0.27-5.39-0.7-10.79-1.11-16.17
			c-0.46-5.87-0.79-11.78-1.52-17.61c-0.39-3.15,0.13-4.17,3.45-4.29c13.8-0.56,27.58-1.51,41.36-2.31
			c6.05-0.47,12.09-0.82,18.18-0.89c-8.11,5.82-15.22,12.87-22.26,19.82c-11.39,11.49-22.26,23.42-32.93,35.59l2.15,1.87
			c16-17.94,32.24-36.05,50.73-51.42c12.62-9.75,27.98-15.28,42.9-20.55c15.14-5.01,30.71-8.78,46.71-9.06
			c6.94-0.16,11-0.33,17.82,1.02c1.31,0.26,3.89-0.3,6.13-1.55l0.01-0.03c2.74-1.87,5.52-3.69,8.26-5.56
			c16.98-13.72,44.21-3.15,51.04,12.31c0.17,0.27,2.2,4.78,3.59,7.87c0.75,1.7,1.41,3.43,2.01,5.2c0.69,2.04,1.35,3.3,2.69,4.81
			c6.92,7.47,17.26,10.64,25.91,14.98l31.42,14.39c0.59,2.1,1.28,4.11,2.1,6c2.07,4.83,4.65,10.56,7.4,16.63
			c8.1,17.94,18.18,40.26,20.51,51.93c3.39,16.95,8.86,70.52,7.61,107.23c-0.19,5.52-0.4,11.03-0.6,16.52
			c-1.21,32.16-2.34,62.53,0.82,82.96c3.99,25.87,21.33,58.38,46.73,70.45c2.53,1.21,5.14,2.47,7.83,3.78
			c19.55,9.48,42.84,20.8,63.67,20.8c5.64,0,11.13-0.83,16.29-2.77c24.69-9.26,53.61-32.11,59.29-54.32
			c3.42-13.4-7.58-43.53-18.23-72.66c-5.87-16.11-11.43-31.32-13.73-41.66c-6.16-27.73-10.14-51.32-0.7-80.84
			c5.29-16.57,13.26-31.6,19.65-43.66c5.21-9.84,8.99-16.95,9.61-21.66h0.06c0.5-3,0.32-9.01,0.3-8.99
			c-0.17-0.23,0.14-0.26,0.03-0.04c3.71,3.39,6.61,7.48,9.71,11.33c3.12,3.85,6.94,7.04,11.15,9.65
			c6.62,4.15,12.57,9.34,18.79,14.05c5.27,4.01,11.19,7.25,15.44,12.64c5.51,2.83,9.28,7.78,13.47,12.16l0.33-3.95
			c-4.57-4.73-9.26-9.29-14.41-13.36c-9.81-7.31-28.52-21.62-35.7-27.13c-1.81-1.39-3.52-2.93-5.11-4.6
			c-6.06-6.33-12.34-12.52-13.46-22.33c-0.82-7.17-1.77-14.32-2.87-21.44c-0.55-3.55,0.56-5.24,3.99-5.53
			c5.03-0.43,9.94-1.46,14.61-3.53c1.35-0.6,2.67-1.08,3.72,0.6c5.57,8.89,11.16,17.77,16.7,26.67c0.17,0.29-0.11,0.86-0.17,1.31
			c0.73-1.01,1.24-0.3,1.82,0.23c11.35,10.17,22.71,20.34,34.07,30.49l3.12-1.52c-0.86-0.78-1.74-1.54-2.61-2.3
			c-11.75-9.77-24.03-18.89-34.04-30.76c-5.17-7.99-10.37-15.96-15.5-23.98c-0.79-1.23-2.14-2.67-0.01-4.09
			c3.91-2.6,7.32-5.69,10.41-9.06c0,0,0.01-0.01,0.03-0.03c5.64-6.15,10.24-13.27,14.86-20.35c1.85-2.41,3.36-4.08,4.83-5.3
			c0.01-0.01,0.01-0.01,0.03-0.01c5.26-5.41,10.05-8.98,16.09-11.69c7.81-3.5,15.64-2.07,19.63,0c3.98,2.08,8.26,5.52,18.18,5.27
			c0,0,23.63-1.28,31.12-1.28c7.5,0,25.85,0.8,37.03,5.27c11.17,4.47,41.81,20.42,50.11,31.51c0.29,0.37,0.52,0.78,0.7,1.19
			c0,0,0,0,0.01,0.01c4.7,5.69,9.13,12.04,11.52,15.44c2.66,4.11,5.2,8.26,7.11,11.39c1.9,3.15,3.15,5.27,3.19,5.36
			c12.73,22.85,27.06,48.36,42.07,67.33c19.46,24.57,48.4,34.04,67.27,34.68c0.43,0.01,0.89,0.01,1.39,0.01
			c10.67,0,36.11-3.27,62.98-6.75c28.51-3.68,57.98-7.48,75.66-8.27c1.54-0.07,2.73-1.34,2.73-2.87V651.15
			C2792.7,649.6,2791.48,648.33,2789.94,648.27z M124.21,177.83c-0.59-0.89-0.47-2.83,1.02-3.29c3.5-1.11,3.85,2.56,6.39,4.55
			C128.42,179.28,125.91,180.3,124.21,177.83z M2362.52,50.06c-5.24-8.16-8.65-16.92-9.88-26.73c-1.38-10.97,2.48-18.51,12.48-22.32
			c11.19-4.25,22.13-9.08,32.95-14.28c2.02-0.98,4.09-1.59,6.09-1.77c0.53,0.03,0.85,0.04,1.15,0.09c8.2,0.95,8.56,1.48,5.64,9.59
			c-1.74,4.85-1.47,9.78,0.03,14.48c1.11,3.53,0.06,5.36-2.54,7.35c-6.68,5.11-13.03,10.74-19.83,15.68
			c-6.91,5.01-12.74,11-17.54,18.2C2367.65,55.49,2365.83,55.2,2362.52,50.06z M2224.59,241.15c0.34-0.72,0.83-1.16,1.67-1.02
			c6.45-5.63,13.51-10.38,19.73-16.37c9.26-8.95,15.05-20.74,23.61-30.22c2.77-3.07,5.34-6.36,8-9.55c0.63-1.65,1.75-2.84,3.16-3.76
			c5.13-6.59,10.02-13.4,15.81-19.42c-0.01-1.36,0.91-1.88,1.9-2.33c2.9-6.69,8.27-11.43,12.31-17.23
			c16.24-23.35,35.52-44.18,51.8-67.52c6.12-8.75,9.77-19.06,16.67-27.3c3.68-4.39,8.11-7.64,12.38-11.22
			c1.46-1.22,2.3-0.66,3.39,0.79c2.02,2.7,3.15,5.73,3.39,9.03c0.26,3.75,2.07,6.42,4.64,8.76c15.07,13.66,26.66,29.83,33.44,49.64
			c2.31,6.76,5.3,13.27,8.1,19.83c3.19,7.45,9.46,11.69,15.73,15.78c2.15,1.42,2.38,2.84,2.2,5.21
			c-1.45,17.88-7.28,34.83-10.28,52.38c-0.99,5.73-3.91,10.61-8.11,14.63c-6.71,6.42-13.23,13.04-19.95,19.46
			c-3.35,3.19-4.39,6.62-3.36,11.42c1.81,8.34,0.89,16.82,0.6,25.33c-0.57,17.06,0.83,34.21-0.09,51.23
			c-1.25,22.86-0.46,45.93-4.98,68.55c-1.21,6.08,0.83,12.06,1.28,18.11c0.98,13.41,1.46,26.86,4.71,40.03
			c2.56,10.44,7.93,18.37,16.65,23.9c6.85,4.34,11.75,10.01,13.66,18.47c1.03,4.54,1.41,9.45-1.39,12.57
			c-5.31,5.9-62.68,15.8-67.78,34.07c-0.76,2.73-2.48,6.84-4.84,6.56c-2.25-0.27-5-2.11-7.53-3.29
			c-12.6-5.82-25.15-11.73-37.73-17.61c-6.33-3.04-12.67-6.08-19.02-9.12c-5.4-1.19-9.65-5.21-15.21-6.35
			c-4.83-0.96-9.32-0.49-13.93,0.46c-6.42,1.34-8.11,0.62-9.59-5.8c-5.43-23.73-12.65-47.08-15.9-71.27
			c-2.2-16.33-4.02-32.87-1.11-49.45c0.62-3.55-0.72-7.15-1.15-10.71c-1.55-13.01-5.14-25.46-8.79-37.93
			c-2.2-7.48-3.56-15.22-0.91-22.74c4.52-12.78,0.92-24.04-4.61-35.3c-4.78-9.72-10.77-18.44-18.11-26.18
			C2223.73,244.3,2221.85,242.99,2224.59,241.15z M2274.94,558.78c1.91-0.04,2.61,1.29,3.04,3.04c0.75,3.15,1.64,6.23,2.47,9.35
			c-5.31,1.01-10.04-3.72-8.88-8.96C2272,560.38,2272.85,558.81,2274.94,558.78z M2273.76,556.24c1.23-2.21,2.15-3.86,3.2-5.72
			C2278.12,556.33,2278.12,556.33,2273.76,556.24z M2271.08,555.55c-4.01,5.29-3.68,10.56-1.11,16.06c1.68,3.56,0.76,5.47-2.9,5.46
			c-11.91,0-23.6,2.3-35.4,3.36c-1.09,0.09-2.34-0.1-3.25,0.37c-2.76,1.44-3.43,0.1-4.01-2.48c-3.5-15.77-7.4-31.45-10.66-47.28
			c-2.5-12.14-6.49-23.5-13.1-33.84c-3.19-5.01-3.3-10.9-3.86-16.55c-1.18-11.88-1.85-23.81-6.48-34.99
			c-0.92-1.24-1.31-2.64-1.28-4.19c-5.56-17.02-11.22-34.01-16.56-51.11c-0.89-2.84-4.24-5.31-1.85-8.96
			c3.78-5.82,7.3-12.57,14.92-12.78c20.71-0.55,40.43-6.92,60.62-10.53c3.13-0.56,4.15-0.01,5.23,3.22
			c3.23,9.64,2.56,19.35,1.52,29.14c0.3,1.05,0.37,2.08-0.43,2.96c0.03,0.22,0,0.43-0.09,0.63c0.46,0.6,0.56,1.24,0.09,1.9
			c0.04,1.25,0.09,2.48,0.14,3.72c0.76,1.51,0.56,3.12,0.45,4.71c0.7,17.22,3.52,34.05,6.97,50.91c2,9.78,2.13,20.06,4.54,29.87
			c1.59,6.48,2.43,13.17,5.01,19.37c3.56,3.78,5.04,8.4,5.16,13.6C2279.87,531.28,2279.88,543.99,2271.08,555.55z M2225.03,255.46
			c0.27,1.26-0.33,2.11-1.11,3.03c-0.99,1.16-2.83,1.62-3,3.49c1.02,1.32,1.97,0.39,2.43-0.2c3.94-5.18,5.85-1.74,7.91,1.85
			c3.89,6.72,8.03,13.3,11.73,20.14c2.46,4.54,3.62,9.72,1.85,14.74c-4.83,13.77-2.9,27.09,1.64,40.39c1.11,3.23,1.9,6.59,2.74,9.91
			c0.7,2.76,0.39,4.34-3.06,4.95c-12.98,2.33-25.89,5.11-38.82,7.74c-7.38,2.1-14.89,3-22.53,2.79
			c-8.59,1.48-13.57,7.45-17.95,14.76c-6.41,10.69-9.32,23.19-16.42,33.41c-4.88,7.04-11.49,12.49-17.11,18.87
			c-1.38,1.57-3.1,2.81-4.31,4.5c-5.56,7.77-13.59,10.08-22.15,11.23c-14.68,1.97-28.93,5.34-42.05,12.93
			c-4.94,2.86-10.34,4.65-15.65,6.64c-3.27,1.24-4.83,0.27-5.49-3.33c-0.88-4.8-3.6-9.06-3.89-14.06c-1.8-1.31-1.62-3.49-2.17-5.34
			c-4.84-14.43-12.54-27.06-22.32-38.36c-3.89-4.47-8.16-8.55-11.22-13.82c-4.73-8.1-5.67-17.31-7.45-26.25
			c-2.27-11.39-1.98-23.22-5.13-34.45c-0.91-3.25,1.28-3.55,3.07-4.37c13.39-6.05,26.84-11.92,40.85-16.27
			c17.9-5.56,34.93-13.7,52.69-19.6c20.44-6.79,40.59-15.05,61.8-18.54c14.53-2.38,28.39-6.39,41.71-12.42
			c6.25-2.83,11.71-7.54,17.55-11.35C2214.51,244.97,2223.66,249.05,2225.03,255.46z M1499.8,541.36
			c-5.72-1.01-11.36-2.48-17.11-3.27c-9.55-1.31-19.1-0.1-28.62,0.79c-6.15,0.59-6.23,0.53-4.84-5.47
			c1.32-5.64,3.4-10.69,7.45-14.98c5.64-5.97,9.24-13.59,13.64-20.54c1.59-2.51,2.94-3.32,5.99-1.92c4.25,1.97,7.83,4.61,11.17,7.87
			c7.21,7.02,14.55,13.89,21.92,20.72c2,1.87,10.58,8.49,15.57,11.98c-0.52,0.17-1.03,0.33-1.55,0.47
			C1516.32,542.99,1508.15,542.84,1499.8,541.36z M1510.95,546.56c-12.87,13.21-27.65,20.54-43.4,25.77
			c-9.62,3.19-18.87-1.12-28.15-3.3c-2.24-0.53-1.94-2.11-1.25-3.79c2.66-6.48,5.37-12.91,7.88-19.45c0.73-1.9,1.85-2.3,3.58-2.4
			c6.74-0.39,13.47-0.9,20.22-1.34c6.13-0.39,12.19-0.1,18.23,1.49C1495.19,545.43,1502.52,546.3,1510.95,546.56z M1382.41,522.95
			c2.28,5.52,4.54,11.04,6.89,16.8c-4.65,0.99-8.22-1.19-11.92-2.18c-18.83-4.98-37.6-10.14-56.37-15.34
			c-4.06-1.12-8.29-2.11-11.98-4.09c-3.16-1.69-5-5.01-5.33-9.13c-0.53-6.52,1.9-12.58,2.33-18.94c0.37-5.56,1.19-11.09,1.45-16.66
			c0.14-3.32,1.87-4.28,4.48-4.32c22.29-0.32,44.58-0.93,66.87-0.73c12.7,0.1,13.59,2.01,10.67,14.66
			c-2.57,11.12-4.77,22.35-8.04,33.29C1380.76,518.69,1381.52,520.81,1382.41,522.95z M1267.18,534.25
			c-5.16-1.38-10.25-2.36-15.45-3.42c-8.42-1.74-16.2-6.48-25.12-5.74c-1.26,0.1-1.92-0.53-2.11-1.98
			c-1.91-14.95-3.88-29.89-5.82-44.84c-0.06-0.46,0.1-0.95,0.23-2.1c9.03,5.04,18.57,8.23,27.16,13.64
			c4.71,2.97,9.69,5.29,14.85,7.3c10.4,4.08,19.98,10.08,30.72,13.43c2.34,0.73,4.27,1.8,5.8,3.07l-2.76,26.76
			C1285.99,539.47,1277.68,537.05,1267.18,534.25z M1294.67,545.74l-0.11,24.96l-0.01,0.09l-1.84-0.32
			c-18.11-3.07-35.19-10.17-61.01-12.21c-1.48-0.11-3.04-0.22-4.65-0.32c-0.14-7.2-0.36-18.81-1.38-25.13
			c-0.42-2.57,1.05-2.53,2.79-2.51c7.17,0.07,13.82,2.86,20.72,4.27c2.92,0.6,5.85,1.19,8.78,1.68c4.21,0.7,13.39,4.11,17.95,5.03
			C1279.2,541.94,1289.71,544.44,1294.67,545.74z M983.56,627.84c-3.26-0.16-7.41,2.25-9.45,0.01c-1.67-1.82-0.62-6.25-0.99-9.49
			c-0.36-2.94-0.88-6-3.68-7.61c-6.42-3.68-8.24-10.04-8.92-16.89c-0.4-4.22-0.07-8.53-0.07-12.8c1.88-11.48-2.38-21.69-6.41-31.81
			c-1.46-3.71-0.72-5.95,1.57-8.65c6.76-8,13.08-16.36,18.11-25.71c1.55-2.87,3.12-5.69,3.58-9.02c0.43-3.13,1.82-5.39,5.04-6.22
			c13.36-3.45,24.34-11.09,33.97-21.08c7.93,18.24,35.73,83,42.27,97.06c-0.96,1.94-2.56,3.46-4.74,4.75
			c-22.18,13.07-43.04,28.11-62.27,45.57C989.07,628.21,986.31,627.97,983.56,627.84z M985.34,633.34
			c-2.38,2.07-4.73,4.18-7.17,6.15c-0.85,0.68-1.77,2.47-2.94,1.13c-1.25-1.41-1.67-3.46-1.51-5.47c0.1-1.41,0.6-2.37,2.28-2.38
			c3.06-0.03,6.12-0.27,9.18-0.43C985.24,632.68,985.28,633.01,985.34,633.34z M448.97,788.32c2.04,11.76,3.02,23.63,4.75,35.4
			c1.64,11,2.86,22.06,4.27,33.1c0.16,0.27,0.39,0.55,0.46,0.83c2.47,9.15,2.47,9.12-6.68,9.87c-4.62,0.39-9.12,1.91-13.8,1.74
			c-3.69-0.14-7.22,1.87-10.97,0.8c-6.22,0.7-12.47,1.31-18.67,2.17c-2.61,0.37-3.79-0.5-4.11-3.19c-0.8-7.14-1.69-14.28-2.54-21.43
			c-3.39-26.83-7.5-53.57-10.3-80.5c-0.3-2.96,0.55-3.91,3.07-4.04c4.87-0.24,9.77-1.03,14.59-0.66c11.55,0.9,22.92-0.13,34.24-2.25
			c3.43-0.65,4.7,0.26,4.77,4.09C448.21,772.31,447.62,780.58,448.97,788.32z M499.38,651.1c-0.5-5.04-1.35-10.07-1.44-15.09
			c-0.16-9.88-0.9-19.7-2.1-29.49c-0.72-5.89-4.57-9.54-8.76-12.78c-3.13-2.44-6.62-4.38-9.78-6.79c-1.65-1.26-4.48-1.82-4.06-4.84
			c0.4-3.02,2.63-4.35,5.23-5.1c-1.06-1.38,0.46-2.3,0.57-3.48c-0.55-3.63,1.36-4.98,4.48-5.31c18.08-1.97,35.89-5.83,53.87-8.63
			c15.94-2.47,31.91-4.98,47.6-9.03c5.04-1.29,8.96,1.26,12.84,8.67c1.57,1.36,3.06,2.86,5.33,2.73c5.56-1.01,11.13-1.98,16.69-3.04
			c3.69-0.7,5.69,0.33,6.62,4.62c3.32,15.51,8.07,30.63,17.22,43.5c6.41,9.02,14.39,16.82,21.14,25.59
			c9.38,12.22,18.27,24.85,27.35,37.31c7.47,10.28,6.41,21.8,4.25,33.44c-0.45,2.4-1.13,4.75-0.75,7.25
			c1.12,2.83,3.62,3.46,6.09,3.96c6,1.25,4.29,6.88,5.24,10.66c0.85,3.48-2.69,3.6-5.06,4.34c-11.62,3.52-23.81,3.94-35.49,7.44
			c-6.2,1.87-11.66,5.72-18.12,6.62c-1.09,0.16-2.15,0.55-3.25,0.56c-14.92,0.27-28.81,5-42.74,10.07
			c-5.26,1.91-11.4,1.41-17.15,1.55c-28.93,0.72-57.65-3.02-86.5-4.25c-13.89-0.59-27.58,1.44-41.36,2.43
			c-2.15,0.16-4.7,1.02-4.24-2.93c-1.49-10.28-1.95-20.31,2.73-30.15c6.32-13.27,12.22-26.73,15.65-41.26
			c0.95-4.01,3.62-7.54,5.3-11.39c1.03-2.4,2.59-2.43,4.61-1.84c7.73,2.28,8.89,1.88,13.57-5c1.61-2.37,2.94-4.94,4.41-7.43
			C498.56,653.04,498.75,652.07,499.38,651.1z M425.85,575.23c-19.98,3.58-39.25,10.24-58.94,15.08
			c-11.55,2.84-23.41,2.6-35.03,4.68c-2.5,0.46-2.71-1.52-3.06-3.33c-1.55-8.2-2.63-16.55-4.83-24.56
			c-2.59-9.38-3.12-19.04-4.9-28.51c-0.59-3.03,0.27-4.09,3.12-4.48c3.88-0.52,7.68-1.77,11.58-2.18c3.62-0.39,5.07-4.22,8.66-4.95
			c3.17-0.65,3.72,0.22,4.21,2.94c1.72,9.84,3.55,19.66,5.43,29.47c1.06,5.53,1.65,5.7,6.95,3.69c10.69-4.05,19.96-11,30.22-15.88
			c11.42-5.43,19.62-15.05,28.88-23.32c3.56-3.19,7.12-6.42,10.53-9.81c1.59-1.59,2.59-1.72,4.12,0.1c4.24,5.03,8.65,9.9,13.31,15.2
			c-3.5,2.77-6.89,4.24-10.64,5.1c-5.11,1.16-6.88,4.98-3.3,8.69c4.83,4.98,3.73,10.23,2.79,15.84c-0.4,2.43-1.35,4.85-1.28,7.25
			C433.86,572.11,431.13,574.28,425.85,575.23z M345.81,303.93c-2.53,13.82-4.77,27.58-3.09,41.77c0.43,3.69,3.65,6.49,2.63,10.47
			l0.2-0.11l-0.14,0.17c-15.68-7.88-29.23-19.07-43.26-30.42c4.24-3.66,9.02-5.63,13.18-8.57c11.46-8.1,22.66-16.56,32.85-26.41
			c0.01,0,0.01-0.01,0.03-0.01c0.03,0.04,0.07,0.1,0.11,0.16l-0.14-0.14C349.64,295.61,346.6,299.56,345.81,303.93z M764.07,257.37
			c1.18,3.82,0.52,7.77,0.62,11.66c0.39,15.57,0.62,31.14,1.05,46.71c0.09,3.33-0.47,5.13-4.12,6.13
			c-19.93,5.52-39.8,11.32-59.62,17.23c-4.37,1.31-8.72,2.46-13.17,3.27c-0.78,0.1-1.55,0.2-2.33,0.32
			c-7.4,4.19-15.8,5.08-23.65,7.8c-3.02,1.03-5.43-1.23-7.76-2.73c-7.73-4.95-15.24-10.27-22.99-15.17
			c-6.1-3.86-8.46-10.44-11.1-16.57c-4.97-11.55-12.08-21.08-20.94-29.67c-10.77-10.46-20.62-21.93-31.17-32.63
			c-7.97-8.1-11.55-18.04-12.77-29.23c-2.92-9.75,0.82-17.84,5.86-25.59c0.09-1.08,0.76-1.58,1.65-1.85
			c-0.49-1.65,0.63-2.79,1.29-4.02c3.62-6.88,7.57-13.57,10.84-20.62c3.98-8.57,10.7-13.14,18.84-16.5
			c25.03-10.33,49.72-21.5,74.97-31.22c8.36-3.22,16.92-5.89,25.29-9.05c6.97-2.63,13.44-1.68,20.01,1.59
			c5.06,2.53,10.57,3.98,15.28,7.34c6.61,4.74,8.67,11.99,10.61,19.55c5.76,22.43,11.46,44.9,17.72,67.17
			c3.56,12.67,6.59,25.26,5.8,38.58c-0.07,1.31-0.2,2.6-0.3,3.91C765.12,254.94,764.68,256.15,764.07,257.37z M741.14,130.81
			c-1.36-2.83-3.23-5.33-5.63-7.15c-10.89-8.33-22.56-14.62-36.58-13.54c-3.22,0.24-6.36,1.57-9.55,2.4
			c-0.82,0.19-1.65,0.37-2.47,0.56c-4.55,2.48-9.38,4.15-14.41,5.17c-0.5,0.07-1.01,0.14-1.52,0.22
			c-5.85,4.47-12.98,5.86-19.49,8.73c-18.13,8-36.45,15.48-54.69,23.19c-4.18,1.78-8.33,3.69-12.48,5.54
			c-0.29,0.13-0.59,0.52-0.8,0.46c-4.91-1.13-5.56-6.32-8.24-9.58c-1.9-2.3,0.07-3.48,1.59-4.6c11.59-8.49,24.4-14.55,37.37-20.21
			c3.85-1.68,7.43-4.37,11.88-4.27c14.07-6.29,28.39-11.83,43.3-15.54c3.48-0.86,6.88-2.43,10.58-2
			c27.39-6.66,50.51,2.66,71.29,20.72c2.57,2.24,2.18,7.41-0.62,10.86C746.64,136.75,743.87,136.47,741.14,130.81z M577.41,158.04
			c1.19,1.72,0.47,3.02-0.43,4.42c-8.85,13.67-15.65,28.58-23.51,42.86c-1.8,3.26-3,7.01-2.7,10.83
			c1.03,12.81,2.7,25.45,10.94,35.86c1.88,2.37,3.94,4.6,5.92,6.88c13.99,13.66,27.42,27.91,40.46,42.51
			c5.77,6.48,8.49,15.24,12.18,23.18c2.18,4.73,5.03,8.57,9.34,11.36c8.39,5.46,16.69,11.04,24.98,16.65
			c4.02,2.71,8.1,2.67,12.61,1.34c23.96-7.05,47.96-13.9,71.94-20.81c9.35-2.92,18.66-5.96,28.25-7.9c2.03-0.93,2.48-2.6,2.44-4.8
			c-0.53-28.25-0.88-56.51-1.54-84.77c-0.23-10.11-4.22-19.46-6.72-29.08c-5.29-20.39-10.83-40.72-16.26-61.08
			c-0.88-3.3,3.83-9.29,6.85-8.7c1.59,0.32,1.45,2.01,1.77,3.19c3.94,14.84,7.83,29.69,11.72,44.54
			c3.92,14.99,7.38,30.15,11.86,44.97c3.88,12.85,3.48,25.97,3.82,39.04c0.56,21.17,0.57,42.35,0.86,63.54
			c0.01,1.29,0.33,2.6,0.42,3.89c0.17,2.73,0.37,5.26-3.63,4.94c-2.67-0.22-4.29,3.39-7.28,2.87c-8.86,4.06-18.21,6.42-27.62,8.57
			c-11.17,4.77-23.02,5.96-34.87,7.04c-9.84,0.89-19.42,2.84-28.87,5.96c-12.38,4.09-24.99,7.41-37.44,11.33
			c-2.84,0.89-3.95,0.2-4.73-2.59c-2.7-9.74-5.63-19.4-8.26-29.17c-0.67-2.5-1.74-4.28-3.72-5.92c-9.57-7.9-18.89-16.09-27.43-25.23
			c-4.21-4.5-9.57-7.84-14.46-11.6c-13.73-10.53-25.46-23.04-34.68-38.06c-5.74-7.5-11.39-15.08-17.31-22.43
			c-2.74-3.4-3.3-7.24-2.27-11.06c5.5-20.44,11.5-40.75,21.11-59.56c4.25-8.32,9.36-16.16,16.79-22c1.78-1.39,2.71-1.71,3.98,0.46
			C573.62,152.42,575.47,155.27,577.41,158.04z M789.22,118.54c8.95-1.05,16.14-6.87,23.83-11.16c2.97-1.68,4.78-2.67,7.01,1.26
			c5.2,9.13,10.94,17.92,16.43,26.89c3.4,5.53,4.18,11.13,2.14,17.72c-2.64,8.49-0.66,17.65-2.08,26.4
			c-1.62,10.01,0.88,19.53,3.13,29.03c3.4,14.35,3.6,28.93,3.49,43.55c-0.04,7.5-0.06,7.54-7.35,7.6c-7.07,0.04-14.13,0-21.21,0
			v0.19c-8.49,0-16.98-0.13-25.45,0.07c-2.73,0.06-4.11-0.46-4.01-3.62c0.63-19.27-6.22-36.98-10.77-55.15
			c-5.24-20.88-10.4-41.84-16.59-62.43c-2.67-8.89,0.33-15.5,3.96-22.51c0.49-0.95,0.96-3.06,3.04-1.36
			C772.23,121.13,780.91,119.52,789.22,118.54z M772.1,100.56c4.55-6.23,9.52-12.15,14.33-18.17c0.8-1.01,1.57-2.1,2.51-2.93
			c3.29-2.87,7.27-5.79,11.2-5.47c2.76,0.22,4.09,5.16,5.72,8.2c2.8,5.3,5.44,10.69,8.2,16c0.7,1.38,1.48,2.69-0.32,3.73
			c-10.47,6.08-20.32,13.89-32.62,12.93c-1.13,0-1.61,0.04-2.08,0c-4.61-0.37-10.31,0.62-12.27-4.93
			C765.4,105.99,769.98,103.46,772.1,100.56z M844.46,153.34c2.46-0.6,2.5,3.17,3.68,4.96c3.65,5.49,6.91,11.25,10.6,16.69
			c1.41,2.1,1.52,3.12-0.68,4.57c-1.92,1.26-3.27,2.36-4.94-0.32c-2.9-4.64-5.95-9.18-9.03-13.67c-1.31-1.88-1.62-3.83-1.18-6.02
			C843.33,157.36,842.32,153.86,844.46,153.34z M1211.27,226.89c-3.78-5.95-5.33-12.84-6.45-19.81c-0.22-1.35-0.76-3.16,0.56-3.99
			c1.28-0.8,2.28,0.76,3.17,1.55c3.79,3.4,7.48,6.92,11.2,10.4c0.75,0.8,1.49,1.61,2.25,2.41c10.96,10.99,20.83,23.07,30.49,35.26
			c5.33,6.72,6.65,15.76,9.21,23.97c1.82,5.9,2.89,12.06,4.51,18.04c0.65,2.36,0.37,3.58-2.03,4.35
			c-15.11,4.83-30.15,9.87-45.7,12.97c-5.73,1.13-10.94-0.07-15.41-4.04c-3.55-3.13-7.35-5.08-12.11-5.8
			c-4.74-0.73-7.07-5.39-9.72-9.12c-0.72-1.03,0.37-1.75,0.99-2.46c4.57-5.2,8.4-10.93,11.55-17.16c3.6-9.74,9.48-18.05,15.07-26.57
			c0.47-1.01,0.72-2.3,1.48-2.99C1216.28,238.52,1215.16,232.99,1211.27,226.89z M1401.37,288.55c-8.22-10.23-5.52-26.6,5.24-35.37
			C1404.09,264.81,1398.8,275.56,1401.37,288.55z M1395.13,259.03c-2.51,2.74-1.65,6.52-1.88,9.9c-0.69,10.07-0.63,10.07-9.35,6.28
			c-6.08-2.64-12.14-5.31-18.23-7.9c-2.18-0.93-3.03-2.24-3.45-4.94c-2.56-16.65-5.89-33.16-9.67-49.56
			c-2.13-9.21-1.68-18.96-5.36-27.85c-0.43-1.05-0.7-2.17-1.13-3.22c-2.83-6.84-2.82-6.79,3.04-10.64
			c12.88-8.46,25.42-17.41,36.65-28.18c2.56-2.46,3.55-2.87,5.39,1.01c10.18,21.6,18.91,43.82,27.36,66.2
			c1.25,3.3,1.38,6.69,1.41,11.02c1.06,8.92-4.5,15.55-10.44,21.67C1404.44,247.99,1400.02,253.73,1395.13,259.03z M1390.06,281.19
			c3.22,1.41,5.56,3.55,6.92,6.88c1.67,4.05,3.94,7.93,4.91,12.15c1.19,5.21,3.83,8.78,8.13,11.29c2.03,1.18,3.17,2.53,1.39,5
			c-1.8,2.5-4.05,3.83-6.19,1.24c-3.23-3.92-6.81-2.61-10.64-1.94c-15.64,2.7-31.18,5.72-46.29,10.97
			c-19.53,6.79-38.71,14.36-55.77,26.67c-3.89,2.81-5.7,2.3-7.61-2.11c-2.61-6.08-3.36-12.6-4.9-18.91
			c-2.87-11.73-5.59-23.5-8.37-35.24c-0.86-1.08-0.78-2.23-0.29-3.42c-2.82-2.18-2.57-5.76-3.26-8.73
			c-2.15-9.26-5.13-18.23-8.11-27.2c-2.28-6.82-7.14-11.48-11.58-16.52c-1.98-2.25-2.69-4.04-1.57-7.28
			c4.37-12.77,4.16-12.74,15.98-7.67c30.88,13.23,61.83,26.3,92.71,39.55C1367.1,270.87,1378.53,276.17,1390.06,281.19z
			 M1384.6,360.76c-1.08-3.62-2.14-7.24-3.2-10.87c-1.95-7.38-3.89-14.78-5.85-22.16c-0.79-2.94-0.1-4.29,3.16-4.73
			c6.51-0.85,12.93-2.24,19.37-3.46c5.29-1.02,6.88,0.32,7.14,5.85c0.36,7.77,0.8,15.54,1.22,23.32c1.41,3.63,0.72,7.51,1.13,11.26
			c3.65,15.04,4.84,30.12,0.96,45.37c-0.55,2.18-1.41,2.57-3.22,2.28c-6.2-0.99-12.41-1.98-18.63-2.89
			c-2.46-0.36-3.39-1.26-3.02-4.16C1385.37,387.34,1385.17,374.06,1384.6,360.76z M1283.96,476.23c0.57,6.08,0.37,12.08,0.06,18.15
			c-0.2,3.89-1.59,4.9-4.87,4.17c-3.38,1.01-6.1-1.06-9.06-2.08c-15.12-5.53-28.64-14.35-42.48-22.51c-0.42-0.24-0.8-0.62-1.24-0.7
			c-9.31-1.87-10.51-9.32-11.27-17.35c-2.31-24.39-6.56-48.31-16.47-70.79c-1.02-2.31-1.58-3.72,2.03-4.29
			c8.22-1.29,16.33-3.25,24.43-5.11c4.18-0.96,8.42-1.91,12.6-1.13c11.81,2.18,22.28-1.68,32.53-7.08c4.19-2.2,8.27-4.64,12.75-6.25
			c2.36-0.85,3.66-0.19,5.17,1.67c6.65,8.14,10.3,17.65,8.85,28.14c-2.18,15.78,0.55,31.07,2.56,46.48
			c0.69,5.37,1.48,10.71,2.11,16.09c0.46,3.91-1.01,6.15-5.03,6.64C1289.83,461.05,1283.29,469.19,1283.96,476.23z M1163.63,522.29
			c0.9,3.36,0.26,4.98-3.16,5.93c-17.41,4.81-34.67,10.11-51.65,16.4c-1.85,0.69-3.59,0.53-5.46-0.27
			c-6.76-2.9-13.67-4.95-21.05-2.69c-1.94,0.59-2.53-0.86-3.03-2.23c-6.87-19.17-15.44-37.59-22.88-56.5
			c-1.84-4.68-3.73-9.36-5.64-14.03c-1.11-2.71-0.88-4.35,2.53-4.02c1.71,0.16,3.45,0.03,5.17,0.03c8.03,0.53,15.41-2.87,23.08-4.35
			c13.41-2.6,26.89-4.87,40.42-6.72c9.9-1.36,19.32,1.39,28.72,3.81c9.31,2.4,18.73,4.09,28.19,5.53c3.15,0.49,3.32,1.36,3.17,4.83
			c-0.47,11.29-6.08,19.19-12.65,27.49C1163.59,502.81,1160.99,512.34,1163.63,522.29z M1119.68,547.41
			c-3.66,2.73-8.23,4.18-8.52,2.02c-0.27-2.25,3.73-3.16,7.77-3.81C1119.97,545.47,1120.51,546.78,1119.68,547.41z M979.82,481.76
			c-0.88-1.7-2.3-4.01,0.99-4.18c15.05-0.79,24.79-12.22,36.48-19.69c6.81-4.34,13.24-9.29,20.06-14.13
			c1.71,5.34,3.29,10.61,5.1,15.78c0.88,2.5-0.11,3.16-2.2,3.58c-2.3,0.47-4.51,1.41-6.82,1.84c-8.93,1.68-16.42,6.1-22.71,12.84
			c-7.91,8.46-17.21,14.35-29.44,17.16C984.27,489.41,981.85,485.64,979.82,481.76z M748.69,821.96c-0.8,0.47-1.71,1.16-2.56,1.13
			c-6.48-0.2-10.79,3.5-14.42,8.47c-1.64,2.24-2.97,2.57-5.04,0.19c-5.46-6.31-11.23-12.32-16.82-18.53
			c-3.16-3.5-7.01-6.46-7.86-11.83c-0.24-1.52-1.85-3.06-3.2-4.08c-2.94-2.24-5.36-5.04-7.81-7.77c-8.62-9.57-8.76-20.57-0.45-30.59
			c2.37-2.84,5.14-5.24,7.99-7.38c1.19-0.16,1.32,0.9,1.75,1.46c5.49,7.17,11.75,13.56,17.97,19.99
			c4.55,5.46,11.78,14.18,15.5,18.94c5.66,7.28,16.5,26.28,16.6,26.44C750.96,820.09,750.19,821.08,748.69,821.96z M756.45,834.22
			c-2.6-1.64-4.95-3.07-7.27-4.6c-0.63-0.4-1.13-1.03-1.75-1.62C753.85,825.07,755.17,825.96,756.45,834.22z M687.27,822.5
			c3.55-3.91,6.19-8.72,10.77-11.56c1.12-0.7,2.08-1.95,3.55-0.29c6.94,7.99,14.81,15.02,21.3,23.44c2.81,1.78,3.46,3.48,0.53,6.09
			c-3.19,2.83-5.9,6.26-8.9,9.34c-2,2.04-3.98,2.56-5.47-0.65c-7.8-7.4-14.23-16.13-21.64-23.91
			C686.61,824.14,686.4,823.45,687.27,822.5z M681.08,817.46c-3.55,0.04-11.63-9.44-11.59-13.47c0.06-5.4,8.11-13.26,13.77-13.43
			c3.89-0.11,11.52,8.06,11.59,12.41C695.06,809.7,685.96,817.39,681.08,817.46z M717.43,852.73c3.07-3.46,6.23-6.84,9.48-10.14
			c2.13-2.17,3.82-1.44,4.93,1.26c5.37,5.44,10.74,10.89,16.11,16.32c1.01,1.03,1.26,1.95,0.36,3.38
			c-3.27,5.16-6.42,10.43-9.58,15.65c-1.22,2.03-2.33,2.94-4.01,0.33c-1.62-2.54-4.34-4.28-5.21-7.43c-1.24-0.26-1.87-1.22-2.37-2.3
			c-3.2-3.58-6.41-7.14-8.96-11.27C715.07,857.01,715.56,854.85,717.43,852.73z M697.92,740.52c0.4-1.34,1.44-1.05,2.3-1.01
			c6.72,0.42,13.08-0.76,19.09-4.11c2.03-1.12,3.12-0.24,2.37,2.14c-0.95,2.94-1.91,5.93-4.87,7.53c-3.85,2.08-7.28,5.03-10.34,6
			C702.29,751.14,697.03,743.44,697.92,740.52z M697.99,672.96c6.79,7.71,13.3,15.4,11,26.94c-0.04,1.24-0.1,2.47-0.16,3.71
			c-0.62,3.32-1.23,6.64-1.94,10.44c-2.13-2.46-5.39-1.69-7.47-3.5c-3.07-2.67,0.57-5.33,0.46-8.06c0-0.59,0.01-1.16,0.01-1.75
			C701.07,691.38,701.94,682.04,697.99,672.96z M719.22,726.22c-4.34,1.71-6.65,0.29-7.9-4.29c-1.65-6.06-1.05-11.99,0.2-18.01
			c2.04-9.87,0.2-18.74-6-26.76c-12.22-15.8-23.12-32.6-33.82-49.53c-3.99-6.32-8.62-12.06-13.63-17.46
			c-2.51-2.7-3.49-4.02,1.15-5.59c18.66-6.25,37.83-10.71,56.4-17.21c18.57-6.49,37.31-12.44,55.91-18.8
			c10.69-3.65,20.64-9.51,31.61-12.49c1.77-0.49,2.46-1.92,3.39-3.16c3.09-4.14,6.08-8.33,9.69-13.3
			c9.75,18.18,20.11,35.29,28.29,53.47c6.42,14.26,24.23,19.73,36.29,14.43c1.06-0.47,2.21-1.21,3.85-0.57
			c-0.53,2.79-3.1,2.97-4.74,3.86c-15.27,8.39-30.88,16.14-45.92,24.96c-21.43,12.6-40.13,29.18-58.74,45.82
			c-16.92,15.12-34.28,29.67-52.81,42.63C721.42,724.94,720.37,725.76,719.22,726.22z M688.17,659.33
			c-0.56-0.92-1.12-1.82-1.68-2.74C688.16,656.78,688.23,658.01,688.17,659.33z M686.39,656.46c-1.09-0.63-1.71-1.59-1.74-2.9
			C685.67,654.23,686.56,655.01,686.39,656.46z M684.57,653.43c-0.57,0-0.8-0.36-0.88-0.89
			C684.28,652.51,684.47,652.93,684.57,653.43z M420.92,427.76c2.3-2.86,5.53-4.21,9.58-4.08c22.3,0.67,44.64,1.01,66.96,1.48
			c2.81,0.06,8.85,5.26,9.29,7.9c0.33,2.03-1.12,2.97-2.25,4.04c-17.71,16.66-35.42,33.32-53.18,49.92
			c-17.16,16.06-34.37,32.06-51.57,48.07c-1.28,1.19-2.56,2.5-4.04,3.35c-11.46,6.45-22.97,12.78-34.44,19.17
			c-3.45,1.91-5.39,1.72-6.08-3.15c-1.31-9.41-3.32-18.7-4.84-28.06c-0.66-4.01-1.98-5.97-6.22-4.22
			c-3.94,1.62-8.06,1.95-11.86,4.87c-3.22,2.47-8.34,2.3-12.67,3.09c-1.84,0.34-1.8-1.54-2.01-2.7
			c-1.91-10.46-3.71-20.95-5.66-31.41c-0.46-2.47,0.55-3.55,2.51-4.39c22.05-9.39,43.46-20.45,66.11-28.25
			c7.28-2.5,12.06-7.96,17.42-12.91c4.09-3.81,7.32-8.57,11.92-11.81c-0.12-0.92,0.47-1.09,1.15-1.15
			C414.26,434.18,417.96,431.45,420.92,427.76z M410.41,421.31c2.66-0.01,7.3-3.02,5.85,3.89
			C413.74,424.84,412.35,422.6,410.41,421.31z M529.44,242.39c2.84,3.45,5.4,7.14,7.47,11.17c2.84,1.84,3.6,5.27,5.47,7.86
			c10.34,14.26,22.2,27.07,34.66,39.27c3.83,3.76,9.22,5.57,13.01,9.57c0.93,0.96,2.93,1.57,2.24,3.27
			c-0.34,0.85-2.07,1.16-3.22,1.52c-12.41,3.99-24.83,7.94-37.26,11.88c-7.28,2.3-12.83,7.37-18.4,12.39
			c-1.59,1.45-3.1,3.13-4.87,0.29c-6.91-11.04-18.04-16.98-27.5-24.93c-4.44-3.73-9.59-6.56-13.33-11.23
			c-1.25-0.01-2.48-0.06-2.44-1.92l-0.09,0.2c-9.08-5.47-17.31-12.06-24.59-19.95c-2.14-2.31-2.11-3.79,0.42-5.74
			c15.77-12.18,31.14-24.89,46.99-36.94C515.28,233.57,524.66,236.61,529.44,242.39z M522.6,233.15c-0.04-1.06,0.6-1.71,1.71-1.77
			c1.34-0.07,2.05,0.7,2.14,1.91c0.09,1.06-0.67,1.68-1.71,1.85C523.58,235,522.66,234.43,522.6,233.15z M782.52,351.25
			c-0.63,8.46,2.71,15.51,4.58,22.86c1.44,5.69,2.99,11.33,4.62,16.96c0.57,1.97,0.37,2.86-1.8,3.35c-3.96,0.9-7.87,2.15-11.75,3.4
			c-2.8,0.92-3.94-0.29-4.62-3c-2.15-8.53-4.35-17.06-6.85-25.49c-0.56-1.85,0.17-2.11,1.18-2.84c5.13-3.72,8.17-8.95,8.32-15.37
			c0.1-3.89,2.5-3.96,4.58-3.92C783.78,347.24,781.97,350.25,782.52,351.25z M844,309.1c-0.03,4.35,0.75,8.47,2.21,12.47
			c0.63,1.72,0.62,2.56-1.16,3.38c-12.01,5.59-23.93,11.36-35.96,16.88c-2.69,1.24-5.67,1.87-8.57,2.54
			c-3.68,0.85-5.03-0.83-4.11-4.62c0.23-0.95,0.3-1.94,0.53-2.89c0.95-3.86-0.22-6.06-4.29-6.06c-4.97,0-6.31-2.63-6.29-7.73
			c0.03-17.88-0.34-35.78-0.85-53.66c-0.11-4.02,1.23-5.08,4.74-5.03c8.47,0.13,16.96,0.04,25.44,0.04v-0.45
			c8.17,0,16.34,0.13,24.5-0.07c2.89-0.07,3.75,0.78,3.73,3.85C843.81,281.54,844.04,295.33,844,309.1z M583.44,435.83
			c11.73-3.45,23.47-6.94,35.13-10.63c3.1-0.98,4.44-0.37,5.29,3c3.29,12.97,6.55,25.95,10.38,38.75c1.36,4.54,0.17,5.82-3.76,6.22
			c-1.38,0.14-2.7,0.79-4.06,1.21c-1.34,0.9-2.87,0.59-4.34,0.75c-4.22,2.86-9.31,2.77-13.86,4.48c-1.71,0.43-3.42,0.85-5.13,1.26
			c-9.81,4.48-10.3,4.12-12.87-6.29c-2.79-11.25-5.85-22.41-8.9-33.56C580.63,438.49,580.4,436.72,583.44,435.83z M578.21,430.14
			c-2.76-8-5.47-16.01-8.39-23.96c-1.32-3.58,1.45-3.45,3.09-3.94c9.65-2.83,19.35-5.46,29.01-8.23c3.16-0.92,6.25-2.08,9.36-3.13
			c1.62-0.26,2.41,0.55,2.81,2.02c2.2,8.19,4.34,16.39,6.65,24.55c0.57,2.01-0.2,2.63-1.78,3.12c-12.48,3.81-24.98,7.58-37.4,11.55
			C579.39,432.81,578.8,431.87,578.21,430.14z M629.92,435.33c-0.89-3.32-1.75-6.64-2.63-9.94c-0.37-1.38-0.49-2.64,1.19-3.13
			c5.27-1.54,10.54-3.04,15.81-4.57c1.65-0.39,2.17,0.55,2.56,2.03c3.83,14.35,7.65,28.7,11.66,42.97c0.62,2.23,0.12,2.92-1.81,3.4
			c-5.18,1.32-10.34,2.69-15.47,4.19c-2.86,0.85-2.25-1.82-2.56-2.89C635.65,456.74,632.8,446.03,629.92,435.33z M695.4,408.17
			c2.1-0.29,2.83,0.82,2.97,2.74c1.08,13.56,2.14,27.12,3.25,40.67c0.16,2-0.45,3.03-2.43,3.33c-10.86,1.69-21.33,5.07-31.83,8.29
			c-3.12,0.96-4.44,0.24-5.29-2.96c-3.63-13.72-7.45-27.37-11.13-41.08c-0.33-1.25-1.35-3.12,0.76-3.69
			c5.82-1.58,11.26-4.83,17.55-4.24c4.18-2.31,8.72-1.05,13.08-1.45C686.69,409.26,691.04,408.76,695.4,408.17z M694.48,397.18
			c2.56-0.67,2.97,0.96,3.25,3.27c0.53,4.18-2.18,3.38-4.67,3.58c-2.18,0.52-3.43-0.26-3.65-3.16
			C689.05,396.31,692.42,397.71,694.48,397.18z M833.99,410.04c2.94-0.85,5.53-2.1,7.99-3.95c9.91-7.45,19.96-14.71,29.82-22.25
			c2.47-1.9,3.82-2.05,5.3,0.99c2.56,5.27,5.36,10.4,8.04,15.58c1.03,1.59,2.07,3.17,3.1,4.77c1.94,2.99,3.78,6.05,5.85,8.93
			c1.52,2.13,1.03,3.43-0.99,4.71c-17.95,11.33-35.06,24.13-53.6,34.41c-7.21,3.99-15.54,4.97-23.25,7.64
			c-4.47,1.55-9.21,4.15-13.51,3.62c-4.05-0.5-5.08-7.01-8.82-9.58c-9.51-6.55-8.53-17.92-11.43-27.49
			c-0.88-2.87,1.68-2.64,3.13-3.09C801.72,419.51,817.82,414.67,833.99,410.04z M802.98,405.74c8.27-2.43,16.52-5.01,24.72-7.71
			c2.96-0.98,5.99-2.13,6.45-6.19c0.16-1.41,1.62-1.9,2.63-2.63c9.12-6.64,18.28-13.2,27.35-19.92c2.18-1.61,3.23-1.49,4.62,1.03
			c4.05,7.25,4.15,7.18-2.27,12.02c-9.21,6.92-18.41,13.83-27.59,20.8c-2.63,2-5.74,2.51-8.73,3.45
			c-7.32,2.33-14.66,4.64-22.02,6.92c-1.34,0.43-2.73,0.67-4.55,1.11c-3.1-0.34-1.69-3.75-2.66-5.63
			C799.92,407.01,801.09,406.3,802.98,405.74z M782.57,494.26c6.02-1.82,12.04-3.65,17.68-5.36c2.4-0.65,2.83,0.92,3.12,2.36
			c2.47,12.35,3.35,25.03,7.37,37.04c0.42,1.25-0.03,1.75-1.19,2c-1.08,0.23-2.1,0.7-3.15,1.03c-2.84,0.89-6.08,3.04-8.44,2.28
			c-2.74-0.89-1.91-5.41-3.07-8.2c-0.63-1.49-1.03-3.56-2.81-3.58c-4.35-0.03-4.61-3.43-5.43-6.48c-1.61-6-3.22-11.98-4.78-17.98
			C781.57,496.25,780.56,494.86,782.57,494.26z M770.95,492.68c-4.58,1.55-9.15,3.13-13.74,4.65c-1.16,0.39-2.56,0.99-3.12-0.85
			c-1.06-3.56-2.14-7.14-2.94-10.76c-0.52-2.3,1.68-2.03,2.82-2.34c5.5-1.55,11.04-2.9,16.57-4.32c2.37-1.03,4.77-2,7.4-2.01
			c5.77-2.66,12.12-3.29,18.01-5.56c1.29-0.49,2.15-0.23,2.51,1.21c0.79,3.16,1.52,6.35,2.37,9.51c0.42,1.52-0.3,2.21-1.51,2.46
			c-5.08,0.99-9.71,3.82-14.97,4.11C779.89,490.06,775.42,491.37,770.95,492.68z M804.79,480.27c-0.73-2.66-1.35-5.36-2.08-8.01
			c-0.52-1.91-0.17-2.84,1.94-3.42c6.18-1.68,12.28-3.66,18.41-5.59c2.43-0.76,3.76-0.22,4.19,2.67c0.4,2.71,1.32,5.36,2,7.94
			c0.49,2.11-1.16,2.14-2.23,2.46c-6.43,1.94-12.94,3.65-19.35,5.73C805.64,482.71,805.19,481.76,804.79,480.27z M794.9,417.26
			c-3.2,0.76-6.56,1.22-9.54,2.57c-4.31,1.95-5.5-0.11-6.25-3.96c-0.66-3.53-1.71-6.98-2.57-10.47c-0.27-1.08-0.88-2.47,0.62-2.92
			c4.97-1.48,10-2.76,14.58-4.01c1.69-0.33,2,0.5,2.27,1.55c1.15,4.25,2.28,8.52,3.55,12.73
			C798.37,415.46,797.29,416.69,794.9,417.26z M777.83,435.79c0.75,2.33,1.48,4.67,1.95,7.07c1.55,7.86,5.18,13.9,12.51,17.23
			c2.53,1.13,4.19,3.38,4.41,6.66c-18.57,5.3-37.14,10.6-55.71,15.93c-8.62,2.47-17.23,4.88-25.81,7.51
			c-2.6,0.79-3.73,0.75-4.41-2.61c-2.41-12.04-3.4-24.29-5.14-36.42c-0.39-2.79,0.27-4.04,3.02-4.8
			c19.68-5.47,39.28-11.2,58.96-16.67C775.15,427.57,775.22,427.77,777.83,435.79z M773.58,422.04c-1.35,3.92-7.77,3.43-12.01,4.7
			c-17.59,5.24-35.17,10.57-52.95,15.15c-3.16,0.82-3.91-0.16-4.05-3.06c-0.23-4.09-0.6-8.17-0.93-12.42
			c-0.85-3.04,0.52-3.76,3.48-4.34c14.18-2.76,27.85-7.63,41.78-11.35c5.76-1.54,11.53-3.1,17.19-4.97
			c2.81-0.92,3.91-0.16,4.52,2.74C771.6,413.12,774.85,418.38,773.58,422.04z M707.06,489.93c0.57,1.85-0.33,2.53-2.01,2.99
			c-9.54,2.67-19.06,5.41-28.55,8.26c-2.33,0.7-3.48,0.03-4.09-2.37c-2.28-9.02-4.62-18.02-7.01-27.02
			c-0.52-1.91-0.95-3.39,1.61-4.06c10.48-2.74,20.93-5.63,31.38-8.45c0.46-0.13,0.95-0.09,1.61-0.13c2.01-0.55,2.41,0.83,2.71,2.69
			C704.27,471.19,704.26,480.8,707.06,489.93z M643.58,474.12c4.81-1.39,9.68-2.63,14.52-3.95c2.13-0.57,2.7,0.73,3.15,2.53
			c1.18,4.74,2.46,9.46,3.69,14.19c1.15,4.35,2.21,8.72,3.5,13.01c0.6,2,0.26,3.1-1.67,3.69c-4.77,1.44-9.55,2.87-14.29,4.39
			c-1.77,0.56-2.38-0.7-2.67-1.91c-0.89-3.75-2.97-7.17-2.93-11.19c-3.32-5.47-3.65-11.99-5.67-17.92
			C640.49,474.85,642.31,474.48,643.58,474.12z M653.97,511.98c4.52-1.28,9.06-2.5,13.53-3.98c2.17-0.72,3.16-0.22,3.72,2.07
			c1.45,5.83,3.03,11.65,4.54,17.46c0.43,1.65,0.92,3.26-1.58,3.59c-4.97,0.65-9.92,1.41-14.07,2c-2.33,0.11-3.4-0.07-3.83-2.08
			c-1.16-5.41-2.47-10.79-4.02-16.09C651.65,512.85,652.42,512.41,653.97,511.98z M654.33,540.44c3.12,11.13,6.26,22.25,9.39,33.38
			c0.09,0.32,0.11,0.65,0.17,0.96c0.4,1.71-0.52,2.25-1.92,2.67c-5.41,1.59-10.81,3.25-16.19,4.95c-1.58,0.5-2.69,0.43-3.26-1.44
			c-3.56-11.5-7.18-22.98-10.67-34.51c-0.82-2.7,0.92-3.98,3.12-4.44c5.53-1.15,11.09-2.1,16.62-3.26
			C653.17,538.43,653.9,538.91,654.33,540.44z M654.91,583.99c10.36-3.3,10.15-3.23,13.74,7.48c1.39,4.17,0.46,5.74-3.4,6.72
			c-4.04,1.03-7.96,2.61-11.92,3.95c-2.15,0.62-2.56-1.16-3.38-2.5C643.2,588.61,643.52,587.61,654.91,583.99z M667.49,571.85
			c-2.77-10.38-5.77-20.7-8.79-31.01c-0.55-1.91-0.63-3.03,1.72-3.32c4.98-0.59,9.94-1.39,14.66-2.08c2.48-0.57,2.74,1.08,3.19,2.73
			c2.59,9.59,5.18,19.17,7.91,28.72c0.57,2.04-0.23,2.48-1.84,2.97c-4.35,1.35-8.66,2.84-12.95,4.38
			C669.08,575.07,668.16,574.4,667.49,571.85z M674.94,577.47c6.71-2.04,13.34-4.41,20.01-6.64c4.38-1.64,8.72-3.46,13.16-4.85
			c3.45-1.08,7.58,1.69,8.83,5.47c1.71,5.13,0.46,10.51-3.27,11.95c-4.65,1.81-9.38,3.63-14.42,4.05
			c-7.61,2.4-15.24,4.71-22.82,7.22c-2.1,0.69-2.8,0.01-3.23-2.02c-0.56-2.7-0.93-5.59-2.14-7.99
			C668.57,579.77,671.18,578.62,674.94,577.47z M716.22,558.77c-5.54,0.99-10.81,3.6-16.27,5.46c-9.45,3.22-9.35,3.38-12.01-6.76
			c-4.14-15.78-8.36-31.54-12.5-47.34c-0.52-1.98-1.32-3.83,1.94-4.75c21.95-6.1,43.85-12.37,65.69-18.84
			c3.3-0.99,4.12,0.23,4.77,2.99c0.62,2.7,1.32,5.39,2.1,8.06c1.46,5.07,2.76,5.74,7.54,4.21c5.24-1.69,10.53-3.23,15.71-5.08
			c3.07-1.11,4.35-0.11,5.11,3.06c1.75,7.31,3.82,14.53,5.73,21.79c0.7,2.69,1.64,4.38,4.93,4.83c3.68,0.5,2.93,4.77,3.58,7.55
			c0.57,2.53-1.68,2.73-3.25,3.15c-10.33,2.69-20.55,5.74-30.62,9.34c-12.44,4.45-24.98,8.59-37.66,12.25
			C719.55,559.09,718.06,560.03,716.22,558.77z M730.77,560.21c18.23-5.9,36.37-12.12,54.71-17.65c8.26-2.48,16.39-5.39,24.63-7.9
			c1.16-0.36,2.66-1.15,3.45,0.17c0.8,1.31-0.59,2.33-1.32,3.33c-0.1,0.13-0.27,0.2-0.34,0.34c-5.43,10.38-14.68,14.71-24.73,19.09
			c-19.73,8.57-40.52,13.59-60.48,21.26c-0.45,0.17-0.88,0.34-1.09,0.43c-1.35,0.36-2.04-0.17-2.43-1.22
			c-0.23-0.6-0.5-1.19-0.67-1.82C719.14,563.97,719.14,563.98,730.77,560.21z M810.21,500.49c-0.75-3.5-1.16-7.08-2.81-10.33
			c-1.11-2.18-0.32-3.55,2.04-4.21c6.81-1.9,13.53-4.24,20.41-5.79c4.34-0.99,4.81-3.25,3.72-6.98c-0.75-2.5-1.06-5.17-2.01-7.57
			c-1.41-3.48-0.42-4.74,2.99-5.8c11.42-3.56,20.83-11.2,30.86-17.49c10.21-6.39,20.12-13.31,30.1-20.09
			c1.52-1.03,2.57-1.62,3.79,0.36c11.65,18.63,22.85,37.54,33.19,57c0.46,0.86,0.76,1.8,1.16,2.73c0.27,2.46-1.69,2.04-2.84,2.1
			c-10.7,0.56-21.17,2.74-31.74,4.25c-18.5,2.64-36.87,6.03-55.28,9.25c-10.02,1.75-20.22,2.59-30.13,5.17
			C811.21,503.72,810.64,502.51,810.21,500.49z M908.5,429.5c-6.39-10.69-13.16-21.14-19.85-31.64c-1.82-2.86-1.77-4.47,1.44-6.35
			c6.42-3.76,12.54-8.07,18.7-12.27c1.72-1.18,2.86-1.61,4.24,0.5c10.44,15.94,20.9,31.87,30.71,48.23
			c2.66,4.09,5.3,8.17,7.94,12.25c7.4,10.01,13.64,20.72,18.69,32.24c1.08,2.46,0.49,3.03-1.69,3.56
			c-8.85,2.18-17.67,4.52-26.51,6.69c-1.71,0.4-3.4,1.02-4.62-1.69C929.4,462.92,918.61,446.4,908.5,429.5z M907.24,375.04
			c-6.61,4.39-13.27,8.7-19.81,13.21c-2.01,1.38-3.19,1.31-4.42-0.95c-3.58-6.53-7.27-13-10.99-19.43c-1.15-2-1.21-3.22,1.01-4.58
			c6.06-3.73,11.99-7.71,17.91-11.69c1.78-1.21,3-1.28,4.27,0.7c4.27,6.68,8.62,13.27,12.93,19.91c0.26,0.4,0.47,0.85,0.82,1.46
			C908.37,374.13,907.84,374.63,907.24,375.04z M889,347.79c-6.2,3.81-12.24,7.91-18.35,11.89c-0.9,0.6-1.95,1.49-2.77,0
			c-4.87-8.8-9.09-17.91-11.52-27.52c0.01-1.45-0.14-2.77,1.42-2.92c5.29-0.5,8.93-4.51,13.37-6.84c2.54-1.32,4.02-1.42,5.72,1.36
			c4.09,6.78,8.47,13.36,12.84,19.95C890.88,345.51,891.15,346.47,889,347.79z M863.96,361.16c1.35,2.33-0.2,3.06-1.68,4.12
			c-9.78,7.09-19.55,14.23-29.28,21.4c-1.49,1.11-3.15,2.03-3.35,4.42c-0.13,1.55-1.58,2.36-2.97,2.79
			c-8.42,2.63-16.83,5.21-25.23,7.84c-1.94,0.6-2.86-0.17-3.37-2.15c-3.33-12.8-6.72-25.56-10.11-38.35
			c-0.11-0.47-0.26-0.93-0.5-1.78c1.06-3.2,3.85-5.08,6.51-6.94c6.25-4.37,13.73-5.37,20.72-7.53c11.71-3.6,23.25-7.51,34.45-12.55
			c2.27-1.02,2.89,0.24,3.36,1.88C855.26,343.77,859.12,352.69,863.96,361.16z M786.55,338.91c0.27-1.88-0.67-4.74,2.8-4.19
			c1.39,0.23,4.11-0.62,3.22,2.54c-0.66,2.34,0.37,6.06-3.52,6.28C785.72,343.73,786.75,340.88,786.55,338.91z M771.57,354.25
			c-1.08,4.85-5.03,9.08-9.77,10.07c-6.33,1.31-10.73-0.88-14.61-7.53c7.11-2.08,14.12-4.09,21.1-6.22
			C771.92,349.47,772.3,351.02,771.57,354.25z M757.68,368.96c2.4,0.11,3.17,1.32,3.71,3.46c2,8.07,4.05,16.13,6.25,24.16
			c0.82,2.99,0.07,4.39-2.86,5.21c-18.02,5.01-35.99,10.18-53.99,15.28c-1.21,0.33-2.46,0.47-3.69,0.72
			c-2.87,0.57-4.38-0.4-4.41-3.72c-0.03-4.91-0.23-9.81-1.12-14.05c0.33-8.65,4.32-15.58,6.25-23.14c0.47-1.9,1.65-3.72,1.69-5.59
			c0.13-6.79,4.12-8.04,9.55-8.52c7.01-0.6,14.06-1.44,20.75-4.02c1.81-0.69,3.2-0.1,4.32,1.74
			C747.27,365.66,751.78,368.7,757.68,368.96z M705.64,369.91c-0.22,0.79-0.42,1.58-0.63,2.37c-0.76,2.11-1.52,4.24-2.3,6.35
			c-0.83,2.6-1.95,5.14-2.44,7.8c-0.93,5.14-3.78,6.71-8.57,6.75c-6.91,0.06-6.99,0.4-6.35,7.61c0.24,2.73,0.01,4.15-3.23,4.27
			c-10.37,0.42-20.64,1.75-30.39,5.79c-2.2,0.9-3.03,0.26-3.52-1.97c-1.68-7.64-4.34-15.01-5.79-22.75
			c-0.6-3.13-0.03-4.42,2.84-5.27c13.87-4.08,27.72-8.29,41.55-12.47c4.83-1.46,9.68-2.8,14.68-3.46
			C706.64,364.26,707.07,364.71,705.64,369.91z M644.77,411.39c0.22,1.72-0.99,1.95-2.03,2.25c-4.97,1.42-10,2.64-14.91,4.27
			c-2.18,0.72-2.74-0.23-3.22-2.03c-2.11-8.01-4.18-16.06-6.46-24.03c-0.62-2.17,0.01-3,1.87-3.52c4.67-1.32,9.32-2.73,13.97-4.08
			c1.19-0.34,2.48-0.89,3.04,0.98C639.63,393.95,642.2,402.67,644.77,411.39z M631.2,380.13c-4.44,0.85-8.75,2.48-13.11,3.72
			c-1.55,0.45-2.77,0.83-3.43-1.69c-5.5-20.94-11.19-41.82-16.79-62.73c-0.09-0.29,0.06-0.63,0.16-1.45
			c9.44,7.94,17.55,16.86,27.22,23.84c1.57,1.13,1.72,3.09,2.23,4.77c2.56,8.75,4.95,17.55,7.55,26.28
			C636.21,376.84,635.75,379.27,631.2,380.13z M606.34,363.32c1.05,1.29,1.01,2.87,1.03,4.44c2.56,4.78,3.15,10.21,4.58,15.35
			c0.57,2.03-0.6,2.54-2.11,2.99c-14.02,4.11-28.05,8.17-42.04,12.41c-2.48,0.76-3.33-0.13-4.09-2.38
			c-1.42-4.15-3.38-8.13-4.68-12.31c-4.29-13.76-14.45-23.17-22.92-33.79c-2.64-3.32-2.71-4.87,0.52-8.1
			c9.67-9.68,21.93-12.95,34.1-16.72c6.62-2.04,13.27-3.92,19.82-6.16c3.2-1.09,4.51,0.01,5.3,3.16
			C599.28,335.91,602.83,349.6,606.34,363.32z M505.33,236.79c-14.99,12.9-30.94,24.49-46.09,37.14c-1.9,1.58-2.87,1.45-4.34-0.6
			c-6.92-9.69-13.97-19.29-21.03-28.9c-1.49-2.03-2.23-4.21-2.25-6.79c-0.04-3.6-0.37-7.21-0.55-10.18c-0.7-2.89,0.8-3.88,2.31-4.84
			c7.51-4.74,12.6-11.48,15.88-19.99c3.71-9.58,11.39-16.21,17.29-24.16c1.59-2.15,3-0.3,4.04,1.13c3.56,4.97,7.04,10.01,10.6,14.98
			c8.29,11.5,16.56,23.01,24.95,34.43C508.39,232.06,508.77,233.81,505.33,236.79z M456.75,283.02
			c4.91,7.41,12.02,12.34,18.76,17.65c0.86,0.36,1.74,0.69,2.05,1.74c13.99,9.67,27.6,20.01,40.37,31.24
			c7.76,6.82,12.58,17.16,19.79,24.83c10.51,11.17,17.02,24.46,22.1,38.85c1.02,2.92,0.47,4.21-2.3,4.57
			c-10.28,1.34-20.02,5.2-30.2,7.04c-6.46,1.18-12.42,4.38-18.84,6.05c-6.32,1.65-10.83,1.12-15.21-5.14
			c-13.96-19.99-31.87-36.39-47.01-55.35c-9.62-12.05-21.3-22.15-30.13-34.94c-3.59-5.21-3.72-5.13,0.96-8.82
			c11.73-9.26,23.51-18.5,35.22-27.82C453.98,281.57,455.1,280.53,456.75,283.02z M548.73,411.08c-0.88,2.6-1.42,5.86-4.68,7.08
			c-10.46,3.89-20.9,7.8-31.41,11.49c-2.92,1.02-4.34-2.05-5.13-3.89c-1.12-2.61,1.78-1.69,2.93-2.15
			c6.91-2.77,14.2-4.11,20.42-9.18c4.34-3.52,10.41-4.25,15.96-5.39C548.15,408.77,549,409.06,548.73,411.08z M435.08,508.69
			c2.28-4.18,6.89-6.97,10.54-10.3c0.79-0.73,1.51-0.04,2,0.53c4.16,4.9,8.29,9.84,12.41,14.78c0.39,0.47,0.66,1.05,1.09,1.77
			c-1.39,1.29-2.67,2.46-3.92,3.66c-7.99,7.58-7.99,7.6-15.18-1.01c-0.52-0.62-1.13-1.18-1.52-1.87
			C438.84,513.44,433.54,511.49,435.08,508.69z M457.5,504.11c-0.69-0.96-1.36-1.91-2.05-2.87c-6.46-6.26-6.42-6.19,0-12.21
			c17.36-16.27,34.73-32.56,51.96-48.99c2.94-2.8,4.84-3.12,7.43,0.4c2.93,4.02,6.32,7.7,9.65,11.37c1.51,1.67,1.59,2.71-0.17,4.31
			c-12.91,11.6-24.93,24.26-38.22,35.46c-6.69,5.64-12.75,12.09-18.93,18.37c-2.08,2.11-3.32,2.11-4.98-0.36
			C460.83,507.61,459.08,505.93,457.5,504.11z M514.07,433.79c10.46-3.76,20.28-7.31,30.66-11.04c-0.78,5.4-0.95,10.38-4.78,14.52
			c-3.33,3.6-6.12,7.8-9.05,11.81c-1.26,1.72-2.31,1.71-3.63,0.14C523.04,444.23,518.77,439.28,514.07,433.79z M534.93,450.98
			c10.6-10.15,13.72-24.06,17.54-37.64c0.65-2.3,1.45-4.7,3.73-5.96c6.79-3.76,8.65-2.77,11.42,4.62
			c8.39,22.49,13.96,45.86,20.06,69.07c0.78,2.93,0.75,4.41-2.54,5.2c-6.1,1.48-12.19,3.13-17.92,5.83
			c-1.82,0.86-2.48-0.13-3.49-1.26c-9.08-10.41-18.17-20.81-27.33-31.17c-1.31-1.48-2.54-2.9-2.63-5.66
			C534.01,453.35,534.12,451.77,534.93,450.98z M571.75,494.84c11.43-3.39,22.91-6.66,34.37-10.01c8.73-2.54,17.48-5.08,26.2-7.73
			c3.02-0.9,4.55-0.69,5.49,3.22c2.1,8.72,4.87,17.25,7.54,25.79c0.8,2.59,0.53,3.75-2.24,4.51c-15.74,4.31-31.44,8.83-47.15,13.27
			c-0.32,0.09-0.62,0.16-1.46,0.37c-0.73,0.22-1.48-0.62-2.25-1.48c-7.31-8.09-14.58-16.21-21.97-24.21
			C567.69,495.79,570.01,495.35,571.75,494.84z M622.61,521c7.11-2.01,14.2-4.04,21.26-6.22c2.83-0.88,4.25-0.43,4.83,2.93
			c0.75,4.34,1.9,8.62,3.13,12.84c0.65,2.24,0.89,3.6-1.92,4.09c-5.86,1.01-11.62,2.7-17.61,2.82c-6.05,0.13-11.56,2.76-17.36,4.05
			c-2.94,0.65-5.04,0.1-7.14-2.08c-3.4-3.53-7.02-6.84-11.46-11.13C606.06,525.59,614.35,523.34,622.61,521z M611.38,546.78
			c0.96-0.8,2.08-0.76,3.2-0.62c1.91-0.3,3.81-0.62,5.7-0.92c2.34-0.67,4.64-2.1,7.05-0.23c1.94,1.51,2.63,3.6,2.27,5.97
			c-0.32,2.17-2.05,2.54-3.81,2.86c-1.51,0.27-3,0.69-4.5,1.03c-1.9,1.55-4.24,1.22-6.39,1.67c-0.59,0.06-1.19,0.13-1.78,0.19
			c-3.06,1.49-6.43,1.22-9.65,1.87c-2.2,0.45-6.38-4.74-6.29-7.51c0.1-3.42,2.81-2.9,4.88-3.19
			C605.16,547.45,608.27,547.15,611.38,546.78z M629.2,555.93c1.19-0.24,2.38-1.01,2.99,0.93c2.66,8.67,5.36,17.35,8.04,26.01
			c0.22,1.82-1.01,2.31-2.17,2.87c-1.68,0.83-2.04-0.29-2.61-1.62c-3.55-8.22-4.77-17.18-7.47-25.65
			C627.59,557.22,627.65,556.24,629.2,555.93z M640.66,589.69c1.92-0.96,2.36,0.72,2.92,2.04c1.55,3.66,2.33,7.71,5.6,11.03
			c-1.94,0.63-3.19,1.31-4.16,0.09c-2.27-2.86-4.44-5.83-5.62-9.39C638.91,592.03,639.12,590.45,640.66,589.69z M649.67,607.69
			c1.46-1.52,2.76,0.36,3.82,1.52c8.19,8.8,15.54,18.31,21.51,28.9c2.71,4.83,6.72,8.78,8.53,14.19
			c-8.43-9.54-14.69-20.88-23.31-30.26c-3.15-3.42-6.02-7.08-8.93-10.71C650.46,610.27,648.01,609.41,649.67,607.69z M830.86,644.28
			c10.21-6.46,21.26-11.22,31.73-17.15c7.57-4.29,15.21-8.47,22.81-12.71c1.36-0.78,2.46-1.82,3.69,0.5
			c6.69,12.61,13.34,25.22,18.54,38.61c0.17,0.46,0.37,0.9,0.57,1.36c0.26,1.85-1.35,2.03-2.31,2.63
			c-14.35,8.76-28.71,17.49-43.09,26.23c-0.4,0.24-0.86,0.39-1.26,0.63c-8.11,4.93-8.19,4.98-13.43-3.69
			c-6.26-10.4-12.24-21-18.67-31.28C827.57,646.44,828.68,645.66,830.86,644.28z M890.63,608.97c-0.53-0.95-0.69-2.11-1.22-3.83
			c4.57,0.75,8.75,1.08,12.87,0c1.77-0.46,2.4,1.01,3.17,2.28c6.89,11.35,13.39,22.91,19.06,34.97c1.08,2.28,1.78,3.78-1.26,4.61
			c-0.88,0.24-1.62,0.98-2.43,1.46c-6.03,3.65-6.08,3.68-9.98-2.4C903.22,634.23,897.5,621.26,890.63,608.97z M889.44,599.85
			c-5.34-0.85-9.61,2.69-14.51,3.59c-11.03,2.04-19.42-1.97-24.76-12.06c-10.7-20.22-21.36-40.49-32.17-60.64
			c-3.23-6.03-4.45-12.55-5.56-19.19c-0.37-2.24,0.46-2.82,2.37-3.15c17.78-3.17,35.56-6.45,53.34-9.67
			c13.76-2.5,27.53-5.03,41.33-7.37c6.66-1.13,13.39-1.9,22.49-3.16c2.7-0.93,3.3,1.81,3.91,6.42c1.94,14.97,6.16,29.43,10.41,43.82
			c1.32,4.48,0.62,7.25-2.64,10.18c-4.81,4.34-10.38,7.35-15.83,10.53c-15.51,9.06-29.13,19.96-35.88,38.02
			C891.54,598.29,890.16,599.95,889.44,599.85z M944.25,513.86c-0.16-0.57-0.13-1.62,0.19-1.84c4.71-3.16,8.03-8.93,14.62-8.57
			c3.76,0.22,7.53,0.27,11.23-0.66c2.9-0.73,3.69,0.36,2.81,3.26c-1.9,6.19-5.66,11.26-9.09,16.5c-3.78,5.79-7.99,11.25-12.91,16.5
			C947.42,530.82,946.47,522.15,944.25,513.86z M972.81,497.86c-2.9,0.63-6.02,1.57-8.82,1.06c-7.18-1.29-12.87,1.24-17.94,6.1
			c-3.04,2.92-4.31,1.94-4.77-2.01c-0.49-4.24-1.29-8.42-1.95-12.62c-0.34-2.23,0.92-2.87,2.66-3.29c9.61-2.31,19.23-4.6,28.81-7.07
			c2.2-0.57,3.56-0.27,4.5,1.97c0.8,1.95,1.92,3.76,2.67,5.74C979.45,491.7,976.82,497,972.81,497.86z M977.28,473.82
			c-1.64,0.49-2.13-0.96-2.69-2.13c-16.06-33.12-38.23-62.12-57.38-93.15c-1.61-2.6-1.64-4.12,1.06-5.72c3-1.75,5.82-3.85,8.72-5.77
			c1.48-0.98,3.29-1.97,3.65-3.69c1.03-4.97,4.75-6.97,8.42-9.15c0.7-1.49,2.31-1.52,3.43-2.31c6.32-4.8,12.65-9.58,18.94-14.42
			c1.44-1.09,2.31-0.95,3.36,0.67c6.89,10.71,13.9,21.34,20.87,32c5.64,7.05,11.36,14.07,16.03,21.92c0.29,0.13,0.68,0.17,0.85,0.39
			c10.59,13.96,21.47,27.68,30.66,42.73c2.83,4.62-1.18,5.31-3.26,7.22c-3.65,2.14-6.68,5.33-10.6,7.01
			c-9.21,6.81-18.28,13.8-27.66,20.32C987.48,472.67,982.09,472.41,977.28,473.82z M996.08,364.81c7.99-2.83,16-5.59,24.03-8.29
			c1.41-0.49,2.93-2.27,4.25-1.08c1.8,1.64,1.71,4.47,2.63,6.69c0.03,1.46-0.92,1.87-2.01,2.3c-9.31,3.73-19.06,6.23-27.86,11.39
			c-2.13,1.23-2.59-1.65-3.5-2.9C989.56,367.34,989.62,367.09,996.08,364.81z M1034.82,429.51c-7.88-12.25-16.98-23.54-25.44-35.33
			c-2.54-3.55-5.03-7.17-7.91-10.38c-3.22-3.58-1.64-5.24,1.8-6.55c7.66-2.93,15.31-5.8,22.95-8.75
			c21.77-8.37,43.53-16.76,65.28-25.21c2.14-0.83,3.59-1.52,5.49,1.08c9.91,13.51,16.78,28.77,23.58,44.06
			c0.2,0.45,0.3,0.93,0.55,1.72c0.14,1.52-1.41,1.57-2.4,2.08c-9.12,4.81-19.1,7.34-28.45,11.52c-10.93,4.9-21.93,9.65-32.66,14.99
			c-5.34,2.67-9.62,7.17-13.85,11.56C1039.6,434.65,1038.16,434.71,1034.82,429.51z M1142.42,446.77c0.89,2.54,1.29,4.11-2.17,3.53
			c-19.58-3.19-38.36,2.43-57.36,5.6c-4.64,0.78-9.23,1.91-13.86,2.77c-3.88,0.72-6.53,0.07-7.17-5.07
			c-0.57-4.64-2.43-9.11-3.71-13.64c-1.64-1.26-1.57-3.49-2.63-5.07c-2.77-6.32-2.23-8.73,3.4-12.04c3.39-1.98,7.04-3.55,10.66-5.08
			c17.08-7.28,34.21-14.43,51.27-21.79c2.24-0.96,2.79-0.4,3.63,1.72C1130.96,413.87,1136.69,430.31,1142.42,446.77z M1131.3,391.12
			c12.04-3.6,23.87-7.88,36.77-6.85c6.39,0.52,12.87-0.43,19.2-1.77c3.92-0.82,5.9,0.24,7.77,4.44
			c8.17,18.35,11.59,38.02,14.76,57.68c1.15,7.15,1.78,14.52,2.61,22.2c-19.75-9.98-41.22-10.07-61.51-15.4
			c-1.87-0.49-2.96-0.88-3.65-2.94c-5.73-17.31-11.58-34.58-18.7-51.33C1127.02,393.55,1127.8,392.17,1131.3,391.12z
			 M1044.65,437.22c4.58-4.98,4.83-5.04,7.74,1.16c2.23,4.7,3.88,9.59,5.06,14.71c1.59,6.98,1.71,6.95-5.5,7.41
			c-3.27,1.38-4.18-1.19-4.85-4.12c-1.15-4.93-2.31-9.85-3.49-14.76C1043.2,439.94,1043.33,438.67,1044.65,437.22z M1174.42,495.96
			c6.84-7.45,11.17-15.54,11.36-25.94c0.06-3.85,1.94-5.04,5.7-5.06c7.4-0.04,13.62,3.22,19.95,6.42c1.92,0.96,2.18,2.67,2.43,4.41
			c2.17,15.07,4.27,30.13,6.45,45.18c0.33,2.3,0.19,3.71-2.66,3.63c-6.58-0.16-13.16-0.04-17.79-0.04
			c-10.57-0.3-19.22-0.68-27.79,0.73c-2.71,0.45-3.81-0.4-4.37-3.39C1165.81,512.02,1167.95,503.02,1174.42,495.96z M1301.29,406.66
			c-0.1-3.26,0.22-6.56,0.59-9.81c1.49-12.85-1.85-24.33-9.11-34.58c-1.87-2.64-0.69-3.49,1.24-4.81
			c18.99-13.16,39.88-21.77,61.61-28.44c3.75-1.16,7.57-2.1,11.27-3.42c2.63-0.93,3.91-0.56,4.65,2.54
			c2.28,9.35,4.93,18.61,7.27,27.96c3.73,14.92,1.9,29.96,0.56,44.98c-0.17,2.03-1.28,1.75-2.63,1.45
			c-3.82-0.88-7.71-1.51-11.52-2.51c-9.45-2.48-18.33,0.34-27.19,3.09c-7.81,2.4-15.47,5.37-23.44,7.2
			c-1.61,0.85-3.22,1.69-5.1,1.46C1301.89,415.34,1301.58,415.22,1301.29,406.66z M1248.04,223.33c-1.84,3.95-3.22,8.13-4.98,12.74
			c-8.8-9.26-16.37-18.6-25.26-27.02c3.25-1.05,5.59,0.59,7.96,1.52c6.89,2.7,13.67,5.67,20.54,8.43
			C1248.44,219.87,1249.21,220.8,1248.04,223.33z M1186.78,306.27c7.43,0.11,13.16,3.78,18.11,9.23c0.92,1.01,2.3,1.55,3.48,2.3
			c3.48,0.82,6.74,0,10.15-0.9c15.64-4.15,31.29-8.22,46.56-13.73c3.32-1.21,3.68,0.85,4.24,3.17c3.25,13.67,6.52,27.33,9.78,40.99
			c0.14,0.63,0.22,1.29,0.37,1.92c0.62,2.57,3.76,4.91,2.4,7.24c-1.08,1.87-4.29,2.36-6.55,3.49c-4.37,2.2-8.66,4.6-13.11,6.58
			c-6.84,3.03-13.87,5.03-21.47,3.92c-11.04-1.59-21.47,2.17-31.98,4.52c-12.8,2.86-12.87,3.26-19.83-7.74
			c-8.26-13.06-19.75-23.22-28.61-35.73c-4.5-6.36-4.54-12.97,0.85-18.69c4.94-5.23,10.18-10.15,15.25-15.27
			c0.92-0.93,1.98-1.59,2.54-0.09C1180.46,301.57,1184.66,302.89,1186.78,306.27z M1187.93,373.54c1.72,2.99,2.44,4.67-2.15,4.95
			c-6.56,0.43-13.07,1.78-19.69,1.72c-8.65-0.09-17.28-0.27-25.41,3.68c-0.79,0.39-1.8,0.29-3.26,0.49
			c9.48-16.57,10.51-35.49,17.64-52.98C1165.3,346.53,1178.94,358,1187.93,373.54z M1150.9,328.66c-2.05,7.37-4.11,14.74-6.15,22.12
			c-1.8,6.45-3.58,12.88-5.31,19.33c-1.94,7.22-6.41,12.61-11.66,17.38c-1.8,1.64-2.89,0.82-3.73-1.39
			c-2.54-6.52-5.72-12.71-8.8-18.96c-4.24-8.59-8.67-17.03-14.53-24.56c-1.88-2.41-1.54-4.02,0.82-5.4
			c6.32-3.66,9.88-9.82,13.47-15.98c3.27-5.64,7.67-10.31,11.98-15.07c1.25-1.39,2.1-1.41,3.4-0.1c6.46,6.43,13,12.78,19.52,19.14
			C1150.9,326.15,1151.35,327.27,1150.9,328.66z M1105.97,327.61c-2,3.26-5.64,4.58-8.24,7.18c-1.13,1.13-2.01,0.45-2.69-0.56
			c-1.16-1.75-2.23-3.56-3.33-5.34c-1.78-2.3-3.82-4.41-4.77-7.3c-4.64-7.63-8.66-15.58-11.56-24.13c-2.74-3.06-3.82-6.92-4.8-10.83
			c-2.64-8.03-5.73-15.84-10.14-23.02c-1.35-2.2-0.69-3.22,1.8-4.02c9.87-3.23,19.65-6.69,29.43-10.21c2.8-1.02,3.15-0.1,3.53,2.73
			c1.01,7.25,0.53,14.71,2.71,21.83c0.73,2.38,1.46,4.7,3.1,6.49c7.18,7.91,13.39,16.93,23.37,22.36
			C1116.79,310.08,1111.34,318.81,1105.97,327.61z M1067.52,291.27c6.98,16.24,14.29,32.31,25.21,47.02
			c-8.95,3.26-17.29,6.29-25.65,9.34c-7.28,3.71-15.09,5.89-22.63,8.85c-1.12,0.59-2.2,1.28-3.35,1.75
			c-10.51,4.32-11.88,3.42-13.11-8.67c-3.36-5.69-4.75-12.22-7.21-18.3c-3.04-6.48-5.14-13.33-7.51-20.06
			c-0.85-2.38-0.4-3.65,2.14-4.64c16.13-6.32,32.36-12.21,49.1-16.5C1065.94,289.7,1066.84,289.71,1067.52,291.27z M1021.65,346.43
			c0.33,0.9,0.67,1.82,0.98,2.66c-0.14,2.61-2.33,2.66-3.75,3.17c-7.83,2.89-15.73,5.56-23.61,8.3c-0.45,0.16-0.89,0.34-1.35,0.42
			c-2.54,0.39-4.58,3.99-6.98,2.46c-2.08-1.32-3.15-4.37-4.67-6.65c-4.39-6.58-8.72-13.21-13.23-19.7c-1.57-2.28-1.81-3.78,0.7-5.5
			c10.96-7.55,21.21-16.33,34.45-19.66c3.29-0.82,4.8-1.11,6.09,2.87C1013.76,325.44,1017.83,335.88,1021.65,346.43z
			 M1004.88,307.16c-14.26,3.48-25.89,12.12-37.43,20.9c-2.48,1.9-3.65,1.15-5.11-1.12c-7.9-12.21-15.88-24.34-23.81-36.52
			c-2.99-4.6-2.84-6.06,1.74-8.42c6.91-3.55,12.93-8.5,19.43-12.7c2.38-1.55,3.45-1.82,5,1.06c3.26,6.1,3.45,6,9.26,2.15
			c3.71-2.46,7.43-4.9,11.1-7.4c1.29-0.88,2.25-1.57,3.32,0.46c6.52,12.48,14.09,24.4,18.1,38.17c0.14,0.46,0.34,0.92,0.56,1.51
			C1007.15,306.76,1005.86,306.92,1004.88,307.16z M940.43,276.44c-1.91,2.17-4.97,3.23-7.88,4.96c-1.01-1.58-2.7-3.09-2.84-4.73
			c-0.29-3.5,3.32-3.59,5.3-5.04c0.85-0.63,2.17-2.73,3.4-0.57C939.38,272.75,941.66,275.03,940.43,276.44z M936.1,294.54
			c4.45,6.89,9.59,13.3,13.27,20.71c4.01,4.54,6.78,10,10.25,14.94c1.03,1.45,0.83,2.6-0.7,3.63c-2.34,1.59-4.58,3.33-6.87,5.01
			c-3.39,2.9-6.87,5.69-10.79,7.8c-2.73,2.36-5.46,4.73-8.8,6.16c-4.93,6.26-9.09,13.26-16.69,16.79c-2.56,1.19-3.6,1.22-5.2-1.26
			c-10.17-15.91-20.42-31.75-30.82-47.48c-2.33-3.52,0.66-3.99,2.2-4.88c14.05-8.23,28.16-16.34,42.31-24.37
			C931.42,287.5,931.58,287.53,936.1,294.54z M926.12,285.53c-2.77,1.46-5.49,3.07-8.22,4.62c-11.6,6.75-23.18,13.54-34.81,20.24
			c-3,1.72-6.1,2.24-9.77,2.21c-5.06-0.06-9.85,2.94-14.62,5.04c-7.44,3.26-6.18,2.79-8.82-3.79c-1.31-3.25-1.9-6.52-1.88-10.04
			c0.1-14.62-0.42-29.27,0.2-43.86c0.6-14.15,0.83-28.22-1.26-42.22c-1.13-7.58-2.77-15.11-4.41-22.62
			c-0.78-3.59,0.43-5.23,3.73-5.7c4.74-0.66,9.25-2.08,12.9-5.44c2.84-2.61,4.51-1.85,6.46,1.26
			c12.08,19.14,24.27,38.22,36.52,57.25c6.48,10.05,13.16,19.96,19.69,29.97c0.95,1.46,2.28,2.76,2.46,4.68
			c1.87,0.73,1.88,2.9,3.02,4.24C928.94,283.25,928,284.52,926.12,285.53z M865.27,171.51c-0.73,1.68-1.52,3.07-3.19,0.83
			c-3.39-5.27-6.81-10.51-10.13-15.81c-0.78-1.24-1.94-2.63,0.59-3.66c8.22-3.36,16.37-6.92,24.56-10.41
			c0.86-0.89,1.32-0.43,1.54,0.55C880.51,151.32,872.95,167.42,865.27,171.51z M849.3,181.09c2.02,2.7,0.6,3.27-1.64,3.65
			c-1.24,0.22-2.56,0.17-3.69,0.63c-2.53,1.03-3.17-0.36-3.12-2.48c0.11-3.91,0.37-7.8,0.57-11.69c0.33-0.11,0.66-0.23,0.98-0.34
			C844.69,174.27,846.84,177.81,849.3,181.09z M565.75,145.13c-19.22,16.72-26.17,40.6-33.87,63.95
			c-2.17,6.59-5.85,12.22-8.92,18.24c-0.98,1.94-2.74,3.1-4.75,3.81c-5.1,1.31-8.26-1.9-10.99-5.53
			c-4.39-5.86-8.53-11.94-12.78-17.92c-8.36-10.83-16.2-22.06-24.01-33.32c-3.76-5.41-7.86-10.57-11.82-15.84
			c-1.65-2.18-1.67-3.52,0.89-5.39c8.23-5.97,17.19-10.81,24.67-17.92c9.32-8.86,16.63-19.58,25.06-29.26
			c3.6-4.11,7.04-8.36,11.02-12.11c1.64-1.57,2.53-1.78,4.12,0.07c13.73,15.93,27.55,31.77,41.39,47.58
			C566.98,142.9,567.16,143.91,565.75,145.13z M464.83,172.6c0.9,5.18-5.76,7.88-8.88,11.86c-5.11,6.52-9.35,13.43-12.67,21.07
			c-2.67,6.13-7.25,10.46-13.03,13.83c-1.25-4.88-2.17-9.21-3.5-13.39c-0.76-2.36-0.75-4.35,0.23-6.58
			c2.34-5.37,4.39-10.84,5.7-16.6c2.69-11.82,11.07-18.51,20.14-24.65c1.28-0.86,1.97-0.55,2.69,0.59c0.16,0.27,0.34,0.53,0.53,0.79
			C459.12,163.85,463.98,167.85,464.83,172.6z M426.88,229.36c-0.69,10.71,3.76,19.09,10.37,26.87c5.08,5.99,9.23,12.81,13.99,19.12
			c1.74,2.3,1.67,3.6-0.65,5.4c-12.08,9.36-24.09,18.86-36.03,28.39c-2.07,1.65-3.25,1.52-4.77-0.76
			c-9.13-13.72-18.4-27.35-28.62-40.21c-1.55-1.95-1.87-3.15,0.49-4.98c14.82-11.53,29.49-23.28,44.45-35.13
			C426.59,228.86,426.89,229.12,426.88,229.36z M423.96,224.22c-15.11,11.96-30.25,23.9-45.33,35.93c-1.77,1.42-2.6,1.28-4.11-0.49
			c-8.88-10.44-17.15-21.33-24.72-32.83c-2.31-3.52-1.9-5.73,1.11-8.2c16.76-13.76,33.49-27.58,50.18-41.43
			c2.03-1.68,3.03-1.65,4.58,0.82c6.22,10,13.69,19.22,17.46,30.69c1.26,3.86,1.97,7.91,2.93,11.89
			C426.31,222.44,425.11,223.31,423.96,224.22z M375.44,268.81c2.54,0.17,4.77,5.4,7.2,8.36c6.94,8.44,12.74,17.79,19.09,26.71
			c7.34,10.36,14.65,20.75,21.97,31.14c0.17,0.27,0.34,0.56,0.52,0.85c13.95,11.13,23.88,26.28,35.93,39.31
			c4.74,5.13,8.44,11.09,13.93,15.68c7.83,6.56,12.6,15.98,17.95,24.7h-25.91c-9.22-0.36-18.44-0.73-27.68-1.11
			c-11.43-0.3-22.86-0.55-34.3-0.92c-2.8-0.09-5.04-1.74-6.95-3.73c-8.43-8.78-16.21-18.14-23.86-27.66l-0.3-0.1l0.04-0.33
			c-5.16-7.22-11.23-13.64-17.19-20.11c-7.6-8.24-10.13-17.81-9.57-29.03c0.55-10.54,2.04-20.85,4.39-31.05
			c2.44-10.5,6.52-19.98,15.93-26C369.82,273.51,372.66,268.64,375.44,268.81z M393.56,414.43c-1.18,2.64-3.68,1.65-5.79,1.39
			c-0.75,0.17-1.48,0.42-2.24,0.52c-3,0.37-5.93-0.39-6.97-3.43c-1.09-3.16,1.85-4.51,3.91-6.03c2.05-2.7,4.05-2.46,6.06,0.09
			c0.69,0.86,1.64,1.54,2.25,2.44C391.89,410.98,394.69,411.87,393.56,414.43z M348.71,291.36c-0.09-0.09-0.16-0.17-0.24-0.24
			C348.89,290.92,348.97,290.99,348.71,291.36z M322.53,347.85c5.63,4.35,12.18,7.77,18.17,11.91c8.43,5.8,15.35,13.37,23.02,20.06
			c2.14,1.87,4.15,3.89,6.22,5.86c4.28,3.36,7.14,8.09,10.6,12.22c1.88,2.24,0.98,3.82-1.38,4.85
			c-13.59,14.59-29.67,22.28-49.52,19.59c-11.53-0.24-23.08-0.52-34.61-0.75c-5.79-0.1-10.83-2.64-15.9-5.1
			c-13.7-8.42-23.8-20.68-33.03-33.87c-1.36-0.27-1.62-1.39-1.8-2.59c-2.9-4.22-4.47-9.35-8.52-12.84c-2.7-2.33,0.53-2.83,1.64-3.5
			c13.83-8.34,27.72-16.59,41.59-24.85c5.31-3.16,10.67-6.26,15.96-9.45c1.94-1.18,3.45-1.46,5.4,0.34
			C307.36,336.29,315.03,342.06,322.53,347.85z M376.05,417.58c-3.17,1.87-6.81,1.91-10.89,2.87c2.13-1.81,3.71-2.92,5-4.31
			C372.82,413.25,374.75,413.38,376.05,417.58z M296.56,433.46c0.36-2.21,1.74-1.97,3.12-1.95c5.82,0.03,11.63,0.19,17.44,0.11
			c2.9-0.04,4.06,0.99,4.42,4.19c0.49,4.61-0.03,9.45,1.64,13.89c0.49-0.04,0.96-0.07,1.45-0.1c-0.4-4.73-0.72-9.44-1.22-14.15
			c-0.29-2.76-0.96-5.18,3.32-4.7c13.57,1.54,26.4-2.73,39.4-5.77c8.23-1.92,16.4-4.57,24.99-4.44c1.59-0.7,3.17-0.78,4.73,0.17
			c5.74-1.08,9.92,2.51,13.7,5.9c3.81,3.42-1.42,3.82-2.53,5.63c-0.13,1.62-1.62,2.01-2.47,3c-3.65,3.81-7.55,7.38-10.89,11.46
			c-5.46,6.71-12.18,10.86-20.22,13.31c-8.96,2.76-16.69,8.53-25.41,12.06c-11.78,4.78-23.34,10.2-34.94,15.47
			c-1.87,0.85-3,1.15-3.89-1.24c-2.08-5.63-4.35-11.17-6.53-16.75c-0.98-1.77-1.95-3.52-2.93-5.27
			C290.11,454.97,294.86,444.06,296.56,433.46z M301.18,477.03c1.57,4.24,3.26,8.43,4.94,12.74c-0.86,2.23-3.06,2.59-4.74,3.56
			c-1.91,1.12-1.92-0.99-2.38-1.97c-1.71-3.66-3.22-7.43-4.96-11.09c-1.09-2.33-0.42-3.49,1.82-4.17
			C297.82,475.53,299.86,473.43,301.18,477.03z M304.76,496.38c2.14-1.09,2.82-0.85,3.3,1.8c3.15,17.23,6.23,34.47,8.46,51.88
			c1.31,10.23,2.27,20.51,4.45,30.61c0.7,3.26,2.54,5.93,3.16,9.16c1.18,6.2,0.98,6.55-5.21,6.79c-2.3,0.79-2.41-1.12-2.83-2.69
			c-3.75-14.45-6.15-29.14-7.04-44.09c-0.96-16.24-3.3-32.29-6.71-48.16C301.73,498.78,302.48,497.54,304.76,496.38z M435.6,540.41
			c-0.24-1.92,1.25-1.84,2.31-2.13c1.41-0.16,2.21,0.46,2.05,1.85c-0.13,1.25-0.45,2.89-1.92,2.74
			C436.99,542.78,435.79,541.87,435.6,540.41z M437.38,569.51c0.66-3.99,1.28-8.01,2.04-12.01c0.4-2.1,1.72-2.24,3-0.86
			c2.5,2.69,4.83,5.54,7.25,8.29c1.03,1.13,0.8,2.07-0.34,2.76c-3.1,1.88-6.3,3.55-10.17,3.96
			C437.62,572.05,437.11,571.18,437.38,569.51z M444.3,552.31c-2.47-2.7-2.04-5.52-1.03-8.62c2.96-9.15,9.91-14.89,16.39-21.01
			c21.43-20.18,42.91-40.29,64.39-60.42c3.72-3.5,5.82-3.53,9.29,0.5c9.94,11.56,20.19,22.85,30.12,34.44
			c7.8,9.09,15.93,18,24.23,26.66c4.85,5.04,9.48,10.33,14.22,15.5c0.96,1.05,2.33,1.97,1.58,3.71c-0.73,1.72-2.5,2.3-3.96,2.15
			c-12.77-1.22-24.66,3.76-36.97,5.72c-27.92,4.44-55.8,9.16-83.69,13.74c-6.05,0.99-12.09,1.92-18.14,2.87
			c-2.27,0.26-3.85-0.82-5.31-2.54C451.78,560.71,448.11,556.44,444.3,552.31z M448.34,899.48c0.34,1.18,1.02,2.67-0.88,3.75
			c-10.9,6.22-20.34,14.56-29.83,22.82c-1.61,1.41-3.27,3.04-5.89,3.53c-0.83-6.87-1.62-13.64-2.5-20.41
			c-1.19-9.25-2.4-18.5-3.68-27.73c-0.34-2.48-0.52-4.04,2.81-4.5c15.64-2.21,31.22-4.84,46.86-7.14c1.59-0.23,4.19-2.01,5,1.44
			c0.63,2.74,1.25,5.52-2.2,6.94c-0.86,0.34-1.49,1.24-2.33,1.67C447.61,884.07,445.77,890.82,448.34,899.48z M399.65,880.93
			c1.94,17.11,4.65,34.12,6.16,51.29c0.16,1.8-0.17,2.93-1.45,4.28c-11.81,12.48-26.71,17.87-42.76,20.68
			c-4.28,0.75-8.57-0.24-12.62-1.24c-9.67-2.38-18.27-0.17-26.6,4.87c-11.02,6.66-22.66,11.88-34.48,16.79
			c-5.73,2.38-8.85,0.59-12.29-4.39c-1.55-2.23-2.8-4.84-3.55-7.48c-4.41-15.53-15.21-26.27-26.02-36.57
			c-10.43-9.92-16.26-22.25-20.84-35.57c-1.26-3.69,2.4-3.79,4.28-4.14c3.85-0.72,7.81-0.76,11.73-0.99
			c1.88-0.11,3.78-0.03,5.66-0.03c11.93-0.78,23.9-0.13,35.72,1.08c18.41,1.88,36.37-1.39,54.43-3.56
			c19.82-2.37,39.63-4.95,59.42-7.51C398.46,878.17,399.36,878.33,399.65,880.93z M282.11,984.23
			c16.66-5.56,32.26-13.64,47.54-22.32c6.64-3.76,13.28-2.92,19.7-1.38c12.44,2.97,23.55-1.18,34.81-5.26
			c2.8-1.02,5.5-2.38,8.24-3.59c1.55-1.06,3.1-2.11,4.64-3.16c2.14-1.84,4.27-3.69,6.39-5.53c2.83-3.69,4.95-3.49,6.87,1.01
			c1.78,4.15,3.98,8.1,5.99,12.14c0.29,0.56,0.56,1.11,0.83,1.67c2,2.33,3.1,5.17,4.34,7.96l0.29,0.06c0.14,0.26,0.29,0.53,0.42,0.8
			c4.28,5.62,8.47,11.32,12.87,16.82c1.69,2.11,1.52,3.66-0.2,5.46c-3.65,3.81-7.25,7.67-10.87,11.49
			c-4.17,4.28-8.32,8.56-12.48,12.84c-1.21,1.81-2.3,3.75-4.32,4.74c-1.05,1.32-2.11,2.64-3.16,3.96
			c-7.12,9.44-16.2,16.79-24.3,25.16c-10.7,11.06-22.59,20.62-33.77,31.07c-1.45,1.36-2.4,2.01-4.04-0.13
			c-15.31-20.05-31.14-39.67-43.53-61.94c-0.46-0.83-0.75-1.78-1.12-2.66c-2.2-1.72-3-4.48-4.52-6.71c-3.79-6-7.58-11.99-11.36-18
			C280.27,986.99,279.21,985.2,282.11,984.23z M412.14,938.79c-1.05-2.11-0.23-2.96,1.02-3.96c10.43-8.36,20.87-16.7,31.37-24.98
			c1.35-1.08,2.96-2.3,4.54-2.46c4.22-0.45,4.58-2.51,3.76-6.36c-2.53-11.82-2-12.83,7.81-19.43c4.38-2.94,9.15-4.97,14.03-6.78
			c2.6-2.47,6-3.5,8.95-5.36c0.57-4.09,3.3-5.67,6.69-7.07c5.49-2.27,10.77-5.08,16.7-7.93c0.3,6.74-0.75,13.2,1.84,19.17
			c0.52,1.19,0.73,2.54,1.32,3.66c5.08,9.72,3.35,19.68,0.96,29.61c-0.88,3.65-3.39,6.23-5.97,8.67
			c-5.39,4.98-10.76,9.97-16.13,14.95c-13,13.26-26.02,26.5-38.96,39.83c-2.9,2.99-5.69,6.15-8.11,9.54
			c-1.8,2.5-2.73,2.18-4.48,0.03C427.3,967.39,419.34,953.37,412.14,938.79z M466.3,868.55c3.66-0.57,7.3-1.28,11.76-2.08
			c-3.55,3.88-7.48,5.27-11.27,6.92c-1.25,0.55-2.05,0.06-2.1-1.52C464.65,870.41,464.15,868.89,466.3,868.55z M506.6,851.3
			c-5.8,3.19-11.79,6.06-17.85,8.65c-6.94,2.94-14.3,4.28-21.66,5.57c-2.34,0.4-3.07-0.45-3.55-2.99
			c-3.75-20.29-6.05-40.8-8.13-61.33c-1.34-13.1-1.28-26.37-2-39.54c-0.16-2.73,1.29-2.9,3.1-3.07c15.77-1.54,31.51-3.81,47.42-2.01
			c3.73,0.42,7.51,0.46,11.27,0.67c8,0.3,16.03-0.63,23.98,0.7c18.61,3.78,37.47,1.44,56.69,2.34c-1.34,3.89-4.61,5.67-6.22,8.72
			c-10.4,19.69-27.12,33.1-43.32,46.88c-7.27,6.18-14.2,12.68-21.17,19.17c-1.41,1.28-2.28,3.25-4.35,3.66
			C517,844.04,512.18,848.22,506.6,851.3z M578.19,790.3c6.15-6.55,11.3-13.99,16.69-21.21c2.93-3.95,5.64-8.22,11.3-10.15
			c1.91,11.12,3.79,22.06,5.63,33c2.44,14.55,4.78,29.1,7.28,43.63c0.45,2.57,0.24,3.85-2.66,4.32
			c-10.07,1.67-20.09,3.62-30.13,5.52c-4.32,0.83-8,0.37-11.83-2.66c-9.49-7.47-19.43-14.3-29.59-21.67
			C556.74,811.16,567.9,801.23,578.19,790.3z M614.87,784.99c-1.45-8.39-2.56-16.83-4.18-25.18c-0.55-2.86,0.42-3.78,2.6-4.68
			c8.65-3.59,17.41-5.95,26.87-6.41c13.47-0.63,26.63-3.98,39.51-8.17c3.22-1.05,6.42-1.38,9.71-1.57c3.23-0.19,4.98,0.93,6.29,4.28
			c1.38,3.52-0.23,5.23-2.36,6.92c-8.95,7.14-13.57,16.62-13.8,28.28c-0.09,4.35-1.87,7.47-4.58,10.3
			c-3.12,3.23-6.25,6.46-9.52,9.52c-4.73,4.41-4.93,6.51-0.43,11.27c2.84,3.03,5.9,5.86,8.85,8.79c2.76,2.74,2.8,4.94-0.04,8.17
			c-3.23,3.68-6.55,5.66-11.35,5.73c-9.44,0.14-18.64,2.44-27.96,3.63c-10.77,3.59-10.79,3.58-12.25-8.01
			c-0.24-1.91-0.49-3.83-0.72-5.74C619.28,809.75,617.02,797.38,614.87,784.99z M628.97,841.83c2.17-0.07,4.32-0.49,6.48-0.75
			c4.87-1.92,9.97-2.57,15.08-3.29c2.41-0.34,2.86,0.72,3.07,2.92c0.7,7.65,2.34,15.3,2.3,22.92c-0.06,10.3,4.35,17.65,11.23,24.23
			c5.56,5.31,5.5,5.62-0.04,11.12c-6.98,6.97-12.98,15.11-21.74,20.01c-1.22,0.67-2.21,1.59-3.69,1.88
			c-2.83,0.55-4.41,0.29-4.9-3.39c-1.35-10.04-3.15-20.02-4.77-30.02c-2.21-13.47-4.28-26.97-6.75-40.39
			C624.55,843.28,625.48,841.95,628.97,841.83z M659.23,855.09c3.65,1.41,5.26,4.8,7.77,7.07c1.02,0.93,0.96,2.04-0.07,2.93
			c-1.91,1.64-3.17,4.05-6.08,5.39C660.31,865.3,659.79,860.37,659.23,855.09z M665.11,879.23c-2.27-2.37-2.05-4.52,0.43-6.53
			c0.24-0.2,0.39-0.52,0.62-0.75c4.95-5.3,4.95-5.31,10.08,0c1.77,1.82,3.5,3.69,5.26,5.53c3.12,2.07,3.38,4.14,0.09,6.36
			c-0.88,0.6-1.61,1.52-2.25,2.43c-2.63,3.66-5.18,3.91-7.73-0.09C669.44,883.87,667.29,881.53,665.11,879.23z M659.26,847.81
			c-1.46-1.42-0.96-3.29-0.89-5.06c-0.91-5.7-0.91-5.7,5.56-5.83c6.05-0.53,10.2-4.41,13.95-8.82c2.54-2.97,5.04-1.72,6.84,0.29
			c7.09,7.96,14.55,15.6,20.95,24.23c3.04,1.44,2.34,3.48,0.59,5.36c-5.66,6.06-11.37,12.06-17.13,18.01
			c-2.76,2.84-2.99-0.9-4.24-1.94C675.53,866.16,668.02,856.32,659.26,847.81z M692.94,878.15c5.23-5.34,10.48-10.67,15.51-16.23
			c2.57-2.86,4.54-3.1,6.65,0.26c3.13,4.24,7.32,7.61,9.57,12.61c1.18,0.23,1.9,0.99,2.24,2.17c8.86,11.03,8.95,6.43,1.08,17.91
			c-2.81,4.12-5.89,8.07-8.8,12.12c-1.02,1.39-1.94,2.79-3.58,0.75c-6.82-8.52-15.38-15.3-22-24.01
			C690.8,882.2,690.68,880.45,692.94,878.15z M721.82,910.67c4.81-6.66,9.58-13.36,14.35-20.06c0.82-1.13,1.68-1.36,2.79-0.49
			c3.78,2.97,8.11,4.9,12.31,7.08c1.39,0.73,2.5,1.48,1.81,3c-0.96,8.39-5.52,14.92-8.11,22.25c-0.67,1.87-1.85,1.25-3.06,0.65
			c-6.46-3.33-12.91-6.69-19.43-9.92C721.09,912.48,720.92,911.91,721.82,910.67z M752.41,893.01c-13.26-5.59-13.37-5.87-6.03-18.35
			c1.97-3.35,3.96-6.68,6.22-9.79c2.05-2.82,1.62-4.68-0.82-7.05c-5.93-5.79-11.49-11.98-17.38-17.82
			c-1.95-1.92-1.91-3.03-0.09-5.03c5.56-6.13,5.26-5.46,12.57-1.88c10.56,5.16,16.09,13.74,19.02,24.92
			c0.57,2.18,1.54,4.27,2.27,6.25c-0.19,0.88-0.24,1.55-0.5,2.14c-3.89,8.75-7.8,17.48-11.75,26.21c-0.42,0.93-1.03,1.65-2.18,0.95
			C753.33,893.29,752.86,893.19,752.41,893.01z M765.68,845.6c9.23,3.83,10.02,4.42,4.84,12.52
			C768.8,853.65,767.32,849.8,765.68,845.6z M757.88,827.23c-0.43-2.83-0.04-3.96,2.71-4.09c4.06-0.22,8.11-0.57,13.85-0.99
			c1.84-0.07,5.07,1.38,8.42,2.11c2.15,0.46,2.46,2.08,1.57,4.06c-2.43,5.47-4.88,10.94-7.22,16.46c-0.67,1.59-1.29,2.41-3.12,1.54
			C765.98,842.44,759.39,837.35,757.88,827.23z M782.59,820.95c-6.97-2.2-13.97-1.21-20.91-0.63c-4.04,0.33-6.65-1.05-8.5-3.91
			c-1.05-1.95-9.32-17.31-11.13-19.85c-1.94-2.71-3.33-5.43-7.21-9.48c-0.07-0.07-0.14-0.16-0.22-0.22
			c-7.12-8.89-15.7-16.36-22.98-25.11c-4.64-5.59-3.94-7.22,1.61-11.43c5.29-3.99,10.64-4.19,16.73-3.86
			c21.92,1.21,43.8,3.27,65.76,3.48c2.15,0.01,22,1.24,27.52,0.83c4.52-0.34,6.98,3.19,4.54,6.75c-4.48,6.52-5.2,14.28-7.28,21.57
			c-0.82,2.83-1.75,5.03-4.47,6.41c-5.06,2.57-9.11,6.95-14.43,9.11c-1.38,2.03-3.39,3.12-5.53,4.02
			c-2.61,6.41-5.21,12.8-7.84,19.19C787.17,820.43,784.79,821.65,782.59,820.95z M824.48,779.9c0.69-3,1.48-5.99,2.41-8.9
			c0.36-1.13,0.66-2.8,2.18-2.81c1.42-0.03,1.95,1.42,2.6,2.53c0.16,0.27,0.26,0.59,0.4,0.88c1.38,2.83,5.34,6.16,3.76,8.17
			c-1.65,2.11-6.26,1.72-10.69,2.66C824.21,782.66,824.17,781.24,824.48,779.9z M820.69,746.31c-5.06-0.32-11.33-0.56-14.42-0.8
			c-0.34,0.01-0.67,0.01-1.02,0.01c-12.84,0.14-25.52-2.51-38.36-2.79c-12.54-0.27-25.05-1.68-37.56-2.73
			c-1.68-0.14-4.16,0.76-4.84-1.72c-0.7-2.61,0.65-4.88,2.6-6.45c4.31-3.46,8.37-7.21,12.48-10.94
			c17.75-16.09,35.82-31.81,53.86-47.57c3.79-3.32,7.3-7.11,12.01-9.15c4.91-5.6,11.16-9.42,16.88-13.95
			c1.77-1.39,2.56-0.29,3.3,1.02c7.37,13.07,16.17,25.26,22.16,39.19c3.27,7.65,6.72,8.96,11.83,3.39
			c5.6-6.12,12.71-9.41,19.39-13.5c16.09-9.87,32.34-19.42,48.46-29.23c2.2-1.32,4.27-1.85,6.69-1.39
			c7.25,1.34,14.53,2.59,21.77,4.04c1.41,0.27,3.62,0.06,3.95,1.81c0.32,1.68-1.59,2.57-2.81,3.59
			c-13.85,11.55-28.9,21.27-43.7,31.4c-20.84,14.23-41.45,28.82-62.17,43.24c-2.21,1.54-4.51,2.93-6.78,4.38
			c-1.29,0.89-2.59,1.77-3.89,2.66C831.03,747.04,825.83,746.44,820.69,746.31z M938,644.14c3.15-1.87,5.9-3.6,8.76-5.16
			c1.03-0.57,2.69-1.28,3.32,0.22c0.85,2.04,1.52,4.37,1.44,6.53c-0.06,1.68-2.05,0.88-3.1,0.75
			C945.05,646.03,941.65,645.75,938,644.14z M958.11,647.92c-1.12,0-1.51-0.88-1.75-1.94c-0.4-1.75-1.22-3.43-1.41-5.2
			c-0.78-7.5-5.14-8.73-11.5-4.88c-3.26,1.95-6.66,3.69-9.84,5.79c-2.73,1.8-4.19,1.52-5.69-1.74
			c-5.53-11.96-11.96-23.41-18.73-34.66c-2.24-3.73-4.37-6.2-8.96-4.31c-1.34,0.56-3.55,1.26-4.48,0.04
			c-1.16-1.48,0.5-3.25,1.15-4.78c6.09-14.52,17.32-23.77,29.95-31.51c7.7-4.71,15.77-8.78,22.51-15.44
			c5.54,11.56,7.87,23.07,6.94,35.33c-0.89,11.75,1.28,22.33,10.24,30.84c3.1,2.96,3.23,8.06,2.13,12.57
			c-0.69,2.79-0.83,5.36,0.37,8.13c1.15,2.67,2.11,6.19,0.06,8.33C966.26,647.41,962.1,647.93,958.11,647.92z M1020.01,475.21
			c6.53-4.87,14.62-5.83,22.18-8.11c1.48-0.45,2.25,0.17,2.79,1.72c5.1,14.78,11.46,29.03,17.35,43.47
			c3.91,9.62,7.44,19.43,11.22,29.11c0.82,2.13,0.57,3.4-1.29,4.97c-6.39,5.39-10.84,11.98-12.19,20.25
			C1053.6,553.14,1026.03,488.73,1020.01,475.21z M1066.1,560.81c3.45-6.3,8.04-10.96,14.59-13.89c9.02-4.04,16.88-0.42,24.77,3.2
			c2.24,1.02,1.02,2.3-0.65,2.83c-2.69,0.86-22.84,8.49-27.85,11.82c-3.59,2.76-8.47,6.53-12.49,9.57
			C1064.33,569.76,1063.73,565.12,1066.1,560.81z M1070.78,572.24c5.01-3.32,10.33-6.45,16.07-8.33c2.9-1.02,8.66-3.17,8.66-3.2
			c2.86-0.98,12.84-4.41,16.49-5.67c5.79-1.98,10.76-4.47,15.53-9.12c4.87-4.73,11.83-7.76,18.9-9.25
			c11.33-2.38,22.03-7.35,33.85-7.9c12.93-0.59,25.69,1.62,38.58,1.41c1.9-0.03,2.31,1.01,2.6,2.66c1.09,6.3,1.34,17.46,1.29,24.9
			c-29.16-1.26-73.03,0.83-90.96,4.95c-11.68,2.67-48.93,14.07-63.72,21.08c-1.09-2.43-2.25-4.97-3.48-7.55
			C1066.64,574.88,1068.71,573.55,1070.78,572.24z M1295.93,507.38c-3.65,0.11-8.26-3.82-8.13-6.88c0.24-5.9,0.69-11.79,1.05-17.68
			c-0.07-1.18-0.13-2.36-0.2-3.53c-1.25-7.09,0.46-11.02,6.22-14.06c0.66-0.36,1.52-0.55,2.25-0.46c1.12,0.13,6.35,8.53,6.18,9.77
			c-1.31,9.01-1.31,18.21-3.43,27.1C1299.29,504.09,1299.42,507.27,1295.93,507.38z M1302.41,463.88c-0.13-1.52,0.83-2.73,2.6-2.8
			c1.74-0.07,2.69,0.66,2.63,3.99c0,0.14-0.39,1.61-2.15,1.8C1303.52,467.07,1302.54,465.53,1302.41,463.88z M1385.87,463.15
			c-4.15-1.21-8.4-1.06-12.64-0.85c-18.21,0.96-36.44,0.6-54.65,0.89c-5.56,0.07-10.83-4.24-11.65-9.94
			c-1.46-10.18-2.57-20.44-4.02-30.62c-0.4-2.86,1.15-3.23,3.06-3.82c13.5-4.21,26.97-8.5,40.47-12.72
			c3.25-1.02,6.25-3.04,9.82-2.74c10.54,0.67,20.57,4.31,30.96,5.86c5.44,0.82,10.84,1.88,16.3,2.43c3.15,0.32,3.65,1.34,3.26,4.61
			c-1.69,14.46-6.92,27.82-11.27,41.46C1393.09,465.27,1393.02,465.21,1385.87,463.15z M1398.98,463.25
			c2.6-7.18,4.87-14.49,7.25-21.76c4.52-13.7,6.09-27.98,8.27-42.21c1.92-12.57-0.55-24.44-1.68-36.61
			c-0.99-10.6-3.27-21.04-3.35-31.74c-0.06-7.8,5.17-16.39,12.04-19.19c5.53-2.24,10.87-5.1,16.4-6.02
			c7.94,0.45,13.84,4.62,19.58,9.11c16.86,13.18,31.17,29.31,46.78,43.92c7.24,6.76,8.36,12.42,4.88,21.16
			c-2.53,6.32-5.6,12.39-7.45,19.07c-2.43,8.78-2.13,17.88-3.95,26.68c-3.3,15.97-8.56,31.07-17.06,44.88
			c-2.69,4.39-4.75,9.22-6.91,13.95c-1.09,2.37-2.05,3.39-4.97,2.69c-12.65-3-25.45-4.94-37.7-10.11
			c-9.21-3.89-19.29-5.63-29.1-7.83C1398.01,468.33,1397.7,466.81,1398.98,463.25z M1515.49,318.09
			c4.93,13.69,6.45,27.92,0.19,41.92c-0.33,0.72-0.37,1.58-0.62,2.34c-0.2,0.6-0.53,1.15-0.99,2.11
			c-9.12-8.17-17.22-17.05-25.56-25.64c-10.73-11.03-22.41-20.87-34.45-30.23c-0.13-0.1-0.27-0.19-0.39-0.3
			c-1.51-1.57-4.29-2.99-4.05-4.88c0.3-2.31,3.42-2.44,5.49-2.92c8.55-2.01,17.44-2.08,25.85-4.88c2.13-0.7,4.44-1.38,5.83-3.02
			c6.43-7.64,14.61-3.45,22.09-3.85c2.05-0.1,1.85,1.91,1.72,3.33C1509.68,301.3,1512.43,309.54,1515.49,318.09z M1549.15,325.93
			c1.35,5.51,4.37,10.48,4.57,16.42c0.12,3.15-0.7,4.74-3.56,5.7c-5.92,1.98-11.93,2.73-17.29,2.51
			c-10.36-0.06-10.24-0.06-10.66-10.23c-0.43-10.51-1.51-20.91-6.84-30.29c-1.09-1.92-2.67-4.7-1.45-6.36
			c1.32-1.8,4.06-0.17,6.13,0.33c3.96,0.95,7.88,2.13,11.88,2.97c3.65,0.76,7.54,0.88,8.65,5.79c0.11,0.53,0.78,1.22,1.31,1.35
			C1547.9,315.66,1548,321.23,1549.15,325.93z M1543.54,308.08c0.03-2.01,1.31-2.73,2.44-2.92c2.5-0.01,3.35,1.13,3.37,3.03
			c0.03,1.85-0.68,3.12-2.67,3.12C1544.61,311.32,1543.49,310.25,1543.54,308.08z M1578.29,356.42c3.36,0.1,4.12,1.02,3.75,4.52
			c-1.77,16.46-3.88,32.9-4.31,49.49c-0.57,21.83,4.25,42.21,15.34,60.85c3.33,5.59,5.11,11.92,7.68,17.88
			c0.95,2.2,0.19,3.45-1.44,4.47c-5.39,3.35-10.84,6.56-16.2,9.97c-2.57,1.64-2.97-0.63-3.69-2.05
			c-6.89-13.79-13.07-28.02-20.8-41.29c-7.25-12.48-6.72-25.68-5.47-38.92c0.69-7.35-2.7-12.05-5.97-17.39
			c-7.25-11.88-16.09-22.45-24.82-33.09c-3.71-4.52-2.73-9.35-1.82-14.35c0.39-2.1,1.8-1.88,3.2-1.88c2.99,0.01,5.97,0,8.96,0
			c5.01,0,10.07,0.36,14.95-1.28c2.51-0.85,4.24,1.58,6.66,1.68C1562.32,355.32,1570.29,356.19,1578.29,356.42z M1557.55,350.85
			c7.65-5.33,14.53-11.49,22.3-16.59c1.61,5.18,1.35,10.17,2.14,14.98c0.4,2.44-0.6,3.13-2.87,2.96
			C1571.94,351.65,1564.75,351.29,1557.55,350.85z M1755.67,399.55c3.63-9.58,7.2-19.19,10.48-28.9c3.58-10.61,1.36-21.84,2.84-32.7
			c0.22-1.62,0.34-3.26,0.52-4.88c-2.31-12.62-4.87-25.19-6.84-37.87c-1.16-7.48-0.39-8.04,6.66-9.65c1.82-0.4,3.62-0.95,5.43-1.42
			c9.44-2.36,18.89-4.64,28.29-7.12c2.67-0.69,3.85-0.12,4.74,2.77c2.9,9.34,5.34,18.83,9.19,27.83c1.54,3.59,3.02,7.15,5.34,10.28
			c10.24,13.82,17.23,29.61,24.79,45.04c3.22,6.58,6.97,12.8,10.67,19.03c1.57,2.63,1.09,3.39-1.67,4.28
			c-10.17,3.32-20.19,7.08-30.33,10.47c-23.05,7.71-45.87,16.26-69.44,22.26c-3.42,0.88-3.89-0.26-4.19-3.39
			C1751.62,409.75,1753.7,404.74,1755.67,399.55z M1746.44,418.45c0.06,3.16-3.27,4.25-5.62,5.13c-19.02,7.12-38.1,14-57.13,21.08
			c-1.81,0.66-2.57,0.32-3.07-1.44c-1.67-5.96-3.95-11.73-4.98-17.88c-2.13-4.02-3.65-8.3-4.75-12.74
			c-7.22-18.93-8.17-38.12-2.37-57.65c0.63-2.13,1.36-3.2,3.69-3.48c8.73-0.99,17.31-3.1,26.14-3.43c3.73-0.13,7.48,0.39,10.96-2.89
			c1.94-1.82,5.97-1.57,9.15-1.09c7.4,1.09,14.38,0.42,20.49-4.71c0.78-0.65,2.11-1.03,3.09-0.88c6.68,1.06,12.77-1.25,18.73-3.71
			c3.95-1.64,3.76,0.06,3.96,3.3c1.31,20.72-4.28,39.77-12.25,58.37C1749.5,403.41,1746.3,410.39,1746.44,418.45z M1849.19,356.61
			c-2.73-4.16-5.06-8.56-6.62-13.36c-5.76-10.69-12.52-20.71-18.73-31.09c-6.41-10.71-8.79-23.38-12.58-35.32
			c-0.86-2.73,1.26-2.96,2.56-3.83c11.65-7.87,24.54-13.08,37.33-18.37c18.05-7.47,35.53-16.32,53.63-23.65
			c6.74-2.61,13.47-5.18,20.18-7.87c1.54-0.62,2.59-0.75,3.49,1.02c3.76,7.31,7.6,14.56,11.42,21.83c2.51,3,4.5,6.32,5.82,10.05
			c6.16,8.49,11.96,17.21,16.99,26.48c5.46,8.06,11.5,15.73,15.5,24.8c4.78,4.85,4.58,11.49,5.24,17.62c0.3,2.84-3.02,3.5-5.4,4.15
			c-4.42,5.01-10.5,7.44-15.86,10.97c-0.11,0.62-0.52,0.7-1.02,0.59c-12.31,10.63-24.75,21.08-39.81,27.29
			c-9.75,4.02-19.55,7.86-30.17,8.62c-8.34,0.6-16.32,3.15-24.14,6.06c-2.69,1.01-3.83,0.36-5.17-2.04
			C1857.45,372.67,1852.15,365.31,1849.19,356.61z M1972.95,289.81c-13.64-21.23-27.63-42.21-39.38-64.7
			c-2.18-4.18-1.22-5.31,2.64-6.68c22.59-7.94,45.13-16.23,68.36-21.72c24.42-5.76,45.33-18.63,66.01-32.31
			c2.84-1.88,4.35-2.18,6.45,1.36c4.68,7.93,10.14,15.35,15.05,23.14c2.07,3.29,4.09,3.5,6.88,1.26c2.11-1.69,4.32-3.25,6.75-5.04
			c6.36,12.62,12.64,25.08,20.21,36.74c8.98,13.83,18.02,27.65,27.37,41.21c2.48,3.62,1.45,4.6-1.84,5.49
			c-9.71,2.61-19.43,5.16-29.11,7.9c-17.08,4.85-33.77,10.92-50.45,17.06c-16.83,6.19-33.95,11.56-50.87,17.54
			c-9.19,3.25-18.25,6.94-27.3,10.58c-2.71,1.09-3.6,0.83-4.91-2.38C1984.58,308.87,1978.98,299.22,1972.95,289.81z M2070.68,159.27
			c-16.11,10.43-32.14,21.01-49.82,28.44c-3.5,1.48-4.35,1.26-5.62-3.15c-2.47-8.55-6.59-16.57-10.05-24.8
			c-1.51-3.6-2.9-7.28-4.7-10.73c-1.85-3.55-0.91-5.24,2.61-6.36c15.3-4.84,30.48-10.08,46.48-11.94c3.71-0.42,8.3-2.64,10.83-1.08
			c2.54,1.58,2.94,6.85,4.44,10.43c2.03,4.78,4.21,9.51,6.31,14.26c0.39,0.89,0.75,1.81,1.03,2.51
			C2072.32,158.3,2071.5,158.74,2070.68,159.27z M2011.07,184.25c1.62,3.62-0.09,5.67-3.75,6.75c-14.03,4.15-28.05,8.34-41.97,12.85
			c-11.06,3.58-21.97,7.64-32.96,11.52c-1.26,0.45-2.64,1.61-3.33-0.67c-3.03-10.14-7.51-19.75-10.02-30.09l-0.01-0.01
			c-2.97-5.76-2.66-7.77,2.56-10.66c7.67-4.22,14.81-9.52,22.98-12.72c0.24-0.2,0.5-0.57,0.75-0.56
			c16.04,0.99,29.96-6.59,44.38-12.11c0.42-0.16,0.93-0.03,1.39-0.03c2.59-1.85,4.37-1.77,5.56,1.82c0.73,2.24,1.95,4.35,2.41,6.74
			C2003.83,165.75,2007,175.21,2011.07,184.25z M1918.96,198.84c1.87,5.18,3.71,10.38,5.63,15.54c0.92,2.51,0.66,4.15-2.13,5.14
			c-6.76,2.43-13.43,5.1-20.14,7.67c-24.75,10.94-49.49,21.86-74.22,32.85c-2.87,1.28-5.72,2.71-8.36,4.44
			c-16.3,10.64-34.93,12.87-53.24,16.2c-1.21,0.22-2.51,0.55-3.56-0.57c-0.72-7.05-1.44-14.1-2.15-21.16
			c-1.8-10.04-1.82-10.08,7.31-14.45c13.77-6.59,27.56-13.13,41.36-19.65c6.87-3.23,14.48-2.41,21.63-4.25
			c13.33-3.39,27.35-3.62,40.1-9.71c3-1.44,5.85-3.04,8.5-5.07c0.72-1.35,1.39-2.76,3.04-3.13c1.61-5.07,4.83-8.59,9.32-11.09
			c5.24-2.92,10.54-5.79,15.51-9.19c3.71-2.54,5.82-2.51,6.68,2.46c0.11,0.65,0.39,1.25,0.59,1.87c0.22,0.8,0.43,1.59,0.66,2.38
			C1916.65,192.36,1917.8,195.61,1918.96,198.84z M1851.68,212.18c-8.66,0.63-16.77,4.12-25.31,4.77
			c-19.75,1.49-35.7,13.44-53.41,20.48c-4.24,1.68-8.24,3.98-12.34,6.05c-1.82,0.93-3.3,1.32-3.69-1.51
			c-2.23-16.1-6.95-31.57-11.03-47.21c-0.89-3.38-0.75-5.3,3.58-6.33c20.84-5.01,41.99-8.07,62.92-12.41
			c6.94-1.44,12.44-0.46,18.44,3.15c14.25,8.56,28.84,16.52,43.83,25.02C1867.04,208.36,1859.68,211.61,1851.68,212.18z
			 M1763.75,325.39c0.5,2.59-0.22,3.58-2.23,4.37c-5.86,2.27-11.6,5.07-18.12,4.24c-2.24-0.29-4.51-0.23-6.29,1.35
			c-7.32,6.56-15.8,4.74-24.17,3.78c-8.62,4.01-17.75,5.21-27.07,5.41c-3.43,1.31-7.09,1.32-10.58,2.15
			c-4.5,1.06-7.17,0.13-6.62-5.37c-1.09-10.02-4.04-19.75-4.45-29.87c-0.17-0.27-0.43-0.5-0.52-0.8c-2.44-7.83-2.25-8.16,5.37-9.62
			c27.24-5.26,54.59-9.97,81.69-16.09c3.78-0.86,6.09-0.6,6.79,4.65C1759.13,301.59,1761.45,313.49,1763.75,325.39z M1664.62,343.85
			c0.27,1.67,0.03,3.17-2.18,2.25c-14.58-6.08-28.28-0.03-42.33,2.89c-10.27,2.14-20.84,2.69-31.28,3.96
			c-1.88,0.23-2.44-0.8-2.63-2.53c-0.6-5.72-1.24-11.42-1.98-17.11c-0.26-1.95,0.65-2.99,1.94-4.09
			c11.75-10.04,25.13-16.43,39.91-19.72c9.05-2.01,18.14-3.76,27.19-5.82c3.43-0.78,5.4-0.32,5.43,3.98
			c0.01,2.43,0.73,4.84,1.13,7.27c0.55,3.52,1.11,7.04,1.65,10.57C1662.54,331.62,1663.62,337.74,1664.62,343.85z M1586.13,361.13
			c0.23-2.4,1.16-3.72,3.78-3.92c8.11-0.63,16.09-2.71,24.32-2.69c6.79,0.03,13.2-2.69,19.66-4.51c9.05-2.56,17.74-2.69,26.83,0
			c4.02,1.19,4.29,2.99,3.4,6.12c-1.85,6.48-2.51,13.17-3.59,19.81c-1.8,11.16,1.74,21.44,4.64,31.77
			c3.13,11.13,6.94,22.05,10.33,33.1c2.04,6.61,1.74,7.34-4.48,9.59c-10.11,3.68-19.53,8.86-29.16,13.66
			c-11.68,5.82-21.92,13.92-32.16,22.03c-4.14,3.27-4.77,3.33-6.32-1.87c-3.07-10.25-9.69-18.61-13.43-28.51
			c-5.07-13.39-8.57-27.07-8.04-36.91C1582.16,396.33,1584.41,378.75,1586.13,361.13z M1486.48,494.2c1.49-1.77,2.71-1.22,3.95,0.06
			c4.47,4.57,9.09,9.02,13.3,13.83c5.36,6.12,11.6,11.26,17.19,17.26c-2.11,1.61-3.19,0.4-4.48-0.59
			c-10.57-8.27-19.68-18.27-29.51-27.39C1485.9,496.42,1485.21,495.68,1486.48,494.2z M1523.72,528.87
			c2.51-1.44,4.91-3.15,7.58-4.27c4.41-2.44,8.78-4.94,13.21-7.31c1.65-0.89,3.33-1.75,5.07,0.19c1.8,2,2.99,3.16-0.2,5.44
			c-4.27,3.04-8.7,5.64-13.2,8.22c-2.03,0.99-3.99,2.1-6,3.12C1527.72,532.08,1523.44,529.03,1523.72,528.87z M1544.81,511.46
			c-5.2,4.09-11.17,6.62-16.76,9.9c-2.3,1.34-3.79,0.96-5.82-0.83c-8.5-7.54-15.91-16.2-23.8-24.36c-2.11-2.18-4.25-4.37-6.62-6.23
			c-2.5-1.98-5.27-2.36-7.53,0.49c-2.38,3.03-4.72,0.93-6.68-0.2c-2.66-1.57-0.16-3.49,0.49-4.97c4.8-11.2,11.46-21.33,17.11-32.01
			c4.84-9.16,7.73-19.65,8.39-30.03c1.18-18.57,7.48-35.32,14.61-53.23c6.49,8.42,12.62,16.23,18.6,24.16
			c3.19,4.24,6.28,8.59,9.06,13.11c2.86,4.62,4.91,9.94,4.06,15.37c-2.79,17.87,2.58,33.38,11.07,48.44
			c5.87,10.44,10.79,21.47,16.26,32.16c1.15,2.25,0.85,3.32-1.29,4.51c-5.4,3-10.67,6.25-15.94,9.45c-1.55,0.95-2.69,1.28-3.82-0.7
			c-0.86-1.54-2.1-2.87-3.3-4.17C1550.46,509.68,1547.44,509.39,1544.81,511.46z M1560.63,521.79c6-3.52,12.05-6.99,18.04-10.53
			c1.36-0.8,2.14-0.95,2.7,0.98c1.72,5.86,4.88,10.96,8.11,15.98c1.34,2.07,1.34,3.1-1.19,4.29c-5.97,2.83-11.39,6.88-17.77,8.85
			c-1.05,0.33-2.1,0.65-2.66,0.83c-2.33,0.33-2.43-1.11-2.76-2.38c-1.38-5.2-2.61-10.47-5.53-15.04
			C1558.52,523.12,1559.51,522.43,1560.63,521.79z M1583.94,511.35c-0.69-1.98-0.55-2.97,1.26-3.78c1.15-0.52,2.33-1.08,3.3-1.88
			c2.99-2.43,5.27-2.38,6.72,1.71c1.85,5.18,6,5.41,10.4,5.36c2.3-0.03,6.12-0.36,6.46,1.22c0.56,2.47-2.76,3.92-4.67,5.44
			c-3.73,2.99-7.64,5.73-11.47,8.55c-0.52,0.37-1.13,0.57-1.7,0.86c-1.26,0.29-1.52-0.82-2.04-1.59
			C1588.92,522.25,1585.93,517.11,1583.94,511.35z M1597.62,503.79c-0.95-2.11-0.7-2.97,0.82-4.37c7.31-6.62,7.34-6.65,11.09,2.71
			c1.02,2.53,2.08,5.04,3.35,8.11C1606.24,510.41,1600.67,510.64,1597.62,503.79z M1612.04,496.7c-3.3-6.15-3.29-6.15,2.37-10.46
			c1.61-1.21,2.8-3,4.84-3.58c6.28-5.7,13.26-10.25,20.65-14.22c11.02-5.9,22.12-11.59,33.75-16.07c2.61-1.02,3.71-0.68,4.47,2.38
			c4.02,16.09,5.31,32.62,7.74,48.96c1.64,10.93,1.42,10.89-9.22,10.73c-16.75-0.27-33.46-0.52-50.18-2.61
			c-7.76-0.98-11.5-4.68-13.24-11.94C1612.96,498.79,1612.45,497.76,1612.04,496.7z M1682.06,453.22c-0.56-2.4-0.27-3.35,2.13-4.24
			c20.65-7.71,41.28-15.51,62.15-22.55c21.72-7.32,43.46-14.56,65.19-21.84c0.14-0.06,0.29-0.14,0.43-0.2
			c4.24-1.72,7.21-2.1,10.3,3.27c4.77,8.3,12.85,13.74,19.76,20.14c7.04,6.51,14.22,12.83,21.34,19.23
			c4.41,5.04,11.48,5.24,16.17,9.75c5.95,1.52,11.17,5.21,17.23,6.38c2.38,0.46,2.3,2.57,2.46,4.17c0.3,3.25,0.36,6.56,0.14,9.81
			c-0.16,2.27,0.07,4.21,1.55,5.89c6.09,5.01,10.8,10.9,11.49,19.39c1.61,13.99,0.57,27.81-2.46,41.51
			c-0.68,3.02-1.65,3.72-4.74,2.94c-7.27-1.82-14.63-4.37-22.12-3.65c-5.14,0.49-10.25,1.31-15.41,0.92
			c-11.68-0.86-22.91-3.96-34.24-6.94c-8.92-2.34-17.85-4.68-26.81-6.94c-6.23-1.58-12.44-3.43-18.76-4.47
			c-5.7-0.93-11.59-0.57-17.32-1.39c-14.39-2.08-28.78-4.25-42.96-7.67c-2.51-0.6-5,0.01-7.48-0.09c-7.86-0.29-15.7-0.89-23.54-1.21
			c-3.79-0.16-5.31-1.92-5.83-5.92C1688.35,490.68,1686.44,471.74,1682.06,453.22z M1880.49,450.64
			c-5.53-4.14-12.62-5.99-18.25-10.6c-8.63-7.08-16.52-15.05-24.63-22.74c-4.25-4.02-9.16-7.35-12.55-12.37
			c-1.88-2.77-2.24-4.27,1.68-5.63c18.4-6.42,36.71-13.14,55.5-18.18c9.81-2.63,20.12-2.86,29.83-6.48
			c15.2-5.66,29.44-12.94,41.69-24.13c2.81-2.57,5.89-4.85,8.85-7.28c6.06-3.65,12.08-7.35,18.5-10.38c3.79-1.8,4.93-0.34,5.8,3.09
			c2.66,10.28,4.32,20.77,5.73,31.28c2.11,15.61,7.28,29.38,18.6,40.49c9.26,9.08,15.51,20.38,19.2,33.15
			c0.55,1.87,1.19,3.68,1.8,5.52c0.13,0.93,0.26,1.87,0.39,2.79c2.94,4.85,3.94,10.48,5.69,15.8c0.86,2.67-1.42,2.63-2.77,2.64
			c-14.59,0.12-28.31-2.71-39.54-13.46c-4.48-4.28-8.72-8.96-14.95-10.56c-0.43,0.4-0.88,0.8-1.31,1.21
			c-0.96,0.69-1.91,1.38-2.87,2.07c-1.72,1.59-3.78,2.18-6.03,2.37c-19.29,1.54-38.26,5.4-57.35,8.34
			C1901.54,459.43,1890.81,458.36,1880.49,450.64z M2175.84,262.92c4.97-3.63,9.98-7.18,15.94-8.82
			C2187.82,259.71,2181.29,260.25,2175.84,262.92z M2192.13,253.85c0.1-0.1,0.2-0.19,0.3-0.29c-0.06,0.07-0.11,0.17-0.2,0.23
			C2192.2,253.82,2192.17,253.83,2192.13,253.85z M2030.24-34.83c0.29-1.16,1.46-1.05,2.37-1.16c2-0.23,4.11-0.82,5.66,1.21
			c7.2,9.41,15.15,18.27,18.4,26.61c-6.97-6.72-16.06-15.5-25.13-24.26C2030.84-33.09,2029.98-33.8,2030.24-34.83z M2024.78-28.4
			c6.16,7.22,14.2,12.37,21.06,18.77c16.29,15.22,28.72,32.89,32.07,56.73c3.1,22.23,6.03,44.51,9.13,66.76
			c0.56,4.08,1.88,7.93,3.12,11.82c5.89,18.71,18.54,31.88,32.62,43.98c8.96,7.71,17.25,16.37,26.67,23.38
			c10.79,8,19.82,18.12,30.59,26.02c8.47,6.2,17.35,11.78,25.97,17.74c4.61,3.19,4.62,3.75-0.23,6.16
			c-12.14,6.06-24.36,11.96-35.62,19.75c-0.26,0.17-0.56,0.3-0.85,0.43c-8.07,3.86-8.47,3.75-13.44-3.76
			c-12.74-19.27-26.35-37.94-37.3-58.47c-3.42-6.42-6.49-13.07-9.69-19.62c-1.45-2.97-3.04-3.35-5.36-0.8
			c-6.75,7.45-6.76,7.4-12.6-0.75c-11.36-15.88-20.02-33.25-25.95-52.06c-0.79-2.48-1.48-4.15-4.47-3.59
			c-18.21,3.42-36.55,6.29-54,13.04c-14.39,5.54-28.7,11.36-43,17.12c-1.95,0.78-3.85,1.38-5.95,1.08
			c-15.04-1.98-25.84,8.13-37.83,14.68c-3.78,2.07-5.66,2.37-6.85-2.69c-1.97-8.39-4.5-16.62-6.79-24.92
			c-0.09-0.76-0.19-1.52-0.27-2.27c-2.96-4.85-3.43-10.61-5.13-15.91c-1.38-4.29-3.39-7.88-6.23-11.13
			c-2.41-1.13-4.84-2.23-7.22-3.42c-2.51-1.25-4.35-3.15-4.93-6.12c-0.86-4.5-1.65-8.99-2.63-13.46c-0.46-2.13,0.55-3.06,2.17-3.63
			c5.89-2.05,11.35-5.11,17.61-6.43c7.43-1.57,13.79-6.23,18.91-12.16c8.52-9.82,17.02-19.62,27-27.89
			c4.98-4.14,6.02-10.53,7.83-16.39c0.73-2.37,1.36-4.57,3.59-5.83c9.08-10.77,18.36-21.28,29.8-29.49
			c6.74-4.81,10.7-12.24,15.37-18.97c1.95-2.81,1.65-5.54,8.09-4.65C2019.06-34.54,2020.69-33.17,2024.78-28.4z M1996.79-47.11
			c1.57-2.64,3.19-5.24,5.06-8.32c1.65,6.32,6.09,9.82,10.89,13.79c-5.77,0.45-10.27-1.91-15.02-2.43
			C1995.24-44.32,1996.02-45.79,1996.79-47.11z M1890.64-18.93c-5.39-5.18-11.68-9.32-17.54-13.97c-1.88-1.48-3.36-3.32-4.55-6.58
			c2.67,0,4.71-0.11,6.72,0.01c10.46,0.69,20.64-0.66,30.62-3.98c2.83-0.95,5.83-1.08,8.85-0.96c18.67,0.67,37.34,0.98,56.03,0.69
			c0.9-0.01,1.81,0.1,3.37,0.2c-3.17,6.36-8.3,9.46-13.8,11.46c-15.22,5.5-30.61,10.54-45.93,15.77c-0.75,0.26-1.61,0.32-2.24,0.75
			C1904.01-9.99,1897.33-12.5,1890.64-18.93z M1743.83,126.83c0.56-5.56,1.26-11.09,1.77-16.65c0.78-8.9,2.2-17.79-0.01-26.73
			c-0.56-2.25,0.23-4.54,0.93-6.74c1.87-5.77,4.74-11.03,7.43-16.39c1.68-3.33,4.21-5.27,7.47-6.25c6.79-2.03,13.64-3.81,20.75-4.01
			c4.48-0.14,8.65-1.59,12.74-3.22c9.48-3.76,19.86-5,28.35-11.52c4.71-3.63,9.32-7.24,11.23-13.41c0.57-1.87,2.04-2.73,3.75-3.4
			c20.21-8,40.4-16.06,60.61-24.1c0.85-1.02,2-0.86,3.1-0.86c10.13-5.31,21.18-7.87,31.78-11.75c10.64-3.89,21.76-6.3,31.96-11.6
			c4.61-2.4,8.59-5.54,11.45-9.95c1.32-2.04,2.66-2.51,4.68-2.05c5.51,1.26,11.02,2.66,16.56,3.71c2.73,0.52,2.14,1.68,1.06,3.23
			c-5.6,8.06-10.69,16.37-18.89,22.26c-10.89,7.83-19.47,18.44-28.18,28.75c-2.1,2.5-3.13,5.99-4.67,9.02
			c-0.11,3.88-1.44,7.22-4.12,9.94c-4.41,5.33-9.78,9.52-14.97,13.95c-3.69,5.11-7.6,10.02-12.6,13.84
			c-3.89,7.24-10.51,10.69-17.62,12.77c-4.68,1.39-9.26,2.92-13.73,4.85c-2.71,1.18-5.29,3-8.47,1.67
			c-0.52-0.22-1.39-0.11-1.84,0.23c-10.14,7.6-22.59,7.87-33.98,11.43c-19.82,6.22-39.58,12.61-59.37,18.91
			c-4.34,1.39-8.69,2.77-13.07,4.05c-7.86,2.28-14.52,6.87-20.98,11.82c-0.76,0.59-1.54,1.38-2.51,0.83
			C1743.41,128.91,1743.74,127.73,1743.83,126.83z M1753.08,129.24c9.64-6.88,20.55-9.55,31.22-13.03
			c22.41-7.27,44.72-14.89,67.47-20.95c6.68-1.78,13.39-3.42,20.01-5.34c2.61-0.76,3.89-0.23,4.15,2.67
			c0.11,1.29,0.7,2.54,0.78,3.83c0.47,8.82,3.95,15.6,11.86,19.43c4.81,0.43,6.53,4.51,7.58,8.23c4.34,15.35,8.6,30.72,13.29,45.97
			c1.01,3.26,0.96,6.03-2.97,8c-6.19,3.1-11.93,7.15-17.94,10.66c-3.82,2.24-6.87,5.13-8.45,9.46c-1.26,3.4-3.03,3.27-5.21,0.95
			c-0.5-0.26-1.16-0.36-1.15-1.12c-1.01,0.4-1.54-0.14-1.84-1.08l-0.01-0.01c-7.02-2.43-13.1-6.85-19.63-10.33
			c-7.78-4.14-15.27-8.9-22.88-13.4c-1.11-0.12-2.14-0.36-2.71-1.51c-3.88-2.67-7.55-1.67-11.94-0.66
			c-14.05,3.23-28.34,5.29-42.5,7.99c-7.38,1.41-14.72,3.13-22.03,4.93c-6.06,1.49-5.86,1.61-6.69-4.71
			c-1.13-8.62-2.38-17.21-0.8-25.89c-0.32-1.98-0.37-3.94,0.43-5.83C1742.05,138.76,1746.4,134.01,1753.08,129.24z M1686.14,167.56
			c-3.25-7.73-5.08-15.76-5.33-24.13c-0.11-3.45,2.8-5.67,4.31-8.47c-0.19-0.96,0.23-1.38,1.12-1.38c1.11-4.38,3.12-5.69,7.77-4.28
			c5.2,1.58,10.84,1.59,16.29,2.27c3.46,0.43,4.83-1.22,4.71-4.83c-0.27-9.09,1.68-17.85,3.89-26.57c0.52-2,1.35-3.13,3.17-3.75
			c4.75-1.61,9.46-3.35,14.2-5.01c4.95-1.75,5.54-1.36,5.86,4.25c0.37,6.58-0.43,13.06-1.38,19.55
			c-1.85,12.65-0.57,25.55-2.63,38.19c-0.07,0.49-0.17,0.96-0.26,1.45c0.07,0.2,0.16,0.4,0.19,0.62c0.01,0.09-0.1,0.19-0.16,0.29
			c-0.17,8.53,0.07,17.02,1.67,25.42c0.46,2.48,0.6,4.41-2.89,5.26c-11.42,2.8-22.72,6.03-33.99,9.41c-2.94,0.88-3.88,0.49-4.83-2.7
			C1695.17,184.06,1689.8,176.23,1686.14,167.56z M1649.97,212.9c28.84-3.68,56.01-14.3,83.98-21.69c6.52-1.74,6.89-1.75,8,5.7
			c2.89,19.65,10.25,38.1,13.77,57.61c1.34,7.4,1.06,14.68,1.23,22.03c0.06,2.43-1.58,2.43-3.03,2.89
			c-11.78,3.66-23.94,5.37-35.96,7.8c-16.01,3.23-32.1,6.06-48.16,9.08c-8.65,1.62-8.65,1.64-9.68-7.32
			c-1.42-3.6-1.67-7.47-2.18-11.26c-0.98-3.94-3.07-7.51-0.37-11.98c1.05-1.75-0.47-5.37-1.15-8.04
			c-3.46-13.6-4.62-27.75-8.69-41.21C1647.02,214.09,1647.86,213.17,1649.97,212.9z M1638.48,215.41c3.4-1.36,4.73,1.58,5.36,3.69
			c1.61,5.43,3.39,10.89,3.59,16.69c2.66,4.88,1.68,10.14,1.38,15.31c-0.1,1.62-0.49,3,0.73,4.54c2.34,2.93,3.99,5.96,1.82,10.15
			c-1.18,2.3,0.89,5.03,1.01,7.64c1.03,5.3,2.05,10.6,3.07,15.9c2.44,9.19,2.46,9.32-6.16,10.8c-17.03,2.94-33.75,7.04-49.82,13.76
			c-3.4,1.42-5.83,4.34-8.78,6.49c-9.75,7.15-19.39,14.48-29.05,21.77c-2.47,1.87-3.92,2.46-4.06-1.87
			c-0.23-7.32-4.48-13.54-5.8-20.64c-0.49-2.67-1.02-4.73,0.8-7.27c2.13-3,1.95-6.52-0.67-9.46c-2.28-2.57-5.43-3.78-8-1.61
			c-4.11,3.48-8.04,2.23-12.22,1.01c-1.51-0.45-3.04-0.78-4.57-1.15c-3.94-0.96-7.86-1.95-11.81-2.84
			c-1.97-0.45-2.36-1.25-1.02-2.94c2.54-3.25,4.48-7.1,8.32-9.02c11.48-11.16,20.9-23.67,25.62-39.67
			c2.2-7.45,7.48-12.39,13.63-16.82c12.21-8.78,25.71-11.35,40.1-11.4C1614.18,218.43,1626.35,216.97,1638.48,215.41z
			 M1526.45,117.12c0.04-0.65,0.09-1.29,0.14-1.94c0.56-14.61,1.31-29.2,1.65-43.8c0.83-35.98,6.13-71.58,7.87-107.49
			c0.37-7.86,1.97-15.6,3.3-23.35c0.52-2.97,1.9-3.58,4.52-3.56c17.91,0.17,35.81-0.75,53.73,0.4c3.65,0.24,4.39,0.8,3.82,4.73
			c-2.04,13.84-1.08,27.88-1.71,41.81c-1.61,35.12-3.15,70.23-4.61,105.35c-0.5,11.98,0.59,23.96,1.02,35.92
			c0.03,1.03,0.06,2.05,0.09,3.07c1.28,18.63,2.57,37.26,3.86,55.9c0.6,8.82,1.12,17.65,1.85,26.47c0.24,2.97-0.53,4.15-3.53,3.89
			c-15.27-1.25-28.52,4.52-40.92,13.11c-6.89,4.78-11.46,11.42-14.09,19.59c-0.78,2.46-1.87,4.8-2.82,7.2
			c-0.69,2.77-2.08,5.18-3.63,7.51c-4.97,7.76-10.25,15.22-17.46,20.97c-1.58,1.26-3.2,2.44-5.11,1.59
			c-2.13-0.93-0.95-3.15-1.02-4.77c-0.53-12.95,2.96-25.19,6.08-37.53c2.03-8.07,3.4-16.24,3.52-24.72
			c0.07-5.5,2.15-11.03,1.57-16.57C1521.61,172.86,1526.72,145.05,1526.45,117.12z M1388.83,121.14
			c10.03-7.71,19.2-16.77,31.19-21.34l-0.04-0.3l0.27-0.06c3.85-10.74,5.51-22.07,7.81-33.22c0.86-4.18,2.46-4.87,5.97-4.47
			c12.62,1.42,25.25,2.8,37.9,3.82c3.71,0.29,4.14,1.88,3.39,4.94c-2.53,10.14-4.62,20.37-6.39,30.66c0.33,1.46,0.27,2.89-0.29,4.28
			c-0.76,5.99-1.57,11.99-2.24,18.01c-0.27,2.44-0.47,4.78-1.92,6.94c-0.75,1.11-1.05,2.99,0.75,3.76c1.45,0.63,2.2-0.5,2.93-1.68
			c3.23-5.24,2.6-11.36,3.49-17.09c0.49-3.16,0.59-6.43,1.26-9.61c-0.36-0.9-0.16-1.64,0.6-2.21c-0.04-10.13,3.48-19.58,5.13-29.37
			c0.22-1.29,0.63-2.56,0.75-3.85c0.27-3.04,1.65-3.96,4.62-3.63c12.8,1.42,25.61,2.64,38.42,3.85c2.13,0.2,3.07,0.92,2.93,3.3
			c-0.78,13.73-0.46,27.53-2.18,41.22l0.03-0.13c0.09,0.75,0.17,1.48,0.26,2.21c-0.01,0.75-0.01,1.49-0.03,2.24
			c-0.3,18.56-1.24,37.07-2.54,55.57c-0.52,7.34,0.66,14.71,0.69,22.02c0.1,18.35-1.59,36.48-7.81,54.03
			c-3.13,8.88-2.81,18.76-2.94,28.25c-0.07,4.44-1.23,5.49-5.44,4.81c-4.31-0.69-8.78-0.2-13.18-0.22c-1.44-0.01-3.03,0.1-3.94,1.29
			c-5.83,7.71-14.48,7.74-22.59,9.09c-7.27,1.21-14.32,3.25-21.44,5.01c-10.14,2.53-19.81,5.9-29.18,10.46
			c-1.8,0.88-8.03-2.89-9.02-5.59c-0.76-2.11-1.46-4.5-1.32-6.69c0.82-12.95-0.46-26.17,4.7-38.63c1.19-2.87,2.15-4.34,5.34-4.94
			c12.22-2.31,24.42-4.61,36.93-3.62c2.37,0.19,2.87-1.19,3.69-3.26c7.41-18.64,5.14-37.99,4.04-57.25c-0.1-1.68,0-4.25-2.37-3.99
			c-2.57,0.27-2.07,2.84-1.94,4.77c0.69,10.64,1.49,21.27,1.57,31.96c0.06,8.03-4.05,14.75-5.3,22.3c-0.27,1.59-1.62,1.35-2.66,1.26
			c-11.88-1.01-23.42,1.51-35.02,3.53c-0.39-0.04-0.76-0.07-1.15-0.11c0.11-0.26,0.22-0.53,0.33-0.79c0.37-4.04,3.12-6.72,5.3-9.64
			c7.44-9.94,7.78-20.68,3.59-31.94c-4.5-12.02-9.18-23.97-13.77-35.95c-5.14-11.73-10.27-23.48-15.41-35.22
			c-1.74-3.27-3.48-6.53-5.2-9.81C1386.24,123.97,1387.13,122.45,1388.83,121.14z M1234.02,114.89
			c2.54-10.58,7.37-20.24,11.23-30.28c0.66-3.92,1.32-7.84,1.98-11.76c-1.9-13.46-3.92-26.9-5.64-40.37
			c-1.59-12.52-3.68-25.06-4.09-37.66c-0.47-13.51-2.48-27.07-0.49-40.66c0.32-2.17,0.24-4.48,3-4.77c2.59-0.27,4.93-0.06,6.03,3.22
			c1.26,3.73,3.62,6.84,6.19,9.77c3.3,3.79,6.02,7.94,7.25,13.03c1.92,7.97,7.22,14.53,8.7,22.74c0.33,1.9,2.03,3.27,3.49,4.52
			c5.96,5.06,11.92,10.1,17.88,15.15c4.62,4.08,9.25,8.16,13.87,12.24c3.1,2.94,8.45,5.66,8.73,8.88c0.32,3.5-5.16,6.35-8.16,9.44
			c-4.47,4.58-8.93,9.18-13.5,13.66c-1.06,1.05-1.71,2.9-3.68,2.66c-11.58,16.27-22.43,33.12-35.57,48.13
			c-2.11,2.4-4.39,4.65-7.51,5.63c-3.36,3.81-7.84,5.96-12.18,9.58C1231.4,122.82,1232.75,118.87,1234.02,114.89z M1203.4,193.05
			c-0.14-7.05,2.48-13.39,5.07-19.69c5.34-13.01,12.64-24.9,19.93-36.72c2.15-3.46,6.18-5.96,9.78-8.1
			c14.19-8.42,24.21-21.23,33.29-34.8c11.03-16.49,23.51-31.47,37.94-44.74c7.78-7.17,7.58-7.2,14.86,0.4
			c7.53,7.86,15.28,15.47,22.91,23.22c0.89,0.9,1.64,1.97,2.46,2.96c2,2.94,3.99,5.9,6,8.85c8.3,12.65,16.63,25.29,24.89,37.97
			c1.12,1.74,1.91,3.72,2.86,5.59c0.76,0.17,1.06,0.7,1.12,1.46c3.09,4.35,1.94,7.08-1.97,10.74c-12.34,11.5-26.1,20.8-39.96,29.97
			c-3.65,2.41-3.99,5.04-2.41,8.65c2.34,5.34,4.71,10.7,5.34,16.65c0.63,2.83,1.45,5.63,1.36,8.59c3.06,15.57,6.13,31.14,9.16,46.71
			c0.79,3.99,1.45,8,2.24,12.44c-4.08-0.36-7.34-2.18-10.67-3.6c-39.6-16.95-79.16-33.95-118.72-51c-2.26-0.96-4.78-1.57-6.45-3.71
			c-1.36-0.1-2.76-0.12-3.88-1.13c-4.35-1.77-8.69-3.56-13.06-5.3C1202.94,197.43,1203.44,195.06,1203.4,193.05z M1116.39,212.31
			c0.55-0.79,0.89-1.61,2.04-1.75c10.27-1.26,20.52-2.59,30.79-3.89c4.19-0.9,8.4-1.81,12.61-2.7c11.62-2.18,23.45-0.79,35.12-2.18
			c1.87-0.22,2.8,0.66,3.09,2.73c1.36,10.31,4.68,19.93,9.62,28.97c0.46,0.83,1.26,1.78,0.57,2.69c-4.42,5.83-6.75,13.21-12.19,18.3
			c-0.7,2.84-2.24,5.2-3.94,7.47c-6.16,16.59-16.96,29.57-29.39,41.31c-5.23,4.94-10.96,9.51-11.71,18.71
			c-5.89-5.66-11.17-10.56-16.26-15.67c-3.3-3.33-6.84-6.06-11.03-8.13c-9.81-4.84-16.33-13.69-22.48-22.46
			c-3.09-4.38-3.07-10.56-3.26-16.14c-0.19-5.4-0.36-10.77-1.77-16.06c-0.75-2.81,0.04-5.44,1.75-7.84
			C1105.49,227.91,1110.93,220.11,1116.39,212.31z M1113.23,211.36c-5.49,8.06-10.56,15.47-15.61,22.89
			c-0.83,1.24-1.58,1.08-2.69,0.26c-8.89-6.64-19-10.63-29.11-14.62c-0.93-0.36-2.28-0.26-2.73-2.18
			C1079.7,216.23,1095.98,212.58,1113.23,211.36z M1050.27,219.31c3.72,0.62,8.72,2.96,13.89,4.68c8.09,2.69,15.73,6.43,23.22,10.5
			c2.33,1.26,4.61,2.67,6.16,5.14c2.56,4.11,2.27,5.18-2.24,6.78c-10.37,3.65-20.74,7.27-31.08,11.02c-2.04,0.73-3.32,0.8-4.5-1.57
			c-4.51-8.96-8.3-18.3-13.47-26.93C1039.51,224.36,1042.89,219.69,1050.27,219.31z M1025.18,210.57
			c0.42-11.49,0.91-22.97,1.12-34.45c0.07-3.39,1.12-4.61,4.41-4.55c9.52,0.17,19.06,0.07,29.33,0.07
			c-4.24,7.48-9.08,13.5-13.92,19.37c-5.44,6.59-8.03,14.02-8.32,22.39c-0.13,3.81-1.69,4.51-4.8,4.78
			C1024.86,218.9,1024.89,219.03,1025.18,210.57z M1037.54,224.32c-2.41,0.33-4.7-0.45-6.82-3.02c2.73-0.13,4.97-2.27,6.91,0.49
			C1038.05,222.38,1038.46,224.2,1037.54,224.32z M1006.81,238.41c4.51-3.07,8.43-7.07,12.95-10.13c7.99-5.41,18.25-2.47,22.63,6.46
			c5.4,11.07,10.46,22.32,15.73,33.46c1.8,3.82,4.11,7.4,5.59,11.33c2.15,5.76,1.92,5.83-3.81,7.37
			c-6.82,1.82-13.79,3.16-20.35,5.97c-3.73,1.34-7.48,2.69-11.23,4.04c-4.52,1.74-9.18,3.2-13.53,5.33
			c-3.43,1.67-4.75,0.47-5.49-2.87c-0.29-1.25-0.86-2.44-1.29-3.66c-0.57-3.29-2.11-6.19-3.52-9.13
			c-4.22-7.84-8.47-15.67-12.65-23.53c-2.87-5.41-3.25-5.53-8.09-2.25c-3.94,2.67-8.03,5.13-11.86,7.94
			c-1.68,1.22-2.7,1.45-3.73-0.53c-0.9-1.74-1.91-3,0.69-4.67C981.62,255.36,994.26,246.95,1006.81,238.41z M963.23,169.43
			c2.76-1.09,5.36-2.66,8.01-4.01c1.46,0.42,1.78,1.74,2.31,2.89c2.92,6.33,5.9,12.62,8.76,18.99c1.13,2.54,2.53,4.88,4.19,7.07
			c0.76,1.01,1.87,2.21,1.84,3.3c-0.24,11.02,6.52,19.22,10.57,28.41c3.95,8.96,4.47,8.75-3.48,14.23c-3.12,2.17-6.43,4.05-9.51,6.3
			c-2.4,1.75-3.6,1.18-4.06-1.67c-0.78-4.67-1.12-9.46-2.27-14.03c-4.7-18.69-10.24-37.1-18.86-54.27
			C958.74,172.64,959.51,170.91,963.23,169.43z M955.09,174.6c6.49,11.39,10.46,24.06,14.72,36.57c4.19,12.27,5.96,25.05,8.99,37.54
			c0.47,1.92-0.24,2.59-1.55,3.48c-9.85,6.65-19.72,13.27-29.44,20.11c-2.41,1.69-3.53,1.21-4.58-1.31
			c-0.56-1.34-1.28-2.63-2.05-3.85c-1.29-2-2.94-2.92-5.16-1.38c-0.26,0.19-0.55,0.34-0.82,0.49c-3,1.58-5.95,5.49-8.73,4.61
			c-2.47-0.78-3.85-5.21-5.77-8.01c-18.37-26.73-35.06-54.65-52.97-81.71c-1.57-2.34-2.01-3.71,0.56-5.83
			c9.59-7.93,14.92-18.24,14.46-31.28c-0.1-3.17,0.66-4.6,3.86-5.04c5.74-0.82,11.42-2.15,16.56-3.16c1.55,0.24,2.54,0.22,3.39,0.56
			C926.09,144.48,943.99,155.08,955.09,174.6z M838.4,38.54c1.42-1.16,2.33-3.04,3.46-4.61c0.22-0.23,0.43-0.46,0.66-0.7
			c5.27-9.82,10.56-19.63,15.8-29.49c0.99-1.87,1.95-1.52,3.25-0.39c4.8,4.21,9.74,8.24,14.42,12.58c3.37,3.13,4.77,7.07,3.65,11.95
			c-1.09,4.77-1.74,9.65-2.59,14.49c0.09,1.01,0.16,2.01-0.63,2.8c-0.59,6.74,3.39,11.07,7.67,14.98
			c5.69,5.21,10.69,10.96,15.27,17.26c7.02,9.68,8.14,21.1,9.65,32.42c0.75,5.67,0.73,11.46,1.08,17.19
			c0.1,1.55,0.19,2.66-1.92,3.04c-7.41,1.34-14.78,2.96-22.13,4.58c-2.27,0.5-3.71,0.19-4.93-2.23
			c-14.55-28.78-29.11-57.55-43.93-86.19C835.38,42.8,835.55,40.86,838.4,38.54z M832.8,46.79c2.94,6.13,5.93,12.24,8.9,18.34
			c2.21,2.99,3.73,6.36,5.21,9.78c9.36,18.14,18.77,36.25,28.09,54.4c3.72,7.25,3.63,7.32-3.52,10.48
			c-6.64,2.93-13.26,5.92-19.95,8.72c-4.68,1.97-6.56,0.76-7.37-4.25c-1.28-8.04-5.87-14.42-10.07-20.9
			c-8.92-13.85-17.49-27.91-24.39-42.99c-1.61-3.52-2.57-7.37-3.6-11.13c-0.49-1.8,0.14-3.5,1.42-4.94
			c6.36-7.07,13.41-13.27,21.21-18.57C830.63,44.45,831.79,44.68,832.8,46.79z M811.34,16.11c0.2-2.83,0.4-5.66,0.66-9.06
			c3.71,3.4,4.58,7.71,6.42,11.43c3.29,6.49,6.52,13,9.88,19.43c0.86,1.65,0.7,2.59-0.85,3.56c-7.08,4.48-13.46,9.94-20.32,16.99
			C808.63,43.46,809.98,29.78,811.34,16.11z M771.96,15.36c4.05-4.97,8.13-9.92,12.16-14.94c1.16-1.44,2.56-1.62,4.22-1.38
			c5.9,0.86,11.79,1.78,17.72,2.37c2.87,0.29,2.25,2.14,2.1,3.83c-1.28,13.31-2.59,26.64-3.94,39.94
			c-0.62,6.02-1.36,12.01-2.1,18.48c-2.27-1.67-2.9-4.14-3.86-6.16c-5.62-11.73-11.82-23.02-20.9-32.31
			c-0.23-0.24-0.47-0.5-0.72-0.75c-2.46-1.15-3.09-4.02-5.01-5.7C770.31,17.6,771.11,16.41,771.96,15.36z M659.85-54.05
			c8.39-0.52,16.01-1.02,23.64-1.46c7.37-0.43,14.74-0.82,22.12-1.18c10.31-0.5,19.89,7.57,21.21,18.41
			c1.13,9.26,1.61,18.63,2.5,27.93c0.37,3.75-1.25,2.89-3.58,2.33c-14.65-3.5-26.93-11.78-39.32-20.15
			C676.26-35.03,668.26-43.99,659.85-54.05z M472.88,17.07c6.45-1.64,13.67-1.13,18.63-7.67c2.56-3.36,6.71-5.16,11.02-6.1
			C522.33-1.05,542.11-5.46,562-9.41c12.78-2.53,25.44-5.82,38.39-7.27c8.26-0.9,16.59-1.13,24.9-1.48
			c17.41-0.7,34.83-1.24,52.25-1.95c6.35-0.26,12.16,0.55,17.94,4.09c12.09,7.43,25.74,11,38.96,15.6
			c7.15,2.48,14.13,5.36,20.41,9.87c6.61,4.74,11.76,11.03,17.19,17.05c0.7,0.26,1.18,0.72,1.31,1.52
			c9.95,7.91,14.97,19.5,20.74,30.51c1.67,3.15,4.5,6.59,4.21,9.62c-0.33,3.53-5.49,3.13-8.23,5.08c-2.5,1.78-4.77,3.65-6.69,6.08
			c-7.6,9.55-15.3,19.02-22.86,28.58c-1.18,1.49-1.98,3.35-2.73,5.14c-2.05,4.94-2.64,5.1-6.97,1.81
			c-7.09-5.41-14.38-10.57-22.36-14.43c-8.34-4.04-17.18-6.13-26.4-6.38c-3.15-0.03-6.29-0.04-9.42-0.07
			c-7.97,0.55-15.84,1.85-23.7,3.36c-16.53,4.35-32.21,11.35-48.37,16.9c-1.02,0.34-1.97,0.92-2.96,1.39
			c-15.31,6.79-30.59,13.62-44.25,23.74c-2.04,1.51-3.16,0.85-4.65-0.92c-7.81-9.19-15.97-18.05-23.61-27.4
			c-13.69-16.76-28.9-31.67-46.73-43.53c-3.81-2.53-6.53-6-8.79-10c-6.51-11.56-13.08-23.08-19.53-34.68
			C467.64,18.5,468.37,18.21,472.88,17.07z M445.35,14.11c0.98-6.51,1.55-13.03,1.71-19.6c0.03-0.96-0.13-2.5,1.36-2.74
			c1.08-0.19,1.67,0.69,2.15,1.58c3.25,5.97,6.51,11.95,9.72,17.94c1.36,2.57-0.23,3.76-2.23,4.25c-3.2,0.78-6.49,1.24-9.74,1.84
			C445.91,17.69,445.05,16.15,445.35,14.11z M444.03,25.82c0.78-3.59,2.43-5.85,6.23-6c2.36-0.09,4.67-0.63,7.02-0.88
			c3.71-0.36,6.42,0.78,8.44,4.54c6.52,12.14,13.41,24.04,20.09,36.09c2.89,5.23,6.82,9.26,11.56,12.65
			c6.45,4.64,12.73,9.55,19.07,14.35c1.54,1.16,3.23,2.03,0.8,4.35c-10.07,9.65-18.51,20.88-27.22,31.83
			c-9.54,12.01-22.49,19.17-34.35,27.91c-1.11,0.82-2,0.8-2.79-0.39c-6.92-10.41-15.74-19.69-18.1-32.83
			c-0.43-2.41-0.47-4.88-0.7-7.34c-0.2-20.44,4.08-40.34,6.49-60.45C441.54,41.68,442.31,33.68,444.03,25.82z M316.82,52.58
			c18.64-6.78,37.28-13.57,55.52-21.54c9.19-4.01,18.18-8.63,27.59-11.99c6-2.14,12.6-2.51,18.93-3.59
			c1.82-0.32,2.94,0.99,3.71,2.48c2.67,5.23,6.56,7.11,12.11,5.37c4.18-1.31,5.29,0.78,4.55,4.81c-3.75,20.34-4.14,41.08-6.75,61.56
			c-0.76,6-2.07,11.88-2.66,17.95c-1.15,12.12,3.88,21.61,10.5,30.62c2.94,4.02,5.99,7.99,9.18,11.81c2.2,2.64,2.01,4.42-1.06,5.95
			c-1.97,0.96-3.38,2.94-5.59,3.5c-1.12,1.11-2.24,2.21-3.36,3.3c-4.06,4.75-8.11,9.46-9.95,15.83c-1.77,6.06-2.97,12.35-6.1,18
			c-1.65,0.26-1.91-1.42-2.51-2.36c-26.28-41.31-54.22-81.25-88.24-116.13c-0.55-0.57-0.99-1.25-1.49-1.88
			c-2.18-1.9-4.61-3.56-5.56-6.59c-5.89-2.8-8.42-8.5-11.06-14.09C313.36,53.02,315.37,53.11,316.82,52.58z M252.42,95.48
			c-1.03-0.14-1.64-0.88-2.2-1.69c-8.1-11.85-16.19-23.7-24.3-35.55c-1.15-1.65-0.89-2.7,0.79-3.92
			c9.51-6.92,18.91-13.97,28.39-20.95c0.73-0.53,1.87-1.29,2.5-1.05c6.26,2.33,12.47,0.42,18.64-0.14c3.36-0.3,6.02,0.43,8.65,2.73
			c6.26,5.44,12.68,10.71,19.22,15.8c2.43,1.88,2.43,2.67-0.13,4.37c-11.48,7.66-22.82,15.53-34.28,23.24
			c-1.9,1.28-3.71,2.54-4.15,5.01c-1.28,7.14-8.14,7.97-12.27,11.89C253.08,95.39,252.71,95.39,252.42,95.48z M308.72,166.34
			c-1.35-1.57-2.79-3.02-3.96-4.67c-3.81-5.37-7.74-10.66-11.22-16.26c-3.68-5.93-8.55-10.36-14.23-13.93
			c-6.97-4.39-10.54-11.92-15.5-18.18c-0.4-0.49-0.7-1.08-1.05-1.62c-2.38-3.82-6.65-7.63-6.66-11.46
			c-0.01-4.29,6.22-5.41,9.49-8.24c1.21-1.05,2.79-2.04,2.94-3.71c0.42-4.62,3.68-6.45,6.86-8.59c10.43-6.97,20.87-13.9,31.11-21.16
			c2.74-1.94,4.04-2.3,5.44,1.22c3.81,9.59,12.02,15.3,18.67,22.3c10.9,11.5,21.6,23.17,31.41,35.72c1.69,2.18,1.64,3.16-0.47,4.91
			c-16.36,13.47-32.6,27.1-48.89,40.67C311.58,164.27,310.4,165.08,308.72,166.34z M313.42,174.27c-1.49-2.17-2.3-4.27,0.57-6.62
			c16.53-13.51,32.93-27.2,49.28-40.96c2.48-2.08,3.81-2.73,6.08,0.49c9.75,13.83,19.81,27.42,29.73,41.1
			c0.66,0.89,1.09,1.97,1.54,2.76c-0.16,1.41-1.05,1.85-1.78,2.44c-12.12,9.81-23.87,20.11-36.34,29.49
			c-4.94,3.72-9.58,7.93-14.02,12.29c-3.06,3.02-5.14,2.23-7.25-0.8C331.93,201.08,322.63,187.71,313.42,174.27z M179.41,333.26
			c18.73-6.79,36.55-15.58,54.15-25.05c20.94-11.26,38.2-27.89,57.29-41.87c16.55-12.11,32.53-25.06,48.63-37.83
			c3.22-2.54,5.11-1.88,7.17,1.24c7.57,11.49,15.73,22.52,24.85,32.73c1.45,1.61,1.12,2.46-0.49,3.72
			c-6.59,5.2-12.28,11.72-20.81,13.87c-2.69,0.68-2.94,4.39-5.1,6.2c-15.07,12.64-30.22,24.99-47.28,34.73
			c-16.92,9.67-33.49,20.01-49.94,30.52c-5.06,3.23-9.85,6.94-15.15,9.8c-1.25,0.67-2.36,1.41-3.95,0.99
			c-20.05-5.26-40.13-10.44-60.19-15.7c-3.63-0.95-7.05-2.5-11.04-5.03C164.94,337.42,172.35,335.8,179.41,333.26z M159.17,384.99
			c-1.12-4.04-2.36-8.06-2.08-12.34c-0.04-0.42-0.07-0.82-0.12-1.22l-0.01-0.07c-1.12-0.92-0.88-2.15-0.75-3.38
			c-1.18-6.38-3.68-12.58-2.04-19.32c0.43-1.82,0.43-3.15,2.84-2.14c20.51,8.43,42.14,12.6,63.51,17.59
			c9.91,2.33,16.07,8.1,19.29,17.84c0.72-0.01,1.02,0.37,0.95,1.11c10.74,14.32,22.05,28.12,35.86,40.63
			c-8.83-0.23-16.56-2.43-24.3-4.77c-16.5-4.98-31.22-14.15-46.68-21.56c-2.41-1.16-4.81-2.34-7.22-3.52
			c-1.62-0.86-3.23-1.71-4.84-2.57c-0.85-0.34-1.69-0.69-2.54-1.03c-3.62-0.76-7.22-1.51-10.84-2.27
			c-5.99-1.01-12.01-1.28-18.05-0.7C160.33,387.44,159.59,386.52,159.17,384.99z M214.46,440.22c-1.21-6.53-1.19-6.72-6.98-4.5
			c-5.16,1.97-10.43,3.63-15.42,5.93c-2.63,1.21-3.59,0.32-4.74-1.82c-7.61-14.12-16.37-27.53-22.91-42.28
			c-2.43-5.46-2.54-5.41,3.12-5.67c1.25-0.06,2.51-0.01,3.76-0.01c14.39-0.16,26.67,6.58,38.96,13.16
			c13.93,7.47,27.8,15.07,43.03,19.42c11.42,3.26,23.12,4.71,34.83,5.99c3.36,0.37,3.96,1.57,3,4.62
			c-1.71,5.39-1.11,11.06-1.65,16.59c-0.56,5.76,3.15,9.85,6.13,13.96c2.97,4.08,1.36,5.53-2.31,7.04
			c-5.77,2.37-5.73,2.51-3.25,8.55c1.55,3.76,3,7.57,4.83,11.17c1.52,3.02,0.78,3.86-2.27,4.41c-20.72,3.68-41.43,7.5-62.13,11.39
			c-2.46,0.46-3.27-0.3-3.75-2.79C222.69,483.64,218.47,461.95,214.46,440.22z M208.39,619.61c-2.77,0.85-4.09,0.11-4.47-2.94
			c-0.33-2.73-1.51-5.37-0.46-8.57c-0.86-6.51-1.16-13.49-2.76-20.12c-6.75-28.32,2.47-50.55,22.48-69.54
			c4.85-4.61,9.87-6.56,15.9-7.61c18.27-3.2,36.52-6.53,54.73-10c3.25-0.62,4.37,0.37,5,3.63c2.81,14.49,5.08,29.08,6.05,43.82
			c0.96,14.97,2.83,29.72,6.62,44.16c1.13,4.25-0.19,5.44-4.01,5.8c-11.89,1.11-23.76,2.56-35.65,3.68
			c-8.34,0.79-16.26,3.29-24.21,5.86C234.6,611.95,221.44,615.63,208.39,619.61z M225.93,622.57c0.2-4.08,4.37-3.36,6.92-4.18
			c2.31-0.75,2.05,1.87,2.34,3.29c1.67,8.23,1.64,8.23-7.22,12.81C226.5,630.62,225.72,626.53,225.93,622.57z M261.24,718.29
			c-1.84-5.03-4.75-8.5-10.34-8.34c-4.18,0.11-6.03-2.66-7.48-6.15c-8.1-19.59-10.54-40.66-14.61-61.3
			c-0.34-1.74-0.16-3.29,1.69-4.06c0.43-0.19,0.78-0.56,1.21-0.76c7.28-3.55,11.76-8.33,7.54-17.42c-0.75-1.64-0.9-3.58,1.61-4.8
			c12.68-6.13,26.04-9.15,39.88-10.21c0.93-0.07,1.88,0.07,2.82,0.1c24.07-2.31,47.96-6.16,72.03-8.52
			c12.32-1.21,24.27-5.01,36.16-8.56c18.84-5.63,38.03-9.72,57.12-14.15c6.76-1.55,13.8-2.9,20.84-3.35
			c1.15-0.09,2.6-1.03,3.32,0.65c0.53,1.25-0.6,2.02-1.22,2.96c-4.75,7.2-3.99,11.26,2.97,16.2c19.2,13.59,16.16,9.88,18.21,32.43
			c0.85,9.16,0.78,18.35,1.22,27.53c0.09,1.95,0.12,3.95-1.15,5.63c-0.29,0.33-0.56,0.66-0.85,1.01c-3.73,6.65-4.01,6.94-10.69,4.65
			c-4.24-1.45-6.28,0.47-8.32,3.98c-6.41,11.02-8.5,23.76-13.31,35.39c-2.73,6.59-5.92,12.97-8.82,19.48
			c-4.18,9.42-3.88,19.52-3.59,29.51c0.12,3.81-0.96,4.97-4.48,5.56c-10.44,1.78-20.87,2.77-31.44,2.17
			c-6.45-0.36-12.93-0.13-19.27,1.12c-16.89,3.35-33.02,0.26-48.87-5.82c-11.5-4.39-23.57-6.78-35.37-10.05
			c-11.15-2.25-21.36,1.82-31.61,5.26c-3.12,1.05-4.09,0.1-5.08-2.66C268.06,736.57,264.59,727.45,261.24,718.29z M249.11,772.08
			c6.19-4.38,13.01-7.87,18.12-13.83c1.45-1.68,3.42-2.77,5.49-3.65c14.32-6.12,28.55-9.84,44.26-4.68
			c10.28,3.38,20.81,6.1,31.08,9.65c10.37,3.56,20.98,5.73,31.93,5.26c3.65-0.17,5.1,0.72,5.59,4.85
			c3.5,29.82,7.28,59.59,10.99,89.38c0.43,3.42,0.6,6.92,1.52,10.2c1.12,4.02-0.66,4.77-3.85,5.14c-9.35,1.11-18.7,2.41-28.05,3.6
			c-19.49,2.5-38.98,4.9-58.45,7.5c-4.6,0.6-9.11-0.32-14.95-0.1c-8.9-0.53-19.1-1.26-29.31-1.69c-2.7-0.13-3.82-0.79-3.94-3.85
			c-0.27-7.54-1.44-15.08-1.32-22.61c0.19-13.46-1.42-26.5-6.2-39.01c-1.09-2.84-0.49-4.22,2.07-5.16c3.91-1.42,4.06-3.92,2.69-7.71
			c-3.36-9.16-6.16-18.56-9.42-27.78C246.39,774.93,246.79,773.71,249.11,772.08z M211.13,868.32c-2.13-3.19-4.42-3.52-7.61-1.61
			c-8.01,4.8-16.21,9.23-24.17,14.13c-2.97,1.82-5.6,0.8-8.52,0.53c-5.33-0.52-10.56-1.39-15.71-2.9
			c-12.14-3.56-17.03-14.76-23.27-24.44c-2.34-3.63-3.89-7.84-7.32-11.79c20.51-10.66,38.5-24.73,59.93-32.67
			c12.52-4.64,24.07-12.51,35.59-19.81c6.43-4.08,13.16-7.65,19.62-11.66c2.08-1.28,3.04-1.51,3.94,1.26
			c2.77,8.69,5.72,17.34,8.75,25.94c0.88,2.51,0.52,3.96-1.94,4.93c-3.06,1.21-4.54,3.13-3.13,6.85
			c4.75,12.65,6.86,25.81,6.85,39.42c-0.01,7.87,0.7,15.76,1.12,23.63c0.09,1.65,0.53,3.32-1.97,3.43
			c-10.2,0.46-20.35,1.87-30.59,1.52c-2.08-0.07-2.92-0.95-3.69-2.83C216.93,877.29,214.11,872.75,211.13,868.32z M177.27,920.12
			c13.54-7.93,27.07-15.87,40.44-24.09c3.38-2.07,4.88-1.98,6.26,2.11c4.58,13.64,11.37,25.59,22.32,35.1
			c6.81,5.9,12.29,13.5,17.74,20.93c2.44,3.35,3.53,7.31,4.9,11.13c1.98,5.53,4.91,10.53,7.78,16.11
			c-6.51,4.09-12.97,8.11-19.4,12.19c-4.75,3.02-8.19,3.03-12.27-1.08c-21.92-22.1-46.27-41.25-68.95-62.43
			C169.88,924.29,169.88,924.44,177.27,920.12z M141.03,1043.38c-2.96-13.41-7.48-26.33-12.6-39.01c-1.01-2.53-2.61-4.65-5.13-5.41
			c-13.46-4.08-17.82-15.83-21.7-27.95c-1.19-3.71-3.56-8.1-2.56-11.17c1.03-3.22,6.36-3.02,9.69-4.61
			c9.95-4.78,20.71-6.36,31.32-8.56c8.75-1.82,15.77-6.75,22.03-13.17c1.9-1.95,2.87-5.39,6.53-4.88c1.55-0.37,2.5,0.7,3.58,1.71
			c21.57,20.28,44.49,38.94,65.98,59.33c10.3,9.78,10.41,9.64-1.03,16.82c-14.65,9.19-29.34,18.31-43.92,27.62
			c-12.62,8.04-26.93,11.49-40.62,16.6C143.52,1054.09,143.23,1053.34,141.03,1043.38z M327.88,1096.43
			c-1.81,2.21-16.85,25.54-22.62,33.88c-3.95,5.67-8.32,11.04-12.65,16.42c-3.78,4.67-9.06,6.28-14.61,6.95
			c-6.97,0.83-13.72-0.8-20.49-2.23c-5.47-1.15-10.74-0.19-16.21,1.19c-11.3,2.87-22.89,5.26-33.85-1.57
			c-7.73-4.81-14.74-5.76-20.35,3.6c-2.51-2.76-2.66-5.86-3.16-8.79c-1.52-9.01-3.78-17.38-8.59-25.55
			c-8.14-13.89-14.39-28.98-21.39-43.6c-2.51-5.27-4.83-10.64-7.31-15.93c-1.08-2.3-0.72-3.26,1.84-4.09
			c8.09-2.6,15.97-5.86,24.07-8.4c17.85-5.62,32.66-17.18,48.52-26.7c0.93-0.56,1.69-1.46,2.9-1.39
			c8.79-5.86,17.36-12.09,26.89-16.66c1.44-2.89,3.13-2.96,4.84-0.33c1.67,2.56,3.22,5.21,4.74,7.88
			c5.93,10.44,12.95,19.73,21.77,27.95c11.55,10.74,21.8,22.99,32.75,34.47c5.62,5.9,8.55,13.62,13.06,20.25
			C328.64,1094.74,328.6,1095.57,327.88,1096.43z M331.57,1092.81c-1.18,0.04-1.48-0.9-1.92-1.72
			c-13.69-24.7-34.64-42.51-53.87-61.96c-4.24-4.29-6.79-9.78-10.04-14.78c-2.3-3.52-4.09-7.43-6.65-10.7
			c-2.81-3.63-1.71-5.4,1.72-7.32c4.42-2.46,8.7-5.23,12.93-8.07c2.4-1.62,3.45-1.11,5.06,1.41c13.21,20.71,24.93,42.45,39.54,62.19
			c6.99,9.45,14.25,18.7,21.4,28.02c0.69,0.89,1.58,1.61,1.75,2.84C341.45,1084.17,333,1092.75,331.57,1092.81z M357.28,1132.59
			c-1.84,0.99-2.79,0.45-3.76-1.21c-6.22-10.6-12.47-21.2-18.77-31.75c-0.9-1.51-0.52-2.51,0.23-3.96
			c2.11-4.18,5.66-6.99,8.67-10.27c2.17-2.37,3.69,0.67,4.67,2.14c3.55,5.39,6.82,10.97,10.15,16.52
			c4.27,7.05,8.47,14.12,12.81,21.36C366.47,1127.85,361.82,1130.12,357.28,1132.59z M461.74,1052.97
			c-11.03,8.89-21.9,18.01-32.76,27.14c-3.5,2.93-6.1,6.46-6.25,11.55c-0.13,3.89-2.36,6.71-5.34,8.73
			c-10.37,7.05-20.65,14.25-31.71,20.15c-3,1.59-6.29,1.98-9.32,3.3c-2.41,1.06-3.3-0.79-4.32-2.48
			c-6.41-10.67-12.84-21.34-19.27-32.01c-0.66-1.11-1.11-2.54-2.05-3.25c-5.66-4.28-2.25-6.91,1.36-9.77
			c14.16-11.19,26.96-24.04,39.65-36.9c15.17-15.34,29.46-31.64,44.12-47.54c0.86-0.93,1.58-2.03,2.3-2.96
			c11.94,18.01,17.08,38.53,25.75,57.36C465.44,1049.7,464.15,1051.02,461.74,1052.97z M582.38,969.53
			c-6.06,4.87-31.61,23.27-37.13,27.17c-10.31,7.31-20.61,14.61-30.95,21.86c-1.81,1.28-3.75,2.31-5.63,3.46
			c-2.33,1.42-4.67,2.84-6.99,4.27c-9.95,6.49-19.92,12.98-29.86,19.5c-1.52,1.01-2.76,2.17-3.96-0.53
			c-8.03-17.88-13.2-37.1-23.24-54.03c-0.16-0.27-0.19-0.63-0.27-0.95c-2.73-3.89-2.47-7.38,1.26-10.41
			c15.51-17.82,32.34-34.25,49.33-50.51c5.41-5.18,11.39-9.79,15.93-15.91c1.77-2.4,3.23-1.19,4.78-0.19
			c12.12,7.83,24.2,15.73,36.34,23.51c11.42,7.34,21.23,16.66,30.63,26.51C584.92,965.68,585.01,967.42,582.38,969.53z
			 M612.86,942.51c-7.64,5.73-14.91,11.95-21.69,18.77c-2.05,2.05-3.53,2.25-5.08-0.53c-1.61-2.9-6.2-4.55-5.7-7.65
			c0.42-2.61,4.09-4.67,6.36-6.94c6.89-6.87,13.76-13.79,21.84-19.49c1.78,3.99,3.29,7.76,5.13,11.35
			C614.72,939.98,614.67,941.15,612.86,942.51z M628.54,930.05c-2.96,2.34-6.02,4.54-9.09,6.74c-1.42,1.02-1.98-0.36-2.41-1.29
			c-0.8-1.74-1.41-3.58-2.2-5.64c4.28-0.93,8.22-1.75,12.14-2.64c0.78-0.17,1.59-0.52,2.13,0.26
			C629.75,928.42,629.2,929.53,628.54,930.05z M631.2,922.38c-4.8,0.78-9.58,1.65-14.07,2.44c-1.87-0.2-3.88,0.22-4.77-1.16
			c-2.5-3.92-4.75-1.61-7.02,0.03c-9.72,7.02-17.85,15.97-26.48,24.32c-1.19,1.13-1.95,3.45-4.45,1.09
			c-17.08-16.2-37.69-26.94-56.96-39.73c-2.25-1.48-3.36-2.8-2.69-5.36c2.94-11.13,0.82-21.37-3.36-31.93
			c-4.04-10.18-0.98-19.37,7.34-26.7c6.94-6.1,13.43-12.78,20.02-19.3c1.92-1.9,3.27-2.04,5.57-0.3
			c10.02,7.6,20.28,14.85,30.38,22.33c2.44,1.8,4.84,2.59,7.9,2.11c10.87-1.67,21.73-3.45,32.46-5.9c3.63-0.83,5.53-0.6,6.25,4.05
			c3.62,23.45,7.6,46.86,11.48,70.29C633.03,920.29,633.65,921.96,631.2,922.38z M635.33,928.52c-0.62,0.23-1.34,0.11-1.59-0.56
			c-0.23-0.6-0.09-1.44,0.66-1.55c3.26-0.47,6.45-2.27,10.61-0.7C641.62,928.18,638.26,927.43,635.33,928.52z M670,968.26
			c-4.41,1.24-8.2,3.22-10.87,7.08c-1.85,2.67-3.29,2.11-4.96-0.07c-2.61-3.43-6.66-5.64-8.19-9.98c-1.85-5.26-4.22-26.64-5.52-31.4
			c-0.75-2.79,2.18-2.53,3.5-2.73c3.89-0.6,5.56-2.46,4.73-6.61c-0.26-1.25-0.33-2.64,1.02-3.49c10.51-6.56,17.51-17.12,26.45-25.45
			c0.34-0.33,0.78-0.55,1.18-0.82c2.08-2.33,4.32-4.54,6.25-7.01c2.64-3.38,5.06-3.86,7.53,0.09c7.08,7.45,14.12,14.92,21.24,22.33
			c1.87,1.94,2.66,3.4,0.65,6.06c-9.91,13.14-17.94,27.65-27.03,41.39C681.71,964.16,676.29,966.48,670,968.26z M721.1,955.32
			c-6.12-2.99-11.62,0.6-17.32,1.77c-4.57,0.93-9.08,2.28-14.78,3.75c4.11-6.32,7.55-11.62,10.99-16.92
			c5.33-8.24,10.73-16.46,15.96-24.77c1.59-2.53,3.13-3.09,5.74-1.54c5.82,3.46,12.21,5.82,17.85,9.68c1.39,0.96,1.61,1.15,0.78,3.1
			c-3.92,9.13-11.58,15.34-16.46,23.65C723.29,955.02,722.37,955.94,721.1,955.32z M803.96,1015.54
			c-6.19,1.55-10.61,0.16-15.51-3.99c-13.24-11.23-27.4-21.26-39.78-33.61c-1.32-1.32-2.84-2.17-2.33-4.78
			c0.33-1.69-1.08-3.33-3.26-3.33c-6.99,0-9.87-6.68-14.76-10.1c-1.21-0.85,0.12-2.07,0.76-2.9c5.62-7.3,9.69-15.63,14.55-23.44
			c1.82-2.92,3.29-3.27,6.29-1.62c11.59,6.39,21.8,14.85,31.86,23.4c16.17,13.76,33.08,26.46,49.46,39.88
			c4.41,3.62,8.92,7.09,14.3,11.37C830.9,1009.58,817.34,1012.2,803.96,1015.54z M876.72,997.25c-8.24,2.57-16.57,4.88-24.86,7.32
			c-1.41,0.4-2.33-0.3-3.36-1.13c-11.89-9.58-23.81-19.12-35.72-28.67c-2.13-1.87-4.24-3.73-6.35-5.6
			c-6.71-4.04-12.55-9.31-18.56-14.32c-2.18-1.82-4.22-3.86-6.32-5.8c-2.59-1.98-5.17-3.98-7.76-5.97
			c-7.34-5.59-14.49-11.5-22.84-15.47c-2.05-0.98-2.97-2.13-1.87-4.5c2.97-6.49,6.05-12.94,8.72-19.58
			c1.02-2.53,2.01-1.98,3.82-1.29c11.19,4.18,19.2,13.2,28.51,20.26c1.58-0.09,2.47,0.86,3.07,2.25c0.33,0.3,0.66,0.62,0.99,0.92
			c1.11-0.06,1.72,0.59,2.1,1.58c9.28,5.54,16.85,13.37,25.02,20.39c2.94,0.4,4.95,2.28,6.68,4.61
			c8.11,4.97,15.05,11.62,22.63,17.36c7.97,6.06,15.77,12.37,23.58,18.63c1.36,1.09,2.57,2.41,3.71,3.73
			C879.89,994.29,879.49,996.38,876.72,997.25z M816.74,891.27c5.85,12.45,17.48,18.47,27.23,26.4
			c20.28,16.49,41.05,32.34,60.91,49.38c4.7,4.02,8.95,8.65,13.95,12.31c1.35,0.99,2.03,1.75,0.76,3.45
			c-2.33,3.07-3.1,7.71-7.34,8.32c-1.01-0.16-1.48-0.16-1.92-0.32c-6.48-2.11-12.7-1.28-18.7,1.78c-2.38,1.21-4.34,0.72-6.42-0.98
			c-21.34-17.44-43.29-34.04-64.44-51.76c-10.61-8.89-21.95-16.85-33.19-24.88c-3.23-2.31-3.16-3.86-1.05-6.82
			c6.59-9.23,15.04-15.48,25.71-18.41C814.13,889.21,815.86,889.41,816.74,891.27z M810.36,883.38c0.56,1.49,0.19,2.11-0.9,2.7
			c-1.12,0.59-2.21,1.41-3.4,1.65c-10.93,2.2-17.09,10.8-23.55,19c-0.79,1.01-1.31,2.9-3.17,1.08c-4.54-4.45-10.33-6.69-15.84-9.36
			c-2.28-1.09-2.94-2-1.77-4.71c5.67-12.94,11.53-25.82,16.16-39.22c1.15-3.3,3.15-3.66,5.43-2.99c5.6,0.65,10.74,1.32,15.9,1.81
			c2.08,0.2,2.92,0.7,2.61,3.25c-0.4,3.32,1.42,6.3,2.46,9.38C806.21,871.79,808.2,877.64,810.36,883.38z M805.95,851.43
			c-7.31-1.02-14.62-2-21.96-2.71c-3.33-0.32-3.45-1.55-2.13-4.31c2.11-4.38,4.18-8.82,5.82-13.41c1.15-3.25,2.51-4.35,5.76-2.93
			c2.43,1.06,5.17,1.31,7.65,2.27c3.36,1.31,5.96,3.52,6.75,7.5c0.63,3.19,1.44,6.35,1.91,8.39
			C809.77,850.06,809.14,851.87,805.95,851.43z M872.52,834.92c-12.39,2.84-24.75,5.87-37.11,8.85c-5.8,1.41-11.66,2.61-17.36,4.37
			c-3.36,1.03-4.65-0.5-5.6-3.29c-0.36-1.08-0.88-2.15-0.95-3.27c-0.67-9.98-6.78-13.86-15.3-15.63c-6.18-1.28-6.12-1.61-3.56-7.55
			c0.2-0.45,0.55-0.85,0.63-1.31c2.51-14.46,13.53-20.74,24.13-27.42c0.66-0.42,1.35-0.8,2.18-1.31c0.53,0.4,1.12,0.66,1.46,1.13
			c5.53,7.5,14.03,10.24,21.56,14.41c7.76,4.29,15.45,8.62,22.76,13.74c4.34,3.04,7.37,6.98,9.44,11.92
			C875.97,832.37,875.97,834.13,872.52,834.92z M936.38,804.22l-0.32,3.43c-0.24,2.64-1.62,5.11-3.91,6.45
			c-0.42,0.24-0.86,0.46-1.35,0.62l-50.08,17.35c-0.52-0.17-0.93-0.56-1.16-1.41c-4.18-14.71-16.59-20.04-27.88-26.57
			c-6.43-3.73-13.08-7.08-19.6-10.64c-2.64-1.45-4.95-3.33-7.21-6.38c3.78-0.75,7.05-1.46,10.36-2.04c3.73-0.66,7.41-0.65,11.03-0.1
			l91.14,6.1C936.92,796.45,936.56,801.04,936.38,804.22z M937.83,786.48l-89.95-6.16c0,0-6.66-1.94-7.78-3.85
			c-2.11-3.6-4.48-7.05-6.49-10.73c-3.16-5.79-1.85-13.67,2.9-16.76c25.36-16.46,49.65-34.6,74.57-51.73
			c5.87-4.04,11.93-7.76,17.79-11.82c1.87-1.31,3.13-1.49,4.93,0.11c2.73,2.43,5.79,4.45,8.57,6.81c3.66,3.1,3.02,8.04,4.38,12.12
			c0.72,2.14,0.85,4.5,1.38,6.71c1.42,6,2.01,11.83-1.22,17.56C943.5,734.78,939.85,764.95,937.83,786.48z M942.17,813.97
			c-1.72-0.29-2.28-1.13-2.15-3.02c0.42-6.41,4.06-44.88,6.64-63.98c0.79-5.85,2.37-11.53,4.68-16.95l0.03-0.1
			c1.12-2.59,2.04-5.26,2.56-8.03c0.2-1.02,0.27-2.05,0.06-3.15c-1.41-6.85-2.97-13.69-4.19-20.6c-0.75-4.24-2.46-7.22-7.34-11.56
			c-6.88-6.1-8.01-5.08-0.5-10.33c7.32-6.13,16.34-9.55,23.27-16.57c4.31,6.89,4.57,14.65,6.88,21.7
			c6.55,20.05-1.08,40.19-1.38,61.25c-0.1,7.35-1.88,64.41-2.33,71.77c-0.13,2.23-0.96,2.93-2.9,2.48
			C955.79,814.72,951.92,815.52,942.17,813.97z M976.36,826.94c-0.3,3.42-0.09,7.02-3.88,6.02c-3.88-0.39-7.47,0.42-6.97-4.24
			c0.29-2.64-0.89-7.25,4.06-6.3C972.11,822.9,976.89,820.82,976.36,826.94z M985.17,818.61c-3.83-0.33-7.93-0.56-11.37-0.7
			c-0.26-0.86,0.06-2.03,0.12-2.9c0.67-12.01,3.45-72.96,2.77-85.76c-1.09-20.45,5.49-39.7-2.81-58.45
			c-4.8-10.86-3.1-18.83,5.3-26.43c23.63-21.36,47.75-41.99,75.33-57.76c2.34-1.35,6.41-3,8.43-1.41c0.13,0.29,0.27,0.6,0.4,0.89
			c-9.62,4.83-17.97,14.05-23.94,18.96c-10.05,8.26-24.63,27.75-34.79,47.93c-6.85,13.62-10.04,27.49-14.74,38.63
			c-4.85,11.58-4.5,23.86-4.7,34.23c-0.29,16.1,0.9,28.02,0.93,28.88L985.17,818.61z M1138.77,755.11
			c-0.92,3.17-5.86,8.56-5.86,8.56l0.01,0.03c-2.53,2.37-4.88,5.18-6.72,8.11c-10.21,16.03-19.48,32.52-27.81,49.55
			c-3.32,6.99-7.07,13.6-9.46,21.05c-0.06,0.19-0.09,0.37-0.13,0.56l-13.97-3.4c-0.11-0.03-72.99-18.43-82.25-20.05
			c-1.03-0.19-2.18-0.34-3.4-0.49l1.24-64.49c-0.01-0.22-1.32-12.41-0.96-28.72c0.23-9.98-0.17-21.69,4.39-32.56
			c5.3-12.58,8.7-26.51,15.4-39.57c10.4-20.29,24.44-38.4,32.87-45.33c5.73-4.71,13.7-13.72,22.95-18.37l0.1,0.23
			c18.37,41.58,54.62,107.36,73.55,148.63c0,0.01,0.01,0.01,0.01,0.03C1140.64,743.06,1140.31,749.87,1138.77,755.11z
			 M1152.74,757.07c-0.52,1.81-2.01,3.27-3.85,3.72c-4.18,1.03-7.81-2.76-6.62-6.89c0.52-1.81,2.03-3.26,3.86-3.71
			C1150.32,749.18,1153.92,752.93,1152.74,757.07z M1283.65,787.82c-4.48,1.31-19.92,5.85-27.13,8.73c-5.04,2-14.66,7.3-24.88,12.9
			c-7.02,3.86-14.29,7.86-20.72,11.16c-3.49,1.8-6.33,3.71-8.72,5.6c-9.02-15.57-18.11-31.08-27.33-46.5
			c-1.8-2.87-3.92-6.74-5.99-9.44c-1.28-1.61-2.92-3.12-4.83-4.19c-1.91-1.05-5.16-2.2-5.16-2.2c-0.95-0.5-1.67-1.38-2.07-2.37
			c-2.4-5.87,0.29-11.06,4.91-14.51c6.85-4.7,13.84-9.15,20.77-13.54c17.06-10.71,33.91-21.84,51.32-31.98
			c8.79-3.6,18.2-6.25,27.76-5.47c7.47,0.3,14.99,0.82,22.38,1.67c1.44,0.27,2.8,0.32,4.11,0.53L1283.65,787.82z M1288.29,693.5
			c-1.29-0.2-2.57-0.3-3.76-0.5c-7.61-0.86-15.14-1.39-22.75-1.71c-10.28-0.8-20.49,2-29.93,5.9
			c-20.8,12.11-41.03,25.56-61.41,38.36c-3.94,2.46-7.78,5.03-11.6,7.67c-0.13,0.09-0.24,0.16-0.37,0.24
			c-7.02,4.68-10.84,0.76-13.17-3.49c-0.75-1.58-1.24-2.61-1.24-2.61c-15.3-33.62-54.78-106.57-74.18-149.57l-0.04-0.11
			c14.48-6.88,51.45-18.15,62.92-20.8c19.4-4.45,71.45-6.46,98.6-4.32c25.64,2.03,41.28,8.89,60.58,12.81l2.41,0.47L1288.29,693.5z
			 M1372.96,742.88c-10.63-3.63-20.22-9.35-30.03-14.82c-8.11-4.61-16.32-9.51-24.36-14.29c-9.34-4.88-16.77-12.84-25.55-18.79
			c-0.29-0.16-0.56-0.3-0.86-0.45l6.56-117.12c11.09,1.82,26.01,7.93,36.77,7.54c14.19-1.06,23.5,1.34,37.5,2.17
			c11.35,0.37,23.41,1.46,34.74,2.18c4.75,0.37,11.73,1.03,16.26,1.75c14.65,2.79,28.72,7.08,42.99,11.59
			c-4.73,13.16-27.83,77.56-31.47,88.61c-3.66,11.15-6.64,27.82-8.22,36.77l-0.49,2.74c-0.43,2.4-1.26,6.58-2.23,11.42
			c-1.25,6.35-2.37,11.99-3.17,16.3C1405.07,753.91,1388.96,748.44,1372.96,742.88z M1489.87,786.75c-0.37,1.74-1.01,3.43-1.88,4.98
			c-4.64,8.19-9.41,17.88-11.49,22.79c-1.06,2.51-2.28,5.79-3.68,9.58c-2.5,6.72-5.59,15.08-9.12,22.42
			c-2.38,4.94-5.77,10.71-8.8,15.64l-9.98-5.41c-0.17-0.1-17.18-10.07-22.19-22.02c-1.44-3.43-2.64-6.05-3.6-8.17
			c-2.54-5.53-3.32-7.21-2.61-10.76c0.39-2.03,1.11-3.81,1.85-5.69c1.19-2.96,2.54-6.32,2.8-11.17c0.16-3.27,0.5-9.03,0.86-14.79
			c0.46-7.97,0.92-15.5,0.95-17.31c0.01-0.46,0.16-1.52,0.4-2.99c8.07,1.92,16.2,3.73,24.36,5.3c12.19,2.21,24.6,4.61,37.13,3.82
			c3.33-0.4,6.52-0.47,9.74-0.65c-0.69,2.07-2.05,4.15-2.7,6.12C1490.81,781.74,1490.67,782.98,1489.87,786.75z M1494.55,781.3
			c-0.01,0.03-0.04,0.07-0.07,0.1c0.86-2.89,3.25-7.88,4.02-9.35c0.01,0,0.01,0,0.01,0c1.42-0.16,2.87-0.36,4.34-0.65
			C1499.79,774.82,1496.69,778.42,1494.55,781.3z M1539.74,700.98c-4.06,21.84-13.7,42.74-28.12,59.66c-2.37,2.7-4.91,4.91-8.01,5.8
			c-5.76,1.55-13.01,1.15-19.19,1.9c-11.98,0.7-23.97-1.64-35.82-3.78c-8.16-1.57-16.32-3.39-24.42-5.31
			c0.9-4.9,2.15-11.25,3.22-16.52c0.95-4.84,1.8-9.03,2.23-11.45l0.49-2.74c1.58-8.89,4.51-25.44,8.13-36.39
			c3.62-11.03,26.81-75.67,31.47-88.63c0.95,0.3,1.88,0.59,2.83,0.92c21.1,7.44,42.22,18.08,63.22,25.92
			c6.16,2.27,12.34,4.44,18.53,6.55C1550.66,652.81,1542.95,687.28,1539.74,700.98z M1567.42,599.24l-3.27-0.89
			c-3.59,13.7-5.14,19.98-8.65,33.69c-6.05-2.05-12.06-4.15-18.02-6.33c-15.41-5.66-32.03-13.57-47.28-19.65
			c-15.78-6.76-32.53-11.52-49.16-15.68c-8.27-2.05-16.76-4.45-25.32-4.9l-9.49-0.67c-11.4-0.7-21.95-1.88-33.39-2.24
			c-11.46-0.57-25.74-3.19-37.1-2.28c-8.72,0.72-23.12-4.58-36.77-7.25l2.79-51.78c0.09,0.69,0.11,1.39,0.09,2.1
			c0.06,0.13,0.11,0.24,0.17,0.37c1.64,3.68,4.01,6.95,6.82,9.82c1.92,1.94,3.52,4.08,3.5,6.23l0.68,10.76h4.19l-0.55-11.5
			c-0.53-4.39-5.5-8.86-8.39-12.44c-0.89-1.11-1.28-2.54-0.42-3.6c0.83-1.03,1.98,0.19,2.9,0.49c17.06,5.62,34.44,9.94,51.76,14.52
			c4.68,1.25,9.36,2.56,15.22,4.15c-7.11,4.45-26.54,12.25-33.12,14.82c-0.09,0.03-0.17,0.06-0.26,0.06l6.69,2.3
			c4.71-2.1,22.02-9.79,26.83-11.46c1.78-0.62,3.58-1.21,5.34-1.85c6.33-2.31,7.9-2.27,10.97,3.39c3.13,5.77,6.09,11.65,9.09,17.75
			l5.67,1.95c-2.28-4.65-4.61-9.26-7.05-13.83c-5.41-10.13-10.37-20.45-14.53-31.21c-1.25-3.22-1.9-6.06-0.89-9.8
			c3.07-11.5,5.46-23.21,8.03-34.84c1.57-7.09,1.65-7.14,8.23-5.59c11.81,2.79,23.34,6.25,34.35,11.66
			c6.41,3.15,13.64,3.62,20.54,5.14c3.37,0.75,6.76,1.49,10.8,2.37c-6.08,9.22-10.6,18.64-17.82,26.07
			c-3.23,3.32-4.22,7.76-5.04,11.98c-3.09,15.97-15.68,42.68-15.68,42.68l3.92,1.65c0.11-0.37,0.19-0.63,0.24-0.79
			c0.01-0.04,0.03-0.09,0.04-0.13c1.34-3.6,1.94-2.46,5.9-0.92c3.94,1.52,8.17,2.2,12.31,3.13c8.06,1.8,15.68,0.63,23.42-2.56
			c15.2-6.28,28.75-15.01,41-26.3c4.04-3.71,9.11-6.28,13.92-8.96c7.71-4.34,15.64-8.27,23.4-12.51c2.02-1.11,2.96-0.66,3.99,1.32
			c3.62,6.86,4.97,14.43,5.97,21.99c1.11,8.44,1.38,17,2.23,25.49c0.37,3.79-0.82,5.47-4.54,5.49c-4.55,0.01-9.11,0.37-13.66,0.53
			c-4.94,0.17-5.76,1.55-4.94,6.42c0.62,3.76,0.66,4.14,1.26,7.9l3.17,1.15c0,0-1.03-5.36-1.61-7.94c-1.03-4.64-0.16-5.07,4.61-5.13
			c4.24-0.04,8.49-0.16,12.74-0.04c4.8,0.12,4.34,1.71,5.21,6.13C1569.26,593.54,1568.68,596.57,1567.42,599.24z M1568.86,574.31
			c-0.6-8-1.41-15.99-2.26-25.23c-0.2-0.86,0.46-2.36,2.56-2.79c7.41-1.51,13.56-5.92,20.02-9.52c2.73-1.52,3.89-1.15,5.24,1.75
			c4.01,8.67,8.27,17.22,12.54,25.75c1.18,2.34,0.83,3.56-1.67,4.22c-10.77,2.86-21.53,5.8-32.3,8.69
			C1570.67,577.8,1569.09,577.38,1568.86,574.31z M1613.78,588.99c-7.4,2.25-30.48,9.21-39.14,10.77c-1.32,0.24-2.4,0.36-3.53-0.36
			c0.16-5.54,0.33-11.07,0.49-16.62c0.07-2.36,1.71-2.8,3.48-3.26c10.33-2.73,20.67-5.46,30.98-8.32c1.72-0.47,3.03-0.59,3.98,1.25
			C1612.75,577.63,1616.42,582.6,1613.78,588.99z M1616.96,582.63c-0.67-6.18-3.6-11.72-6.29-17.22
			c-4.57-9.32-9.15-18.63-13.83-27.91c-1.36-2.73-1.34-4.38,1.54-6.3c6.72-4.5,13.3-9.22,19.09-15.07c2.73-2.74,6.49-1.74,9.79-1.49
			c4.68,0.36,9.38,0.93,13.99,1.91c7.15,1.51,14.32-0.07,21.47,0.47c7.5,0.59,15.07-0.4,22.51,1.34c1.87,0.43,3.17,0.8,3.48,3.13
			c2.54,18.99,5.1,37.99,7.84,56.95c0.55,3.79-0.17,4.78-3.98,4.5c-7.5-0.56-14.97-2.15-21.16-1.41
			c-17.46,0.01-33.26,2.94-49.09,5.29C1618.75,587.34,1617.42,586.72,1616.96,582.63z M1699.97,614.21c-4.7,0.82-9.38,0.92-14.1,0.6
			c-3.39,0.06-10.18,0.17-10.18,0.17l-9.97,0.19l-10.53,0.04c0,0-4.37,0.24-6.52,0c-6.55-0.72-12.9-0.19-18.81,3.02
			c-2.54,1.36-3.83,1.16-4.65-2.07c-1.8-7.14-3.98-14.15-5.9-21.26c-0.43-1.55-1.36-3.3,1.72-3.68c17.62-2.17,35.12-5.79,52.97-5.33
			c7.37,0.19,14.72,0.99,22.09,1.49c1.18,0.09,2,0.26,2.25,1.81c1.22,7.27,2.54,14.52,3.92,21.76
			C1702.64,613.07,1701.97,613.86,1699.97,614.21z M1832.37,606.88c-18.6-0.1-37.1,1.95-55.67,2.64c-5.29,0.2-10.54,0.63-15.84,0.06
			c-12.32-1.34-36.98-0.04-36.98-0.04s-4.16,2.04-6.56,1.35c-10.04,3.13-10.05,3.13-12.09-7.22c-0.53-2.71-0.89-5.47-1.39-8.2
			c-0.46-2.56-1.01-5.1-1.68-8.49h13.47c3.23-0.98,6.51-0.55,9.77-0.3c14.58-1.95,29.16-0.6,43.75-0.14
			c2.11,0.07,4.39,0.76,6.28,1.78c7.41,4.02,15.09,4.11,22.88,1.97c13.7-3.78,27.73-5.4,41.69-7.58c1.68-0.26,2.71,0.09,3.15,1.91
			c0.93,3.94,2.31,10.5,3.35,14.97C1841.67,601.78,1836.94,604.2,1832.37,606.88z M1904.8,581.63c-0.03,0.16-0.03,0.33-0.03,0.49
			c-12.81,1.52-25.39,4.94-37.53,9.16c-5.73,2.04-11.43,4.22-17.02,6.64c-1.01-4.44-2.15-10.84-3.12-14.63
			c-0.75-2.94,1.64-2.3,2.84-2.56c9.59-2.08,19.36-1.87,29.07-2.14c6.76-0.19,13.46-1.03,20.16-1.67
			C1902.55,576.61,1905.71,576.35,1904.8,581.63z M1903.48,572.57c-16.32,0.9-32.77,0.8-48.87,3.22
			c-19.7,2.93-39.58,5.24-58.9,10.67c-5.99,1.68-11.83,1.25-17.44-1.57c-3.89-1.97-7.96-2.77-12.27-3.03
			c-20.14-1.19-40.24,0.32-60.35,1.01c-3.16,0.12-4.39-0.65-4.85-4.01c-2.53-18.84-5.21-37.67-8-56.47
			c-0.6-4.01,1.87-4.02,4.15-3.94c7.07,0.23,14.23,0.04,21.16,1.31c6.66,1.21,13.49,0.53,20.01,2.46
			c12.74,3.76,25.92,4.16,38.92,5.89c15.99,2.1,31.87,4.83,47.21,10.11c7.67,2.64,15.42,4.84,23.41,6.06
			c2.2,0.11,4.19,0.92,6.16,1.84c10.97,2.48,22.06,0.65,33.1,0.89c8.57,0.2,16.7,1.72,24.47,5.53c1.69,0.83,1.88,1.67,1.22,3.39
			c-1.81,4.71-3.52,9.46-5.13,14.26C1906.77,572.37,1905.36,572.47,1903.48,572.57z M1932.15,577.83c-1.01,1.41-2.13,2.59-3.3,3.6
			c-4.58-0.3-9.18-0.26-13.8-0.1c-2.05,0.07-4.11,0.19-6.16,0.37c-0.49-4.61,1.29-8.27,2.86-12.35c0.95-2.41,2.18-6.53,2.87-8.92
			c1.81-6.26,5.24-6.19,10.51-5.63c2.48,0.26,5,0.34,7.5,0.57c5.9,0.55,6.33,0.75,5.44,6.68c-0.47,3.07-0.42,4.31-1.31,7.6
			C1935.97,572.56,1934.78,574.32,1932.15,577.83z M2034.85,620.46c-8.13-4.16-25.05-8.73-27.17-18.15c0,0-1.18-3.06-2.11-5.64
			c-1.41-3.85-3.3-7.48-5.69-10.81c-1.57-2.18-3.22-4.31-5.27-6.1c-8.85-7.78-19.16-10.83-30.55-10.73
			c-8.59,0.07-15.54,4.35-22.33,9.21c-1.81,1.31-3.92,4.05-7.25,3.49c6.59-5.7,8.2-13.95,9.21-21.6c0.45-3.42,1.22-4.52,4.68-3.65
			c3.3,0.82,6.81,0.93,10.23,0.95c10.05,0.03,19.83,2.76,29.96,2.63c6.59-0.07,13.41-0.04,19.99,1.61
			c4.31,0.39,8.85-0.95,12.91,1.44c1.52,0.24,3.55,0.56,5.07,0.79c1.24-0.33,2.46-0.65,3.71-0.98c10.43-4.32,17.34,2.27,23.96,8.85
			c1.91,1.9,3.71,3.5,5.44,4.94c0.85,0.7,1.36,1.72,1.38,2.83c0.09,4.75,0,9.87-0.1,15.2c-0.22,12.55-0.46,26.33,1.72,38.25
			C2053.49,628.84,2034.85,620.46,2034.85,620.46z M2061.09,562.19c-0.33,3.5-2.21,4.15-5.18,2.57c-2.77-1.48-5.63-2.84-8.57-3.83
			c-5.21-1.75-10.44-3.12-16.06-1.34c-2.44,0.76-5.2,1.42-7.84,0.19c-0.29-0.03-0.59-0.06-0.89-0.09
			c-14.75-1.41-44.29-4.19-44.29-4.19s-24.07-1.67-30.72-2.15c-8.07-0.6-16.17-0.79-24.23-1.54c-0.78-0.07-1.58,0.03-2.36-0.1
			c-7.74-1.31-7.65-1.71-6.16-9.74c2.41-12.91,3.27-26,1.98-39.17c-0.52-8.39-4.65-14.82-10.24-20.44
			c-3.36-3.36-3.06-7.58-2.51-11.85c-0.79-6.64-0.79-6.65,5.41-7.7c21.14-3.56,42.24-7.43,63.57-9.48c2.31-0.22,4.05-1.62,6.15-2.2
			c2.03-3.68,4.34-2,6.55-0.3c5.49,4.19,9.81,9.94,16.04,13.14c2.36,1.19,4.61,2.6,6.91,3.91c6.62,2.7,13.8,2.86,20.6,4.81
			c3.25-1.25,6.48,0.13,9.71,0.09c1.45-0.01,2.15,1.05,2.7,2.33c3.38,8,6.12,16.23,7.9,24.77c4.11,9.09,6.1,18.93,9.35,28.32
			c0.3,0.91,0.59,1.82,0.88,2.74c0.79,0.96,1.01,2.11,0.95,3.35C2064.59,543.53,2061.95,552.88,2061.09,562.19z M2065.66,561.07
			c-0.14-2.1,0.32-4.25,0.63-6.35c1.28-8.36,0.9-16.57-1.84-24.62l-0.34-0.1l0.04-0.34c-2.05-6.15-4.18-12.27-6.13-18.46
			c-3.58-11.36-7.05-22.76-10.58-34.14c-1.05-3.42-0.9-5.57,3.33-6.62c7.96-1.95,15.25-5.96,22.52-9.79
			c10.56-5.59,22.07-7.3,33.49-9.23c8.16-1.38,16.03-3.04,22.38-9.09c1.46-1.58,2.94-3.16,4.41-4.75
			c3.53-3.89,6.88-8.01,10.66-11.65c9.88-9.54,16.66-21.03,20.62-34.41c0.24-0.83,0.3-2.24,1.39-2.18c1.44,0.06,1.91,1.49,2.31,2.76
			c3.09,9.61,6.33,19.17,9.22,28.85c2.47,8.26,5.89,16.2,7.64,24.7c0.16,0.2,0.34,0.39,0.46,0.6c0.04,0.07-0.09,0.24-0.13,0.37
			c2.7,5.93,4.85,12.01,5.31,18.67c0.49,6.88,1.26,13.73,2.05,20.58c0.66,5.74,2.21,11.1,5.41,16.03
			c6.56,10.08,9.39,21.64,11.82,33.42c2.9,14.09,6.29,28.06,9.59,42.05c0.76,3.2,0.06,4.11-3.19,4.32
			c-17.39,1.18-34.71,3.43-52.16,3.79c-2.64,0.06-5.27,0.83-7.9,1.26c-5.57,0.79-11.16,1.32-16.8,1.22
			c-20.7-4.38-41.21-9.61-61.69-15.04c-1.51-0.4-3-0.99-4.55-1.19C2064.52,570.53,2066.16,568.84,2065.66,561.07z M2286.59,697.62
			c-0.63,3.76-4.48,11.04-8.93,19.42c-6.51,12.28-14.61,27.56-20.05,44.61c-9.87,30.91-5.79,55.25,0.56,83.85
			c2.38,10.71,8,26.1,13.95,42.38c9.87,27,21.04,57.59,18.05,69.27c-4.58,17.97-29.59,40.56-55.73,50.35
			c-21.86,8.2-51.56-6.22-75.43-17.81c-2.7-1.31-5.33-2.59-7.87-3.79c-23.6-11.22-39.77-41.77-43.53-66.14
			c-3.07-19.89-1.94-49.99-0.75-81.86c0.2-5.49,0.42-11.02,0.6-16.55c1.25-37.11-4.28-91.36-7.71-108.53
			c-2.47-12.32-12.27-34.02-20.91-53.17c-2.73-6.05-5.31-11.76-7.35-16.55c-5.47-12.75-5.14-31.64-4.84-48.3
			c0.07-3.04-0.01-13.3-0.07-18.38c-0.01-1.61,1.38-2.84,2.97-2.67c0.47,0.06,0.93,0.13,1.26,0.22
			c21.64,5.4,43.29,10.86,64.95,16.26c2.92,0.73,5.89,1.05,8.89,0.6c10.23-1.55,20.67-0.45,30.84-2.69
			c5.57-1.22,11.25-1.01,16.89-1.23c14.03-1.74,28.12-2.64,42.22-3.39c5.04-1.03,10.13-1.02,15.24-0.7
			c1.54,0.56,1.26,2.03,1.55,3.26c1.8,7.99,2.6,16.19,5.08,24c1.11,3.46,2.9,6.46,5.37,9.12c4.19,4.54,6.85,10.2,9.65,15.67
			c1.91,3.71,0.09,7.71-3.79,10.37c-4.73,3.25-10.21,3.71-15.51,5.06c-3.85,0.98-10.24,1.9-10.24,1.9l0.79,5.7
			c0,0,17.05-2.61,24.67-2.69c5.04-0.04,10.38,4.29,15.02,7.54c1.45,1.02,1.11,4.9,1.52,7.47
			C2285.37,678.98,2286.96,688.88,2286.59,697.62z M2283.1,651.12c0.72-0.13,1.61-0.65,2.02,0.37c0.39,0.99-0.07,1.87-0.76,2.63
			c-2.54,2.74-6.08,2.54-10.1-1.32C2277.7,652.15,2280.4,651.62,2283.1,651.12z M2275.45,647.41c1.49-3.86,3.04-4.12,7.34-1.21
			C2280.34,647.04,2277.97,647.63,2275.45,647.41z M2285.36,638.05c-0.04,1.05,0.56,2.71-0.57,3.04c-1.28,0.39-2.7-0.4-3.68-1.61
			c-2.93-3.68-4.94-7.96-7.09-12.12c-1.95-3.75-3.88-7.5-6.94-10.43c-4.22-4.06-6.33-9.19-7.15-15.04c-0.47-3.4-1.16-6.76-1.8-10.14
			c-1.03-5.51-0.06-7.37,5.36-8.63c4.11-0.98,8.3-1.8,12.58-1.62c8.69-0.17,10.13,0.62,11.85,9.52c1.64,8.42,4.32,16.69,3.29,25.61
			c-0.52,4.44-0.95,8.6-3.26,12.58C2286.43,631.79,2285.51,634.86,2285.36,638.05z M2282.83,552.72c1.9,5.16,2.4,10.53,2.11,16.03
			C2282.71,563.65,2281.35,558.41,2282.83,552.72z M2304.57,641.06c-4.29,1.71-8.83,1.92-13.23,3c-2.24,0.56-3-0.72-3.16-2.96
			c-0.23-3.36,0.17-6.71,1.81-9.45c2.9-4.88,3.63-10.24,4.54-15.81c1.69,8.46,6.65,14.82,11.1,21.46
			C2306.85,639.13,2307.11,640.06,2304.57,641.06z M2368.73,576.98c-2.96,5.56-6.72,10.15-11.37,13.69
			c-7.9,5.99-15.11,12.84-20.95,20.85c-0.96,1.34-1.95,2.69-2.93,4.02c-6.42,8.72-14.32,16.26-23.27,22.36l-0.11,0.07
			c-0.9-0.59-1.87-1.88-3.43-3.89c-8.79-11.32-10.73-24.72-11.81-38.52c-0.26-3.25-1.02-6.45-1.59-9.68
			c-0.92-5.26,0.11-7.27,5.04-8.79c14.56-4.51,29.14-8.9,43.72-13.34c5.87-1.78,11.75-3.5,17.61-5.27c1.82-0.56,3.42-1.05,5.26,0.46
			C2369.53,562.79,2371.57,571.64,2368.73,576.98z M2368.77,549.61c-13.93,5-27.89,9.9-42.1,13.97
			c-10.27,2.93-20.34,6.64-30.72,9.19c-3.42,0.83-5.1,0.23-5.67-3.39c-2.38-14.86-7.12-29.23-8.75-44.26
			c-0.33-3.09-1.25-6.09-1.87-9.13c-0.62-2.99,0.65-4.01,3.43-5.08c10.6-4.11,19.95-0.29,29.3,4.14
			c8.29,3.92,16.53,7.91,24.79,11.88c5.77,2.63,11.55,5.27,17.32,7.9c4.87,2.27,9.74,4.52,14.61,6.79c1.72,0.79,3.76,1.51,3.69,3.88
			C2372.73,547.95,2370.65,548.93,2368.77,549.61z M2480.65,604.74c-13.4-5.23-20.91-6.88-32.7-8.6
			c-11.33-1.65-20.42-1.13-32.99-0.42l-2.54,0.14c-12.67,0.73-17.71,3.89-30.99-14.76c-2.61-3.68-4.98-8.66-3.59-13.85
			c1.95-7.27,3.58-14.66,9.61-20.11c3.09-2.77,4.84-7.14,7.3-10.71c3.02-4.35,6.29-8.06,10.97-10.17c2.83-1.12,8.09-3.62,15.47-6.1
			c1.68-0.56,3.36-1.18,5-1.85c9.28-3.82,13.17-5.01,24.06-7.88c6.12-1.61,8.63-0.49,11.81,5.36c1.68,3.12,3.26,6.28,5.07,9.87
			c5.83,13.03,13.07,29.36,16.53,37.86c1.42,3.48,3.19,8.04,5.07,12.88c6.15,15.87,13.6,35.13,19.55,44.8
			C2501.56,615.99,2491.34,608.92,2480.65,604.74z M2786.95,752.77c-18.1,1.05-46.19,4.67-73.38,8.17
			c-27.89,3.6-54.2,7.02-63.45,6.69c-17.64-0.6-44.71-9.48-62.95-32.52c-15.17-19.14-29.77-45.4-42.67-68.56
			c-2.77-4.97-5.49-9.85-8.14-14.55c-4.45-7.87-9.32-14.3-14.91-19.76c9.84,4.54,21.5,6.32,22.19,6.42c0.06,0,0.11,0.01,0.16,0.01
			l103.74,9.58c0.04,0,0.09,0,0.14,0.01l139.27,5.63V752.77z"
          />

          <line
            fill="none"
            stroke="#fefefe50"
            strokeWidth="4"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            x1="321.77"
            y1="427.09"
            x2="327.83"
            y2="483.6"
          />
          <g>
            <path
              fill="#fefefe50"
              d="M96.79,485.64c-25.91,6.68-51.85,13.22-78.31,17.38c-18.56,4.16-37.53,11.02-56.82,7.98
				c-6.28-1.09-12.64-4.21-16.35-9.63c-1.73,0.32,2.76,0.11,2.19-0.73c-0.01-0.18,0-0.06,0-0.05c0.1,0.56,0.89,3.22,1.07,3.87
				C-33.54,564.16-9,641.17,9.7,701.38c11.06,18.14,23.1,39.45,33.72,59.07c5.49,9.84,12.24,19.08,18.79,28.28
				c33.52,45.9,69.19,90.23,104.88,134.45c-0.4-0.33,84.44,77.39,84.44,77.46c8.68,9.84,17.37,19.69,26.43,29.18
				c20.45,20.51,38.54,43.28,55.46,66.76c0,0-1.62,1.17-1.62,1.17c-12.27-17.05-25.16-33.62-39.14-49.3
				c-6.85-7.99-14.44-15.23-21.59-22.95c-7.15-7.74-14.14-15.59-21.09-23.49c0.4,0.39-84.52-77.44-84.44-77.36
				c-39.25-48.79-78.98-97.31-114.99-148.58c-3.2-4.72-6.36-9.47-9.13-14.54c-10.8-19.99-22.09-39.74-33.82-59.2
				C-8.53,650.78-31.52,578.05-47.14,527.1c-2.57-8.46-5.24-16.91-7.63-25.43c0,0-0.09-0.37-0.09-0.37s-0.05-0.21-0.05-0.21
				c-0.02-0.1-0.03-0.1-0.05-0.4c-0.59-0.93,3.97-1.1,2.27-0.72c1.28,1.81,2.87,3.38,4.69,4.65c14.49,9.46,38.91,2.48,54.77-1.28
				c7.38-1.98,14.9-3.73,22.51-4.73c7.48-1.29,14.92-2.96,22.35-4.68c14.85-3.47,29.63-7.32,44.32-11.43
				C95.94,482.49,96.79,485.64,96.79,485.64L96.79,485.64z"
            />
          </g>
          <path
            fill="none"
            stroke="#fefefe50"
            strokeWidth="3.2566"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M-39.85,546.62
			c6.71-1.44,14.13-6.47,29.69-5.27s97.13,8.08,102.69,7.9c5.56-0.18,133.36-36.86,133.36-36.86"
          />
          <g>
            <path
              fill="#fefefe50"
              d="M96.13,14.4C75.07-1.85,75.92-32.94,56.45-50.11c-3.16-2.66-6.7-4.72-10.61-6.27
				C26.24-64.37,5.72-69.94-14.67-75.7c-16.03-4.45-33.74-8.96-49.09-12.8c-8.74-0.55-19.82,1.77-14.89,13.02
				c0.88,1.93,2.09,3.91,3.51,5.51c2.61,2.44,6.4,3.17,9.91,4.13c23.85,5.36,49.53,5.54,71.49,17.53
				c8.24,4.62,13.78,12.75,19.11,20.2C39.34-7.85,51.51,13.52,63.78,34.83c12.12,21.38,24,42.87,35.61,64.54
				c0,0-3.16,1.65-3.16,1.65C85.03,79.19,73.53,57.5,61.59,36.06c-11.96-21.38-24.12-42.76-37.93-63
				C15.79-38.27,10.42-45.47-2.92-50.49c-17.09-6.58-35.69-8.41-53.71-11.47c-6.17-1.18-12.34-1.95-18.07-4.93
				c-1.42-0.81-2.66-2.15-3.56-3.54c-1.85-2.66-3.34-5.62-3.72-8.92c-1.09-10.12,10.93-12.01,18.54-11.37
				c16.53,4.03,32.99,8.27,49.39,12.75c20.46,5.74,41.1,11.3,60.86,19.32c3.98,1.58,7.91,3.79,11.27,6.59
				c13.45,11.3,17.54,29.46,25.05,44.46c3.66,7.52,8.41,14.52,14.91,19.56C98.03,11.97,96.13,14.4,96.13,14.4L96.13,14.4z"
            />
          </g>
          <path
            fill="none"
            stroke="#fefefe50"
            strokeWidth="2"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M-18.97-78.1
			c-9.14,15.56-26.16,42.27-32.41,57.94c-8.57,21.49-11.54,70.32,0,100.96S-18.73,200.21-13,211.36S92.53,549.25,92.53,549.25"
          />
          <path
            fill="none"
            stroke="#fefefe50"
            strokeWidth="2"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M-53.27-14.64
			c-0.57,1.97-44.02,56.06-71.79,99.69s-64.56,74.88-62.44,88.46c2.12,13.58,29.89,23.76,49.04,28.25
			c19.15,4.48,119.98,34.99,158.61,56.12s80.34,40.47,80.34,40.47"
          />
          <path
            fill="none"
            stroke="#fefefe50"
            strokeWidth="3.2566"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M-113.56,511.39
			l62.19-7.99c0,0,20.15-5.2,53.03-15.22c32.87-10.02,93.99-48.64,93.99-48.64"
          />
          <path
            fill="none"
            stroke="#fefefe50"
            strokeWidth="2"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M-135.63,415.78
			c0,0,40.26-2.73,58.93-13.26s40.21-32.3,60.8-40.09c20.59-7.79,44.71-20.25,44.71-20.25"
          />
          <path
            fill="none"
            stroke="#fefefe50"
            strokeWidth="4"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M451.12,1094.1
			l105.55,196.23l15.73,22.16c4.4,6.2,11.98,9.33,19.48,8.03l26.94-4.66c6.62-1.15,13.37,1.05,18.05,5.87l110.57,113.92
			l213.49-105.78c32.44-16.07,62.75-36.2,89.82-60.25c10.67-9.48,20.29-19.09,25.85-27.09c18.19-26.17,81.55-118.38,81.55-118.38
			l118.78-164.79c1.03-1.42,2.32-2.63,3.82-3.54l0,0c2.8-1.71,6.13-2.32,9.35-1.7l93.87,17.85"
          />
          <path
            fill="none"
            stroke="#fefefe50"
            strokeWidth="3"
            strokeMiterlimit="10"
            d="M1145.27-108.74c0,0,44.81,33.25,58.11,41.82
			s39.29,14.63,42.93,16.23c3.64,1.6,29.11,53.11,29.11,53.11s19.67,15.29,42.13,36.24c22.46,20.95,110.43,21.05,110.43,21.05"
          />
          <path
            fill="none"
            stroke="#fefefe50"
            strokeWidth="3"
            strokeMiterlimit="10"
            d="M1511.05,284.77c0.35-15.65,3.92-37.3,8.66-58.03
			s1.86-25.27,1.19-51.75s5.69-59.8,4.47-101.11c-1.22-41.31,4.33-63.32,5.82-80.79s6.58-56.3,6.58-56.3l3.19-150.41
			c-0.09,4.31,36.38,36.43,75.64,54.36c39.26,17.93,148.41,50.48,148.41,50.48"
          />
          <path
            fill="none"
            stroke="#fefefe50"
            strokeWidth="3"
            strokeMiterlimit="10"
            d="M1478.72,64.53c0,0-4.88,16.99-7.34,32.55
			c-2.13,13.52-1.85,25.62-2.28,28.72c-0.93,6.68-7.93,11.94-9.52,15.23c-1.6,3.28-5.32,5.52-4.89,16.37
			c0.42,10.85,2.66,38.84,2.66,38.84"
          />
          <path
            fill="none"
            stroke="#fefefe50"
            strokeWidth="4"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M3114.45,761.26
			c0,0-33.99,4.03-40.64,33.29c-6.64,29.26,10.47,90.71,0,144.24s-13.99,133-71.59,158.38c-57.61,25.37-490.68,126-490.68,126
			s-99.76,13.31-137.58,18.1c-37.82,4.79-236.5-5.4-236.5-5.4l-220.7-1.25"
          />
          <path
            fill="none"
            stroke="#fefefe50"
            strokeWidth="4"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M1533.42,732.61
			l60.2,22.88c29.68,17.89,69.24,50.92,103.08,51.32c33.84,0.4,82.47-2.41,113.37-29.73c30.9-27.32,53.24-69.61,79.41-84.85
			s54.26-9.42,63.19-3.71c8.94,5.7,29.59,27.33,30.75,51.62c1.16,24.3-8.41,54.78-26.92,81.35c-18.51,26.57-39.37,20.74-63.19,41.21
			c-11.66,10.02-38.3,38.96-38.3,38.96"
          />
          <path
            fill="none"
            stroke="#fefefe50"
            strokeWidth="2"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M1707.24,806.81
			c0,0-47.55,35.51-82.79,53.31c-18.27,9.23-37.78,16.18-52.31,20.73c-14.01,4.38-29.01,4.45-43.04,0.13l-32.42-9.96
			c0,0-18.91-3.05-21.78-3.43c-2.87-0.38-7.92,2.48-7.92,2.48"
          />
          <path
            fill="none"
            stroke="#fefefe50"
            strokeWidth="4"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M1752.54,802.71
			l-9.36,1.56c0,0,23.74-5.71,45.44,19.04s68.94,83.48,68.94,83.48s13.95,15.52,34.73,38.08c50.74,55.1,142.17,152.25,170.48,168.1
			c39.9,22.34,116.5,42.77,179.69,37.66s103.34-51.07,136.89-72.77c33.54-21.7,92.27-67.02,143.34-84.9
			c51.07-17.87,120.64-33.83,120.64-33.83s65.11-6.38,98.3-21.06c33.19-14.68,26.17-29.36,66.39-37.66
			c40.21-8.3,71.49-12.93,130.85-12.53c59.36,0.4,222.13,11.09,257.88,0"
          />
          <path
            fill="none"
            stroke="#fefefe50"
            strokeWidth="4"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M1885.99,938.02
			l68.67-27.32c5.14-2.05,10.97-1.4,15.54,1.72l10.82,7.39c4.97,3.39,7.72,9.2,7.19,15.19l-0.76,8.69
			c-2.78,31.9,2.6,63.98,15.63,93.23l18.7,41.96"
          />
          <path
            fill="none"
            stroke="#fefefe50"
            strokeWidth="4"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M1812.72,851.81
			c0,0,19.19-28.03,34.25-30.25c15.06-2.22,28.25,0.97,29,11.18c0.44,6-2.91,15.09-5.74,21.56c-1.96,4.48-0.13,9.7,4.19,11.98
			l9.64,5.1"
          />
          <path
            fill="none"
            stroke="#fefefe50"
            strokeWidth="2"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M1631.92,1005.38
			c0,0,35.8-62.23,44.71-81.69c8.91-19.46,38.2-115.21,38.2-115.21"
          />
          <path
            fill="none"
            stroke="#fefefe50"
            strokeWidth="3"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M1287.54,572.15
			l-1.35-3.64c-0.75-2.02-0.03-4.31,1.77-5.49c1.93-1.27,4.84-3.54,8.99-7.62c9.1-8.94,40.78,1.16,40.78,1.16l92.72,16.72
			c7.01,1.27,14.12,1.96,21.25,2.08l18.69,0.31c9.63,0.16,19.21,1.37,28.57,3.61l8.59,2.06c9.07,2.17,17.99,4.93,26.71,8.25
			l30.48,11.61c0,0,12.29-1.76,15.48-11.17c3.19-9.42,11.73-55.05,11.73-55.05"
          />

          <line
            fill="none"
            stroke="#fefefe50"
            strokeWidth="3"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            x1="1564.73"
            y1="601.2"
            x2="1595.19"
            y2="616.14"
          />
          <path
            fill="none"
            stroke="#fefefe50"
            strokeWidth="3"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M1470.39,575.67
			c0,0,17.9-4.31,26.36-13.25c8.46-8.94,30.48-27.45,30.48-27.45c4.59,5.9,21.79,18.99,26.78,28.88
			c4.98,9.89,11.93,37.34,11.93,37.34"
          />
        </g>
      </g>
    </RoadsWrapper>
  )
}

export default Roads

const RoadsWrapper = styled.g`
	pointer-events: none;
`
