import styled from "styled-components"
import { useNavigate } from "react-router-dom"

import Logo from "@components/SVG/Logo/Logo"

import globalStyles from "@data/globalStyles"
import { useStore } from "@state/store"
import verify from "@utils/verify"

function Landsec() {
  const isMobile = useStore((s) => s.isMobile)
  const setTokenIsValid = useStore((s) => s.setTokenIsValid)
  const navigate = useNavigate()

  function handleNav(path) {
    verify(
      () => {
        navigate(path)
      },
      () => {
        setTokenIsValid(false)
        navigate("/login")
      }
    )
  }

  return (
    <LandsecWrapper $ismobile={isMobile}>
      <p className="caption">Landsec’s Piccadilly Lights & Lucent</p>
      {isMobile && (
        <Buttons className="mobileBtn">
          <button
            onClick={() => {
              handleNav("/map")
            }}
          >
            <h1>Explore the Seed Portfolio {">"}</h1>
          </button>
          <button
            onClick={() => {
              window.open("https://landsec.com/")
            }}
          >
            <h1>Visit our website for more information {">"}</h1>
          </button>
        </Buttons>
      )}
      <Banner>
        <Logo className="logo" />
        <div>
          <h3>Who we are</h3>
          <p>
            We are the <strong>largest UK-focused REIT.</strong> We create
            places that make a{" "}
            <strong>
              lasting positive contribution to our communities and our planet.
            </strong>{" "}
            We
            <strong> bring people together,</strong> forming connections with
            each other and <strong>the spaces we create.</strong>
          </p>
        </div>

        {!isMobile && (
          <Buttons>
            <button
              onClick={() => {
                handleNav("/map")
              }}
            >
              <h1>Explore the Seed Portfolio {">"}</h1>
            </button>
            <button
              onClick={() => {
                window.open("https://landsec.com/")
              }}
            >
              <h1>Visit our website for more information {">"}</h1>
            </button>
          </Buttons>
        )}
      </Banner>
    </LandsecWrapper>
  )
}

export default Landsec

const LandsecWrapper = styled.div<{$ismobile: boolean}>`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${globalStyles.colors.lightBlack};
  background-image: url(/assets/images/story/picadilly.webp);
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0;
  animation: fadeIn 0.8s ease-out forwards;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .mobileBtn {
    position: absolute;
    bottom: 27%;
    right: 3%;
    width: 60%;
    color: ${globalStyles.colors.white};
    padding: 2rem;

    & button {
      margin-bottom: 5%;
      border: 1rem solid ${globalStyles.colors.white};
      background-color: ${globalStyles.colors.darkBlue}4D;
      & h1 {
        font-size: 12rem;
        width: 100%;
        border: none;
        @media (max-width: 700px) {
          font-size: 10rem;
        }
      }
    }
  }

  .caption {
    position: absolute;
    top: 2rem;
    left: 2rem;
    color: ${globalStyles.colors.white};
    letter-spacing: 0.2rem;
    font-size: ${p => p.$ismobile ? "10rem" : "1.5rem"};
    background-color: ${globalStyles.colors.black};
    padding:  ${p => p.$ismobile ? "5rem" : "1rem"};
  }
`

const Banner = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 25%;
  background-color: ${globalStyles.colors.darkBlue};
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 5%;

  .logo {
    flex: 1;
    height: 50%;
  }

  & div {
    flex: 2;
    color: ${globalStyles.colors.white};

    & h3 {
      margin-bottom: 5%;
      font-size: 3rem;
    }

    & p {
      font-size: 1.5rem;
      font-family: copy;
    }
  }

  @media (min-width: 500px) {
    .logo {
      flex: 0.5;
      height: 60%;
      margin-left: 3%;
    }
    & div {
      & p {
        font-size: 2rem;
      }
      & h3 {
        margin-bottom: 1%;
        font-size: 2.5rem;
      }
    }
  }

  @media (max-width: 1300px) {
    justify-content: center;
    .logo {
      flex: 0.5;
      height: 40%;
      margin-left: 3%;
    }

    & div {
      padding: 2%;
      & h3 {
        font-size: 12rem;
      }
      & p {
        font-size: 8rem;
        word-spacing: 0.5rem;
        letter-spacing: 0.1rem;
      }
    }
  }
`

const Buttons = styled.div`
  display: flex;
  flex-direction: column;

  & button {
    flex: 2;
    padding: 2%;
    background: none;
    border: none;
    cursor: pointer;
    & h1 {
      width: 80%;
      font-size: 1.5rem;
      color: ${globalStyles.colors.white};
      border: 0.3rem solid ${globalStyles.colors.white};
      padding: 3%;
      @media (min-width: 500px) {
        font-size: 2rem;
      }
    }
  }
`
