import styled from "styled-components"

function MidTown({ cue }) {
  return (
    <MidTownWrapper
      style={{
        opacity: cue ? 0.3 : 1,
      }}
    >
      <path
        fill="#215762"
        stroke="#1B606B50"
        strokeWidth={15}
        d="M1232.88,551.53l1.21,16.35c-1.73-0.73-5.09-1.04-9.39-1.24c-11.49,0.04-16.55,0-25.83,0.04
				c-16.04-0.04-29.53,0.47-45.3,2.01c-10.02,0.89-28.28,4.56-38.97,7.66c-11.39,2.97-23.59,8.05-33.43,12.34
				c-1.61,1.19-9.34,3.44-10.69,3.82c-0.35-0.01-0.59-0.04-1.12-0.16c-0.32,0.26-3.95,4.97-6.18,6.66
				c-17.74,12.22-24.41,20.33-34.91,35.94c-1.26,1.71-4.99,8.73-6.41,10.97c-0.89,1.53-5.36,7.08-6.9,9.01
				c-0.69,2.95-1.23,4.74-1.75,7.43c-9.05-4.64-9.91-5.6-18.96-10.24c-0.44-0.23-11.98-6.47-12.38-6.77
				c-3.54-2.2-12.29-5.55-20.66-12.24c-1.84-1.48-7.3-7.83-17.17-3.21c-6.06,3.36-11.11,6.28-20.34,10.25
				c-2.2,1.11-5.89,0.61-7.68-2.54c-0.37-0.76-1.72-3.42-3.24-6.68c-4.16-8.9-6.21-12.31-8.75-18.03c-0.25-0.56-0.76-1.65-1.65-2.42
				c-1.12-2.1-2.24-4.21-3.37-6.3c-1.23-2.28-2.32-4.42-2.97-5.57c5.2-4.64,10.53-9.24,15.85-13.95c8.97-7.94,19.57-17.68,34.05-28.62
				c1.34-1.74,4.83-3.96,3.09-12.83c-0.6-2.16-3.52-14.09-4.1-16.65c-1.12-4.97-4.32-18.52-5.12-21.5c-0.42-1.57-1.02-3.27-1.54-4.43
				c-0.21-2.76-0.43-5.53-0.69-8.33c-0.3-3.3-0.6-6.48-0.69-7.55c-0.36-0.75-1.51-2.88-4.53-8.83c-1.23-2.28-10.19-19.99-13.34-25.98
				l-15.65-30.49l-17.63-34.36L859.6,334.1L758.78,137.67c-6.71-7.14-12.36-11.34-17.7-13.6c-2.89-1.23-7.38-2.64-11.45-2.86
				c-8.22-0.89-7.78-0.69-14.68-1.23c-12.39-1.04-21.49-1.15-40.86-1.64c6.29-20.03-3.05-42.52-11-57c-1.61-2.92-5.72-4.45-8.63-6.68
				c-3.28-2.52-6.85-4.79-9.7-7.75c-4.59-4.77-4.77-10.13,0.18-14.82c9.45-8.93,19.24-17.49,28.64-26.46c1.86-1.77,3.7-3.56,5.54-5.35
				h321.15c7.97,5.82,11.53,13.05,12.72,22.78c1.09,8.88,5.27,17.85,9.88,25.72c10.89,18.57,15.81,38.33,17.06,59.73
				c1.63,27.92,5.16,55.72,7.23,83.62c0.89,11.97,0.15,24.06,0.15,37.08l0.63,1.26c7.81-1.15,15.63-2.23,23.44-3.3
				c25.28-3.49,64.22-6.7,89.5-10.15c3.95-0.54,3.79,1.76,5.56,6.42c8.52,22.39,5.75,13.34,14.1,35.79c1.03,2.77,5.39,12.98,7.03,17.51
				c3.82,9.17,6.18,12.14,1.25,18.99c-1.81,2.55-20.37,26.42-21.62,29.15c-2.48,4.53-3.29,7.03-4.59,10.32
				c-1.56,5.62-2.73,10.86-4.52,16.63c-1.73,5.45-6.71,20.41-8.21,25.53c-0.76,3.6,3.01,7.25,6.81,6.27c2.6-0.65,5.32-1.32,5.34-1.33
				c14.83-3.79,18.55-4.75,33.36-8.63c5.3-1.37,6.53,1.03,8.57,5.11c6.57,13.1,12.1,23.01,18.83,36.03c1.64,3.1,1.93,4.38,2.17,8.12
				c0.53,9.15,0.83,14.98,1.51,20.56c1.86,22.36,3.26,35.2,2.23,52.68c-0.03,0.58-0.13,2.55-0.19,4.24L1232.88,551.53z"
      />
    </MidTownWrapper>
  )
}

export default MidTown

const MidTownWrapper = styled.g``
