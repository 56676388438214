import {  useContext } from "react"
import { Routes, Route, Navigate } from "react-router-dom"

import Login from "@pages/Login"

import routes from "./routes/routes"
import { AuthContext } from "./App"

function Content() {
  const tokenIsValid = useContext(AuthContext)
  return (
    <Routes>
      <Route
        path={"/login"}
        element={tokenIsValid ? <Navigate to="/" /> : <Login />}
      />
      {routes.map((route, idx) => {
        return (
          route.element && (
            <Route
              key={idx}
              path={route.path}
              element={
                tokenIsValid ? <route.element /> : <Navigate to="/login" />
              }
            />
          )
        )
      })}
    </Routes>
  )
}

export default Content
