import AnimatedMaterial from "./AnimatedMaterial"

function Sphere({
  initialTexture,
  finalTexture,
  blend,
  shaderRef,
  meshProps
}) {
   return (
      <mesh
         scale={[-1, 1, 1]}
         {...meshProps}
      >
         <sphereGeometry args={[500, 60, 40]} />
         <AnimatedMaterial 
           ref={shaderRef}
           //@ts-ignore
           initialTexture={initialTexture}
           finalTexture={finalTexture}
           blend={blend}
         />
      </mesh>
   )
}

export default Sphere
