import styled from "styled-components"
import { useNavigate } from "react-router-dom"

import { useStore } from "@state/store"
import globalStyles from "@data/globalStyles"
import verify from "@utils/verify"

function Sites({ cue }) {
  const setTokenIsValid = useStore((s) => s.setTokenIsValid)
  const setActiveSite = useStore((s) => s.setActiveSite)
  const navigate = useNavigate()

  function handleNav(path) {
    verify(
      () => {
        navigate(path)
      },
      () => {
        setTokenIsValid(false)
        navigate("/login")
      }
    )
  }

  function handleClick(i) {
    setActiveSite(i)
    setTimeout(() => {
      handleNav(`/sites/${i}`)
    }, 200)
  }
  return (
    <SitesWrapper
      style={{
        opacity: cue ? 1 : 0,
        pointerEvents: cue ? "auto" : "none",
        transitionDelay: cue ? "0.8s" : "0s",
      }}
    >
      <g
        id="RED LION COURT"
        onClick={(e) => {
          e.stopPropagation()
          handleClick(3)
        }}
      >
        <rect
          x="1430.6"
          y="672.1"
          fill="#FFFFFF"
          width="39.79"
          height="14.11"
        />
        <text
          x={1436}
          y={678}
          fill={globalStyles.colors.lightBlack}
          style={{
            fontFamily: "main",
            fontSize: 7,
          }}
        >
          Red Lion
        </text>
        <text
          x={1441}
          y={684}
          fill={globalStyles.colors.lightBlack}
          style={{
            fontFamily: "main",
            fontSize: 7,
          }}
        >
          Court
        </text>
        <polygon
          className="pin"
          fill="#FFFFFF"
          points="1454.86,689.62 1450.5,693.99 1446.13,689.62 1446.13,689.62 1437.4,689.62 1450.5,702.72 
			1463.59,689.62 1454.86,689.62 		"
        />
        <polygon
          fill="#FFFFFF"
          points="1451.95,686.23 1450.49,687.68 1449.04,686.23 1449.04,686.23 1446.13,686.23 1450.5,690.59 
			1454.86,686.23 1451.95,686.23 		"
        />

        <ellipse fill="#FFFFFF" cx="1450.49" cy="704.87" rx="2.07" ry="0.5" />
      </g>

      <g
        id="THE FORGE"
        onClick={(e) => {
          e.stopPropagation()
          handleClick(0)
        }}
      >
        {/* <rect x="1384.53" y="689" fill="#FFFFFF" width="39.79" height="8.76" /> */}
        {/* <text
          x={1389}
          y={695}
          fill={globalStyles.colors.lightBlack}
          style={{
            fontFamily: "main",
            fontSize: 7,
          }}
        >
          The Forge
        </text> */}
        {/* <polygon
          fill="#FFFFFF"
          points="1405.88,697.77 1404.43,699.22 1402.97,697.77 1402.97,697.77 1400.06,697.77 1404.43,702.13 
      1408.79,697.77 1405.88,697.77 		"
        />
        <polygon
          className="pin"
          fill="#FFFFFF"
          points="1408.79,701.16 1404.43,705.53 1400.06,701.16 1400.06,701.16 1391.33,701.16 1404.43,714.26 
			1417.52,701.16 1408.79,701.16 		"
        />

        <ellipse fill="#FFFFFF" cx="1404.43" cy="715.71" rx="2.07" ry="0.5" /> */}
      </g>

      <g
        id="LIBERTY"
        onClick={(e) => {
          e.stopPropagation()
          handleClick(2)
        }}
      >
        <polygon
          className="pin"
          fill="#FFFFFF"
          points="1483.53,757.58 1479.16,761.94 1474.8,757.58 1474.8,757.58 1466.07,757.58 1479.17,770.67 
			1492.26,757.58 1483.53,757.58 		"
        />
        <polygon
          fill="#FFFFFF"
          points="1480.62,754.18 1479.16,755.64 1477.71,754.18 1477.71,754.18 1474.8,754.18 1479.16,758.55 
			1483.53,754.18 1480.62,754.18 		"
        />
        <rect
          x="1455.55"
          y="736.46"
          fill="#FFFFFF"
          width="47.22"
          height="17.71"
        />
        <text
          x={1456}
          y={744}
          fill={globalStyles.colors.lightBlack}
          style={{
            fontFamily: "main",
            fontSize: 7,
          }}
        >
          The Liberty of
        </text>
        <text
          x={1461.5}
          y={750}
          fill={globalStyles.colors.lightBlack}
          style={{
            fontFamily: "main",
            fontSize: 7,
          }}
        >
          Southwark
        </text>
        <ellipse fill="#FFFFFF" cx="1479.16" cy="773.7" rx="2.07" ry="0.5" />
      </g>

      <g
        id="TIMBER"
        onClick={(e) => {
          e.stopPropagation()
          handleClick(0)
        }}
      >
        <rect
          x="1334.44"
          y="724.58"
          fill="#FFFFFF"
          width="52.35"
          height="17"
        />
        <ellipse fill="#FFFFFF" cx="1360.61" cy="759.75" rx="2.07" ry="0.5" />
        <polygon
      className="pin"
      fill="#FFFFFF"
      points="1365,745 1360.63,749.36 1356.27,745 1356.27,745 1347.54,745 1360.64,758.1 
              1373.73,745 1365,745"
    />
    <polygon
      fill="#FFFFFF"
      points="1362.09,741.61 1360.63,743.06 1359.17,741.61 1359.17,741.61 1356.27,741.61 1360.63,745.97 
              1365,741.61 1362.09,741.61"
    />
        <text
          x={1337.5}
          y={732}
          fill={globalStyles.colors.lightBlack}
          style={{
            fontFamily: "main",
            fontSize: 7,
          }}
        >
          Timber Square
        </text>
        <text
          x={1348}
          y={739}
          fill={globalStyles.colors.lightBlack}
          style={{
            fontFamily: "main",
            fontSize: 7,
          }}
        >
          Phase 1
        </text>

      </g>

      <g
        id="Timber Square Phase 2"
        onClick={(e) => {
          e.stopPropagation()
          handleClick(1)
        }}
      >
    <polygon
      className="pin"
      fill="#FFFFFF"
      points="1345,777 1349.36,772.64 1353.73,777 1353.73,777 1362.46,777 1349.36,763.9 
              1336.27,777 1345,777"
    />
    <polygon
      fill="#FFFFFF"
      points="1347.91,780.39 1349.36,778.94 1350.82,780.39 1350.82,780.39 1353.73,780.39 
              1349.36,776.03 1345,780.39 1347.91,780.39"
    />
        <rect
          x="1463"
          y="699.5"
          transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 2838.3225 1496.7189)"
          fill="#FFFFFF"
          width="50.51"
          height="17"
        />
        <text
          x={1326.75}
          y={788}
          fill={globalStyles.colors.lightBlack}
          style={{
            fontFamily: "main",
            fontSize: 7,
            textAlign: "center",
          }}
          >
          Timber Square
        </text>
        <text
          x={1337}
          y={795}
          fill={globalStyles.colors.lightBlack}
          style={{
            fontFamily: "main",
            fontSize: 7,
            textAlign: "center",
          }}
        >
          Phase 2
        </text>
        <ellipse fill="#FFFFFF" cx="1349.25" cy="762.25" rx="2.07" ry="0.5" />
      </g>
     
    </SitesWrapper>
  )
}

export default Sites

const SitesWrapper = styled.g`
  & rect,
  path,
  text {
    cursor: pointer;
  }

  & text {
    font-family: main;
  }
`
