import { Fragment, useEffect, useRef } from "react"
import styled from "styled-components"

import EyeIcon from "@components/SVG/EyeIcon/EyeIcon"
import PlayIcon from "@components/SVG/PlayIcon/PlayIcon"
import CloseIcon from "@components/SVG/CloseIcon/CloseIcon"

import globalStyles from "@data/globalStyles"
import { useStore } from "@state/store"

function SitesHeader({ data }) {
  const activeSite = useStore((s) => s.activeSite)
  const menuOpen = useStore((s) => s.menuOpen)
  const setMenuOpen = useStore((s) => s.setMenuOpen)
  const setSiteCopyVisible = useStore((s) => s.setSiteCopyVisible)

  const wrapperRef = useRef<HTMLDivElement>()

  const btnProps = {
    strokeWidth: 4,
    strokeColor: globalStyles.colors.white,
    color: "none",
    bgColor: "none",
  }

  const buttons = [
    {
      Element: CloseIcon,
      props: {
        ...btnProps,
        className: "plus",
      },
      onClick: () => {
        setSiteCopyVisible(true)
        setMenuOpen(!menuOpen)
      },
    },
  ]

  

  return (
    <SitesHeaderWrapper
      ref={wrapperRef}
      style={{
        transform: menuOpen ? "translateX(0%)" : "translateX(100%)",
      }}
    >
      <Opener
        onClick={() => {
          setMenuOpen(!menuOpen)
        }}
      >
        <button>
          <CloseIcon
            strokeWidth={4}
            strokeColor={globalStyles.colors.white}
            color={globalStyles.colors.white}
            bgColor="none"
            style={{
              transform: menuOpen ? "rotate(0deg)" : "rotate(45deg)"
            }}
          />
        </button>
      </Opener>
      <Content>
        <Title>
          {data.name.map((line, i) => {
            return (
              <Fragment key={`ln${i}`}>
                {line} <br />
              </Fragment>
            )
          })}
        </Title>
        <Data>
          <DataLine>
            <p>{data.description}</p>
          </DataLine>
          <DataLine>
            <h6>
              <span>{data.sqf}</span>
              <br />
              SQFT
            </h6>
            <h6>
              <span>{data.status}</span>
              <br />
              STATUS
            </h6>
            <h6>
              <span>{data.gdv}</span>
              <br />
              GDV AT STABILISATION
            </h6>
          </DataLine>
          <DataLine>
            {data.certifications.map((obj, i) => {
              return (
                <Certification key={`cert${i}`}>
                  <img alt="" src={obj.src} />
                  <h3>{obj.caption}</h3>
                </Certification>
              )
            })}
          </DataLine>
        </Data>
        {/* <Buttons>
          {buttons.map((obj, i) => {
            return (
              <button key={`btn${i}`} onClick={obj.onClick}>
                <obj.Element {...obj.props} />
              </button>
            )
          })}
        </Buttons> */}
      </Content>
    </SitesHeaderWrapper>
  )
}

export default SitesHeader

const SitesHeaderWrapper = styled.div`
  position: absolute;
  bottom: 3%;
  right: 0;
  z-index: 4;
  width: 45%;
  height: 50%;
  max-width: 900px;
  transform: translate(100%, 0%);
  transition: transform 0.8s cubic-bezier(0.65, 0.04, 0.38, 0.93);
  background-color: ${globalStyles.colors.lightBlack};
  color: ${globalStyles.colors.white};
  border: 0.2vh solid ${globalStyles.colors.blue};
  border-left: none;
  border-right: none;

  @media (min-width: 500px) {
    height: 60%;
  }
`
const Opener = styled.div`
  position: absolute;
  z-index: 1;
  width: 15%;
  height: 101%;
  left: -15%;
  top: -0.5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(83, 214, 197, 0.3);
  backdrop-filter: blur(0.5rem);
  border-radius: 5rem 0 0 5rem;
  cursor: pointer;

  & button {
    width: 65%;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    max-width: 150px;

    & svg {
      transition: transform 0.8s cubic-bezier(0.58, -0.28, 0.38, 1.34);
    }
  }

  @media (min-width: 700px) {
    width: 10%;
    left: -9.8%;
  }

  @media (min-width: 500px) {
    & button {
      width: 100%;
      aspect-ratio: 1/1;
      & svg {
        width: 100%;
        height: 100%;
      }
    }
  }
`

const Content = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2%;
  padding: 2% 0%;
  font-size: 2rem;
  @media (min-width: 500px) {
    font-size: 3rem;
  }
`

const Title = styled.div`
  text-align: center;
  display: grid;
  place-content: center;
  font-size: 1.2em;
  line-height: 1.2em;
  letter-spacing: 0.5em;
  font-weight: 100;
  flex: 0.2;
  height: 10%;
  max-height: 20%;
`

const Data = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0.6;
  max-height: 60%;
  gap: 1.5rem;
`

const DataLine = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5%;
  padding: 0 5%;

  & h6 {
    font-size: 0.5em;
    font-weight: 100;
    text-align: center;
    line-height: 1.5em;

    & span {
      font-size: 1.5em;
      color: ${globalStyles.colors.blue};
    }
  }

  & p {
    text-align: center;
    font-size: 0.8em;
    margin-bottom: 2rem;
  }
`

const Certification = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  & img {
    width: 5rem;
    max-width: 5rem;
    height: 5rem;
    height: 5rem;
  }

  & h3 {
    width: 100%;
    font-size: 0.6em;
    font-weight: 100;
    text-align: center;
    line-height: 1em;
    color: ${globalStyles.colors.blue};
  }
`

const Buttons = styled.div`
  position: relative;
  width: 100%;
  height: 8%;
  flex: 0.2;
  max-height: 20%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & button {
    width: 20%;
    height: 50%;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;

    & svg {
      max-height: 100%;
    }
  }

  .plus {
    transform-origin: center;
    transform: rotate(45deg);
  }

  @media (min-width: 500px) {
    align-items: flex-start;
    max-height: 25%;
    & button {
      position: relative;
      height: 60%;
      aspect-ratio: 1/1;
      & svg {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        aspect-ratio: 1/1;
      }
    }
    .plus {
      transform-origin: center;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
`
