import { useRef, useMemo, useState, useEffect } from "react"
import styled from "styled-components"
import gsap from "gsap"

import CloseIcon from "@components/SVG/CloseIcon/CloseIcon"
import Transition360 from "@components/Transition360"

import globalStyles from "@data/globalStyles"
import { useStore } from "@state/store"

function Tours({ loaded }) {
  const [tourIndex, setTourIndex] = useState(0)
  const isMobile = useStore((s) => s.isMobile)
  const activeSite = useStore((s) => s.activeSite)
  const siteTourVisible = useStore((s) => s.siteTourVisible)
  const setSiteTourVisible = useStore((s) => s.setSiteTourVisible)
  const wrapperRef = useRef<HTMLDivElement>()
  const shaderRef = useRef<any>()
  const currentIndex = useRef(0)

  const labels = {
    0: ["Exterior", "Reception"],
    1: null,
    2: null,
    3: ["Exterior", "Terrace"],
  }

  const rotations = {
    0: [0, -2.25, 0],
    1: null,
    2: [0, -2, 0],
    3: [0, -1.6, 0],
  }

  const textures = useMemo(() => {
    if (activeSite !== null) {
      const arrays = {
        0: [loaded[0], loaded[1]],
        1: null,
        2: [loaded[2]],
        3: [loaded[5], loaded[6]],
      }
      return arrays[activeSite]
    }
  }, [activeSite])

  function handleClose() {
    wrapperRef.current.style.opacity = "0"
    setTimeout(() => {
      setSiteTourVisible(false)
    }, 800)
  }

  useEffect(() => {
    if (tourIndex !== currentIndex.current) {
      shaderRef.current.uniforms.uInitialTexture.value =
        textures[currentIndex.current]
      shaderRef.current.uniforms.uFinalTexture.value = textures[tourIndex]
      gsap.fromTo(
        shaderRef.current.uniforms.uBlend,
        {
          value: 0,
        },
        {
          value: 1,
          duration: 1.5,
          onComplete: () => {
            shaderRef.current.uniforms.uInitialTexture = textures[tourIndex]
            currentIndex.current = tourIndex
            wrapperRef.current.style.pointerEvents = "auto"
          },
        }
      )
    }
  }, [tourIndex])

  useEffect(() => {
    currentIndex.current = 0
    setTourIndex(0)
  }, [activeSite])

  return (
    siteTourVisible && (
      <ToursWrapper
        ref={(node) => {
          if (node) {
            wrapperRef.current = node
            setTimeout(() => {
              node.style.opacity = "1"
            }, 100)
          }
        }}
      >
        <Transition360
          shaderRef={shaderRef}
          initialTexture={textures[0]}
          finalTexture={textures[0]}
          blend={0}
          meshProps={
            rotations[activeSite]
              ? {
                  rotation: rotations[activeSite],
                }
              : undefined
          }
        />
        <CloseIcon
          className="close"
          style={{
            width: isMobile ? "30rem" : "6rem",
          }}
          strokeWidth={4}
          strokeColor={globalStyles.colors.white}
          color={globalStyles.colors.white}
          bgColor="none"
          onClick={() => {
            handleClose()
          }}
        />
        {labels[activeSite] && (
          <Buttons>
            {labels[activeSite]?.map((txt, i) => {
              return (
                <button
                  key={i}
                  style={{
                    backgroundColor:
                      i === tourIndex
                        ? `${globalStyles.colors.black}`
                        : `${globalStyles.colors.black}66`,
                  }}
                  onClick={() => {
                    wrapperRef.current.style.pointerEvents = "none"
                    setTourIndex(i)
                  }}
                >
                  {txt}
                </button>
              )
            })}
          </Buttons>
        )}
      </ToursWrapper>
    )
  )
}

export default Tours

const ToursWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 15;
  transition: opacity 0.8s ease-in-out;
  opacity: 0;
  background-color: ${globalStyles.colors.black};

  .close {
    position: absolute;
    z-index: 1;
    top: 1vw;
    right: 1vw;
    width: 6rem;
    aspect-ratio: 1/1;
    pointer-events: all;
    cursor: pointer;
    mix-blend-mode: difference;
  }
`

const Buttons = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 2%;
  height: 8%;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 5%;
  left: 50%;
  transform: translateX(-50%);

  & button {
    height: 110%;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    color: ${globalStyles.colors.blue};
    border-radius: 100%;
    aspect-ratio: 1/1;
    display: grid;
    place-content: center;
    transition: background-color 0.8s ease-in-out;
  }
`
