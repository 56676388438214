import { useRef, useState, useEffect } from "react"
import styled from "styled-components"
import { a, useSpring } from "@react-spring/web"

import City from "./Sections/City"
import Detail from "./Sections/Detail"
import Labels from "./Sections/Labels"
import MidTown from "./Sections/MidTown"
import River from "./Sections/River"
import Roads from "./Sections/Roads"
import Sites from "./Sections/Sites"
import SouthBank from "./Sections/SouthBank"
import WestEnd from "./Sections/WestEnd"

import { useStore } from "@state/store"
import TransportIcons from "./Sections/TransportIcons"
import globalStyles from "@data/globalStyles"

function Map({ isAnimated }: { isAnimated: boolean }) {
  const isMobile = useStore((s) => s.isMobile)
  const zoomed = useStore(s => s.mapZoomed)
  const setZoomed = useStore(s => s.setMapZoomed)
  const svgRef = useRef<SVGSVGElement>()

  const [svgAnm, svgAPI] = useSpring(() => ({
    viewBox: zoomed ? "1120 540 335 335" : "0 0 1920 1080",
  }))

  useEffect(() => {
    console.log("zoomed", zoomed, isAnimated)
    if (isAnimated) {
      svgAPI.start({
        viewBox: zoomed ? "1120 540 335 335" : "0 0 1920 1080",
      })
    }
  }, [zoomed])

  return (
    <MapWrapper $ismobile={isMobile}>
      <SVG
        ref={svgRef}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        height="100%"
        width="100%"
        viewBox={svgAnm.viewBox}
        $ismobile={isMobile}
      >
        <WestEnd cue={isAnimated ? zoomed : true} />
        <MidTown cue={isAnimated ? zoomed : true} />
        <City cue={isAnimated ? zoomed : true} />
        <River cue={isAnimated ? zoomed : true} />
        <SouthBank
          cue={isAnimated ? zoomed : true}
          onClick={() => {
            setZoomed(true)
          }}
          style={{
            cursor: zoomed ? "initial" : "pointer"
          }}
        />
        <Detail cue={isAnimated ? zoomed : true} />
        <TransportIcons cue={isAnimated ? zoomed : true} />
        <Roads cue={isAnimated ? zoomed : true} />
        <Labels cue={isAnimated ? zoomed : true} />
        <Sites cue={isAnimated ? zoomed : true} />
      </SVG>
    </MapWrapper>
  )
}

export default Map

const MapWrapper = styled.div<{ $ismobile: boolean }>`
  position: absolute;
  aspect-ratio: 16/9;
  height: ${(p) => (p.$ismobile ? "100%" : "100%")};
  top: ${(p) => (p.$ismobile ? "0%" : "50%")};
  left: ${(p) => (p.$ismobile ? "0%" : "50%")};
  transform: ${(p) =>
    p.$ismobile ? "translate(0%, 0%)" : "translate(-50%, -50%)"};
  touch-action: ${(p) => (p.$ismobile ? "pan-x" : "none")};
  background-color: #aab9ba;
`

const SVG = styled(a.svg)<{ $ismobile: boolean }>`
  touch-action: ${(p) => (p.$ismobile ? "pan-x" : "none")};
  & g {
    transition: opacity 0.8s ease-in;
  }

  & * {
    touch-action: ${(p) => (p.$ismobile ? "pan-x" : "none")};
  }

  .pin {
    transform-origin: 50% 100%;
    transform-box: fill-box;
  }
`
