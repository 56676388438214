/**
 * I am returning this so you can use it outside async functions
 * 
 * @param onSuccess : Function to be used if promise resolve succesfully
 * @param onFail : Function to be used if promise is rejected
 * @returns
 */

async function verify(onSuccess, onFail) {
  try {
    const token = sessionStorage.getItem("landsecTkn");
    const request = await fetch(
      "https://api.lunaplatform.app/api/auth/landsec-verify",
      {
        credentials: "include",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": "true",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
          "x-guest": "true",
          "x-client-id": "landsec",
          "x-auth-token": token,
        },
      }
    )

    const response = await request.json()
    if (response.ok) {
      onSuccess()
    } else {
      onFail()
    }
  } catch (err) {
    return
  }
}

export default verify
