import styled from "styled-components"
import { useNavigate } from "react-router-dom"

import { useStore } from "@state/store"
import globalStyles from "@data/globalStyles"
import verify from "@utils/verify"

function TransportIcons({ cue }) {
  return (
    <TransportIconsWrapper
      style={{
        opacity: cue ? 1 : 0,
        pointerEvents: cue ? "auto" : "none",
        transitionDelay: cue ? "0.8s" : "0s",
      }}
    >
      <g>
        <g>
          <circle
            fill="none"
            stroke="#EB1E1D"
            strokeWidth="1.0521"
            strokeMiterlimit="10"
            cx="1276.4"
            cy="780.9"
            r="2.1"
          />
          <rect x="1273.5" y="780.3" fill="#28319E" width="5.9" height="1.2" />
        </g>
      </g>
      <g>
        <g>
          <circle
            fill="none"
            stroke="#EB1E1D"
            strokeWidth="1.0521"
            strokeMiterlimit="10"
            cx="1148.5"
            cy="798.3"
            r="2.1"
          />
          <rect x="1145.6" y="797.7" fill="#28319E" width="5.9" height="1.2" />
        </g>
      </g>
      <g>
        <g>
          <circle
            fill="none"
            stroke="#EB1E1D"
            strokeWidth="1.0521"
            strokeMiterlimit="10"
            cx="1444.8"
            cy="860.4"
            r="2.1"
          />
          <rect x="1441.8" y="859.8" fill="#28319E" width="5.9" height="1.2" />
        </g>
      </g>
      <g>
        <g>
          <circle
            fill="none"
            stroke="#EB1E1D"
            strokeWidth="1.0521"
            strokeMiterlimit="10"
            cx="1541.8"
            cy="736.7"
            r="2.1"
          />
          <rect x="1538.9" y="736.1" fill="#28319E" width="5.9" height="1.2" />
        </g>
      </g>
      <g>
        <g>
          <circle
            fill="none"
            stroke="#EB1E1D"
            strokeWidth="1.0521"
            strokeMiterlimit="10"
            cx="1561.3"
            cy="600.6"
            r="2.1"
          />
          <rect x="1558.4" y="600" fill="#28319E" width="5.9" height="1.2" />
        </g>
      </g>
      <g>
        <g>
          <circle
            fill="none"
            stroke="#EB1E1D"
            strokeWidth="1.0521"
            strokeMiterlimit="10"
            cx="1518.5"
            cy="544.5"
            r="2.1"
          />
          <rect x="1515.5" y="544" fill="#28319E" width="5.9" height="1.2" />
        </g>
      </g>
      <g>
        <g>
          <circle
            fill="none"
            stroke="#EB1E1D"
            strokeWidth="1.0521"
            strokeMiterlimit="10"
            cx="1493"
            cy="594.9"
            r="2.1"
          />
          <rect x="1490.1" y="594.3" fill="#28319E" width="5.9" height="1.2" />
        </g>
      </g>
      <g>
        <g>
          <circle
            fill="none"
            stroke="#EB1E1D"
            strokeWidth="1.0521"
            strokeMiterlimit="10"
            cx="1135.2"
            cy="558.9"
            r="2.1"
          />
          <rect x="1132.2" y="558.3" fill="#28319E" width="5.9" height="1.2" />
        </g>
      </g>
      <g>
        <polygon
          fill="#FF2424"
          points="1223.6,773.1 1221.1,773.1 1222.5,774.1 1221.3,774.1 1219.9,773.1 1218.3,773.1 1218.3,772.4 
			1219.9,772.4 1220.7,771.8 1218.2,771.8 1218.2,771.1 1220.7,771.1 1219.2,770.1 1220.5,770.1 1222,771.1 1223.6,771.1 
			1223.6,771.8 1221.9,771.8 1221.1,772.4 1223.6,772.4 		"
        />
      </g>
      <g
        style={{
          transform: "translate(330px, -35.5px)",
        }}
        id="test"
      >
        <polygon
          fill="#FF2424"
          points="1223.6,773.1 1221.1,773.1 1222.5,774.1 1221.3,774.1 1219.9,773.1 1218.3,773.1 1218.3,772.4 
			1219.9,772.4 1220.7,771.8 1218.2,771.8 1218.2,771.1 1220.7,771.1 1219.2,770.1 1220.5,770.1 1222,771.1 1223.6,771.1 
			1223.6,771.8 1221.9,771.8 1221.1,772.4 1223.6,772.4 		"
        />
      </g>
      <g>
        <polygon
          fill="#FF2424"
          points="1504.5,595.8 1502,595.8 1503.4,596.8 1502.2,596.8 1500.8,595.8 1499.2,595.8 1499.2,595.1 
			1500.8,595.1 1501.6,594.6 1499.2,594.6 1499.2,593.9 1501.6,593.9 1500.1,592.9 1501.4,592.9 1502.9,593.9 1504.5,593.9 
			1504.5,594.6 1502.9,594.6 1502,595.1 1504.5,595.1 		"
        />
      </g>

      <g>
        <g>
          <circle
            fill="none"
            stroke="#EB1E1D"
            strokeWidth="1.0521"
            strokeMiterlimit="10"
            cx="1450"
            cy="580"
            r="2.1"
          />
          <rect x="1447" y="579.5" fill="#28319E" width="5.9" height="1.2" />
          <text
            x={1428}
            y={592}
            fill={globalStyles.colors.white}
            style={{
              fontFamily: "Bahnschrift",
              fontWeight: 100,
              fontSize: 7
            }}
          >
            Mansion House
          </text>
        </g>

        <g transform="translate(5, 80)">
          <g>
            <circle
              fill="none"
              stroke="#EB1E1D"
              strokeWidth="1.0521"
              strokeMiterlimit="10"
              cx="1298.3"
              cy="572.6"
              r="2.1"
            />
            <rect x="1295.4" y="572" fill="#28319E" width="5.9" height="1.2" />
          </g>
          <g>
            <polygon
              fill="#FF2424"
              points="1309.8,573.6 1307.3,573.6 1308.7,574.5 1307.5,574.5 1306.1,573.6 1304.5,573.6 1304.5,572.9 
			1306.1,572.9 1306.9,572.3 1304.5,572.3 1304.5,571.6 1306.9,571.6 1305.4,570.6 1306.7,570.6 1308.2,571.6 1309.8,571.6 
			1309.8,572.3 1308.2,572.3 1307.3,572.9 1309.8,572.9 		"
            />
          </g>
          <g>
            <polygon
              fill="#FF2424"
              points="1160.8,799.3 1158.3,799.3 1159.7,800.3 1158.5,800.3 1157.1,799.3 1155.5,799.3 1155.5,798.6 
			1157.1,798.6 1157.9,798 1155.4,798 1155.4,797.3 1157.9,797.3 1156.4,796.4 1157.7,796.4 1159.2,797.3 1160.8,797.3 1160.8,798 
			1159.1,798 1158.3,798.6 1160.8,798.6 		"
            />
          </g>
        </g>
        <g>
          <g>
            <circle
              fill="none"
              stroke="#EB1E1D"
              strokeWidth="1.0521"
              strokeMiterlimit="10"
              cx="1298.3"
              cy="572.6"
              r="2.1"
            />
            <rect x="1295.4" y="572" fill="#28319E" width="5.9" height="1.2" />
          </g>
          <g>
            <polygon
              fill="#FF2424"
              points="1309.8,573.6 1307.3,573.6 1308.7,574.5 1307.5,574.5 1306.1,573.6 1304.5,573.6 1304.5,572.9 
			1306.1,572.9 1306.9,572.3 1304.5,572.3 1304.5,571.6 1306.9,571.6 1305.4,570.6 1306.7,570.6 1308.2,571.6 1309.8,571.6 
			1309.8,572.3 1308.2,572.3 1307.3,572.9 1309.8,572.9 		"
            />
          </g>
          <g>
            <polygon
              fill="#FF2424"
              points="1160.8,799.3 1158.3,799.3 1159.7,800.3 1158.5,800.3 1157.1,799.3 1155.5,799.3 1155.5,798.6 
			1157.1,798.6 1157.9,798 1155.4,798 1155.4,797.3 1157.9,797.3 1156.4,796.4 1157.7,796.4 1159.2,797.3 1160.8,797.3 1160.8,798 
			1159.1,798 1158.3,798.6 1160.8,798.6 		"
            />
          </g>
        </g>
      </g>
    </TransportIconsWrapper>
  )
}

export default TransportIcons

const TransportIconsWrapper = styled.g`
  & circle,
  rect,
  polygon {
    transform-origin: center;
    transform-box: fill-box;
    transform: scale(1.5);
  }
`
