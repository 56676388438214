import styled from "styled-components"

import globalStyles from "@data/globalStyles"
import { useStore } from "@state/store"
import { Fragment } from "react"

function CopyLayout({ data }) {
  const activeSite = useStore((s) => s.activeSite)
  const isMobile = useStore((s) => s.isMobile)
  const figures = data.headerData
  const { copyData } = data

  return (
    <LayoutCopyWrapper ismobile={isMobile}>
      <HeaderImg
        src={copyData.headerImage}
        alt=""
        ismobile={isMobile}
        id="headerImg"
      />
      <HeaderTitle ismobile={isMobile} id="headerTitle">
        {data.name[0]}
        <br /> {data.name[1]}
      </HeaderTitle>
      {copyData.subtitle && (
        <SubHeaderTitle ismobile={isMobile} id="subtitle">
          {copyData.subtitle}
        </SubHeaderTitle>
      )}
      <Figures ismobile={isMobile} id="figuresOne">
        <h6>
          SQFT :
          <br />
          <span>{figures.sqf}</span>
        </h6>
        <h6>
          STATUS :
          <br />
          <span>{figures.status}</span>
        </h6>
        <h6>
          {activeSite === 0 ? "GROSS VALUE :" : "GDV at Stab :"}
          <br />
          <span>{figures.gdv}</span>
        </h6>
        <h6>
          TYPICAL FLOOR SIZE :
          <br />
          <span>{figures.floor}</span>
        </h6>
      </Figures>
      <Figures ismobile={isMobile} id="figuresTwo">
        {figures.tdc === null ? (
          <h6>
            LET OR UNDER OFFER :
            <br />
            <span>{figures.usage}</span>
          </h6>
        ) : (
          <h6>
            TDC :
            <br />
            <span>{figures.tdc}</span>
          </h6>
        )}
        <h6>
          YoC at Stabilisation :
          <br />
          <span>{figures.yield}</span>
        </h6>
        <h6>
          TARGET CARBON :
          <br />
          <span>{figures.carbon}</span>
        </h6>
      </Figures>
      <Figures ismobile={isMobile} id="figuresTwo">
        {figures.certifications.map((obj, i) => {
          return (
            <Certification key={`cert${i}`} ismobile={isMobile}>
              <img alt="" src={obj.src} />
              <h6>{obj.caption}</h6>
            </Certification>
          )
        })}
      </Figures>
      <SecondImg
        src={copyData.copyImage}
        alt=""
        ismobile={isMobile}
        id="copyImg"
      />
      {copyData.copy && (
        <Copy ismobile={isMobile} id="copy">
          {copyData.copy.map((ln, i) => {
            return (
              <Fragment key={`ln${i}`}>
                {ln}
                <br />
              </Fragment>
            )
          })}
        </Copy>
      )}
      <DataTitle ismobile={isMobile} id="flexTitle">
        <h1>{copyData.flexTitle}</h1>
      </DataTitle>
      <Data ismobile={isMobile} id="flex">
        {copyData.flex.map((ln, i) => {
          return (
            <div
              key={`fd${i}`}
              style={{
                backgroundImage: `url(${data.gallery[i]})`,
              }}
            >
              <h6>{ln}</h6>
            </div>
          )
        })}
      </Data>
    </LayoutCopyWrapper>
  )
}

export default CopyLayout

type Tmobile = {
  ismobile: boolean
}

const LayoutCopyWrapper = styled.div<Tmobile>`
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const HeaderImg = styled.img<Tmobile>`
  position: relative;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  touch-action: pan-y;
  max-width: ${(p) => (p.ismobile ? "100%" : "65%")};
  height: ${(p) => (p.ismobile ? "auto" : "80vh")};
  object-fit: cover;
  opacity: 0.9;
`

const HeaderTitle = styled.h1<Tmobile>`
  position: relative;
  left: 50%;
  transform: ${(p) =>
    p.ismobile ? "translate(-50%, -50%)" : "translate(-50%, -50%)"};
  color: ${globalStyles.colors.white};
  text-align: center;
  font-family: main-bold;
  font-size: ${(p) => (p.ismobile ? "28rem" : "15rem")};
  touch-action: pan-y;
`

const SubHeaderTitle = styled.h3<Tmobile>`
  position: relative;
  width: 80%;
  left: 50%;
  transform: translate(-50%, 0%);
  color: ${globalStyles.colors.blue};
  text-align: center;
  font-family: main;
  font-size: ${(p) => (p.ismobile ? "12rem" : "4rem")};
  margin-bottom: ${(p) => (p.ismobile ? "10%" : "12%")};
  touch-action: pan-y;
  text-transform: uppercase;
`
const SecondImg = styled.img<Tmobile>`
  position: relative;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: ${(p) => (p.ismobile ? "30%" : "auto")};
  margin-bottom: ${(p) => (p.ismobile ? "15%" : "10%")};
  touch-action: pan-y;
  object-fit: cover;
`

const Copy = styled.p<Tmobile>`
  height: 8%;
  position: relative;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  color: ${globalStyles.colors.white}99;
  text-align: center;
  font-family: copy;
  font-size: ${(p) => (p.ismobile ? "12rem" : "2.5rem")};
  line-height: ${(p) => (p.ismobile ? "18rem" : "2.8rem")};
  font-weight: 100;
  margin-bottom: ${(p) => (p.ismobile ? "12%" : "15%")};
  touch-action: pan-y;
`

const DataTitle = styled.div<Tmobile>`
  position: relative;
  left: 50%;
  height: 18%;
  width: 100%;
  transform: translate(-50%, 0%);
  color: ${globalStyles.colors.white};
  background-color: ${globalStyles.colors.darkBlue};
  font-size: ${(p) => (p.ismobile ? "17rem" : "6rem")};
  margin-bottom: ${(p) => (p.ismobile ? "0%" : "15%")};
  touch-action: pan-y;
  padding: 5% 0%;

  & h1 {
    font-size: 20rem;
    line-height: 25rem;
    text-transform: uppercase;
    font-family: main;
    text-align: center;
    width: 60%;
    margin-left: 20%;
    touch-action: pan-y;
  }
`

const Data = styled.div<Tmobile>`
  position: relative;
  width: 100%;
  height: ${(p) => (p.ismobile ? "200vh" : "60vh")};
  color: ${globalStyles.colors.white};
  text-align: center;
  font-family: main;
  font-size: ${(p) => (p.ismobile ? "14rem" : "2.5rem")};
  line-height: ${(p) => (p.ismobile ? "15rem" : "2.5rem")};
  display: flex;
  flex-direction: ${(p) => (p.ismobile ? "column" : "row")};
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  touch-action: pan-y;
  margin-bottom: 5%;

  & div {
    width: ${(p) => (p.ismobile ? "100%" : "calc(100% / 3)")};
    height: ${(p) => (p.ismobile ? "25%" : "100%")};
    touch-action: pan-y;
    font-size: ${(p) => (p.ismobile ? "16rem" : "2rem")};
    letter-spacing: 0.15rem;
    word-spacing: 0.2rem;
    background-color: ${globalStyles.colors.blue};
    background-size: cover;

    & h6 {
      background-color: ${globalStyles.colors.black}B3;
      padding: 15%;
      display: grid;
      place-content: center;
      width: 100%;
      height: 100%;
      font-family: copy;
      font-size: ${(p) => (p.ismobile ? "1em" : "1.2em")};
      line-height: ${(p) => (p.ismobile ? "1em" : "1.2em")};
    }
  }
`

const Figures = styled.div<Tmobile>`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  gap: 5%;
  margin-bottom: ${(p) => (p.ismobile ? "5%" : "3%")};

  :nth-of-type(3) {
    margin-bottom: ${(p) => (p.ismobile ? "15%" : "7%")};
    align-items: flex-start;
  }

  & h6 {
    font-size: ${(p) => (p.ismobile ? "6em" : "1.5em")};
    font-weight: 100;
    text-align: center;
    line-height: 1.5em;
    color: ${globalStyles.colors.blue};

    & span {
      font-size: 1.5em;
      color: ${globalStyles.colors.white};
      font-family: copy;

      & sub {
        font-size: 0.6em;
        letter-spacing: 0.2em;
      }
    }
  }
`

const Certification = styled.div<Tmobile>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5rem;

  & img {
    position: relative;
    width: ${p => p.ismobile ? "30rem" : "10rem"};
    aspect-ratio: 1/1;
  }

`