import { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import ReactPlayer from "react-player"

import MuteIcon from "@components/SVG/MuteIcon/MuteIcon"

import globalStyles from "@data/globalStyles"
import { useStore } from "@state/store"

function VideoElement({
  url,
  onClick = undefined,
  onEnded = undefined,
  prompt = undefined,
  loop = true,
  ...props
}) {
  const isMobile = useStore((s) => s.isMobile)
  const [mute, setMute] = useState(true)
  const wrapperRef = useRef<any>()
  return (
    <VideoWrapper
      onClick={() => {
        if (onClick) {
          wrapperRef.current.style.opacity = "0"
          setTimeout(() => {
            onClick()
          }, 850)
        }
      }}
      ref={(node) => {
        if (node) {
          setTimeout(() => {
            node.style.opacity = "1"
          }, 100)
          wrapperRef.current = node
        }
      }}
      {...props}
    >
      <ReactPlayer
        url={url}
        // url="/assets/videos/bridge_1.mp4"
        playsinline={true}
        controls={false}
        playing={true}
        loop={loop}
        muted={mute}
        width="100%"
        height="100%"
        onEnded={() => {
          if (onEnded) {
            if (!loop) {
              wrapperRef.current.style.opacity = "0"
              setTimeout(() => {
                onEnded()
              }, 850)
            }else{
              onEnded()
            }
          }
        }}
        config={{
          file: { attributes: { poster: "noposter" } },
        }}
      />
      <MuteWrapper
        style={{
          width: isMobile ? "25rem" : "5rem",
          top: isMobile ? "5rem" : "2rem",
          right: isMobile ? "5rem" : "2rem",
        }}
      >
        <MuteIcon
          color={globalStyles.colors.white}
          strokeColor={globalStyles.colors.white}
          strokeWidth={1}
          onClick={() => {
            setMute(!mute)
          }}
        />
      </MuteWrapper>
      {prompt && (
        <Prompt
          style={{
            fontSize: isMobile ? "16rem" : "2rem",
          }}
        >
          {prompt}
        </Prompt>
      )}
    </VideoWrapper>
  )
}

export default VideoElement

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  background-color: ${globalStyles.colors.black};

  & video {
    object-fit: cover;
    opacity: 0;
    animation: fadeIn 800ms ease-in forwards;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

const MuteWrapper = styled.div`
  position: absolute;
  z-index: 12;
  mix-blend-mode: exclusion;
  pointer-events: none;
`

const Prompt = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 2%;
  right: 2%;
  color: ${globalStyles.colors.blue};
  font-size: 2rem;
  mix-blend-mode: exclusion;
`
